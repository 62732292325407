import { Service } from "../../models/service";
import { ServiceActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface serviceState {
  services: Service[];
};

export const initialServiceState = {
  services: []
};

const serviceReducer = (state: serviceState = initialServiceState, action: Action) => {
  if (action && action.payload) {
    const { services } = action.payload;
    switch (action.type) {
      case ServiceActions.UPDATE:
        return {
          ...state,
          services: services
        };
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default serviceReducer;
