const myColors = {

    fontColor: "#000",
    inverseFontColor: "#000",

    subtitleFontColor: "#757575",

    primaryColor: "#C7EBD0",
    secondaryColor: "#C7EBD0",
    

    errorColor: "#cc2424",
    warnColor: "#ff8d24",
    successColor: "#00c853",

    primaryLayoutColor: "#fafafa",
    secondaryLayoutColor: "#ffffff",
    inverseLayoutColor: "#303030",

    borderColor: "#e8e8e8",
}

export default myColors;
