import { createStyles, makeStyles, Theme } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            height: '100%',
            padding: 20,
        },
        data: {
            fontSize: '12px',
            textTransform: "capitalize",
            overflowWrap: 'anywhere',
        },
        dataHead: {
            fontSize: '13px',
            fontWeight: 'bold',
            textTransform: "capitalize",
            marginBottom: 2,
        }
    }),
);

export default useStyles;