import React, { useCallback, useRef, useState } from 'react';
import { IconButton, Typography, CircularProgress, Grid, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import './GalleryStyles.sass';
import FileUploadForm from '../../../../shared/FileUploadForm/FileUploadForm';
import vendorApi from '../../../../core/api/vendor-api';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../core/store';
import { ReactComponent as DeleteIcon } from '../../../../img/icons/trash.svg'
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import WarningButton from '../../../../shared/Button/WarningButton';
import SuccessButton from '../../../../shared/Button/SuccessButton';

const Gallery = () => {
  // Constants
  const MAX_IMAGES = 9;
  // Component State
  const [isUploading, setIsUploading] = useState(false)
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [deletingImg, setDeletingImg] = useState(null)
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [cropOpen, setCropOpen] = useState<boolean>(false);
  const [upImg, setUpImg] = useState<string>('');
  const [crop, setCrop] = useState<Crop>(
    {
      unit: 'px',
      x: 75,
      y: 75,
      aspect: 1 / 1,
      width: 150,
      height: 150
    }
  )
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  // Redux State
  const user = useSelector((state: IRootState) => state.vendorReducer.vendorUser)

  const onChangeHandler = async (files: any) => {
    setSelectedImageFiles(files);
    const reader = new FileReader();
    reader.addEventListener('load', () => setUpImg(reader.result as string));
    reader.readAsDataURL(files[files.length - 1]);

    setCropOpen(true)
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const uploadSelectedImage = async (value: any) => {
    if (!value || value?.length === 0) return;

    try {
      setIsUploading(true);
      await vendorApi.uploadImageToGallery(value[value.length - 1], selectedImageFiles[selectedImageFiles.length - 1].name);

      setSelectedImageFiles([]);
      setTimeout(() => {
        setIsUploading(false);
      }, 1000);
    } catch (e) {
      setSelectedImageFiles([]);
      setIsUploading(false);
    }
  }

  const handleCropSubmit = () => {
    if (completedCrop && imgRef && imgRef.current) {
      const image = imgRef.current;
      var canvas = document.createElement('canvas')
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = 'high';

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );
        }
        canvas.toBlob(
          (blob) => {
            uploadSelectedImage([blob])
          },
          'image/png',
          1
        );

      }
    }

    setCropOpen(false)
    setCrop({ unit: '%', width: 30, aspect: 1 / 1, height: 50, x: 25, y: 25 })
    setCompletedCrop(null)
  }

  const handleCropClose = () => {
    setCropOpen(false)
    setCompletedCrop(null)
    setSelectedImageFiles([]);
    setIsUploading(false);
  }

  const cropDialog = (
    < Dialog
      open={cropOpen}
      onClose={handleCropClose}
    >
      {/* <DialogTitle >{"Update User Status"}</DialogTitle> */}
      <DialogContent>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
      </DialogContent>
      <DialogActions style={{ display: 'flex', alignSelf: 'center' }}>
        <WarningButton onClick={handleCropClose} color="primary">
          Cancel
        </WarningButton>
        <SuccessButton onClick={handleCropSubmit} color="primary" autoFocus disabled={!completedCrop?.width || !completedCrop?.height}>
          Submit
        </SuccessButton>
      </DialogActions>
    </Dialog >
  )

  const deleteImage = async (imgUrl: any) => {
    try {
      setDeletingImg(imgUrl);
      setIsDeleting(true);
      await vendorApi.deleteGalleryImage(imgUrl);

      setTimeout(() => {
        setIsDeleting(false);
      }, 1000);
    } catch (e) { }
  }

  return (
    <div className="gallery-component-container">
      <Typography variant="h6" noWrap className="main-title">
        Gallery
      </Typography>
      <p>
        Upload photos of your best work
      </p>
      <p>
        ({(user && user?.length > 0) ? user[0].image_url.length : 0} out of {MAX_IMAGES} uploaded)
      </p>

      <div className="gallery-images-container">
        <Grid
          container
          direction="row"
        >
          {(user && user?.length > 0 && user[0]?.image_url?.length < MAX_IMAGES) ? (
            <div className="gallery-image-item">
              <FileUploadForm
                files={selectedImageFiles}
                onChange={onChangeHandler}
                isUploading={isUploading}
              />
            </div>
          ) : null}
          {(user && user?.length > 0) ? user[0].image_url?.map((img: any, i: any) => (
            <div className="gallery-image-item" key={`${img}-${i}`}>
              <img src={img} alt="galleryImg" />
              <div className="delete-icon-container">
                <IconButton
                  size="small"
                  onClick={() => deleteImage(img)}
                >
                  {isDeleting && img === deletingImg ? <CircularProgress size={15} /> : <DeleteIcon fontSize="small" />}
                </IconButton>
              </div>
            </div>
          )) : null}
        </Grid>
      </div>
      {cropDialog}
    </div>
  )
}

export default Gallery;