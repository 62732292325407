import React, { useState, useEffect } from 'react'
import { Avatar, Badge, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create';
import location from '../../../../img/map.png'
import useStyles from './CategoryImageStyles'
import './CategoryImageStyles.sass'
import FileUploadForm from '../../../../shared/FileUploadForm/FileUploadForm';
import Button from '../../../../shared/Button/Button';
// import { Suite } from '../../../../models/category';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Category } from '../../../../models/category';
import WarningButton from '../../../../shared/Button/WarningButton';
import SuccessButton from '../../../../shared/Button/SuccessButton';

interface IProps {
  category?: Category | null
  isUploading: boolean;
  selectedImageFiles: any[];
  setSelectedImageFiles: Function
  setEditItem? : any;
}

interface BlobV2 extends Blob {
  name?: string
  url?: string
}

const CategoryImage = (props: IProps) => {
  const style = useStyles()

  const [locationPictureUrl, setLocationPictureUrl] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop>(
    {
      unit: 'px',
      x: 75,
      y: 75,
      aspect: 1 / 1,
      width: 150,
      height: 150
    }
  )
  const [imgRef, setimgRef] = useState<any>('');
  const [croppedImageUrl, setcroppedImageUrl] = useState<any>();
  const [imgFile, setImgFile] = useState<Category>();
  const [cropOpen, setCropOpen] = useState<boolean>(false);
  let imgReff = "";

  useEffect(() => {
    if (props.category) {      
      setLocationPictureUrl(props.category.image_url || '');
    }
    if(croppedImageUrl && croppedImageUrl !== location){
    }

  }, [props.category]);


  const onChangeHandler = async (files: any) => {
    setImgFile(files);
    props.setSelectedImageFiles(files);
    setCropOpen(true)
  }

  useEffect(() => {
    if (props.selectedImageFiles?.length > 0) {
      let url: string = URL.createObjectURL(props.selectedImageFiles[props.selectedImageFiles.length - 1])
      setLocationPictureUrl(url);
      
      if(props.category){
      props.setEditItem((prevState : any) => (prevState && {
          ...prevState,
          image_url: url
      }));
      };

    }
  }, [props.selectedImageFiles])

  const onImageLoaded = (image: any) => {
    setimgRef(image);
    imgReff = image;
  };

  const onCropComplete = (crop: Crop) => {
    makeClientCrop(crop);
  };


  useEffect(() => {
    if (imgFile !== undefined) {

      imgFile!.image_url = croppedImageUrl;
      props.setSelectedImageFiles(imgFile);

    }
  }, [(croppedImageUrl)])


  const onCropChange = (crop: Crop, percentCrop: Crop) => {
    setCrop(crop);
  };


  const makeClientCrop = async (crop: any) => {
    debugger
    if ((imgReff || imgRef) && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imgRef !== "" ? imgRef : imgReff,
        crop,
        'newFile.jpeg'
      );
      setcroppedImageUrl(croppedImageUrl);
    }
  }


  const getCroppedImg = (image: any, crop: Crop, fileName: any) => {
    debugger
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (ctx !== null) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {

          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          else {
            // blog
            const _blob: BlobV2 = { ...blob };
            _blob.name = fileName;
            // creating a Object URL representing the Blob object given
            const croppedImageUrl = window.URL.createObjectURL(blob);

            resolve(croppedImageUrl);
          }
        },
        'image/jpeg',
        1,
      );
    });
  }

  const handleCropSubmit = async() => {
    if ((imgReff || imgRef) && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imgRef !== "" ? imgRef : imgReff,
        crop,
        'newFile.jpeg'
      );
      setcroppedImageUrl(croppedImageUrl);
    }

    setCropOpen(false);
    setIsEditMode(false);
  }

  const handleCropClose = () => {
    setCropOpen(false)
     setLocationPictureUrl(croppedImageUrl? croppedImageUrl : location);
     setIsEditMode(false);
  }


  const cropDialog = (
    < Dialog
      open={cropOpen}
      onClose={handleCropClose}
    >
      <DialogContent>
        <ReactCrop
          src={locationPictureUrl !== '' ? locationPictureUrl : location}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
          className={style.avatarRootCrop}

        />
      </DialogContent>
      <DialogActions style={{ display: 'flex', alignSelf: 'center' }}>
        <WarningButton onClick={handleCropClose} color="primary">
          Cancel
        </WarningButton>
        <SuccessButton onClick={handleCropSubmit} color="primary" autoFocus>
          Submit
        </SuccessButton>
      </DialogActions>
    </Dialog >
  );

  if (isEditMode) {
    return (
      <div className="location-picture-component-file-upload-container">
        <FileUploadForm
          files={props.selectedImageFiles}
          onChange={onChangeHandler}
          isUploading={props.isUploading}
        />
        {props.isUploading || (
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setIsEditMode(false)}
          >
            Cancel
          </Button>
        )}
      {cropDialog}
      </div>
    );
  }

  return (
    <div className="location-picture-component-container">
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <IconButton onClick={() => setIsEditMode(true)}>
            <CreateIcon className={style.root} />
          </IconButton>
        }
      >
           <Avatar classes={{ root: style.avatarRoot }} alt="Location Picture" src={props.selectedImageFiles?.length === 0 ? 
           (locationPictureUrl ? locationPictureUrl : location) 
           : (croppedImageUrl === location ? locationPictureUrl : ( croppedImageUrl !== '' ? croppedImageUrl : location) )} />
      </Badge>
    </div>
  )
}

export default CategoryImage