import { createStyles, makeStyles, Theme } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "10px 14px"
        },
        label: {
            transform: 'translate(14px, 12px) scale(1)',

        },
        icon: {
            top: "calc(50% - 9px)"
        }
    }),
);

export default useStyles;