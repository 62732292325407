import React, { useEffect, useState } from 'react'
import useStyles from './StatsViewStyles'
import { Grid, Button, Hidden, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import dashboardApi from '../../../core/api/dashboard-api';
import store, { IRootState } from '../../../core/store';
import { useSelector } from 'react-redux';
import time from '../../../core/services/time';
import DateTimePicker from '../../../shared/DateTimePicker/DateTimePicker';
import { Employee } from '../../../models/employee';
import { Customer } from '../../../models/customer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CustomMultiSelectAutocomplete from '../../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete';
import NumberOfBookings from './NumberOfBookings';
import SumOfRevenue from './SumOfRevenue';
import PricePerUnit from './PricePerUnit';
import OccupancyRate from './OccupancyRate';
import CountOfBookingsByCategories from './CountOfBookingsByCategory';
import RevenueByCategory from './RevenueByCategory';
import CountOfBookingsOverTime from './CountOfBookingsOverTime';
import RevenueOverTime from './RevenueOverTime';
import BooingByVendors from './BooingByVendors';
import { Category } from '../../../models/category';
import moment, { Moment } from 'moment'
import CustomDateRangePicker from '../../../shared/DateRangePicker/DateRangePicker';

const barOptions = {
    scales: {
        yAxis: {
            beginAtZero: true,
            gridLines: {
                drawBorder: false,
            }
        },
        x: {
            grid: {
                color: 'white',
                tickColor: 'grey'
            }
        }

    },
    plugins: {
        legend: {
            display: false
        }
    }
};

const options = {
    plugins: {
        legend: {
            position: "bottom",
            align: 'start'
        },
    }
};

const theme = ['#053742', '#39A2DB', '#A2DBFA', '#E8F0F2']

export default function StatsView() {
    const classes = useStyles()
    const { userRole } = useSelector((state: IRootState) => state.userReducer)
    const AllCategories: Category[] = useSelector((state: IRootState) => state.filterReducer.activeCategories)

    const AllEmployees: Employee[] = useSelector((state: IRootState) => state.filterReducer.employees)
    const AllCustomers: Customer[] = useSelector((state: IRootState) => state.filterReducer.customers)

    const [loading1, setloading1] = useState<boolean>(true)
    const [loading2, setloading2] = useState<boolean>(true)
    const [loading3, setloading3] = useState<boolean>(true)

    const [startDate, setstartDate] = useState<Date | null>(time.getLastYear());
    const [endDate, setendDate] = useState<Date | null>(time.getToday());
    const [selectDate, setSelectDate] = React.useState<[Moment, Moment] | null>([moment(time.getLastYear()), moment(time.getToday())]);
    const [filterBy, setFilterBy] = useState<string>('month');
    const [filterByForRev, setFilterByForRev] = useState<string>('month');

    const [categoryFilter, setCategoryFilter] = useState<string[]>([])
    const [empFilter, setempFilter] = useState<string[]>([])
    const [cusFilter, setcusFilter] = useState<string[]>([])

    const [empName, setempName] = useState<string[]>([])
    const [selectedCategories, setSelectedCategories] = useState<string[]>([])
    const [cusName, setcusName] = useState<string[]>([])

    const [clearFilters, setClearFilters] = useState<boolean>(false)

    const fetchData = async () => {
        setloading1(true)
        setClearFilters(false)
        const { categoryFilterData, empFilterData, cusFilterData, start, end } = getFilterValues()

        if (start && end) {
            try {
                await dashboardApi.getCountOfBookings(start, end, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }

            try {
                await dashboardApi.getSumOfRevenue(start, end, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }

            try {
                await dashboardApi.getPricePerUnit(start, end, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }

            // occuring an error because of the below api
            try {
                await dashboardApi.getOccupancyRate(start, end, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }

            try {
                await dashboardApi.getCountOfBookingsByServices(start, end, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }

            try {
                await dashboardApi.getRevenueByServices(start, end, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }

            if (!isVendor()) {
                try {
                    await dashboardApi.getBookingsByVendors(start, end, cusFilterData, categoryFilterData, empFilterData)
                } catch (error) { }
            }
        }
        setloading1(false)
    }

    const fetchCountOfBookings = async () => {
        setloading2(true)
        setClearFilters(false)
        const { categoryFilterData, empFilterData, cusFilterData, start, end } = getFilterValues()

        if (start && end) {
            try {
                await dashboardApi.getCountOfBookingsOverTime(start, end, filterBy, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }
        }
        setloading2(false)
    }

    const fetchRevOverTime = async () => {
        setloading3(true)
        setClearFilters(false)
        const { categoryFilterData, empFilterData, cusFilterData, start, end } = getFilterValues()

        if (start && end) {
            try {
                await dashboardApi.getRevenueOverTime(start, end, filterByForRev, cusFilterData, categoryFilterData, empFilterData)
            } catch (error) { }
        }
        setloading3(false)
    }


    const setFilter = () => {
        let categoryFilterData: string[] = []
        let empFilterData: string[] = []
        let cusFilterData: string[] = []

        AllCategories.map((item) => {
            categoryFilterData.push(item.name)
        })

        AllEmployees.map((item) => {
            empFilterData.push(item.full_name)
        })

        AllCustomers.map((item) => {
            cusFilterData.push(item.email)
        })

        empFilterData = [...new Set(empFilterData)]
        cusFilterData = [...new Set(cusFilterData)]
        categoryFilterData = [...new Set(categoryFilterData)]
        categoryFilterData.sort()
        empFilterData.sort()
        cusFilterData.sort()

        setCategoryFilter(categoryFilterData)
        setempFilter(empFilterData)
        setcusFilter(cusFilterData)
        setClearFilters(false)
    }


    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }

    const filters = (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={1}
        >
            <Grid
                item
                xs={10}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                style={{
                    marginBottom: 20,
                }}
            >
                <Grid item xs={12} md={6} lg={3}>
                    {/* <DateTimePicker label="Start Date" onChange={setstartDate} value={startDate} reset={clearFilters} defaultValue={time.getLastYear()} /> */}
                    <CustomDateRangePicker label='Select Date' onChange={setSelectDate} value={selectDate} reset={clearFilters} defaultValue={[moment(time.getLastYear()), moment(time.getToday())]} />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={2}>
                    <DateTimePicker label="End Date" onChange={setendDate} value={endDate} reset={clearFilters} defaultValue={time.getToday()} />
                </Grid> */}

                <Grid item xs={12} md={6} lg={2}>
                    <CustomMultiSelectAutocomplete label="Customer Email" onChange={setcusName} data={cusFilter} reset={clearFilters} />
                </Grid>
                {
                    !isVendor() &&
                    <Grid item xs={12} md={6} lg={2}>
                        <CustomMultiSelectAutocomplete label="Professional Name" onChange={setempName} data={empFilter} reset={clearFilters} />
                    </Grid>
                }

                <Grid item xs={12} md={6} lg={2}>
                    <CustomMultiSelectAutocomplete label="Category" onChange={setSelectedCategories} data={categoryFilter} reset={clearFilters} />
                </Grid>

            </Grid>
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    size="medium"
                    style={{ float: "right", fontSize : "12px",
                    padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                    onClick={() => {
                    setClearFilters(true);
                    }}
                    startIcon={<ClearAllIcon />}
                >
                    Clear All
                </Button>
            </Grid>
        </Grid>
    )
    const handleClearFilters = () => {
        setcusName([])
        setSelectedCategories([])
        setempName([])
    }

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])


    useEffect(() => {
        setFilter()
    }, [AllCategories, AllEmployees, AllCustomers])

    const getFilterValues = (): { categoryFilterData: number[], empFilterData: number[], cusFilterData: number[], start: number, end: number } => {

        let start: number = 0
        let end: number = 0
        let categoryFilterData: number[] = []
        let empFilterData: number[] = []
        let cusFilterData: number[] = []

        AllCategories.map((item) => {
            if (selectedCategories.includes(item.name)) {
                categoryFilterData.push(item.id)
            }

        })

        AllEmployees.map((item) => {
            if (empName.includes(item.full_name)) {
                empFilterData.push(item.id)
            }
        })

        AllCustomers.map((item) => {
            if (cusName.includes(item.email)) {
                cusFilterData.push(item.id)
            }
        })

        // if (startDate && endDate) {
        //     start = time.DateToUnix(startDate)
        //     end = time.DateToUnix(endDate)
        // }

        if (selectDate) {
            start = time.DateToUnix(selectDate[0].toDate())
            end = time.DateToUnix(selectDate[1].toDate())
        }

        return { categoryFilterData: categoryFilterData, empFilterData: empFilterData, cusFilterData: cusFilterData, start: start, end: end }
    }

    useEffect(() => {
        fetchData()
        fetchCountOfBookings()
        fetchRevOverTime()
    }, [startDate, endDate, selectDate, selectedCategories, empName, cusName])

    useEffect(() => {
        fetchCountOfBookings()
    }, [filterBy])

    useEffect(() => {
        fetchRevOverTime()
    }, [filterByForRev])


    useEffect(() => {
        fetchData()
        fetchCountOfBookings()
        fetchRevOverTime()
        setFilter()
    }, [])

    return (
        <div className={classes.container}>
            <Hidden smDown>
                {filters}
            </Hidden>
            <Hidden smUp>
                <Accordion style={{ marginBottom: 10 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <div className={classes.filterTitle}>Filters</div>

                    </AccordionSummary>
                    <AccordionDetails>
                        {filters}
                    </AccordionDetails>
                </Accordion>

            </Hidden>
            <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                spacing={2}
                style={{
                    marginBottom: 10,
                }}
            >

                <Grid item xs={12} md={6} lg={2}>
                    <NumberOfBookings loading={loading1} />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <SumOfRevenue loading={loading1} />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <PricePerUnit loading={loading1} />
                </Grid>

                <Grid item xs={12} md={6} lg={2}>
                    <OccupancyRate loading={loading1} />
                </Grid>

            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item xs={12} md={8}>
                    <CountOfBookingsOverTime loading={loading1 || loading2} options={barOptions} filterFunction={setFilterBy} filterBy={filterBy} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CountOfBookingsByCategories loading={loading1} options={options} theme={theme} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <RevenueOverTime loading={loading1 || loading3} options={barOptions} filterFunction={setFilterByForRev} filterBy={filterByForRev} />
                </Grid>

                <Grid item xs={12} md={4}>
                    <RevenueByCategory loading={loading1} options={options} theme={theme} />
                </Grid>
                {
                    !isVendor() &&
                    <Grid item xs={12} md={8}>
                        <BooingByVendors loading={loading1} options={barOptions} />
                    </Grid>
                }
            </Grid>
        </div>
    )
}
