import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../styles/colors";

const drawerWidth = 240;
const desktopAppBarHeight = 64;
const mobileAppBarHeight = 56;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            backgroundColor: "#fff",
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        name: {
            fontWeight: "bold",
            cursor: "pointer",
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        content: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                height: `calc(100% - ${desktopAppBarHeight}px)`,
                marginTop: desktopAppBarHeight,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: `calc(100% - ${mobileAppBarHeight}px)`,
                marginTop: mobileAppBarHeight,
            },
        },
        greyIcon: {
            margin: "0 3px",
            transition: '1s',
            "&:hover": {
                color: `${myColors.primaryColor} !important`
            }
        },
        titleCase: {
            textTransform: "capitalize",
            fontSize: 13,
            whiteSpace: "nowrap"
        },
        adminButton: {
            "&:hover": {
                backgroundColor: 'rgba(0, 0, 0, 0.0)',
            }
        },
    }),
);

export default useStyles;