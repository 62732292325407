const arrayToString = (data: string[] | number[]) => {
    let text: string = ''
    text = JSON.stringify(data)
    text = text.substring(1, text.length - 1)
    text = text.replace(/"/g, '')
    return text
}

export {
    arrayToString,
}