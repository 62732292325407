import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../../../styles/colors";

const itemWidth = "12vw"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: itemWidth,
            padding: 10,
            cursor: 'pointer'
        },
        divContainer: {
            width: itemWidth,
            padding: 10,
            cursor: 'pointer'
        },
        imgContainer: {
            padding: 5,
            cursor: 'pointer',
            border: 'solid'
        },
        media: {
            height: itemWidth,
            width: `calc(${itemWidth} - 20px)`,
        },
        card: {
            width: `calc(${itemWidth} - 20px)`,
            position: "relative",
        },
        name: {
            textTransform: "capitalize",
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: "center",
        },
        suit: {
            textTransform: "capitalize",
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: "center",
            color: myColors.subtitleFontColor
        },
        slot: {
            fontSize: 18,
            fontWeight: 'bold',
        },
        overlay: {
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            right: '0px',
            color: 'black',
            whiteSpace: 'nowrap',
        },
        active: {
            backgroundColor: "#C7EBD0"
        },
        activeBanner: {
            backgroundColor: "#C7EBD022"
        },
        inactive: {
            backgroundColor: "#cc2424"
        },
        inactiveBanner: {
            backgroundColor: "#e0e0e0"
        },
        activeBannerDiv: {
            borderColor: "#C7EBD0"
        },
        inactiveBannerDiv: {
            borderColor: "#e0e0e0"
        }
    }),
);

export default useStyles;