import { useEffect, useState } from 'react'
import useStyles from './ActiveViewStyles'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Checkbox, Typography } from '@material-ui/core'
import {Delete, Add} from '@material-ui/icons'
import store, { IRootState } from '../../../core/store'
import { useSelector } from 'react-redux'
import Button from '../../../shared/Button/Button'
import vendorApi from '../../../core/api/vendor-api'
import Table from '../../../shared/Table/CustomTable'
import { Availability } from '../../../models/vendor'
import Calendar from '../../../shared/Calendar/Calendar'
import moment, { Moment } from 'moment'
import WarningButton from '../../../shared/Button/WarningButton'
import SuccessButton from '../../../shared/Button/SuccessButton'
import { TimePicker } from 'antd'
import { AlertActions, StandardHourDataActions, standardHourCheckboxDataActions, standardHourTimeElementDataActions } from '../../../core/store/actions'
import { SortObject } from '../../../models/sort'
import './ActiveView.scss'
import { Stack } from '@mui/material'
import { nanoid } from 'nanoid'

interface Slot {
    start: Moment;
    end: Moment
}

export default function ActiveView() {
    const classes = useStyles()

    const dayNames = [{'value':'MON', 'key': '1'}, {'value':'TUE', 'key': '2'}, {'value':'WED', 'key': '3'}, {'value':'THU', 'key': '4'}, {'value':'FRI', 'key': '5'}, {'value':'SAT', 'key': '6'} ,{'value':'SUN', 'key': '0'}]
    const fixedDate = moment(new Date()).unix()
    const [standardHourCreateDialog, setStandardHourCreate] = useState<boolean>(false)
    const [standardHourCreateDialogUserWarning, setStandardHourCreateUserWarning] = useState<boolean>(false)
    const [isStandardHourUpdate, setIsStandardHourUpdate] = useState<boolean>(false)
    const standardHourData = useSelector((state:IRootState)=>state.standardHourDataReducer)
    const standardHourElements = useSelector((state:IRootState)=>state.standardHourTimeElementDataReducer)
    const standardHourCheckbox = useSelector((state:IRootState)=>state.standardHourCheckboxDataReducer)

    const vendorAvailability: Availability[] = useSelector((state: IRootState) => state.vendorReducer.vendorAvailability)
    const [calendarData, setCalendarData] = useState<{ [key: string]: Availability[] }>({})
    const [selectedSlotIds, setSelectedSlotIds] = useState<number[]>([]);
    const [tableData, setTableData] = useState<Object[]>([]);
    const [isloading, setloading] = useState<boolean>(true)
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [sort, setSort] = useState<SortObject>({ orderBy: 'slot_date', order: "asc" });

    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
    const [endDate, setEndDate] = useState<Moment | undefined>(undefined);
    const [isCreateAddDisabled, setIsCreateAddDisabled] = useState<boolean>(true);
    const [createTimeData, setCreateTimeData] = useState<Slot[]>([]);

    const [editItem, setEditItem] = useState<{
        date: string;
        start_time: string;
        end_time: string;
        duration: string;
        slot_id: number;
        time_slot_id: number;
    } | null>(null)
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [isEditDisabled, setIsEditDisabled] = useState<boolean>(false);

    const [deleteItem, setDeleteItem] = useState<{
        date: string;
        start_time: string;
        end_time: string;
        duration: string;
        slot_id: number;
        time_slot_id: number;
    } | null>(null)
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    const fetchData = async () => {
        setloading(true)
        try {
            await vendorApi.getAvailableHours(sort)
        } catch (error) {
            setloading(false)
        }
    };

    const handleSelect = (slot_date: number) => {
        let temp = [...selectedSlotIds]
        const slot = vendorAvailability.filter(item => item.slot_date === slot_date)
        const i = temp.indexOf(slot[0].slot_id);

        if (i > -1) {
            temp.splice(i, 1);
            setSelectedSlotIds([...temp])
        }
        else {
            setSelectedSlotIds([...selectedSlotIds, slot[0].slot_id])
        }

    }

    const validateTime = () => {
        let tempEndDate = endDate
        let now = moment()
        if (endDate && endDate.unix() === now.startOf('day').unix()) {
            tempEndDate = now.endOf('day').add(-1, 'ms')
        }
        // console.log(tempEndDate)
        if (startDate && tempEndDate && startDate.unix() >= tempEndDate.unix()) {
            setIsCreateAddDisabled(true)
            setIsEditDisabled(true)
            store.dispatch({
                type: AlertActions.UPDATE,
                payload: {
                    alert: { open: true, alertType: "error", message: "Start time can't be greater than the End time" }
                }
            });
            return
        }
        else {
            setIsCreateAddDisabled(false)
            setIsEditDisabled(false)
        }

        if (createTimeData.length > 0) {
            createTimeData.map(slot => {
                if (startDate && tempEndDate && (slot.start.unix() <= tempEndDate.unix()) && (slot.end.unix() >= startDate.unix())) {
                    setIsCreateAddDisabled(true)
                    store.dispatch({
                        type: AlertActions.UPDATE,
                        payload: {
                            alert: { open: true, alertType: "error", message: "Please select a valid time range" }
                        }
                    });
                    return
                }
                else {
                    setIsCreateAddDisabled(false)
                }
            })
        } else {
            setIsCreateAddDisabled(false)
        }

        if (editItem) {
            let temp = vendorAvailability.filter(item => item.slot_id === editItem.slot_id)
            let slots = temp[0].slot_times
            temp[0].slot_times = slots.filter(x => x.id != editItem.time_slot_id)

            temp.map(item => {
                item.slot_times.map(slot => {
                    if (startDate && tempEndDate && (slot.working_from <= tempEndDate.unix()) && (slot.working_to >= startDate.unix())) {
                        setIsEditDisabled(true)
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: "error", message: "Please select a valid time range" }
                            }
                        });
                        return
                    }
                    else {
                        setIsEditDisabled(false)
                    }
                })
            })
        } else {
            setIsEditDisabled(false)
        }
    };

    const handleCreateStartDate = (date: Moment) => {
        setStartDate(date);
    };

    const handleCreateEndDate = (date: Moment) => {
        validateTime()
        setEndDate(date);
    };

    const handleCreateAddTime = () => {
        if (startDate && endDate) {
            setCreateTimeData([...createTimeData, { start: startDate, end: endDate.unix() === moment().startOf('day').unix() ? moment().endOf('day').add(-1, 'ms') : endDate }])
            setIsCreateAddDisabled(true)
            setStartDate(undefined)
            setEndDate(undefined)
        }
    };

    const handleCreateOpen = () => {
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateTimeData([])
        setStartDate(undefined)
        setEndDate(undefined)
        setCreateOpen(false);
    };

    const handleCreate = async () => {
        let data: Object[] = []
        selectedSlotIds.map(id => {
            let slot: Availability = vendorAvailability.filter(item => item.slot_id === id)[0]
            createTimeData.map(slotData => {
                console.log(moment.unix(slot.slot_date), moment(slotData.start))
                let temp = {
                    working_from: moment.unix(slot.slot_date).set({ hour: moment(slotData.start).hours(), minute: moment(slotData.start).minutes(), second: 0, millisecond: 0 }).unix(),
                    working_to: moment.unix(slot.slot_date).set({ hour: moment(slotData.end).hours(), minute: moment(slotData.end).minutes(), second: 0, millisecond: 0 }).unix(),
                    slot_id: id
                }
                data.push(temp)
            })
        })
        await vendorApi.setAvailableHours(data)
        handleCreateClose()
        await fetchData()
    };

    const createDialog = (
        < Dialog
            open={createOpen}
            onClose={handleCreateClose}
            classes={{ paperWidthSm: classes.createDialogPaper }}
        >
            <DialogTitle >{"Set Business Hours"}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        marginBottom: "20px"
                    }}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={6}>
                        <div className={classes.timeLabel}>Start Time</div>
                        <TimePicker
                            value={startDate}
                            use12Hours
                            format="h:mm a"
                            onChange={(date) => { if (date) { handleCreateStartDate(date) } }}
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode as HTMLElement;
                            }}
                            minuteStep={30}
                        />
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                autoOk
                                inputVariant="outlined"
                                label="Start Time"
                                value={startDate}
                                onChange={(date) => { if (date) { handleCreateStartDate(date) } }}
                                minutesStep={30}
                                onError={console.log}
                            />
                        </MuiPickersUtilsProvider> */}
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.timeLabel}>End Time</div>
                        <TimePicker
                            value={endDate}
                            use12Hours
                            format="h:mm a"
                            onChange={(date) => { if (date) { handleCreateEndDate(date) } }}
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode as HTMLElement;
                            }}
                            minuteStep={30}
                        />
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                autoOk
                                inputVariant="outlined"
                                label="End Time"
                                value={endDate}
                                onChange={(date) => { if (date) { handleCreateEndDate(date) } }}
                                minutesStep={30}
                                onError={console.log}
                            />
                        </MuiPickersUtilsProvider> */}
                    </Grid>

                </Grid>
                <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                    <Button
                        disabled={isCreateAddDisabled}
                        variant='contained'
                        color='primary'
                        onClick={handleCreateAddTime}
                    >
                        Add
                    </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                    {
                        createTimeData.length > 0 &&
                        <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}>
                            Selected Time Slots
                        </div>
                    }
                    {
                        createTimeData.length > 0 && createTimeData.map(slot => {
                            return <div style={{ marginBottom: 2 }}>
                                {moment(slot.start).format("hh:mm a")} - {moment(slot.end).format("hh:mm a")}
                            </div>
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus disabled={createTimeData.length === 0}>
                    Create
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleEditOpen = (i: {
        date: string;
        start_time: string;
        end_time: string;
        duration: string;
        slot_id: number;
        time_slot_id: number;
    }) => {
        const slot = vendorAvailability.filter(item => item.slot_id === i.slot_id)[0]
        slot.slot_times.map(item => {
            if (item.id === i.time_slot_id) {
                setStartDate(moment.unix(item.working_from))
                setEndDate(moment.unix(item.working_to))
            }
        })
        setEditItem(i)
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditItem(null)
        setStartDate(undefined)
        setEndDate(undefined)
        setEditOpen(false);
    };

    const handleEdit = async () => {
        if (editItem) {
            await vendorApi.updateAvailableHours(editItem.slot_id, editItem.time_slot_id, moment(startDate).unix(), moment(endDate).unix())
            handleEditClose()
            await fetchData()
        } else {
            handleEditClose()
        }

    };

    const editDialog = (
        < Dialog
            open={editOpen}
            onClose={handleEditClose}
            classes={{ paperWidthSm: classes.createDialogPaper }}
        >
            <DialogTitle >Edit Business Hours on {editItem && editItem.date}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        marginBottom: "20px"
                    }}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={6}>
                        <div className={classes.timeLabel}>Start Time</div>
                        <TimePicker
                            value={startDate}
                            use12Hours
                            format="h:mm a"
                            onChange={(date) => { if (date) { handleCreateStartDate(date) } }}
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode as HTMLElement;
                            }}
                            minuteStep={30}
                        />
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                autoOk
                                inputVariant="outlined"
                                label="Start Time"
                                value={startDate}
                                onChange={(date) => { if (date) { handleCreateStartDate(date) } }}
                                minutesStep={30}
                                onError={console.log}
                            />
                        </MuiPickersUtilsProvider> */}
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.timeLabel}>End Time</div>
                        <TimePicker
                            value={endDate}
                            use12Hours
                            format="h:mm a"
                            onChange={(date) => { if (date) { handleCreateEndDate(date) } }}
                            getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode as HTMLElement;
                            }}
                            minuteStep={30}
                        />
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                autoOk
                                inputVariant="outlined"
                                label="End Time"
                                value={endDate}
                                onChange={(date) => { if (date) { handleCreateEndDate(date) } }}
                                minutesStep={30}
                                onError={console.log}
                            />
                        </MuiPickersUtilsProvider> */}
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleEditClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleEdit} color="primary" autoFocus disabled={isEditDisabled}>
                    Update
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleDeleteOpen = (i: {
        date: string;
        start_time: string;
        end_time: string;
        duration: string;
        slot_id: number;
        time_slot_id: number;
    }) => {
        setDeleteItem(i)
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteItem(null)
    };

    const handleRemove = async () => {
        if (deleteItem) {
            await vendorApi.deleteAvailableHours(deleteItem.slot_id, deleteItem.time_slot_id)
            handleDeleteClose()
            await fetchData()
        } else {
            handleDeleteClose()
        }

    };

    const deleteDialog = (
        < Dialog
            open={deleteOpen}
            onClose={handleDeleteClose}
        >
            <DialogTitle >{"Update Service Status"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to remove {deleteItem && `${deleteItem.start_time} - ${deleteItem.end_time} on ${deleteItem.date}`}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleDeleteClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleRemove} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    // Standard Hour Implementation

    const handleStandardHoursCreateDialogOpen = async () => {
        setloading(true)
        setStandardHourCreate(true)
        await getAllStandardHours()
        setloading(false)
    }

    const handleStandardHoursCreateDialogClose = async() => {
        handleStandardHourClose()
    }

    const handleStandardHourUserWarningOpen = () => {
        if(!(isStandardHourUpdate)){
            setStandardHourCreateUserWarning(true)
            return
        }
        
        handleStandardHourApply()
    }
    
    const handleStandardHourUserWarningConfirm = () => {
        setStandardHourCreateUserWarning(false)
        handleStandardHourApply()
    }
    
    const handleStandardHoursCreateUserWarningDialogClose = () => {
        setStandardHourCreateUserWarning(false)
    }

    const getAllStandardHours = async() => {
        let data = await vendorApi.getAllStandardHours().catch((error) => {
            console.log(error)
        })

        if(Object.keys(data).length == 0){
            setIsStandardHourUpdate(false)
            return
        }
        
        for (let id in data){
            let slots = data[id]
            
            slots.forEach((slot:any) => {
                let uuid = handleStandardHourAdd(id) 

                store.dispatch({
                    type: StandardHourDataActions.ADD_HOUR_DATA,
                    payload: {
                        uuid: uuid,
                        value: {start:moment.unix(slot['start']), end:moment.unix(slot['end']), id:id}
                    }
                })
            })
        }

        setIsStandardHourUpdate(true)

        console.log(standardHourElements)
        console.log(standardHourData)
    }

    const isUnavailable = (key:String) => {
        let data = standardHourElements.filter((item:any) => {
            if(item.id == key)
                return item
        })
        
        if(data.length == 0) return true
        return false
    }

    const handleStandardHourCheckbox = (e:any, key:string) => {
        let standardHourCheckboxCopy = standardHourCheckbox
        let standardHourElementsCopy = standardHourElements
        let otherElementsInId = Array()

        if(key in standardHourCheckboxCopy){
            standardHourCheckboxCopy[key] = !standardHourCheckboxCopy[key]

            store.dispatch({
                type: standardHourCheckboxDataActions.UPDATE_CHECKBOX_DATA,
                payload: standardHourCheckboxCopy
            })

            standardHourElementsCopy.forEach((element:any) => {
                if (element.id == key) 
                    otherElementsInId.push(element.key)
            })
            
            if(standardHourCheckboxCopy[key] == true && otherElementsInId.length == 0){
                handleStandardHourAdd(key)
            }
            
            if(standardHourCheckboxCopy[key] == false){
                standardHourElementsCopy = standardHourElementsCopy.filter((item:any) => !(item.id == key))

                store.dispatch({
                    type: standardHourTimeElementDataActions.UPDATE_ELEMENT_DATA,
                    payload: standardHourElementsCopy
                })
            }

            if(standardHourCheckboxCopy[key] == false){
                for(let uuid in standardHourData){
                    let slotData = standardHourData[uuid]
                    if(slotData['id'] == key){
                        delete standardHourData[uuid]
                    }
                }
                store.dispatch({
                    type: StandardHourDataActions.UPDATE_HOUR_DATA,
                    payload: standardHourData
                })
            }
        }
    }

    const validateStandardTime = (startTime:Moment, endTime:Moment, key:string, id:string) => {
        console.log("Validating Time Ranges", endTime.hour())
        let tempEndDate = endTime


        if (endTime && startTime && endTime.hour() == 0) {
            tempEndDate = endTime.add(23, 'hours').add(59, 'minutes')
        }

        console.log(startTime.unix(), tempEndDate.unix())
        
        if (startTime && tempEndDate && startTime.unix() >= tempEndDate.unix()) {
            store.dispatch({
                type: AlertActions.UPDATE,
                payload: {
                    alert: { open: true, alertType: "error", message: "Start time can't be greater than the End time" }
                }
            });
            return false
        }

        if (Object.keys(standardHourData).length > 1) {
            for (let tempKey in standardHourData) {
                if (tempKey != key.split("---")[0] && standardHourData[tempKey]['id'] == id){
                    let slot = standardHourData[tempKey]
                    console.log(slot, startTime, tempEndDate)
                    if (startTime && tempEndDate && (slot['start'].unix() < tempEndDate.unix()) && (slot['end'].unix() > startTime.unix())) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: "error", message: "Please select a valid time range" }
                            }
                        })
                        delete standardHourData[key.split("---")[0]]
                        store.dispatch({
                            type: StandardHourDataActions.UPDATE_HOUR_DATA,
                            payload: standardHourData
                        })
                        
                        return false
                    }
                }   
            }
        }

        return true
    }

    const handleStandardHourClose = () => {
        setStandardHourCreate(false)
        store.dispatch({
            type: standardHourCheckboxDataActions.CLEAR_CHECKBOX_DATA,
            payload: null
        })
        store.dispatch({
            type: standardHourTimeElementDataActions.CLEAR_ELEMENT_DATA,
            payload: null
        })
        store.dispatch({
            type: StandardHourDataActions.CLEAR_HOUR_DATA,
            payload: null
        })
    }

    const handleStandardHourAdd = (id:string) => {
        let uuid = nanoid()
        standardHourElements.push({key: uuid, id: id})

        store.dispatch({
            type: standardHourTimeElementDataActions.UPDATE_ELEMENT_DATA,
            payload: standardHourElements
        })

        if(id in standardHourCheckbox && standardHourCheckbox[id] == false){
            standardHourCheckbox[id] = true
            
            store.dispatch({
                type: standardHourCheckboxDataActions.UPDATE_CHECKBOX_DATA,
                payload: standardHourCheckbox
            })

        }
        return uuid
    }

    const handleStandardHourDelete = (key:string, id:string) => {
        const index = standardHourElements.map(function(element:any) { return element.key; }).indexOf(key)
        let otherElementsInId = Array()

        if (index > -1) {
            standardHourElements.splice(index, 1);
        }
        
        if(key in standardHourData){
            delete standardHourData[key]
        }

        store.dispatch({
            type: StandardHourDataActions.UPDATE_HOUR_DATA,
            payload: standardHourData
        })

        standardHourElements.forEach((element:any) => {
            if (element.id == id) 
            otherElementsInId.push(element.id)
        })

        if(id in standardHourCheckbox && otherElementsInId.length==0){
            standardHourCheckbox[id] = false
        }

        store.dispatch({
            type: standardHourTimeElementDataActions.UPDATE_ELEMENT_DATA,
            payload: standardHourElements
        })

        store.dispatch({
            type: standardHourCheckboxDataActions.UPDATE_CHECKBOX_DATA,
            payload: standardHourCheckbox
        })
    }

    const handleStandardHourCreateStartDate = (key:string, id:string, date:Moment) => {
        let validateResults = true
        let keyHead = key.split("---")[0]
        let keyTail = key.split("---")[1]
        
        date = moment.unix(fixedDate).set({hour: date.hour(), minute: date.minute(), second: 0, millisecond: 0})

        if(keyTail == "End"){
            validateResults = validateStandardTime(standardHourData[keyHead]["start"], date, key, id)
        }else if(keyTail == "Start" && keyHead in standardHourData){
            validateResults = validateStandardTime(date, standardHourData[keyHead]["end"], key, id)
        }

        if(validateResults){
            if(keyHead in standardHourData){
                if(keyTail == "Start" && keyHead in standardHourData && standardHourData[keyHead]["start"] != undefined){
                    standardHourData[keyHead]["start"] = date
    
                    store.dispatch({
                        type: StandardHourDataActions.UPDATE_HOUR_DATA,
                        payload: standardHourData
                    })
                }
                if(keyTail == "End"){
                    standardHourData[keyHead]["end"] = date

                    store.dispatch({
                        type: StandardHourDataActions.UPDATE_HOUR_DATA,
                        payload: standardHourData
                    })
                }
            }else{
                if(keyTail == "Start"){
                    standardHourData[keyHead] = {}
                    standardHourData[keyHead]["start"] = date
                    standardHourData[keyHead]["end"] = undefined
                    standardHourData[keyHead]["id"] = id
    
                    store.dispatch({
                        type: StandardHourDataActions.UPDATE_HOUR_DATA,
                        payload: standardHourData
                    })
                }
            }
        }
    }

    const handleStandardHourApply = async() => {
        let appliedData:{[code:string]:Array<{working_from:any, working_to:any}>} = {}
        let availableDayCodes = Array()

        for (let tempKey in standardHourData) {
            let slotData = standardHourData[tempKey]
            availableDayCodes.push(slotData['id'])
        }

        availableDayCodes.forEach(code => {
            if(!(code in appliedData)){
                appliedData[code] = Array()
            }
        })

        for (let tempKey in standardHourData) {
            let slotData = standardHourData[tempKey]
            appliedData[slotData['id']].push({working_from: slotData['start'].unix(), working_to: slotData['end'].unix()})
        }

        if(!(isStandardHourUpdate)){
            await vendorApi.addStandardHours(appliedData)
        }else{
            await vendorApi.updateStandardHours(appliedData)
        }
        handleStandardHourClose()
        await fetchData() 
    }

    const createStandardHoursDialog = (
        <Dialog
            open={standardHourCreateDialog}
            onClose={handleStandardHoursCreateDialogClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent style={{padding:"8%"}}>
                {
                    dayNames.map(days => {
                        return <Grid
                                style={{
                                    marginBottom: "20px",
                                    borderBottom: "1px solid #f3f3f3"
                                }}
                                spacing={2}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                key={days.key}
                            >
                                <Grid item xs={1}>
                                    <Stack direction="row" style={{alignItems:"center"}}>
                                        <input type="checkbox" onChange={(e) => handleStandardHourCheckbox(e, days.key)} checked={standardHourCheckbox[days.key]}/>
                                        <Typography variant='subtitle1' style={{fontWeight:800, paddingLeft:'10px'}}>{days.value}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={1}> 
                                </Grid>
                                <Grid item xs={9}> 
                                    {
                                        isUnavailable(days.key) == true?

                                        <Typography variant='subtitle1' style={{ fontWeight: 600 }}> Unavailable </Typography>:

                                        <Stack direction="column">
                                            <Grid
                                                style={{
                                                    width: "100%",
                                                    marginBottom: "20px",
                                                    borderBottom: "1px solid #f3f3f3"
                                                }}
                                                spacing={2}
                                                container
                                                direction="row"
                                                justifyContent="center"
                                            >
                                            {
                                                standardHourElements.map((elements:any) => {
                                                    return elements.id == days.key?
                                                    <>
                                                        <Grid item xs={5} key={elements.key+"StartDateGrid"} id={elements.key+"StartDateGrid"}>
                                                            <TimePicker
                                                                placeholder='Start'
                                                                value={elements.key.split('---')[0] in standardHourData? standardHourData[elements.key.split('---')[0]]['start'] : null}
                                                                use12Hours={true}
                                                                format="h:mm a"
                                                                onChange={(date) => { if (date) {handleStandardHourCreateStartDate(elements.key+"---Start", elements.id, date) } }}
                                                                getPopupContainer={(triggerNode) => {
                                                                    return triggerNode.parentNode as HTMLElement;
                                                                }}
                                                                minuteStep={30}
                                                                key={elements.key+"---Start"}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5} key={elements.key+"EndDateGrid"} id={elements.key+"EndDateGrid"}>
                                                            <TimePicker
                                                                placeholder='End'
                                                                value={elements.key.split('---')[0] in standardHourData ? standardHourData[elements.key.split('---')[0]]['end'] : null}
                                                                use12Hours={true}
                                                                format="h:mm a"
                                                                onChange={(date) => { if (date) {handleStandardHourCreateStartDate(elements.key+"---End", elements.id, date) } }}
                                                                getPopupContainer={(triggerNode) => {
                                                                    return triggerNode.parentNode as HTMLElement;
                                                                }}
                                                                minuteStep={30}
                                                                key={elements.key+"---End"}
                                                                disabled={elements.key.split('---')[0] in standardHourData && standardHourData[elements.key.split('---')[0]]['start'] != undefined ? false : true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} key={elements.key+"DeleteDateGrid"} id={elements.key+"DeleteDateGrid"}> 
                                                            <Delete style={{transform: 'translate(0%, 25%)'}} key={elements.key+"DeleteDateButtonGrid"} onClick={() => handleStandardHourDelete(elements.key, elements.id)}></Delete>
                                                        </Grid>
                                                    </>
                                                    : <></>
                                                })
                                            }
                                            </Grid>
                                        </Stack>
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <Stack direction="row">
                                        <div id='stdhrListPlaceholder' onClick={() => handleStandardHourAdd(days.key)}>
                                            <Add></Add>
                                        </div>
                                    </Stack>       
                                </Grid>
                            </Grid>
                    })
                }
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleStandardHourClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleStandardHourUserWarningOpen} color="primary" autoFocus disabled={Object.keys(standardHourData).length === 0 && isStandardHourUpdate === false}>
                    {isStandardHourUpdate? "Update" : "Apply"}
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const createStandardHoursDialogUserWarning = (
        <Dialog
            open={standardHourCreateDialogUserWarning}
            onClose={handleStandardHoursCreateUserWarningDialogClose}
            maxWidth="xs"
        >
            <DialogTitle >{"Are you sure?"}</DialogTitle>
            <DialogContent>
                <Typography>By adding Standard Business Hours, all future availability times will be updated to this new setting.</Typography>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleStandardHoursCreateUserWarningDialogClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleStandardHourUserWarningConfirm} color="primary" autoFocus>
                    Ok
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    // End

    useEffect(() => {
        let groupedResults = vendorAvailability.reduce(function (r, a) {
            r[moment.unix(a.slot_date).format('MMMM')] = r[moment.unix(a.slot_date).format('MMMM')] || [];
            r[moment.unix(a.slot_date).format('MMMM')].push(a);
            return r;
        }, Object.create(null))

        // console.log(`groupedResults ==.`, groupedResults)
        // console.log(`vendorAvailability ==>`, vendorAvailability)
        setCalendarData(groupedResults)

        let tableData: {
            date: string;
            start_time: string;
            end_time: string;
            duration: number;
            slot_id: number;
            time_slot_id: number;
        }[] = []
        vendorAvailability.map((item) => {

            if (item.slot_times.length > 0) {
                item.slot_times.map((slot) => {
                    let data: {
                        date: string;
                        start_time: string;
                        end_time: string;
                        duration: number;
                        slot_id: number;
                        time_slot_id: number;
                    } = {
                        date: moment.unix(item.slot_date).format("Do MMMM YYYY"),
                        start_time: moment.unix(slot.working_from).format("hh:mm a"),
                        end_time: moment.unix(slot.working_to).format("hh:mm a"),
                        duration: moment.duration(moment.unix(slot.working_to).diff(moment.unix(slot.working_from))).asMinutes(),
                        slot_id: item.slot_id,
                        time_slot_id: slot.id
                    };
                    tableData.push(data)
                })
            }
            // else {
            //     tableData.push(data)
            // }

        })
        setTableData(tableData)
        setloading(false)
    }, [vendorAvailability])

    useEffect(() => {
        validateTime()
    }, [startDate, endDate])

    useEffect(() => {
        fetchData()
    }, [sort])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        
        <div className={classes.container}>
            <Grid direction='column'>
                <Grid
                    direction='row'
                    container
                    alignItems="flex-start"
                    justifyContent='center'
                >
                    <Grid item sm={12} lg={6} style={{overflowY:'scroll', height:"65vh", top:"10%"}}>
                        {
                            Object.keys(calendarData).map((key: string) => {
                                return <Calendar
                                    data={calendarData[key].map(obj => obj.slot_date).sort(function (a, b) { return a - b })}
                                    onClick={handleSelect}
                                />
                            })
                        }
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Table
                            data={tableData}
                            isloading={isloading}
                            editFunction={handleEditOpen}
                            deleteFunction={handleDeleteOpen}
                            ignore={["slot_id", "time_slot_id"]}
                        />
                    </Grid>
                </ Grid>
                <Grid
                    direction='row'
                    container
                    alignItems="flex-start"
                    justifyContent='center'
                    style={{paddingTop:"5%"}}
                >
                    <Grid item xs={12} sm={2} lg={2} xl={2} style={{padding:"2%"}}>
                        <Button
                            disabled={selectedSlotIds.length === 0}
                            variant='contained'
                            color='primary'
                            onClick={handleCreateOpen}
                            style={{ width:"100%", height:"100%"}}
                        >
                            Assign Times
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={2} lg={2} xl={2} style={{padding:"2%"}}>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleStandardHoursCreateDialogOpen}
                            style={{width:"100%", height:"100%", overflow: "hidden", whiteSpace: "nowrap"}}
                        >
                            <span>Assign Standard Times</span>
                        </Button>
                    </Grid>
                    <Grid item sm={12} lg={6} xl={6}>
                    </Grid>
                </Grid>
            </Grid>
            {createDialog}
            {createStandardHoursDialog}
            {createStandardHoursDialogUserWarning}
            {editDialog}
            {deleteDialog}
        </div>
    )
}
