import classNames from 'classnames'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import authApi from '../../../../core/api/auth-api'
import Button from '../../../../shared/Button/Button'
import TextField from '../../../../shared/TextField/TextField'
import MyNavigator from '../../../../route_names/navigation'

import style from './AdminForm.module.scss'
import { InputAdornment } from '@material-ui/core'
interface Iprops {
    token: string
}

interface Istate {
    userName: string,
    fullName: string,
    mobileNumber: string,
    password: string,
    cPassword: string,
}

interface Ierror {
    userNameErrorText: string,
    fullNameErrorText: string,
    mobileNumberErrorText: string,
    passwordErrorText: string,
    cPasswordErrorText: string,
}

const formatMobile = (value: string): string => {
    const onlyNums: string = value.replace(/[^0-9]/g, '');
    let number: string = ''
    if (onlyNums.length < 10) {
        number = onlyNums
    }
    if (onlyNums.length === 10) {
        number = onlyNums.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1) $2-$3'
        );

    }
    return number
}

function EmpForm(props: Iprops) {
    const Navigator = MyNavigator()
    const [state, setState] = useState<Istate>({
        userName: "",
        fullName: "",
        mobileNumber: '',
        password: "",
        cPassword: '',
    })
    const [error, setError] = useState<Ierror>({
        userNameErrorText: '',
        fullNameErrorText: '',
        mobileNumberErrorText: '',
        passwordErrorText: '',
        cPasswordErrorText: '',
    })


    const handleChange = (event: any) => {
        event.persist();
        const { name, value } = event.target

        if (name === "email") {
            let text: string = value
            text = text.toLowerCase()
            setState(prevState => ({
                ...prevState,
                [name]: text
            }))
        }
        else if (name === "mobileNumber") {
            const onlyNums: string = value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                let number = formatMobile(value)
                setState(prevState => ({
                    ...prevState,
                    [name]: number
                }))
            }
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: value
            }))
        }

        handleError(name, value)

    }

    const handleSignUp = async (event: any) => {
        event.preventDefault();
        handleError()

        let { userNameErrorText,
            fullNameErrorText,
            mobileNumberErrorText,
            passwordErrorText,
            cPasswordErrorText } = error
        let data = {
            username: state.userName,
            full_name: state.fullName,
            mobile_no: state.mobileNumber.replace(/[^0-9]/g, ''),
            password: state.password
        }

        if (!passwordErrorText && !cPasswordErrorText && !userNameErrorText && !fullNameErrorText && !mobileNumberErrorText) {
            try {

                await authApi.confirmAdmin(props.token, data);
                Navigator.pushNamed('/login')
            } catch (err:any) {
                setError({
                    ...error,
                    userNameErrorText: err.message,
                    passwordErrorText: err.message
                })
            }
        }
    }

    const handleError = (name: string = "submit", value: string = '') => {
        if (name !== "submit") {
            const ErrName: string = name + "ErrorText"
            let ErrVariable: string = ''

            if (!value) {
                ErrVariable = 'This field cannot be empty'
            }

            if (name === 'cPassword') {
                if (state.password !== value) {
                    ErrVariable = "Password did not match.";
                }
            }

            if (name === "mobileNumber") {
                // let mobileRegex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
                // if (!mobileRegex.test(value)) {
                //     ErrVariable = "Please enter valid mobile number";
                // }
                if (value.length < 10) {
                    ErrVariable = "Mobile number must have 10 digits";
                }
            }

            setError(prevErr => ({
                ...prevErr,
                [ErrName]: ErrVariable
            }))
        }
        else {
            const errors: Ierror = { ...error }
            Object.keys(state).forEach(key => {
                if (`${Reflect.get(state, key)}` === '') {
                    const ErrName: string = key + "ErrorText"
                    Reflect.set(errors, `${ErrName}`, 'This field cannot be empty')
                }
            });
            setError(errors)
        }


    }
    return (
        <>
            <form className={style.container} onSubmit={handleSignUp}>
                <div className={classNames('split-form')}>
                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="userName"
                                    onChange={handleChange}
                                    error={!!error.userNameErrorText}
                                    helperText={error.userNameErrorText}
                                    value={state.userName}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="User Name"
                                    variant="outlined" />
                            </div>
                        </div>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="fullName"
                                    onChange={handleChange}
                                    error={!!error.fullNameErrorText}
                                    helperText={error.fullNameErrorText}
                                    value={state.fullName}
                                    className='full-width'
                                    fullWidth
                                    autoComplete="off"
                                    label="Full Name"
                                    variant="outlined" />
                            </div>
                        </div>
                    </div>

                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="mobileNumber"
                                    onChange={handleChange}
                                    error={!!error.mobileNumberErrorText}
                                    helperText={error.mobileNumberErrorText}
                                    value={state.mobileNumber}
                                    className='full-width'
                                    fullWidth
                                    InputProps={{
                                        startAdornment:
                                            < InputAdornment position="start" >
                                                +1
                                            </InputAdornment>
                                    }}
                                    autoComplete="off"
                                    label="Mobile Number"
                                    variant="outlined" />
                            </div>
                        </div>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="password"
                                    onChange={handleChange}
                                    error={!!error.passwordErrorText}
                                    helperText={error.passwordErrorText}
                                    value={state.password}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    type='password' />
                            </div>
                        </div>
                    </div>

                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="cPassword"
                                    onChange={handleChange}
                                    error={!!error.cPasswordErrorText}
                                    helperText={error.cPasswordErrorText}
                                    value={state.cPassword}
                                    className='full-width'
                                    fullWidth
                                    autoComplete="off"
                                    label="Confirm Password"
                                    variant="outlined"
                                    type='password' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.actions}>
                    <Button
                        onClick={handleSignUp}
                        variant="contained"
                        color="primary"
                        type="submit">
                        Sign Up
                    </Button>
                </div>
            </form>
        </>
    )
}

export default EmpForm
