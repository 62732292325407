import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20,
        },
        createDialogPaper: {
            height: 450
        },
        timeLabel: {
            width: "100%",
            textAlign: "left",
            color: myColors.subtitleFontColor
        }
    }),
);

export default useStyles;