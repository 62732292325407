import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, InputAdornment, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../../../../shared/Button/Button'
import TextField from '../../../../shared/TextField/TextField'
import useStyles from './ExternalBookingStyles'
import { VenderSocial, VendorUser } from '../../../../models/vendor'
import { IRootState } from '../../../../core/store'
import { useSelector } from 'react-redux'
import vendorApi from '../../../../core/api/vendor-api'
import hyperlink from '../../../../img/icons/hyperlink.png'



const formatMobile = (value: string): string => {
    const onlyNums: string = value.replace(/[^0-9]/g, '');
    let number: string = ''
    if (onlyNums.length < 10) {
        number = onlyNums
    }
    if (onlyNums.length === 10) {
        number = onlyNums.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1) $2-$3'
        );

    }
    return number
}

export default function ExternalBooking() {
    const useStyle = useStyles()
    const user: VendorUser[] = useSelector((state: IRootState) => state.vendorReducer.vendorUser)
    const [bookingUrl, setBookingUrl] = useState<string | null>(null)
    const [platform, setPlatform] = useState<string>('')
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [error, setError] = useState<string | null>('')

    const handleUrlChange = async (event: any, url:any) => {
        if (event)
            event.persist()

        const { value } = url
        
        let errorResults = handleError(value)

        console.log(errorResults, value)

        if (errorResults){
            await vendorApi.updateBookingUrlDetails({booking_url: value})
            setBookingUrl(value)
        }

        setBookingUrl(value)
    }

    const handleError = (value: any, name?: string) => {
        let error = null
        if (!!value) {
            let urlRegex = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig)
            if (value == ""){
                error = null
            }else if (!urlRegex.test(value)) {
                error = "Please enter valid URL"
            }
        }

        setError(error)
        if(error){
            return false
        }

        return true
    }

    useEffect(() => {
        if (user.length > 0) {
            setBookingUrl(user[0]?.booking_url || '')
        }
    }, []);

    return (
        <div>
            <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item >
                        <Typography variant="h6" noWrap className={useStyle.title}>
                            External Booking
                        </Typography>

                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        xs={12} md={6} lg={6}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item lg={2} md={12} xs={12} className={useStyle.urlTitle}>
                            <img src={hyperlink} height="16px" width='16px' style={{ marginRight: 5 }} />
                            Booking URL :
                        </Grid>
                        <Grid item lg={10} md={12} xs={12}>
                            <TextField
                                onFocus={() => {
                                    setPlatform("booking_url")
                                }}
                                onChange={(event) => handleUrlChange(event, event.target)}
                                onBlur={(event) => handleUrlChange(event, event.target)}
                                value={bookingUrl}
                                error={!!error}
                                helperText={error}
                                name="url"
                                className='full-width'
                                autoComplete="off"
                                fullWidth
                                // InputProps={{
                                //     startAdornment:
                                //         < InputAdornment position="start" >
                                //             {key === 'mobile_no' && "+1"}
                                //         </InputAdornment>
                                // }}
                                // label={key === 'mobile_no' ? "Mobile Number" : key === 'display_email' ? 'Email address' : 'URL'}
                                variant="outlined" />
                        </Grid>
                    </Grid>

                </Grid>

                {/* <Grid item
                    xs={12}>
                    <Divider variant='fullWidth' />
                </Grid> */}

            </Grid>
        </div>
    )
}
