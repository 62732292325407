import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken, isSupported, onMessage
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyADqzOMMU59g8ZGLTlHGR_2uUJK64zSajw",
  authDomain: "mint-salon.firebaseapp.com",
  projectId: "mint-salon",
  storageBucket: "mint-salon.appspot.com",
  messagingSenderId: "1099356573649",
  appId: "1:1099356573649:web:b3d6b602194bf381085af0",
  measurementId: "G-7PDTMDXVZ4",
};
let messaging: any;
let supportedNotifications: boolean;
const init = async () => {
  // Initialize Firebase
  initializeApp(firebaseConfig);
  supportedNotifications = await isSupported();
  if (supportedNotifications) {
    messaging = getMessaging();
  } else {
    console.log("Browser doesn't support push notifications");
  }
};
init();
const getFCMToken = () => {
  if (!messaging) return false;
  try{
    var notify = getToken(messaging, {
      vapidKey:
        "BE6Pfc5wfTV50ltbc8l_JqimLsMFoOL7SDn73jXSB7X1Mp2ohWRpQtfTmcmGfPMcYI9GXRR7N3fOWKCNJx32Ro0",
    });
    var notifyJson = JSON.stringify(notify)
    return notifyJson
  }catch(e){
    return false;
  }
};

const onBackgroundFCMMessageListener = () => {
  return new Promise(function (resolve) {
    if (!supportedNotifications) {
      return resolve(false);
    }
    navigator.serviceWorker.addEventListener("message", function (event) {
      resolve({
        data: event.data?.data,
        notification: event.data?.notification,
      });
    });
  });
};

const onForegroundFCMMessageListener = () => {
  return new Promise((resolve) => {
    if (!supportedNotifications) {
      return resolve(false);
    }
    onMessage(messaging, (payload) => {
      resolve({ data: payload.data, notification: payload.notification });
    });
  });
};

export {
  messaging,
  supportedNotifications,
  getFCMToken,
  onForegroundFCMMessageListener,
  onBackgroundFCMMessageListener,
};
