import { GiftCard, PurchasedGiftCard } from "../../models/giftCard";
import { GiftCardActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface giftCardState {
  giftCards: GiftCard[];
  purchasedGiftCards: PurchasedGiftCard[];
  validMessage: string;
  validState: number | null;
};

export const initialGiftCardState = {
  giftCards: [],
  purchasedGiftCards: [],
  validMessage: '',
  validState: null
};

const giftCardReducer = (state: giftCardState = initialGiftCardState, action: Action) => {
  if (action && action.payload) {
    const { giftCards, purchasedGiftCards, validMessage, validState } = action.payload;
    switch (action.type) {
      case GiftCardActions.UPDATE_MASTER:
        if (giftCards) {
          return {
            ...state,
            giftCards: giftCards
          };
        }
        break;
      case GiftCardActions.UPDATE_PURCHASED:
        if (purchasedGiftCards) {
          return {
            ...state,
            purchasedGiftCards: purchasedGiftCards
          };
        }
        break;
      case GiftCardActions.UPDATE_VALID_MESSAGE:
        if (validMessage) {
          return {
            ...state,
            validMessage: validMessage,
            validState: validState
          };
        }
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default giftCardReducer;
