import React, { useEffect, useReducer, useState } from "react";
import routeNameObjects from "../../route_names/route_name_objects";
import Page from "../../shared/Page/Page";
import useStyles from "./EmployeesStyles";
import Table from "../../shared/Table/CustomTable";
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Button from "../../shared/Button/Button";
import TextField from "../../shared/TextField/TextField";
import employeeApi from "../../core/api/employee-api";
import { IRootState } from "../../core/store";
import { useSelector } from "react-redux";
import { Employee, SuiteForProf } from "../../models/employee";
import CustomSelect from "../../shared/Select/Select";
import time from "../../core/services/time";
import ClearAllIcon from "@material-ui/icons/ClearAll";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import authApi from "../../core/api/auth-api";
import WarningButton from "../../shared/Button/WarningButton";
import SuccessButton from "../../shared/Button/SuccessButton";
import { Suite } from "../../models/suite";
import CustomTab from "../../shared/Tab/Tab";
import PersonIcon from "@material-ui/icons/Person";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import MyLoader from "../../shared/Loader/my_loader";
import CustomMultiSelectAutocomplete from "../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete";
import { User, UserRole } from "../../models/user";
import CustomSelectAutoComplete from "../../shared/SelectAutoComplete/SelectAutoComplete";
import SuiteApi from "../../core/api/suite-api";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Location } from "../../../src/models/employee";
import suiteApi from "../../core/api/suite-api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Employees() {
  const classes = useStyles();
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const user: User = useSelector((state: IRootState) => state.userReducer.user);
  const userRole: UserRole = useSelector(
    (state: IRootState) => state.userReducer.userRole
  );
  const [toggleItem, setToggleItem] = useState<Employee | null>(null);
  const [toggleOpen, setToggleOpen] = useState<boolean>(false);
  const [email, setemail] = useState<string>("");
  const [payment, setPayment] = useState<number | null>(0);
  const [userType, setuserType] = useState<string>("Professional");
  const [creditType, setcreditType] = useState<string>("variable");
  const [variableCredit, setVariableCredit] = useState<number | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<number>(1);
  const [fixedCredit, setFixedCredit] = useState<
    [number | null, number | null, number | null, number | null, number | null]
  >([null, null, null, null, null]);
  const [selectedSuite, setSelectedSuite] = useState<string>("");
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const employees: Employee[] = useSelector(
    (state: IRootState) => state.employeeReducer.employees
  );
  const admins: Employee[] = useSelector(
    (state: IRootState) => state.employeeReducer.admins
  );
  const customers: Object[] = useSelector(
    (state: IRootState) => state.employeeReducer.customer
  );
  const superAdmins: Employee[] = useSelector(
    (state: IRootState) => state.employeeReducer.superAdmins
  );
  const AllSuites: Suite[] = useSelector(
    (state: IRootState) => state.filterReducer.availableSuites
  );
  const [suiteFilter, setsuiteFilter] = useState<string[]>([]);
  const [clearFilters, setClearFilters] = useState<boolean>(false);
  const [disableTableDataVendor, setDisableTableDataVendor] = useState<
    string[]
  >([]);
  const [activeTableFilterVendor, setActiveTableFilterVendor] = useState<
    string[]
  >([]);
  const [disableTableDataAdmin, setDisableTableDataAdmin] = useState<string[]>(
    []
  );
  const [activeTableFilterAdmin, setActiveTableFilterAdmin] = useState<
    string[]
  >([]);
  const [disableTableDataSuper, setDisableTableDataSuper] = useState<string[]>(
    []
  );
  const [activeTableFilterSuper, setActiveTableFilterSuper] = useState<
    string[]
  >([]);
  const [location, setLocation] = React.useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = React.useState<number[]>([]);
  const [selectedLocationArr, setSelectedLocationArr] = React.useState<
    Location[]
  >([]);

  const [isloading, setloading] = useState<boolean>(true);
  const [activeSuites, setActiveSuites] = React.useState<Suite[]>([]);
  const [activeSuitesForEdit, setActiveSuitesForEdit] = React.useState<Suite[]>(activeSuites);
  const [allSuites, setAllSuites] = React.useState<Suite[]>([]);
  const [selectedActiveSuites, setSelectedActiveSuites] = React.useState<number[]>([]);
    const [selectedActiveSuitesArr, setSelectedActiveSuitesArr] = React.useState<Suite[]>([]);
    const [displayName, setDisplayName] = useState<string>('')
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [isEditPassword, setIsEditPassword] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<Employee | null>(null)
    const [password, setPassword] = useState<string>('')
    const [selectedTab, setSelectedTab] = useState<number>(0)

    
  console.log(`adminfs ===>`, admins)


  const fetchEmployees = async () => {
    setloading(true);
    let activeFilterDataVendor: boolean[] = [];
    let activeFilterDataAdmin: boolean[] = [];
    let activeFilterDataSuper: boolean[] = [];

    employees.map((item) => {
      if (item.disable && disableTableDataVendor.includes("Active")) {
        // console.log('Active', item.disable, item)
        activeFilterDataVendor.push(false);
      }

      if (item.disable === 0 && disableTableDataVendor.includes("Inactive")) {
        // console.log('Inactive', item.disable, item)
        activeFilterDataVendor.push(true);
      }
    });

    admins.map((item) => {
      if (item.disable && disableTableDataAdmin.includes("Active")) {
        // console.log('Active', item.disable, item)
        activeFilterDataAdmin.push(false);
      }

      if (item.disable === 0 && disableTableDataAdmin.includes("Inactive")) {
        // console.log('Inactive', item.disable, item)
        activeFilterDataAdmin.push(true);
      }
    });

    superAdmins.map((item) => {
      if (item.disable && disableTableDataAdmin.includes("Active")) {
        // console.log('Active', item.disable, item)
        activeFilterDataSuper.push(false);
      }

      if (item.disable === 0 && disableTableDataAdmin.includes("Inactive")) {
        // console.log('Inactive', item.disable, item)
        activeFilterDataSuper.push(true);
      }
    });

    activeFilterDataVendor = [...new Set(activeFilterDataVendor)];
    activeFilterDataAdmin = [...new Set(activeFilterDataAdmin)];
    activeFilterDataSuper = [...new Set(activeFilterDataSuper)];

    // print new array
    // console.log('array', activeFilterData)
    try {
      if(userRole.name === "admin"){
              await employeeApi.getallVendorsForAdmin(activeFilterDataVendor);
              await employeeApi.getAllAdminForAdmin(activeFilterDataAdmin);
      }
      else{
              await employeeApi.getallVendors(activeFilterDataVendor);
              await employeeApi.getAllAdmin(activeFilterDataAdmin);
      }
      await employeeApi.getAllCustomers();
    } catch (error) {
      setloading(false);
    }
    if (userRole.name === "sys_admin") {
      try {
        await employeeApi.getAllSuperAdmin(activeFilterDataSuper);
      } catch (error) {}
    }
  };

  const FetchLocations = async () => {
    try {
      if(userRole.name === "admin"){
            const location = await employeeApi.getLocationsForAdmin();
             setLocation(location);
      }
      else {
      const location = await employeeApi.getLocations();
      setLocation(location);
      }
      
    } catch (error) {
      setloading(false);
    }
  };

  const setFilter = async () => {
    let suiteFilterData: string[] = [];
    let activeFilterDataVendor: string[] = [];
    let activeFilterDataAdmin: string[] = [];
    let activeFilterDataSuper: string[] = [];

    AllSuites.map((item) => {
      if (item.disabled === 1) {
        suiteFilterData.push(item.display_name);
      }
    });

    employees.map((item) => {
      if (item.disable || item.disable == 0) {
        if (item.disable) {
          activeFilterDataVendor.push("Active");
        }
        if (item.disable == 0) {
          activeFilterDataVendor.push("Inactive");
        }
      }
    });

    admins.map((item) => {
      if (item.disable || item.disable == 0) {
        if (item.disable) {
          activeFilterDataAdmin.push("Active");
        }
        if (item.disable == 0) {
          activeFilterDataAdmin.push("Inactive");
        }
      }
    });

    superAdmins.map((item) => {
      if (item.disable || item.disable == 0) {
        if (item.disable) {
          activeFilterDataSuper.push("Active");
        }
        if (item.disable == 0) {
          activeFilterDataSuper.push("Inactive");
        }
      }
    });

    // remove duplicate elements from array
    const arr = activeFilterDataVendor.filter(function (
      item,
      index,
      inputArray
    ) {
      return inputArray.indexOf(item) === index;
    });

    // remove duplicate elements from array
    const arr2 = activeFilterDataAdmin.filter(function (
      item,
      index,
      inputArray
    ) {
      return inputArray.indexOf(item) === index;
    });

    // remove duplicate elements from array
    const arr3 = activeFilterDataSuper.filter(function (
      item,
      index,
      inputArray
    ) {
      return inputArray.indexOf(item) === index;
    });

    // print new array
    // console.log('all', arr)

    suiteFilterData = [...new Set(suiteFilterData)];
    suiteFilterData.sort();

    getAllActiveSuites();

    setActiveTableFilterVendor(arr);
    setActiveTableFilterAdmin(arr2);
    setActiveTableFilterSuper(arr3);
    setloading(false);
    setClearFilters(false);
  };

  const getAllActiveSuites = async () => {
     if(userRole.name === "admin") {
                await employeeApi.getAllSuiteForAdmin().then((data) => {
                  
                  setAllSuites(data);
                  console.log("suite all", data);
                  let activeSuite : Suite[] = [];
                  data.map((i : Suite) => {
                    if(i.booking_status === "NOT_OCCUPIED"){
                      activeSuite.push(i);
                    }
                  });

              setActiveSuites(activeSuite);
              });

     }
    else{
    await employeeApi.getAllSuite().then((data) => {
      setAllSuites(data);
      console.log("suite all", data);
      let activeSuite : Suite[] = [];
      data.map((i : Suite) => {
        if(i.booking_status === "NOT_OCCUPIED"){
          activeSuite.push(i);
        }
       });

       setActiveSuites(activeSuite);
    });
  }

  }

  const handleToggleOpen = (item: Employee) => {
    setToggleItem(item);
    setToggleOpen(true);
  };

  const handleToggleClose = () => {
    setToggleOpen(false);
    setSelectedActiveSuites([]);
    setSelectedActiveSuitesArr([]);
  };

  const handleVisibilityUpdate = async () => {
    
    setToggleOpen(false);
    if (toggleItem) {
      setloading(true);
      await employeeApi
        .toggleVisibilityById(toggleItem.id, toggleItem.disable === 1 ? 0 : 1)
        .then(() => {
          setloading(false);
        });
        
      if(selectedTab === 0 && selectedActiveSuites){
        if(toggleItem.disable === 0 ){
          const data: SuiteForProf = {
              suite_array : selectedActiveSuites,
          }
          setloading(true);
          await authApi.updateProfWithNewSuite(toggleItem.id, data)
          .then(() => {
          fetchEmployees();
          setSelectedActiveSuitesArr([]);
          setSelectedActiveSuites([]);
          setloading(false);
        });
        }
        else{
          const data: SuiteForProf = {
              suite_array : [],
          }
          setloading(true);
          await authApi.updateProfWithNewSuite(toggleItem.id, data)
          .then(() => {
          fetchEmployees();
          setSelectedActiveSuitesArr([]);
          setSelectedActiveSuites([]);
          setloading(false);
        });
      }
     }
    }
    setloading(false);
  };

    const handleMultiSuitesChange = (event : any) => {
        let ids = [];
        ids = event.target.value;
        setSelectedActiveSuites(ids);
        let suite : Suite[] = [];
        ids.forEach((element : any) => {
            suite.push([...activeSuites].find((suite) => suite.id == element) as unknown as Suite);            
        });
        setSelectedActiveSuitesArr(suite);    
    };

  const handleChange = (event: any) => {   
    let ids = [];
    ids = event.target.value;
    setSelectedLocation(ids);
    let loc: Location[] = [];
    ids.forEach((element: any) => {
      loc.push(
        [...location].find((loc) => loc.id == element) as unknown as Location
      );
    });
    setSelectedLocationArr(loc);
  };

  useEffect(() => {}, [location, selectedLocationArr]);

  const toggleDialog = (
    <Dialog open={toggleOpen} onClose={handleToggleClose}>
      <DialogTitle>{"Update User Status"}</DialogTitle>
      <DialogContent>
        {toggleItem && user.id === toggleItem?.id ? (
          <DialogContentText>
            You can't deactivate currently logged in user!!!
          </DialogContentText>
        ) : (
          <>
          <DialogContentText>
            Are you sure you want to{" "}
            {toggleItem && toggleItem.disable === 1 ? "deactivate" : "activate"}{" "}
            {toggleItem && toggleItem.username} User?
          </DialogContentText>
            {toggleItem && toggleItem.disable === 0 && selectedTab === 0 &&
            <>
             <DialogContentText>
             Please select suites
             </DialogContentText>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="suite-mutiple-checkbox-label"
                        className={classes.label}
                        > Suite Number</InputLabel>
                        <Select
                        labelId="suites-mutiple-checkbox-label"
                        id="suites-mutiple-checkbox"
                        multiple
                        value={selectedActiveSuites}
                        onChange={handleMultiSuitesChange}
                        input={<OutlinedInput label="Suite Numbers" />}
                        renderValue={ x => (selectedActiveSuitesArr.map((i : any) => i?.name)).join(', ')}
                        MenuProps={MenuProps}
                        >
                        {activeSuites.map((item : Suite) => (
                            <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={selectedActiveSuites.includes(item.id)} />
                            <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Grid>
            </>
            }
          </>
        )}
      </DialogContent>
      <DialogActions
      style={ {display : 'flex', justifyContent: 'space-around'} }>
        <WarningButton onClick={handleToggleClose} color="primary">
          Cancel
        </WarningButton>
        <SuccessButton
          onClick={handleVisibilityUpdate}
          color="primary"
          autoFocus
          disabled={user.id === toggleItem?.id}
        >
          {toggleItem && toggleItem.disable === 1 ? "Deactivate" : "Activate"}
        </SuccessButton>
      </DialogActions>
    </Dialog>
  );
  const handleEmailChange = (event: any) => {
    event.persist();
    const { value } = event.target;
    let text: string = value;
    text = text.toLowerCase();
    setemail(text);
    handleError(text, "email");
  };

  const handleUserTypeChange = (value: string) => {
    setuserType(value);
    handleError(value, "role");
  };

  const handleUsersuiteChange = (event : any) => {
      let ids = [];
      ids = event.target.value;
      setSelectedActiveSuites(ids);
      let loc : Suite[] = [];
      ids.forEach((element : any) => {
          loc.push([...activeSuitesForEdit].find((loc) => loc.id == element) as unknown as Suite);            
      });
      setSelectedActiveSuitesArr(loc);    
  };

  const handleCreditTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const type = (event.target as HTMLInputElement).value;
    setcreditType(type);

    if (type === "variable") {
      setFixedCredit([0, null, null, null, null]);
    } else {
      setVariableCredit(null);
    }
  };

  const handleVariableValueChange = (event: any) => {
    event.persist();
    const { value } = event.target;
    const onlyNums: string = value.replace(/[^0-9]/g, "");
    let newVal: null | number = Number.isNaN(parseInt(onlyNums, 10))
      ? null
      : parseInt(onlyNums, 10);
    setVariableCredit(newVal);
  };

  const handleFixedValueChange = (event: any) => {
    event.persist();
    const { value } = event.target;
    let temp: [
      number | null,
      number | null,
      number | null,
      number | null,
      number | null
    ] = [...fixedCredit];
    const onlyNums: string = value.replace(/[^0-9]/g, "");
    let newVal: null | number = Number.isNaN(parseInt(onlyNums, 10))
      ? null
      : parseInt(onlyNums, 10);
    temp[selectedWeek] = newVal;

    setFixedCredit([...temp]);
    handleError(newVal, "fixedCredit");
  };

  const handleError = (value: any, name?: string) => {
    const ErrVal: string = name ? name : "";
    let ErrVariable: string = "";

    if (!value) {
      ErrVariable = "This field cannot be empty";
    }

    if (name === "email") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(value)) {
        ErrVariable = "Please enter valid email address";
      }
    }

    if (name === "fixedCredit") {
      if (payment && value > payment) {
        ErrVariable = "Maximum value exceeded";
      }

      if (!value) {
        ErrVariable = "";
      }
    }

    setError((prevState) => ({
      ...prevState,
      [ErrVal]: ErrVariable,
    }));
  };

  const handleCreate = () => {
    handleError(selectedSuite, "suite_id");
    handleError(userType, "role");
    handleError(email, "email");
    setSubmitClicked(true);

    if (editOpen) {
        handleError(displayName, "displayName")
        handleEditSubmit()
        handleCreateClose()
    }

    if(isEditPassword){
      handleUpdatePassword();
    }
  };

  const handleCreateSubmit = async () => {
    if (userType === "Professional") {
      let temp: Suite[] = allSuites.filter(
        (suite) => suite.display_name === selectedSuite
      );
      // let suite_id = temp.length > 0 ? [temp[0].id] : 0;
       const data: Object = {
                email: email,
                role: 'vendor',
                suite_array : selectedActiveSuites,
                payment: payment === null ? 0 : payment,
                variable_credit: variableCredit === null ? 0 : variableCredit,
                weekly_credits: fixedCredit.map(el => el === null ? 0 : el)
            }
      setloading(true);
      await authApi.createUser(data).then(() => {
        setloading(false);
      });
    } 
    else if (userType === "Admin") {
      
      const data = {
        email: email,
        location_array: JSON.stringify(selectedLocation),
      };      
      await authApi.createAdmin(data); 

    } else {
      await authApi.createSuperAdmin(email);
    }
    fetchEmployees();
  };

  const handleCreateOpen = () => {
    setError({
      email: "",
      suite_id: "",
      fixedCredit: "",
    });
    setCreateOpen(true);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
    setEditOpen(false);
    setEditItem(null)
    setError({})
    setemail('')
    setuserType('')
    setPayment(null)
    setSelectedSuite('')
    setVariableCredit(null)
    setFixedCredit([null, null, null, null, null])
    setSubmitClicked(false)
    setSelectedLocationArr([]);
    setSelectedLocation([]);
    setSelectedActiveSuites([]);
    setSelectedActiveSuitesArr([]);
    setPassword('');
    setIsEditPassword(false);
    };


    const handleEditOpen =( item: Employee) => {        
        setError({
            name: '',
            address: '',
        })
        setEditItem(item)
        setDisplayName(item.username)
        setEditOpen(true);
        setIsEditPassword(false);
        if(selectedTab === 0){
          let suiteNoArr : number[] = [];
          let suiteArr : Suite[] = [];
          let activeSuiteArr : Suite[] = activeSuites;
          item.suite_array.split(",").map( (i) => {
            if(parseInt(i, 10) > 0){
              suiteNoArr.push(parseInt(i, 10));
              allSuites.map((x) => {
                if(x.id == (parseInt(i, 10))){
                  suiteArr.push(x);
                  activeSuiteArr.push(x);
                }
              })
            }
          });
          setActiveSuitesForEdit(activeSuiteArr)
          setSelectedActiveSuites(suiteNoArr);
          setSelectedActiveSuitesArr(suiteArr);
        }
        if(selectedTab === 1){
          let locNoArr : number[] = [];
          let locArr : Location[] = [];

          item.location_array.replace(/\[|\]/g,'').split(",").map( (i) => { 
            
            if(parseInt(i, 10) > 0){
              locNoArr.push(parseInt(i, 10));
              location.map((x) => {
                if(x.id == (parseInt(i, 10))){
                  locArr.push(x);
                }
              })
            }
            
          });
          setSelectedLocation(locNoArr);
          setSelectedLocationArr(locArr);
        }
    }

    const handleEditSubmit = async () => {
        
        if (selectedTab === 0) {
            
            let temp: Suite[] = allSuites.filter(suite =>
                suite.display_name === selectedSuite
            )
            let suite_id = temp.length > 0 ? [temp[0].id] : 0

            let suite = allSuites.find((suite) => suite && suite.name === selectedSuite ); 

            const data: SuiteForProf = {
                suite_array : selectedActiveSuites,
            }
            
            await authApi.updateProfWithNewSuite(editItem ? editItem.id : 0, data);
        }
        else if (selectedTab === 1) {
            const data = {
                location_array : JSON.stringify(selectedLocation),
            }
            await authApi.updateAdmin( editItem ? editItem.id : 0, data)    
        };

        fetchEmployees();
    }

    const handleUpdatePassword = async () => {
      setloading(true);
      try {
          await authApi.updatePasswordForUser( editItem ? editItem.id : 0, password); 
          setloading(false)
          setIsEditPassword(false)
      }
      catch(error) {
         setloading(false)
      }
    }

    const handlePasswordChange = (event :any) => {
        event.persist() ;
        const { value } = event.target;
        setPassword(value);
        
    }

  const onSetSelectedTab = (index : any) => {
    console.log(`index ==>`, index)
    setSelectedTab(index);
  };

  const handleCreatePasswordOpen = (item : Employee) => {
        setIsEditPassword(true);
        setEditOpen(false);
        setEditItem(item);
  };

  const createDialog = (
      <Dialog
            open={createOpen || editOpen || isEditPassword}
            onClose={handleCreateClose}
        >
      <DialogTitle >{createOpen ? "Create New User" : editOpen ?  `Update ${editItem?.email} User` :  `Update password of ${editItem?.email} User`}</DialogTitle>
      <DialogContent>
        <Grid
          style={{
            marginBottom: "20px",
          }}
          spacing={2}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
    {createOpen &&
        <>
          <Grid
            spacing={2}
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={6}>
              <CustomSelect
                label="User Type"
                onChange={handleUserTypeChange}
                data={
                  userRole.name === "admin"
                    ? ["Professional", "Admin"]
                    : ["Professional", "Admin", "Super Admin"]
                }
                setInitial={true}
                defaultValue={selectedTab === 3 ?  undefined: selectedTab }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={handleEmailChange}
                error={!!error.email}
                helperText={error.email}
                value={email}
                name="email"
                className="full-width"
                autoComplete="off"
                fullWidth
                label="Email Address"
                variant="outlined"
              />
            </Grid>
            {userType === "Admin" && (
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="location-mutiple-checkbox-label"
                    className={classes.label}
                  >
                    {" "}
                    Location
                  </InputLabel>
                  <Select
                    labelId="location-mutiple-checkbox-label"
                    id="location-mutiple-checkbox"
                    multiple
                    value={selectedLocation}
                    onChange={handleChange}
                    input={<OutlinedInput label="Location" />}
                    renderValue={(x) =>
                      selectedLocationArr.map((i: any) => i.name).join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {location.map((item: Location) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox
                          checked={selectedLocation.includes(item.id)}
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {userType === "Professional" && (
              <>
                <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="suite-mutiple-checkbox-label"
                        className={classes.label}
                        >  Suite Number</InputLabel>
                        <Select
                        labelId="suites-mutiple-checkbox-label"
                        id="suites-mutiple-checkbox"
                        multiple
                        value={selectedActiveSuites}
                        onChange={handleMultiSuitesChange}
                        input={<OutlinedInput label="Suite Numbers" />}
                        renderValue={ x => (selectedActiveSuitesArr.map((i : any) => i?.name)).join(', ')}
                        MenuProps={MenuProps}
                        >
                        {activeSuites.map((item : Suite) => (
                            <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={selectedActiveSuites.includes(item.id)} />
                            <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.selectTitle}>
                  Select credit type
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      value={creditType}
                      onChange={handleCreditTypeChange}
                    >
                      <FormControlLabel
                        value="variable"
                        control={<Radio classes={{ root: classes.root }} />}
                        label="Open Credit"
                      />
                      <FormControlLabel
                        value="fixed"
                        control={<Radio classes={{ root: classes.root }} />}
                        label="Fixed Credit"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {creditType === "variable" ? (
                  <Grid
                    item
                    xs={12}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12}>
                      <TextField
                        onChange={handleVariableValueChange}
                        value={variableCredit}
                        name="variableCredit"
                        className="full-width"
                        autoComplete="off"
                        type="number"
                        fullWidth
                        label="Open Credit Value"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item style={{ marginBottom: 20 }}>
                      <ButtonGroup color="primary" disableElevation>
                        <Button
                          variant={
                            selectedWeek === 1 ? "contained" : "outlined"
                          }
                          onClick={() => {
                            setSelectedWeek(1);
                          }}
                        >
                          Week 1
                        </Button>
                        <Button
                          variant={
                            selectedWeek === 2 ? "contained" : "outlined"
                          }
                          onClick={() => {
                            setSelectedWeek(2);
                          }}
                        >
                          Week 2
                        </Button>
                        <Button
                          variant={
                            selectedWeek === 3 ? "contained" : "outlined"
                          }
                          onClick={() => {
                            setSelectedWeek(3);
                          }}
                        >
                          Week 3
                        </Button>
                        <Button
                          variant={
                            selectedWeek === 4 ? "contained" : "outlined"
                          }
                          onClick={() => {
                            setSelectedWeek(4);
                          }}
                        >
                          Week 4
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedWeek === 1 && (
                        <TextField
                          onChange={handleFixedValueChange}
                          value={fixedCredit[1]}
                          error={!!error.fixedCredit}
                          helperText={error.fixedCredit}
                          name="fixedCredit"
                          className="full-width"
                          autoComplete="off"
                          fullWidth
                          type="number"
                          label={`Fixed Credit Value (week ${selectedWeek})`}
                          variant="outlined"
                        />
                      )}

                      {selectedWeek === 2 && (
                        <TextField
                          onChange={handleFixedValueChange}
                          value={fixedCredit[2]}
                          error={!!error.fixedCredit}
                          helperText={error.fixedCredit}
                          name="fixedCredit"
                          className="full-width"
                          autoComplete="off"
                          fullWidth
                          type="number"
                          label={`Fixed Credit Value (week ${selectedWeek})`}
                          variant="outlined"
                        />
                      )}

                      {selectedWeek === 3 && (
                        <TextField
                          onChange={handleFixedValueChange}
                          value={fixedCredit[3]}
                          error={!!error.fixedCredit}
                          helperText={error.fixedCredit}
                          name="fixedCredit"
                          className="full-width"
                          autoComplete="off"
                          fullWidth
                          type="number"
                          label={`Fixed Credit Value (week ${selectedWeek})`}
                          variant="outlined"
                        />
                      )}

                      {selectedWeek === 4 && (
                        <TextField
                          onChange={handleFixedValueChange}
                          value={fixedCredit[4]}
                          error={!!error.fixedCredit}
                          helperText={error.fixedCredit}
                          name="fixedCredit"
                          className="full-width"
                          autoComplete="off"
                          fullWidth
                          type="number"
                          label={`Fixed Credit Value (week ${selectedWeek})`}
                          variant="outlined"
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
           </>
       }

                    {
                        editOpen && editItem &&
                        <>                            
                        {
                            // userType === "Admin" &&
                             selectedTab === 1 &&
                            <>

                          {/* admin location  */}
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="location-mutiple-checkbox-label"
                                        className={classes.label}
                                        > Location</InputLabel>
                                        <Select
                                        labelId="location-mutiple-checkbox-label"
                                        id="location-mutiple-checkbox"
                                        multiple
                                        value={selectedLocation}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Location" />}
                                        renderValue={ x => (selectedLocationArr.map((i : any) => i?.name)).join(', ')}
                                        MenuProps={MenuProps}
                                        >
                                        {location.map((item : Location) => (
                                            <MenuItem key={item.id} value={item.id}>
                                            <Checkbox checked={selectedLocation.includes(item.id)} />
                                            <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                               </>
                                
                        }
                           {/* professional suite */}
                        {
                                selectedTab === 0 &&
                                <>
                                    <Grid item xs={12}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="suite-mutiple-checkbox-label"
                                        className={classes.label}
                                        >  Suite Number</InputLabel>
                                        <Select
                                        labelId="suites-mutiple-checkbox-label"
                                        id="suites-mutiple-checkbox"
                                        multiple
                                        value={selectedActiveSuites}
                                        onChange={handleUsersuiteChange}
                                        input={<OutlinedInput label="Suite Numbers" />}
                                        renderValue={ x => (selectedActiveSuitesArr.map((i : any) => i?.name)).join(', ')}
                                        MenuProps={MenuProps}
                                        >
                                        {activeSuitesForEdit.map((item : Suite) => (
                                            <MenuItem key={item.id} value={item.id}>
                                            <Checkbox checked={selectedActiveSuites.includes(item.id)} />
                                            <ListItemText primary={item.name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                </>
                        }
                    </>
                    }

                    {
                    isEditPassword && (selectedTab === 0 || selectedTab === 1 ) &&

                          <Grid item xs={12}>
                                      <TextField
                                      onChange={handlePasswordChange}
                                      error={!!error.type}
                                      helperText={error.type}
                                      value={password}
                                      className='full-width'
                                      autoComplete="off"
                                      fullWidth
                                      label="Password"
                                      type={"password"}
                                      variant="outlined" />
                       </Grid> 
                   }

                    
        </Grid>
       

      </DialogContent>
      <DialogActions style={ {display : 'flex', justifyContent: 'space-around'} }>
        <WarningButton onClick={handleCreateClose} color="primary">
          Cancel
        </WarningButton>
        <SuccessButton onClick={handleCreate} color="primary" autoFocus>
          {createOpen ? "Create" : "Update"}
        </SuccessButton>
      </DialogActions>
    </Dialog>
  );

  const handleExport = () => {
    type Temp = {
      id: number;
      username: string;
      full_name: string;
      email: string;
      mobile_no: string;
      last_login_at: Date;
      disable: number;
    };
    let formatData: Temp[] = [];

    employees.forEach((item) => {
      let temp: Temp = {
        id: item.id,
        username: item.username,
        full_name: item.full_name,
        email: item.email,
        mobile_no: item.mobile_no,
        last_login_at: time.unixToDate(
          item.last_login_at ? item.last_login_at : 0
        ),
        disable: item.disable,
      };
      formatData.push(temp);
    });
    const fileName = `Professionals Data ${time.DateToString(new Date())}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(formatData);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  };

  const handleAdminExport = () => {
    const fileName = `Admins Data ${time.DateToString(new Date())}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(admins);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  };

  const handleSuperAdminExport = () => {
    const fileName = `Admins Data ${time.DateToString(new Date())}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(superAdmins);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  };

  const handleCustomerExport = () => {
    const fileName = `Cutomer Data ${time.DateToString(new Date())}.xlsx`;
    const ws = XLSX.utils.json_to_sheet(customers);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  };

  const handleClearFilters = () => {
    setFilter();
  };

  useEffect(() => {
    if (clearFilters) {
      handleClearFilters();
    }
  }, [clearFilters]);

  useEffect(() => {
   getAllActiveSuites();   
  }, [createOpen, editOpen, toggleOpen]);

  useEffect(() => {
    setFilter();
  }, [AllSuites, employees, admins, superAdmins, createOpen]);

  useEffect(() => {
    if (userType === "vender") {
      if (
        submitClicked &&
        JSON.stringify(error) ===
          JSON.stringify({
            email: "",
            suite_id: "",
            fixedCredit: "",
          })
      ) {
        handleCreateSubmit();
        handleCreateClose();
      } else {
        setSubmitClicked(false);
      }
    } else {
      if (submitClicked && error.email === "") {
        handleCreateSubmit();
        handleCreateClose();
      } else {
        setSubmitClicked(false);
      }
    }
  }, [error]);

  useEffect(() => {
    fetchEmployees();
  }, [disableTableDataVendor, disableTableDataAdmin, disableTableDataSuper]);

  useEffect(() => {
    fetchEmployees();
    FetchLocations();
  }, []);

  return (
    <Page
      currentRoute={routeNameObjects.employees}
      parentRoutes={[routeNameObjects.employees]}
    >
      <div className={classes.container}>
        <Grid
          item
          spacing={2}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCreateOpen}
            >
              Create New
            </Button>
          </Grid>
        </Grid>

        <CustomTab
          onSetSelectedTab={onSetSelectedTab}
          tabsData={
            userRole.name === "admin"
              ? [
                  {
                    title: "Professional",
                    icon: <PersonIcon />,
                    component: (
                      <div>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={10}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Grid item xs={12} md={6} lg={2}>
                              <CustomMultiSelectAutocomplete
                                label="Active/Inactive"
                                onChange={setDisableTableDataVendor}
                                data={activeTableFilterVendor}
                                reset={clearFilters}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              style={{ float: "right" }}
                              onClick={() => {
                                setClearFilters(true);
                              }}
                              startIcon={<ClearAllIcon />}
                            >
                              Clear All
                            </Button>
                          </Grid>
                        </Grid>
                        <Table
                          data={employees}
                          isloading={isloading}
                          exportFunction={handleExport}
                          toggle={{
                            toggleFunction: handleToggleOpen,
                            toggleColumn: "disable",
                          }}
                           ignore={["disable", "id", "suite_display_name","suite_booking_status", "suite_array"]}
                         editFunction={handleEditOpen}
                         editFunctionForPassword={handleCreatePasswordOpen}
                          overrides={[{ from: 'suite_name_array', to: "Suite Numbers" }]}
                        />
                      </div>
                    ),
                  },
                  {
                    title: "Admin",
                    icon: <AccountCircleIcon />,
                    component: (
                      <div>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={10}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Grid item xs={12} md={6} lg={2}>
                              <CustomMultiSelectAutocomplete
                                label="Active/Inactive"
                                onChange={setDisableTableDataAdmin}
                                data={activeTableFilterAdmin}
                                reset={clearFilters}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              style={{ float: "right" }}
                              onClick={() => {
                                setClearFilters(true);
                              }}
                              startIcon={<ClearAllIcon />}
                            >
                              Clear All
                            </Button>
                          </Grid>
                        </Grid>
                        <Table
                          data={admins}
                          isloading={isloading}
                          exportFunction={handleAdminExport}
                          toggle={{
                            toggleFunction: handleToggleOpen,
                            toggleColumn: "disable",
                          }}
                          ignore={[
                            "disable",
                            "role_id",
                            "id",
                            "suite_booking_status",
                            "location_array",
                            "location_array_string"
                          ]}
                          overrides={[{ from: 'location_name_array', to: "Locations" }]}
                        />
                      </div>
                    ),
                  },
                ]
              : [
                  {
                    title: "Professional",
                    icon: <PersonIcon />,
                    component: (
                      <div>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={10}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Grid item xs={12} md={6} lg={2}>
                              <CustomMultiSelectAutocomplete
                                label="Active/Inactive"
                                onChange={setDisableTableDataVendor}
                                data={activeTableFilterVendor}
                                reset={clearFilters}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              style={{ float: "right" }}
                              onClick={() => {
                                setClearFilters(true);
                              }}
                              startIcon={<ClearAllIcon />}
                            >
                              Clear All
                            </Button>
                          </Grid>
                        </Grid>
                        <Table
                          data={employees}
                          isloading={isloading}
                          exportFunction={handleExport}
                          toggle={{
                            toggleFunction: handleToggleOpen,
                            toggleColumn: "disable",
                          }}
                                                     ignore={["disable", "id", "suite_display_name","suite_booking_status", "suite_array"]}
                         editFunction={handleEditOpen}
                         editFunctionForPassword={handleCreatePasswordOpen}
                          overrides={[{ from: 'suite_name_array', to: "Suite Numbers" }]}/>
                      </div>
                    ),
                  },
              
                  {
                    title: "Admin",
                    icon: <AccountCircleIcon />,
                    component: (
                      <div>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={10}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Grid item xs={12} md={6} lg={2}>
                              <CustomMultiSelectAutocomplete
                                label="Active/Inactive"
                                onChange={setDisableTableDataAdmin}
                                data={activeTableFilterAdmin}
                                reset={clearFilters}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              style={{ float: "right" }}
                              onClick={() => {
                                setClearFilters(true);
                              }}
                              startIcon={<ClearAllIcon />}
                            >
                              Clear All
                            </Button>
                          </Grid>
                        </Grid>
                        <Table
                          data={admins}
                          isloading={isloading}
                          exportFunction={handleAdminExport}
                          toggle={{
                            toggleFunction: handleToggleOpen,
                            toggleColumn: "disable",
                          }}
                          ignore={[
                            "disable",
                            "role_id",
                            "id",
                            "suite_booking_status",
                            "location_array",
                            "location_array_string"
                          ]}
                          editFunction={handleEditOpen}
                           editFunctionForPassword={handleCreatePasswordOpen}
                            overrides={[{ from: 'location_name_array', to: "Locations" }]}

                        />
                      </div>
                    ),
                  },
                  {
                    title: "Super Admin",
                    icon: <SupervisedUserCircleIcon />,
                    component: (
                      <div>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={10}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Grid item xs={12} md={6} lg={2}>
                              <CustomMultiSelectAutocomplete
                                label="Active/Inactive"
                                onChange={setDisableTableDataSuper}
                                data={activeTableFilterSuper}
                                reset={clearFilters}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              style={{ float: "right" }}
                              onClick={() => {
                                setClearFilters(true);
                              }}
                              startIcon={<ClearAllIcon />}
                            >
                              Clear All
                            </Button>
                          </Grid>
                        </Grid>
                        <Table
                          data={superAdmins}
                          isloading={isloading}
                          exportFunction={handleSuperAdminExport}
                          toggle={{
                            toggleFunction: handleToggleOpen,
                            toggleColumn: "disable",
                          }}
                          ignore={[
                            "disable",
                            "role_id",
                            "id",
                            "suite_booking_status",
                          ]}
                        />
                      </div>
                    ),
                  },
                  {
                    title: "Customer",
                    icon: <EmojiPeopleIcon />,
                    component: (
                      <Table
                        data={customers}
                        isloading={isloading}
                        ignore={["id", "dob", "created_at"]}
                        exportFunction={handleCustomerExport}
                      />
                    ),
                  },
                
                ]
          }
        />
      </div>
      {toggleDialog}
      {createDialog}
    </Page>
  );
}