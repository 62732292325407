import { AlertObject } from "../../models/alert";
import { AlertActions } from "./actions";

const initial: AlertObject = { open: false, alertType: "error", message: '' }

interface Action {
  type: string;
  payload: any;
}

interface alertState {
  alert: AlertObject;
};

export const initialAlertState = {
  alert: initial,
};

const bookingReducer = (state: alertState = initialAlertState, action: Action) => {
  if (action && action.payload) {
    const { alert } = action.payload;
    switch (action.type) {
      case AlertActions.UPDATE:
        if (alert) {
          return {
            ...state,
            alert: alert,

          };
        }
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default bookingReducer;
