import { Location } from "../../models/location";
import LocationActions from "./actions/location";


interface Action {
  type: string;
  payload: any;
}

interface locationState {
  allLocations: Location[];
};

export let initialLocationState = {
  allLocations: []
};

const locationReducer = (state: locationState = initialLocationState, action: Action) => {
  if (action && action.payload) {
    const { allLocations } = action.payload;

    if(allLocations){
      initialLocationState = {allLocations};
    }
    // switch (action.type) {
      
    //   case LocationActions.UPDATE:
    //     return {
    //       ...state,
    //       locations: allLocations
    //     };
        
    //   default:
    //     return { ...state };
    // }
  }

  return initialLocationState;
};

export default locationReducer;
