import { Booking } from "../../models/booking";
import { Category } from "../../models/category";
import { Customer } from "../../models/customer";
import { Employee } from "../../models/employee";
import { Service } from "../../models/service";
import { Suite } from "../../models/suite";
import { FilterActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface filterState {
  employees: Employee[];
  customers: Customer[];
  bookings: Booking[];
  suites: Suite[];
  availableSuites: Suite[];
  services: Service[];
  activeCategories: Category[];
  availableCategories: Category[];
};

export const initialFilterState = {
  employees: [],
  customers: [],
  bookings: [],
  suites: [],
  availableSuites: [],
  services: [],
  activeCategories: [],
  availableCategories: [],
};

const filterReducer = (state: filterState = initialFilterState, action: Action) => {
  if (action && action.payload) {
    const { employees, customers, bookings, suites, services, activeCategories, availableSuites, availableCategories } = action.payload;
    switch (action.type) {
      case FilterActions.UPDATE_BOOKINGS:
        if (bookings) {
          return {
            ...state,
            bookings: bookings
          };
        }
        break;
      case FilterActions.UPDATE_CUSTOMERS:
        if (customers) {
          return {
            ...state,
            customers: customers
          };
        }
        break;

      case FilterActions.UPDATE_EMPLOYEES:
        if (employees) {
          return {
            ...state,
            employees: employees
          };
        }
        break;
      case FilterActions.UPDATE_SUITES:
        if (suites) {
          return {
            ...state,
            suites: suites
          };
        }
        break;

      case FilterActions.UPDATE_AVAILABLE_SUITES:
        if (availableSuites) {
          return {
            ...state,
            availableSuites: availableSuites
          };
        }
        break;
      case FilterActions.UPDATE_SERVICES:
        if (services) {
          return {
            ...state,
            services: services
          };
        }
        break;

      case FilterActions.UPDATE_CATEGORIES:
        if (activeCategories) {
          return {
            ...state,
            activeCategories: activeCategories
          };
        }
        break;

        case FilterActions.UPDATE_AVAILABLE_CATEGORIES:
          if (availableCategories) {
            return {
              ...state,
              availableCategories: availableCategories
            };
          }
          break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default filterReducer;