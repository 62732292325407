import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, InputAdornment, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../../../../shared/Button/Button'
import TextField from '../../../../shared/TextField/TextField'
import useStyles from './SocialMediaStyles'
import { VenderSocial, VendorUser } from '../../../../models/vendor'
import { IRootState } from '../../../../core/store'
import { useSelector } from 'react-redux'
import vendorApi from '../../../../core/api/vendor-api'
import facebook from '../../../../img/icons/facebook.png'
import insta from '../../../../img/icons/instagram.png'
import twitter from '../../../../img/icons/twitter.png'
import mobile from '../../../../img/icons/mobile.png'
import youtube from '../../../../img/icons/youtube.png'
import email from '../../../../img/icons/email.png'
import pinterest from '../../../../img/icons/pinterest.png'

const icons = [facebook, insta, twitter, mobile, youtube, email, pinterest]

const formatMobile = (value: string): string => {
    const onlyNums: string = value.replace(/[^0-9]/g, '');
    let number: string = ''
    if (onlyNums.length < 10) {
        number = onlyNums
    }
    if (onlyNums.length === 10) {
        number = onlyNums.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1) $2-$3'
        );

    }
    return number
}

export default function SocialMedia() {
    const useStyle = useStyles()
    const user: VendorUser[] = useSelector((state: IRootState) => state.vendorReducer.vendorUser)
    const [SocialMediaList, setSocialMediaList] = useState<VenderSocial | null>(null)
    const [platform, setPlatform] = useState<string>('')
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [error, setError] = useState<VenderSocial>({
        facebook_url: '',
        instagram_url: '',
        twitter_url: '',
        mobile_no: '',
        youtube_url: '',
        display_email: '',
        pintrest_url: ''
    })

    const handleUrlChange = (event: any) => {
        event.persist();
        const { value } = event.target
        if (platform === "mobile_no") {
            const onlyNums: string = value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                let number = formatMobile(value)
                setSocialMediaList(prevState => (!!prevState ?
                    {
                        ...prevState,
                        [platform]: number
                    }
                    : null
                ))
            }

        } else {
            setSocialMediaList(prevState => (!!prevState ?
                {
                    ...prevState,
                    [platform]: value
                }
                : null
            ))
        }

        handleError(value)
    }

    const handleEdit = () => {
        if (SocialMediaList) {
            Object.keys(SocialMediaList).map((key) => {
                const ignore: string[] = []
                if (!ignore.includes(key)) {
                    handleError(Reflect.get(SocialMediaList, key), key)
                }

            })

            setSubmitClicked(true)
        }
    };

    const handleEditSubmit = async () => {
        if (SocialMediaList) {
            let data: VenderSocial = { ...SocialMediaList }
            data.mobile_no = SocialMediaList.mobile_no.replace(/[^0-9]/g, '')
            await vendorApi.updateContactDetails(data)
        }
    };

    const handleError = (value: any, name?: string) => {
        const ErrVal: string = name ? name : platform
        let ErrVariable: string = ''

        if (!!value) {
            if (ErrVal === 'display_email') {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

                if (!pattern.test(value)) {
                    ErrVariable = "Please enter valid email address";
                }
            } else if (ErrVal === "mobile_no") {
                // let mobileRegex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
                // if (!mobileRegex.test(value)) {
                //     ErrVariable = "Please enter valid mobile number";
                // }
                if (value.length < 10) {
                    ErrVariable = "Mobile number must have 10 digits";
                }
            } else {
                let urlRegex = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig)
                if (!urlRegex.test(value)) {
                    ErrVariable = "Please enter valid URL";
                }
            }
        }

        setError(prevState => ({
            ...prevState,
            [ErrVal]: ErrVariable
        }))
    }

    const keyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleEdit()
        }
    }

    const formatKey = (value: string) => {
        value = value.replace("vendor", 'professional')
        let output: string
        if (!!value) {
            output = value.split("_").join(" ")

            if (value === "mobile_no") {
                output = "Mobile Number"
            }

            if (value === "display_email") {
                output = "Email Address"
            }

            if (value === "facebook_url") {
                output = "Facebook URL"
            }

            if (value === "twitter_url") {
                output = "Twitter URL"
            }

            if (value === "youtube_url") {
                output = "Youtube URL"
            }

            if (value === "pintrest_url") {
                output = "Pinterest URL"
            }

            if (value === "instagram_url") {
                output = "Instagram URL"
            }

        }
        else {
            output = "unknown"
        }

        return output

    }

    useEffect(() => {
        if (submitClicked && JSON.stringify(error) === JSON.stringify({
            facebook_url: '',
            instagram_url: '',
            twitter_url: '',
            mobile_no: '',
            youtube_url: '',
            display_email: '',
            pintrest_url: ''
        })) {
            handleEditSubmit()
        }
        else {
            setSubmitClicked(false)
        }
    }, [error])

    useEffect(() => {
        if (user.length > 0) {
            setSocialMediaList({
                facebook_url: user[0].facebook_url,
                instagram_url: user[0].instagram_url,
                twitter_url: user[0].twitter_url,
                mobile_no: formatMobile(user[0].mobile_no),
                youtube_url: user[0].youtube_url,
                display_email: user[0].display_email,
                pintrest_url: user[0].pintrest_url

            })
        }

    }, [user]);

    return (
        <div>
            <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item >
                        <Typography variant="h6" noWrap className={useStyle.title}>
                            Contact Details
                        </Typography>

                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    {SocialMediaList &&
                        Object.keys(SocialMediaList).map((key, i) => {
                            let value: string = Reflect.get(SocialMediaList, `${key}`)
                            return (
                                <Grid
                                    key={key}
                                    item
                                    xs={12} md={6} lg={6}
                                    spacing={2}
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item lg={2} md={12} xs={12} className={useStyle.urlTitle}>
                                        <img src={icons[i]} height="16px" width='16px' style={{ marginRight: 5 }} />
                                        {formatKey(key)} :
                                    </Grid>
                                    <Grid item lg={10} md={12} xs={12}>
                                        <TextField
                                            onFocus={() => {
                                                setPlatform(key)
                                            }}
                                            onChange={handleUrlChange}
                                            onBlur={handleEdit}
                                            onKeyPress={(event) => {
                                                keyPress(event)
                                                setPlatform(key)
                                            }}
                                            value={value}
                                            error={!!Reflect.get(error, `${key}`)}
                                            helperText={Reflect.get(error, `${key}`)}
                                            name="url"
                                            className='full-width'
                                            autoComplete="off"
                                            fullWidth
                                            InputProps={{
                                                startAdornment:
                                                    < InputAdornment position="start" >
                                                        {key === 'mobile_no' && "+1"}
                                                    </InputAdornment>
                                            }}
                                            label={key === 'mobile_no' ? "Mobile Number" : key === 'display_email' ? 'Email address' : 'URL'}
                                            variant="outlined" />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }

                </Grid>

                {/* <Grid item
                    xs={12}>
                    <Divider variant='fullWidth' />
                </Grid> */}

            </Grid>
        </div>
    )
}
