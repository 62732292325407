import httpApi from './http-api';
import store from '../store';
import { SortObject } from '../../models/sort';
import { arrayToString } from './utils';
import SupportActions from '../store/actions/support';
import {  Suite } from '../../models/suite';
import time from '../services/time';
import { LoadingActions, SuiteActions } from '../store/actions';
import filtersApi from './filters-api';
import {Location } from '../../models/location'
class SuiteApi {
    async getAll(sort: SortObject, disable?: boolean[]): Promise<Suite[]> {

        let url: string = `/suites/getsuite`

        if (sort && sort.orderBy) {
            url = url + `?sort=${sort.order}&column=${sort.orderBy}`
        }

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { Suites } = await httpApi.get(`${url}`);

            if (Array.isArray(Suites)) {
                store.dispatch({
                    type: SuiteActions.UPDATE,
                    payload: {
                        allSuites: Suites
                    }
                });
            }
            filtersApi.getAvailableSuites()
            return Suites
        } catch (error) {
            return []
        }


    }
    async getAllSuiteForAdmin(sort: SortObject, disable?: boolean[]): Promise<Suite[]> {

        let url: string = `/suites/admin/getsuite`

        if (sort && sort.orderBy) {
            url = url + `?sort=${sort.order}&column=${sort.orderBy}`
        }

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { Suites } = await httpApi.get(`${url}`);

            if (Array.isArray(Suites)) {
                store.dispatch({
                    type: SuiteActions.UPDATE,
                    payload: {
                        allSuites: Suites
                    }
                });
            }
            filtersApi.getAvailableSuites()
            return Suites
        } catch (error) {
            return []
        }


    }

    async getAllMainSuites(): Promise<Suite[]> {

        let url: string = `/suites/suite-without-duplicates`

        try {
            const { suite } = await httpApi.get(`${url}`);

            if (Array.isArray(suite)) {
                store.dispatch({
                    type: SuiteActions.UPDATE_PRIMARY_SUITES,
                    payload: {
                        allMainSuites: suite
                    }
                });
            }
            return suite
        } catch (error) {
            return []
        }


    }

    async getUnoccupiedSuites(): Promise<Suite[]> {

        let url: string = `/suites/get-unoccupied`

        try {
            const { Suites } = await httpApi.get(`${url}`);

            if (Array.isArray(Suites)) {
                store.dispatch({
                    type: SuiteActions.UPDATE_UNOCCUPIED_SUITES,
                    payload: {
                        unoccupiedSuites: Suites
                    }
                });
            }
            return Suites
        } catch (error) {
            return []
        }


    }

    async createSuite(data: FormData) {
        try {
            const res = await httpApi.post('/suites/add', data);
            filtersApi.getAvailableSuites()
            return res
        } catch (error) {

        }

    }

    async createSubSuite(data: Object) {
        try {
            const res = await httpApi.post('/suites/sub-suite/add', data);
            filtersApi.getAvailableSuites()
            return res
        } catch (error) {

        }

    }

    async deleteById(id: number) {
        try {
            const res = await httpApi.delete(`/suites/${id}`);
            filtersApi.getAvailableSuites()
            return res
        } catch (error) {

        }

    }

    async editSuiteById(id: number, data: FormData) {
        try {
            const res = await httpApi.put(`/suites/update/${id}`, data);
            filtersApi.getAvailableSuites()
            return res
        } catch (error) {

        }

    }

    async editSubSuiteById(id: number, data: Object) {
        try {
            const res = await httpApi.put(`/suites/sub-suite/${id}`, data);
            filtersApi.getAvailableSuites()
            return res
        } catch (error) {

        }

    }

    async toggleVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.put(`/suites/${id}/status`, { disabled: visibility });
            filtersApi.getAvailableSuites()
        } catch (error) {

        }

    }


    async getLocations(): Promise<Location[]> {
        let url: string = `/location`
        try {
            const { locations } = await httpApi.get(`${url}`);
            return locations
        } catch (error) {
            return []
        }
    }

     async updateSuite(id : number, data: FormData) {
         
        try {
            const res = await httpApi.put(`/suites/update/${id}`, data);
            filtersApi.getAvailableSuites()
            return res
        } catch (error) {
            return []
        }

    }

}
export default new SuiteApi();