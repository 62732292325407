import { useHistory } from "react-router-dom";
import { RouteNames } from "./route_names";

export default function MyNavigator() {
  const history = useHistory();

  const pushToPage = (routeDetails: RouteNames) => {
    history.push(routeDetails.route);
  };

  const replaceToPage = (routeDetails: RouteNames) => {
    history.replace(routeDetails.route);
  };

  const pushNamed = (route: string) => {
    history.push(route);
  };

  const replaceNamed = (route: string) => {
    history.replace(route);
  };

  const back = () => {
    history.goBack();
  };

  return {
    pushToPage,
    replaceToPage,
    pushNamed,
    replaceNamed,
    back,
  };
}
