import MaterialTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 10,
    },
    palette: {
        type: 'light',
        primary: {
            main: '#C7EBD0',
        },
        secondary: {
            main: '#CCFFFB',
        }
    },
    shape: {
        borderRadius: 5,
    },

});

export default function TextField(Props: TextFieldProps) {
    return (
        <ThemeProvider theme={theme}>
            <MaterialTextField
                {...Props}
                size="small">
                {Props.children}
            </MaterialTextField>
        </ThemeProvider>
    )

}
