import React, { Component } from 'react'
import MaterialButton, { ButtonProps } from '@material-ui/core/Button';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 10,
        button: {
            fontSize: '0.9rem',
            fontWeight: 600,
        },
    },
    palette: {
        type: 'light',
        primary: {
            main: '#00c853',
        }
    },
    shape: {
        borderRadius: 5,
    },
    overrides: {
        MuiButton: {
            root: {
                padding: "10px"
            },
            sizeSmall: {
                padding: "6px 14px",
                fontSize: 12,
                minWidth: 150,
            }
        }
    }
});

interface Props extends ButtonProps {

}

export default class SuccessButton extends Component<Props> {
    render() {

        const { variant, ...props } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <MaterialButton
                    {...props}
                    size="small"
                    variant={!!variant ? variant : 'contained'}>
                    {this.props.children}
                </MaterialButton>
            </ThemeProvider>
        )
    }
}
