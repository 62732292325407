import httpApi from './http-api';
import store from '../store';
import { CategoryActions, TvScreenActions } from '../store/actions';
import { Category } from '../../models/category';
import { vendorPublicProfile } from '../../models/tvScreen';

class TvSceenApi {

    async getAllCategories(): Promise<Category[]> {
        try {
            const { Category } = await httpApi.get('/category/getall_tvscreen');
            store.dispatch({
                type: CategoryActions.UPDATE,
                payload: {
                    categories: Category
                }
            });
            return Category
        } catch (error) {
            return []
        }

    }

    async getAllVendors(): Promise<vendorPublicProfile[]> {
        try {
            const { Support } = await httpApi.get('/user/get_vendor_tv');
            store.dispatch({
                type: TvScreenActions.UPDATE_VENDORS,
                payload: {
                    publicVendors: Support
                }
            });
            return Support
        } catch (error) {
            return []
        }

    }

}

export default new TvSceenApi();