import { User, UserRole } from "../../models/user";
import { UserActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface userState {
  user: User | null,
  userRole: UserRole | null,
};

export const initialUserState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') as User : null,
  userRole: localStorage.getItem('userRole') ? JSON.parse(localStorage.getItem('userRole') || '{}') as UserRole : null,
};

const userReducer = (state: userState = initialUserState, action: Action) => {
  if (action && action.payload) {
    const { user, userRole } = action.payload;
    switch (action.type) {
      case UserActions.UPDATE_USER:
        if (user) {
          return {
            ...state,
            user: user
          };
        }

        break;

      case UserActions.LOGIN:
        if (user && userRole) {
          return {
            ...state,
            user: user,
            userRole: userRole
          };
        }

        break;

      case UserActions.LOGOUT:
        if (!user && !userRole) {
          return {
            ...state,
            user: null,
            userRole: null
          };
        }

        break;

      default:
        return { ...state };
    }
  }

  return state;
};

export default userReducer;
