import React, { useEffect, useState } from 'react'
import routeNameObjects from '../../route_names/route_name_objects'
import Page from '../../shared/Page/Page'
import useStyles from './GiftCardsStyles'
import Table from '../../shared/Table/CustomTable'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Button } from '@material-ui/core'
import DateTimePicker from '../../shared/DateTimePicker/DateTimePicker'
import time from '../../core/services/time'


import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { GiftCard, PurchasedGiftCard } from '../../models/giftCard'
import { useSelector } from 'react-redux'
import { IRootState } from '../../core/store'
import { SortObject } from '../../models/sort'
import giftCardApi from '../../core/api/giftCard-api'
import TextField from '../../shared/TextField/TextField'
import WarningButton from '../../shared/Button/WarningButton'
import SuccessButton from '../../shared/Button/SuccessButton'
import {default as DiscountButton} from '../../shared/Button/Button'
import CustomTab from '../../shared/Tab/Tab'
import { Customer } from '../../models/customer'

import RedeemIcon from '@material-ui/icons/Redeem';
import StoreIcon from '@material-ui/icons/Store';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import MyLoader from '../../shared/Loader/my_loader'
import CustomMultiSelectAutocomplete from '../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete'
import moment, { Moment } from 'moment'
import CustomDateRangePicker from '../../shared/DateRangePicker/DateRangePicker'

import MenuItem from '@mui/material/MenuItem';
import { nanoid,customAlphabet } from 'nanoid'
import {addMonths} from 'date-fns'

export default function GiftCards() {
    const classes = useStyles()
    const AllCustomers: Customer[] = useSelector((state: IRootState) => state.filterReducer.customers)
    const giftCards: GiftCard[] = useSelector((state: IRootState) => state.giftCardReducer.giftCards)
    const purchasedGiftCards: PurchasedGiftCard[] = useSelector((state: IRootState) => state.giftCardReducer.purchasedGiftCards)
    const [purchaseStartDate, setpurchaseStartDate] = useState<Date>(time.getLastYear());
    const [purchaseEndDate, setpurchaseEndDate] = useState<Date>(time.getToday());
    const [redeemStartDate, setredeemStartDate] = useState<Date>(time.getLastYear());
    const [redeemEndDate, setredeemEndDate] = useState<Date>(time.getToday());
    const [selectPurchasedDate, setSelectPurchasedDate] = React.useState<[Moment, Moment] | null>([moment(time.getLastYear()), moment(time.getToday())]);
    const [selectRedeemDate, setSelectRedeemDate] = React.useState<[Moment, Moment] | null>([moment(time.getLastYear()), moment(time.getToday())]);
    const [empName, setempName] = useState<string>('')
    const [customerName, setcustomerName] = useState<string[]>([])
    const [redeemStatus, setRedeemStatus] = useState<string[]>([])

    const [sort, setSort] = useState<SortObject>({ orderBy: 'id', order: "asc" });
    const [purchasedSort, setPuechasedSort] = useState<SortObject>({ orderBy: 'id', order: "asc" });
    const [newGiftCard, setnewGiftCard] = useState<GiftCard | null>(null);
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [key, setKey] = useState<string>('')
    const [error, setError] = useState<{ [key: string]: string }>({})
    const [toggleItem, setToggleItem] = useState<GiftCard | null>(null)
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);
    const [cusFilter, setcusFilter] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [clearFilters, setClearFilters] = useState<boolean>(false)
    const [disableTableData, setDisableTableData] = useState<string[]>([])
    const [activeTableFilter, setActiveTableFilter] = useState<string[]>([])
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<GiftCard | null>(null)
    const [displayName, setDisplayName] = useState<string>('')


    const [createDiscountOpen, setCreateDiscountOpen] = useState<boolean>(false);
    const [discount,setDiscount]=useState<null |number>(null)
    const [discountErr,setDiscountErr]=useState<string>("")

    const [giftcardCreateOpen,setGiftcardCreateOpen]=useState<boolean>(false)
    const [giftcardType,setGiftcardType]=useState<any|null>(false)

    const userDetails = useSelector((state: IRootState) => state.userReducer)




    const fetchMasterData = async () => {
        setLoading(true)
        let activeFilterData: boolean[] = []

        giftCards.map((item) => {
            if (item.disable && disableTableData.includes("Active")) {
                // console.log('Active', item.disable, item)
                activeFilterData.push(false)
            }

            if(item.disable === 0 && disableTableData.includes("Inactive")) {
                // console.log('Inactive', item.disable, item)
                activeFilterData.push(true)
            }
        })

        activeFilterData = [...new Set(activeFilterData)]

        // print new array
        // console.log('array', activeFilterData)

        try {
            await giftCardApi.getAll(sort, activeFilterData)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }

    }

    const fetchPurchasedData = async () => {
        let purchaseStart: number = 0
        let redeemStart: number = 0
        let purchaseEnd: number = 0
        let redeemEnd: number = 0
        let customerIds: number[] = []

        setLoading(true)
        // purchaseStart = time.DateToUnix(purchaseStartDate)
        // redeemStart = time.DateToUnix(redeemStartDate)
        // purchaseEnd = time.DateToUnix(purchaseEndDate)
        // redeemEnd = time.DateToUnix(redeemEndDate)
        if (selectPurchasedDate) {
            purchaseStart = time.DateToUnix(selectPurchasedDate[0].toDate())
            purchaseEnd = time.DateToUnix(selectPurchasedDate[1].toDate())
        }

        if (selectRedeemDate) {
            redeemStart = time.DateToUnix(selectRedeemDate[0].toDate())
            redeemEnd = time.DateToUnix(selectRedeemDate[1].toDate())
        }

        if (customerName.length > 0) {
            AllCustomers.map((item) => {
                if (customerName.includes(item.email)) {
                    customerIds.push(item.id)
                }
            })
        }

        try {
             await giftCardApi.getAllPurchased(purchaseStart, purchaseEnd, redeemStart, redeemEnd, customerIds, redeemStatus, purchasedSort)
        } catch (error) {
            setLoading(false)
        }
    }

    const setFilter = () => {
        let cusFilterData: string[] = []
        let activeFilterData: string[] = []

        purchasedGiftCards.map((item) => {
            cusFilterData.push(item.customer_email)
        })

        giftCards.map((item) => {
            if (item.disable || item.disable == 0){
                if(item.disable){
                    activeFilterData.push("Active")
                }
                if(item.disable == 0){
                    activeFilterData.push("Inactive")
                }
                
            }
        })

        // remove duplicate elements from array
        const arr = activeFilterData.filter( function( item, index, inputArray ) {
            return inputArray.indexOf(item) === index;
        });
            
        // print new array
        // console.log('all', arr)

        cusFilterData = [...new Set(cusFilterData)]
        cusFilterData.sort()
        setcusFilter(cusFilterData)
        setActiveTableFilter(arr)
        setLoading(false)
        setClearFilters(false)
    }

    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setnewGiftCard(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }
    const handleEditChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setEditItem(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }

    const handleError = (value: any, name?: string) => {
        const ErrVal: string = name ? name : key
        let ErrVariable: string = ''

        if (!value) {
            ErrVariable = 'This field cannot be empty'
        }

        if (ErrVal === "value" || ErrVal === "validity_period") {
            if (value < 0) {
                ErrVariable = 'Minimum value is 0'
            }
        }

        setError(prevState => ({
            ...prevState,
            [ErrVal]: ErrVariable
        }))
    }



    const handleToggleOpen = (item: GiftCard) => {
        setToggleItem(item)
        setToggleOpen(true);
    };

    const handleToggleClose = () => {
        setToggleOpen(false);
    };

    const handleVisibilityUpdate = async () => {
        setToggleOpen(false);
        if (toggleItem) {
                setLoading(true);
                await giftCardApi.toggleVisibilityById(toggleItem.id, toggleItem.disable === 1 ? 0 : 1).then(() => {
                setLoading(false)
            }
        );
        }
        // if (toggleItem) {
        //     await giftCardApi.toggleVisibilityById(toggleItem.id, toggleItem.disable === 1 ? 0 : 1)
        // }
        // setToggleOpen(false);
    };

    const toggleDialog = (
        < Dialog
            open={toggleOpen}
            onClose={handleToggleClose}
        >
            <DialogTitle >{"Update Gift Card Status"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {toggleItem && toggleItem.disable === 1 ? "deactivate" : "activate"} {toggleItem && toggleItem.id} Gift Card?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleToggleClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleVisibilityUpdate} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleExport = () => {
        type Temp = {
            id: number;
            name: string;
            value: number;
            validity_period: number;
            created_at: Date;
            disable: number;
        }
        let formatData: Temp[] = []

        giftCards.forEach(item => {
            let temp: Temp = {
                id: item.id,
                name: item.name,
                value: item.value,
                validity_period: item.validity_period,
                created_at: time.unixToDate(item.created_at ? item.created_at : 0),
                disable: item.disable
            }
            formatData.push(temp)
        })

        const fileName = `Gift Card Master Data ${time.DateToString(new Date())}.xlsx`
        const ws = XLSX.utils.json_to_sheet(formatData);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    };

    const handlePurchasedExport = () => {
        type Temp = {
            id: number;
            value: number;
            exp_date: Date;
            redeemed_value: number;
            purchase_date: Date;
            last_redeemed_date: Date;
            last_redeemed_value: number;
            vendor_email: string;
            customer_email: string;
            vendor_full_name: string;
            customer_name: null | string;
            redeemStatus: string;
        }
        let formatData: Temp[] = []

        purchasedGiftCards.forEach(item => {
            let temp: Temp = {
                id: item.id,
                value: item.value,
                exp_date: time.unixToDate(item.exp_date ? item.exp_date : 0),
                redeemed_value: item.redeemed_value,
                purchase_date: time.unixToDate(item.purchase_date ? item.purchase_date : 0),
                last_redeemed_date: time.unixToDate(item.last_redeemed_date ? item.last_redeemed_date : 0),
                last_redeemed_value: item.last_redeemed_value,
                vendor_email: item.vendor_email,
                customer_email: item.customer_email,
                vendor_full_name: item.vendor_full_name,
                customer_name: item.customer_name,
                redeemStatus: item.redeemStatus
            }
            formatData.push(temp)
        })

        const fileName = `Purchased Gift Cards Data ${time.DateToString(new Date())}.xlsx`
        const ws = XLSX.utils.json_to_sheet(formatData);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    };

    const handleCreate = () => {
        setSubmitClicked(true)
        if (newGiftCard) {

            const ignore: string[] = ["id", "disable"]
            Object.keys(newGiftCard).map((key) => {
                if (!ignore.includes(key)) {
                    handleError(Reflect.get(newGiftCard, key), key)
                }
            })
        }

        if (createOpen) {
            handleError(displayName, "displayName")
        }

        if (editOpen) {
            handleError(displayName, "displayName")
            handleEditSubmit()
            handleCreateClose()
        }

        setSubmitClicked(true)
        handleCreateSubmit();
        handleCreateClose();

    };

    const handleCreateSubmit = async () => {
        if (newGiftCard) {
            await giftCardApi.create(newGiftCard)
        }
    }

    const handleCreateOpen = () => {
        const item: GiftCard = {
            id: 0,
            name: '',
            value: 1,
            validity_period: 0,
            disable: 0,
        }
        setnewGiftCard(item)
        setError({
            name: '',
            value: '',
            validity_period: ''
        })
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
        setnewGiftCard(null)
        setEditItem(null)
        setKey('')
        setError({})
        setSubmitClicked(false)
    };

    const handleGiftCardOpen = (item: GiftCard) => {
        setError({
            name: '',
            displayName: '',
            image_url: '',
        })
        setEditItem(item)
        setDisplayName(item.name)
        setEditOpen(true);
    };

    const handleEditSubmit = async () => {
        const id: number = editItem ? editItem.id : 0
            if (editItem) {
                console.log(editItem);
                
                await giftCardApi.update( id ,editItem);  
            }
            fetchPurchasedData();
    }

    const createDialog = (
        < Dialog
            open={createOpen || editOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{createOpen ? "Create New Gift Card" : `Update ${editItem?.name} Gift Card`}</DialogTitle>
            {
                newGiftCard &&
                <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid
                            spacing={2}
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("name")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.name}
                                    helperText={error.name}
                                    value={newGiftCard.name}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Gift Card Name"
                                    variant="outlined" />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("value")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.value}
                                    helperText={error.value}
                                    value={newGiftCard.value}
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                    type="number"
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Value"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("validity_period")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.validity_period}
                                    helperText={error.validity_period}
                                    value={newGiftCard.validity_period}
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    type="number"
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Validity Period(Months)"
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            }
            {
                editOpen && editItem &&
                <>

                <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid
                            spacing={2}
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("name")
                                    }}
                                    onChange={handleEditChange}
                                    error={!!error.name}
                                    helperText={error.name}
                                    value={editItem.name}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Gift Card Name"
                                    variant="outlined" />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("value")
                                    }}
                                    onChange={handleEditChange}
                                    error={!!error.value}
                                    helperText={error.value}
                                    value={editItem.value}
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                    type="number"
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Value"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("validity_period")
                                    }}
                                    onChange={handleEditChange}
                                    error={!!error.validity_period}
                                    helperText={error.validity_period}
                                    value={editItem.validity_period}
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    type="number"
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Validity Period(Months)"
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                </>
            }

            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus>
                     {createOpen ? "Create" : "Update"}
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )
    const handleClearFilters = () => {
        setcustomerName([])
        setRedeemStatus([])
        setFilter()
    }

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])

    useEffect(() => {
        if (loading) {
            setFilter()
        }
    }, [purchasedGiftCards, giftCards])

    useEffect(() => {
        if (!loading) {
            fetchPurchasedData()
        }
    }, [purchasedSort, selectPurchasedDate, selectRedeemDate, redeemStartDate, customerName, redeemStatus])

    useEffect(() => {
        if (submitClicked && JSON.stringify(error) === JSON.stringify({
            name: '',
            value: '',
            validity_period: ''
        })) {
            handleCreateSubmit()
            handleCreateClose()
        }
        else {
            setSubmitClicked(false)
        }
    }, [error])


    useEffect(() => {
        fetchMasterData()
    }, [sort, disableTableData])

    useEffect(() => {
        fetchMasterData()
        fetchPurchasedData()
    }, [])


     const handleCreateDiscountOpen = () => {
        setDiscount(null)
        setCreateDiscountOpen(true);
    };

     const handleCreateDiscountClose = () => {
        setCreateDiscountOpen(false);
        setDiscount(null)
    };

    const handleDiscountChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setDiscount(value as number)
        if (value < 0) {
            setDiscountErr("Amount must be positive value.")
        }
        if (value > 99) {
            setDiscountErr("Amount must be less than 100.")
        }
        else if (value === null || value === "") {
            setDiscountErr("This field can't be empty.")
        }
        else {
            setDiscountErr("")
        }
    }

    const handleCreateDiscount = async () => {
        console.log(discount)
        handleCreateDiscountClose()
        const res = await giftCardApi.updateDiscountAll(discount)
        setDiscount(null)
        console.log(res)
        if(res){
            fetchMasterData()
        }
    }

    const createDiscountDialog = (
        < Dialog
            open={createDiscountOpen}
            onClose={handleCreateDiscountClose}
        >
            <DialogTitle >{"Update Discount Percentage"}</DialogTitle>
            <DialogContent>
                <Grid
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <TextField
                            error={!!discountErr}
                            helperText={discountErr}
                            onChange={handleDiscountChange}
                            value={discount}
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            type="number"
                            label="Discount percentage"
                            variant="outlined" />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <WarningButton onClick={handleCreateDiscountClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreateDiscount} color="primary" autoFocus disabled={!discount || !!discountErr}>
                    Update
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    

    const handleGiftcardCreateOpen = () => {
        setGiftcardCreateOpen(true);
    };

     const handleGiftcardCreateClose = () => {
        setGiftcardCreateOpen(false);
        setGiftcardType(null)
    };

    const handleGiftcardTypeChange = (value: string) => {
        setGiftcardType(value)
    }

    const handleGiftcardCreateSubmit = async ()=>{
        const selectedCard:any = giftCards.find(card=>card.id===giftcardType)
        const nowtime:any = time.getToday();
       
        const {discount,name,validity_period,value}=selectedCard;

        const correctedValidityPeriod = validity_period === 0 ? 36 : validity_period 

        console.log(`nowTime -->`, nowtime)

        const newCard = {
            value:value,
            discount:discount,
            purchase_date: time.DateToUnix(nowtime[1]?.toDate()),
            exp_date: time.DateToUnix(addMonths(new Date(), correctedValidityPeriod)),
            user_id:userDetails.user.id,
            redeemed_value:0,
            name:name,
            giftcard_code : generateGiftcardCode(),
        }

        await giftCardApi.createGiftcard(newCard)
        handleGiftcardCreateClose()
        fetchPurchasedData()

    }

    
    const generateGiftcardCode = ()=>{
        const nanoid = customAlphabet('1234567890abcdef', 10)
        let code = nanoid().toUpperCase()
        let includescode = purchasedGiftCards.filter(card=>card.giftcard_code ===code)
        if(includescode.length ===0){
            return code
        }else{
            generateGiftcardCode()
        }
    }
    
    const giftcardCreateDialog = (
        < Dialog
            open={giftcardCreateOpen}
            onClose={handleGiftcardCreateClose}
        >
            <DialogTitle > Create New Gift Card ID </DialogTitle>

                <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"

                    >
                        <Grid
                            spacing={2}
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={12}>
                                           <TextField
                                                value={giftcardType}
                                                label="Giftcard Type"
                                                onChange={(e)=>handleGiftcardTypeChange(e.target.value)}
                                                style={{width:"200px"}}
                                                select
                                            >
                                                {
                                                giftCards.map(card=>
                                                <MenuItem value={card.id} style={{width:"180px"}}>{card.name}</MenuItem>)}
                                            </TextField>
                            </Grid>

                        </Grid>
                    </Grid>
                </DialogContent>
            
            <DialogActions>
                <WarningButton onClick={handleGiftcardCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleGiftcardCreateSubmit} color="primary" autoFocus>
                      Generate ID
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )


    return (
        <Page
            currentRoute={routeNameObjects.giftCards}
            parentRoutes={[routeNameObjects.giftCards]}
        >
            <div className={classes.container}>

                <CustomTab
                    tabsData={[
                        {
                            title: "Gift Card Master",
                            icon: <RedeemIcon />,
                            component: (
                            <div>                                    
                                <DiscountButton color="primary" variant="contained" onClick={handleCreateDiscountOpen}> Update Discount </DiscountButton>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Grid item xs={12} md={6} lg={2}>
                                        <CustomMultiSelectAutocomplete
                                        label="Active/Inactive" 
                                        onChange={setDisableTableData} 
                                        data={activeTableFilter} 
                                        reset={clearFilters} 
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        variant="contained"
                                        size="medium"
                                            style={{ float: "right", fontSize : "12px",
                                            padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                                            onClick={() => {
                                            setClearFilters(true);
                                            }}
                                            startIcon={<ClearAllIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Table
                                    data={giftCards}
                                    isloading={loading}
                                    createFunction={handleCreateOpen}
                                    exportFunction={handleExport}
                                    sortFunction={setSort}
                                    sort={sort}
                                    numberColumns={['value', 'validity_period']}
                                    toggle={
                                        {
                                            toggleFunction: handleToggleOpen,
                                            toggleColumn: "disable"
                                        }
                                    }
                                    ignore={["disable", "id"]}
                                    editFunction={handleGiftCardOpen}
                                />
                            </div>
                            ),
                        },
                        {
                            title: "Purchased Gift Cards",
                            icon: <StoreIcon />,
                            component: (
                                <div>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <Grid
                                            item
                                            xs={10}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={2}
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            <Grid item xs={12} md={6} lg={3}>
                                                {/* <DateTimePicker label="Purchase Start Date" onChange={setpurchaseStartDate} value={purchaseStartDate} reset={clearFilters} defaultValue={time.getLastYear()} /> */}
                                                <CustomDateRangePicker label='Purchase Date' onChange={setSelectPurchasedDate} value={selectPurchasedDate} reset={clearFilters} defaultValue={[moment(time.getLastYear()), moment(time.getToday())]} />
                                            </Grid>
                                            {/* <Grid item xs={12} md={6} lg={2}>
                                                <DateTimePicker label="Purchase End Date" onChange={setpurchaseEndDate} value={purchaseEndDate} reset={clearFilters} defaultValue={time.getToday()} />
                                            </Grid> */}
                                            <Grid item xs={12} md={6} lg={3}>
                                                {/* <DateTimePicker label="Redeem Start Date" onChange={setredeemStartDate} value={redeemStartDate} reset={clearFilters} defaultValue={time.getLastYear()} /> */}
                                                <CustomDateRangePicker label='Redeem Date' onChange={setSelectRedeemDate} value={selectRedeemDate} reset={clearFilters} defaultValue={[moment(time.getLastYear()), moment(time.getToday())]} />
                                            </Grid>
                                            {/* <Grid item xs={12} md={6} lg={2}>
                                                <DateTimePicker label="Redeem End Date" onChange={setredeemEndDate} value={redeemEndDate} reset={clearFilters} defaultValue={time.getToday()} />
                                            </Grid> */}
                                            <Grid item xs={12} md={6} lg={2}>
                                                <CustomMultiSelectAutocomplete label="Customer Email" onChange={setcustomerName} data={cusFilter} reset={clearFilters} />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={2}>
                                                <CustomMultiSelectAutocomplete label="Redeem Status" onChange={setRedeemStatus} data={['NEW', 'FULLY REDEEMED', 'PARTIALLY REDEEMED']} reset={clearFilters} />
                                            </Grid>
                                            {/* <Grid item xs={12} md={6} lg={2}>
                                            <CustomSelect label="Professional Name" onChange={setempName} data={['one', 'two', 'three']} />
                                        </Grid> */}

                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                            variant="contained"
                                            size="medium"
                                                style={{ float: "right", fontSize : "12px",
                                                padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                                                onClick={() => {
                                                setClearFilters(true);
                                                }}
                                                startIcon={<ClearAllIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Table
                                        createFunction={handleGiftcardCreateOpen}
                                        data={purchasedGiftCards}
                                        isloading={loading}
                                        exportFunction={handlePurchasedExport}
                                        sortFunction={setPuechasedSort}
                                        ignore={['id']}
                                        numberColumns={['value', 'redeemed_value', 'last_redeemed_value', 'remaining_value']}
                                        sort={sort}
                                    />
                                </div>
                            ),
                        }
                    ]}
                />
            </div>
            {createDialog}
            {toggleDialog}
            {createDiscountDialog}
            {giftcardCreateDialog}
        </Page>
    )
}
