import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'

interface IProps {
    updateFunction: Function;
}
export default function QRscanner(props: IProps) {
    const [data, setdata] = useState<string>("")

    const handleScan = (data: string | null) => {
        if (data) {
            setdata(data)
        }
    }
    const handleError = (err: any) => {
        console.error(err)
    }

    useEffect(() => {
        props.updateFunction(data)
    }, [data])

    return (
        <div>
            <QrReader
                delay={300}
                onError={(err: any) => { handleError(err) }}
                onScan={(data: string | null) => { handleScan(data) }}
                style={{ width: '100%' }}
                facingMode="environment"
            />
            <p>{data}</p>
        </div>
    )
}
