import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useStyles from './SelectStyle'

interface Props {
    onChange?: Function;
    label: string;
    data: string[];
    disabled?: boolean;
    required?: boolean;
    setInitial?: boolean;
    reset?: boolean;
    setInitialValue?: string;
    defaultValue?: number
}

export default function CustomSelect(props: Props) {
    const classes = useStyles()
    const [value, setValue] = useState<number>(0)
    const [data, setData] = useState<string[]>([])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as number);
    };

    useEffect(() => {
        let selected: string = ''
        if (data && data.length > 0) {
            selected = data[(value / 10) - 1]
        }
        if (props.onChange) {
            props.onChange(selected)
        }

    }, [value]);

    useEffect(() => {
        if (props.reset) {
            setValue(0)
        }
    }, [props.reset]);

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    useEffect(() => {
        if (props?.setInitial) {
            setValue(props.defaultValue  ? (props.defaultValue + 1) * 10 :  10)
        }

        if (props?.setInitialValue && props.defaultValue === undefined) {
            let index = props.data.indexOf(props.setInitialValue)
            if (index !== -1) {
                setValue((index + 1) * 10)
            }
        }
    }, []);

    return (
        <FormControl
            variant="outlined"
            style={{
                width: "100%"
            }}
            error={props?.required}
        >
            <InputLabel classes={{ root: classes.label }} style={{ whiteSpace: "nowrap" }} id="demo-simple-select-outlined-label">{props.label}</InputLabel>
            <Select
                classes={{ root: classes.root, icon: classes.icon }}
                disabled={!!props.disabled ? props.disabled : props.data.length === 0 ? true : false}
                value={value}
                onChange={handleChange}
                labelId="demo-simple-select-outlined-label"
                label={props.label}
            >
                {data.map((item, key) => {
                    return (
                        <MenuItem value={(key + 1) * 10} key={key}>{item}</MenuItem>
                    )
                })}
            </Select>

            {props?.required && <FormHelperText>This field is required!</FormHelperText>}
        </FormControl>
    )
}
