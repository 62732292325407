import httpApi from './http-api';
import store from '../store';
import { SortObject } from '../../models/sort';
import { arrayToString } from './utils';
import SupportActions from '../store/actions/support';
import { Support } from '../../models/support';
import time from '../services/time';
import { LoadingActions } from '../store/actions';

class SupportApi {

    async getAll(
        startDate: number,
        endDate: number,
        sort?: SortObject,
        employee?: number[],
        suite?: number[],
        ticket?: number[]): Promise<Support[]> {

        let url: string = `/SupportTicket/getsupport?from=${startDate}&to=${endDate}`
        if (sort && sort.orderBy) {
            url = url + `&sort=${sort.order}&column=${sort.orderBy}`
        }

        if (employee && employee.length > 0) {
            url = url + `&employee=${arrayToString(employee)}`
        }
        if (suite && suite.length > 0) {
            url = url + `&suite=${arrayToString(suite)}`
        }

        if (ticket && ticket.length > 0) {
            url = url + `&ticket=${arrayToString(ticket)}`
        }

        try {
            const { Support } = await httpApi.get(`${url}`);

            if (Array.isArray(Support)) {
                store.dispatch({
                    type: SupportActions.UPDATE,
                    payload: {
                        supportTickets: Support
                    }
                });
            }
            return Support
        } catch (error) {
            return []
        }

    }
    
    async getByUserId(
        startDate: number,
        endDate: number,
        sort?: SortObject,
        suite?: number[],
        ticket?: number[]): Promise<Support[]> {

        let url: string = `/SupportTicket/getsupportbyId?from=${startDate}&to=${endDate}`
        if (sort && sort.orderBy) {
            url = url + `&sort=${sort.order}&column=${sort.orderBy}`
        }

        if (suite && suite.length > 0) {
            url = url + `&suite=${arrayToString(suite)}`
        }

        if (ticket && ticket.length > 0) {
            url = url + `&ticket=${arrayToString(ticket)}`
        }

        try {
            const { Support } = await httpApi.get(`${url}`);

            if (Array.isArray(Support)) {
                store.dispatch({
                    type: SupportActions.UPDATE,
                    payload: {
                        supportTickets: Support
                    }
                });
            }
            return Support
        } catch (error) {
            return []
        }

    }
    async getAllForAdmin(
       startDate: number,
        endDate: number,
        sort?: SortObject,
        employee?: number[],
        suite?: number[],
        ticket?: number[]): Promise<Support[]> {

        let url: string = `/SupportTicket/admin/getsupport?from=${startDate}&to=${endDate}`
        if (sort && sort.orderBy) {
            url = url + `&sort=${sort.order}&column=${sort.orderBy}`
        }

        if (employee && employee.length > 0) {
            url = url + `&employee=${arrayToString(employee)}`
        }
        if (suite && suite.length > 0) {
            url = url + `&suite=${arrayToString(suite)}`
        }

        if (ticket && ticket.length > 0) {
            url = url + `&ticket=${arrayToString(ticket)}`
        }

        try {
            const { Support } = await httpApi.get(`${url}`);

            if (Array.isArray(Support)) {
                store.dispatch({
                    type: SupportActions.UPDATE,
                    payload: {
                        supportTickets: Support
                    }
                });
            }
            return Support
        } catch (error) {
            return []
        }

    }

    async create(data: Support) {
        const { name, message, suite_id, ticket_status } = data

        try {
            const res = await httpApi.post('/SupportTicket/add', { name, message, suite_id, ticket_raised_datetime: time.DateToUnix(new Date()), ticket_status });
            return res
        } catch (error) {

        }

    }

    async updateState(id: number, state: string, remark?: string) {
        try {
            const res = await httpApi.put(`/SupportTicket/resolve/${id}`, { status: state, closing_remark: remark ? remark : '' });
            if (state === "CANCELLED") {
                this.getByUserId(time.DateToUnix(time.getLastYear()), time.DateToUnix(time.getToday()))
            }
            else {
                this.getAll(time.DateToUnix(time.getLastYear()), time.DateToUnix(time.getToday()))
            }
        } catch (error) {

        }
    }

    async updateSeenState(id: number, state: number) {
        try {
            const res = await httpApi.put(`/SupportTicket/updateSeen/${id}`, { status: state });
            console.log(res)
        } catch (error) {

        }
    }

    async getChatToken(userId: number): Promise<string> {
        try {
            const { token } = await httpApi.get(`/chat/get-token/${userId}`);
            return token
        } catch (error) {
            return ''
        }

    }
}
export default new SupportApi();