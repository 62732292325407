import React from 'react'
import routeNameObjects from '../../route_names/route_name_objects'
import Page from '../../shared/Page/Page'
import useStyles from './ServicesStyles'
import CustomTab from '../../shared/Tab/Tab'
import ServiceTab from './components/Services/service'
import CategoryTab from './components/Category/Category'
import RoomServiceIcon from '@material-ui/icons/RoomService';
import CategoryIcon from '@material-ui/icons/Category';

export default function Services() {
    const classes = useStyles()

    return (
        <Page
            currentRoute={routeNameObjects.services}
            parentRoutes={[routeNameObjects.services]}
        >
            <div className={classes.container}>
                <CustomTab
                    tabsData={[
                        {
                            title: "Service",
                            icon: <RoomServiceIcon />,
                            component: (
                                <ServiceTab />
                            ),
                        },
                        {
                            title: "Category",
                            icon: <CategoryIcon />,
                            component: (
                                <CategoryTab />
                            ),
                        }
                    ]}
                />
            </div>
        </Page>
    )
}
