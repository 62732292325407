import { combineReducers } from 'redux';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer from './user.reducer'
import serviceReducer from './service.reducer';
import employeeReducer from './employee.reducer';
import filterReducer from './filter.reducer';
import rentReducer from './rent.reducer';
import supportReducer from './support.reducer';
import bookingReducer from './booking.reducer';
import giftCardReducer from './giftCard.reducer'
import vendorReducer from './vendor.reducer';
import reviewReducer from './review.reducer';
import dashboardReducer from './dashboard.reducer';
import categoryReducer from './category.reducer';
import loadingReducer from './loading.reducer';
import alertReducer from './alert.reducer';
import tvScreenReducer from './tvScreen.reducer';
import suiteReducer from './suite.reducer';
import locationReducer from './location.reducer';
import {standardHourDataReducer, standardHourCheckboxDataReducer, standardHourTimeElementDataReducer} from './standardHour.reducer'

const rootReducer = combineReducers({
    userReducer,
    serviceReducer,
    employeeReducer,
    filterReducer,
    rentReducer,
    supportReducer,
    bookingReducer,
    giftCardReducer,
    vendorReducer,
    reviewReducer,
    dashboardReducer,
    categoryReducer,
    loadingReducer,
    alertReducer,
    tvScreenReducer,
    suiteReducer,
    locationReducer,
    standardHourDataReducer,
    standardHourCheckboxDataReducer,
    standardHourTimeElementDataReducer
});

const store = createStore(
    rootReducer,
    composeWithDevTools()
);
export type IRootState = ReturnType<typeof rootReducer>

export default store;