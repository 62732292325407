import React, { useEffect, useState } from 'react'
import Table from '../../../../shared/Table/CustomTable'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import { IRootState } from "../../../../core/store";
import { useSelector } from "react-redux";
import TextField from '../../../../shared/TextField/TextField'
import SuccessButton from '../../../../shared/Button/SuccessButton'
import WarningButton from '../../../../shared/Button/WarningButton'
import CustomMultiSelectAutocomplete from '../../../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import useStyles from '../../RentPaymentsStyles';
import rentApi from '../../../../core/api/rent-api';
import { RentHistory, RentMaster } from '../../../../models/rent';
import time from '../../../../core/services/time';
import DatePicker from '../../../../shared/DatePicker/DatePicker';
import { Suite } from '../../../../models/suite';
import { Employee } from '../../../../models/employee';
import CustomDateRangePicker from '../../../../shared/DateRangePicker/DateRangePicker';
import { Moment } from 'moment';
import moment from 'moment';
import { Pagination } from '../../../../models/pagination';
import { SortObject } from '../../../../models/sort';

export default function HistoryTab() {
    const classes = useStyles()
    const rent: RentHistory[] = useSelector((state: IRootState) => state.rentReducer.rentHistoryData)
    const rentHistoryCount: number = useSelector((state: IRootState) => state.rentReducer.rentHistoryCount)
    const vendorMasterRent: RentMaster[] = useSelector((state: IRootState) => state.rentReducer.rentMasterData)
    const allSuites: Suite[] = useSelector((state: IRootState) => state.filterReducer.suites)
    const allEmployees: Employee[] = useSelector((state: IRootState) => state.filterReducer.employees)
    const { userRole, user } = useSelector((state: IRootState) => state.userReducer)

    const [variableCredits, setVariableCredits] = useState<number>(0);

    const [value, setValue] = useState<number>(0);
    const [valueErr, setValueErr] = useState<string>("");
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<null | RentHistory>(null)
    const [sort, setSort] = useState<SortObject>({ orderBy: 'suite_id', order: "asc" });
    const [pagination, setPagination] = useState<Pagination>({ pageNo: 1, limit: 25 })

    const [rentAll, setRentAll] = useState<RentHistory[]>([]);
    const [vendorFilter, setVendorFilter] = useState<string[]>([])
    const [suiteFilter, setSuiteFilter] = useState<string[]>([])
    const [stateFilter, setStateFilter] = useState<string[]>([])

    const [selectDate, setSelectDate] = React.useState<[Moment, Moment] | null>([moment(time.getToday()), moment().add(3, 'months')]);

    const [paymentStartDate, setPaymentStartDate] = useState<Date | null>(null);
    const [vendorName, setVendorName] = useState<string[]>([])
    const [suite, setSuite] = useState<string[]>([])
    const [rentStatus, setRentStatus] = useState<string[]>([])

    const [isloading, setloading] = useState<boolean>(true)
    const [clearFilters, setClearFilters] = useState<boolean>(false)

    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }

    const isPast = (date: number) => {
        if (time.DateToUnix(time.getToday()) > date) {
            return true
        }
        else {
            return false
        }
    }

    const fetchData = async () => {
        setloading(true)
        let suiteIdsFilter: number[] = []
        let empIdsFilter: number[] = []
        let start: number = 0
        let end: number = 0

        if (selectDate) {
            start = time.DateToUnix(selectDate[0].toDate())
            end = time.DateToUnix(selectDate[1].toDate())
        }

        allSuites.map((item) => {
            if (suite.includes(item.display_name)) {
                suiteIdsFilter.push(item.id)
            }
        })

        allEmployees.map((item) => {
            if (vendorName.includes(item.username)) {
                empIdsFilter.push(item.id)
            }
        })

        if (isVendor()) {
            try {
                empIdsFilter = [user.id]
                await rentApi.getMaster({ orderBy: 'suite_id', order: "asc" }, pagination, user.id)
            } catch (error) {

            }
        }

        try {
            await rentApi.getHistory(start, end, pagination, sort, suiteIdsFilter, empIdsFilter, rentStatus)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
        const allRecords = await rentApi.getHistoryAll(start, end, sort, suiteIdsFilter, empIdsFilter, rentStatus)
        setRentAll(allRecords)
    }

    const setFilter = () => {
        let vendorFilterData: string[] = []
        let suiteFilterData: string[] = []
        let statusFilterData: string[] = []

        if(typeof rentAll != 'undefined'){
            rentAll.map((item) => {
                statusFilterData.push(item.rent_status)
                suiteFilterData.push(item.suite_name)
                vendorFilterData.push(item.username)
            })
        }

        vendorFilterData = [...new Set(vendorFilterData)]
        suiteFilterData = [...new Set(suiteFilterData)]
        statusFilterData = [...new Set(statusFilterData)]

        vendorFilterData.sort()
        suiteFilterData.sort()
        statusFilterData.sort()

        setVendorFilter(vendorFilterData)
        setSuiteFilter(suiteFilterData)
        setStateFilter(statusFilterData)
        setloading(false)
        setClearFilters(false)
    }

    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setValue(value as number)
        if (value < 0) {
            setValueErr("Amount must be positive value.")
        }
        else if (value === null || value === "") {
            setValueErr("This field can't be empty.")
        }
        else if (isVendor() && value > variableCredits) {
            setValueErr("Maximum value exceeded.")
        }
        else if (selected && value > selected.balance_payment) {
            setValueErr("Maximum value exceeded.")
        }
        else {
            setValueErr("")
        }

    }

    const handleCreate = async () => {
        if (selected) {
            await rentApi.updateFixedCredit({ id: selected.id, credit: value, referentialRentId: isVendor() ? vendorMasterRent[0].id : '' })
        }
        handleCreateClose()
        fetchData()
    }

    const handleCreateOpen = (item: RentHistory) => {
        setValue(0)
        setSelected(item)
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setValue(0)
        setSelected(null)
    };

    const handlePagination = (page: number, rowsPerPage: number) => {
        setPagination({ pageNo: page, limit: rowsPerPage })
    };

    const createDialog = (
        < Dialog
            open={createOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{`Assign Credits to Week ${selected?.week_no}`}</DialogTitle>
            <DialogContent>
                {
                    selected &&
                    <>
                        {
                            isVendor()
                                ?
                                <DialogContentText>
                                    Available Open Credits : {variableCredits}
                                </DialogContentText>

                                :
                                <>
                                    {/* <DialogContentText>
                                        Professional Name : {selected.username}
                                    </DialogContentText> */}
                                    <DialogContentText>
                                        Professional Suite Number: {selected.suite_name}
                                    </DialogContentText>
                                </>
                        }

                        <DialogContentText>
                            Currently Assigned Fixed Credits : {selected?.credit}
                        </DialogContentText>

                        {
                            !isPast(selected.week_date) &&
                            <Grid
                                spacing={2}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        error={!!valueErr}
                                        helperText={valueErr}
                                        onChange={handleChange}
                                        value={value}
                                        className='full-width'
                                        autoComplete="off"
                                        fullWidth
                                        type="number"
                                        label="Fixed Credits"
                                        variant="outlined" />
                                </Grid>
                            </Grid>
                        }
                    </>
                }


            </DialogContent>

            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus disabled={!value || !!valueErr}>
                    Update
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleClearFilters = () => {
        setVendorName([])
        setSuite([])
        setRentStatus([])
        setFilter()
    }

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])

    useEffect(() => {
        setFilter()
    }, [rentAll])


    useEffect(() => {
        if (vendorMasterRent && vendorMasterRent.length > 0) {
            setVariableCredits(vendorMasterRent[0].credit_balance)
        }
    }, [vendorMasterRent])

    useEffect(() => {
        fetchData()
    }, [selectDate, vendorName, suite, rentStatus, pagination.limit, pagination.pageNo, sort])

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Grid item xs={12} md={6} lg={3}>
                        {/* <DatePicker label="Payment Date" onChange={setPaymentStartDate} value={paymentStartDate} reset={clearFilters} defaultValue={null} enabledDates={paymentFilter} /> */}
                        <CustomDateRangePicker label='Payment Date' onChange={setSelectDate} value={selectDate} reset={clearFilters} defaultValue={[moment(time.getToday()), moment().add(3, 'months')]} />
                    </Grid>
                    {
                        !isVendor() &&
                        <>
                            <Grid item xs={12} md={6} lg={2}>
                                <CustomMultiSelectAutocomplete label="Professional Name" onChange={setVendorName} data={vendorFilter} reset={clearFilters} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={2}>
                                <CustomMultiSelectAutocomplete label="Suite Number" onChange={setSuite} data={suiteFilter} reset={clearFilters} />
                            </Grid>
                        </>
                    }

                    <Grid item xs={12} md={6} lg={2}>
                        <CustomMultiSelectAutocomplete label="Rent Status" onChange={setRentStatus} data={stateFilter} reset={clearFilters} />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ float: "right", fontSize : "12px",
                        padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                        onClick={() => {
                        setClearFilters(true);
                        }}
                        startIcon={<ClearAllIcon />}
                    >
                        Clear All
                    </Button>
                </Grid>
            </Grid>
            {
                isVendor() &&
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Grid item>
                        <div className={classes.balance}>
                            Available Open Credit Balance : {variableCredits}
                        </div>
                    </Grid>
                </Grid>
            }

            <Table
                data={rent}
                isloading={isloading}
                onClick={handleCreateOpen}
                paginationFunction={handlePagination}
                count={rentHistoryCount}
                sort={sort}
                sortFunction={setSort}
                ignore={isVendor() ? ['suite_id', 'username', 'suite_name'] : ['suite_id']}
                order={['id', 'suite_name', 'username']}
                numberColumns={['credit', 'balance_payment', 'rent_amount']}
                overrides={[{ from: 'rent_amount', to: "Weekly Rent Amount" }]}
            />
            {createDialog}
        </div >
    )
}
