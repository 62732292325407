import { Review } from "../../models/review";
import { ReviewActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface reviewState {
  reviews: Review[];
};

export const initialReviewState = {
  reviews: []
};

const reviewReducer = (state: reviewState = initialReviewState, action: Action) => {
  if (action && action.payload) {
    const { reviews } = action.payload;
    switch (action.type) {
      case ReviewActions.UPDATE:
        return {
          ...state,
          reviews: reviews
        };
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default reviewReducer;
