import React, { useEffect, useState } from 'react'
import Table from '../../../../shared/Table/CustomTable'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import { IRootState } from "../../../../core/store";
import { useSelector } from "react-redux";
import TextField from '../../../../shared/TextField/TextField'
import SuccessButton from '../../../../shared/Button/SuccessButton'
import WarningButton from '../../../../shared/Button/WarningButton'
import CustomSelectAutoComplete from '../../../../shared/SelectAutoComplete/SelectAutoComplete'
import { RentMaster } from '../../../../models/rent'
import { Employee } from '../../../../models/employee'
import rentApi from '../../../../core/api/rent-api'
import { SortObject } from '../../../../models/sort';
import { Pagination } from '../../../../models/pagination';

export default function MasterTab() {
    const rent: RentMaster[] = useSelector((state: IRootState) => state.rentReducer.rentMasterData)
    const rentMasterCount: number = useSelector((state: IRootState) => state.rentReducer.rentMasterCount)
    const [value, setValue] = useState<number>(0);
    const [valueErr, setValueErr] = useState<string>("");
    const [createOpen, setCreateOpen] = useState<boolean>(false);

    const [sort, setSort] = useState<SortObject>({ orderBy: 'suite_id', order: "asc" });
    const [selected, setSelected] = useState<null | RentMaster>(null)
    const [pagination, setPagination] = useState<Pagination>({ pageNo: 1, limit: 25 })

    const [isloading, setloading] = useState<boolean>(true)

    const fetchData = async () => {
        setloading(true)

        try {
            await rentApi.getMaster(sort, pagination)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }


    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setValue(value as number)
        if (value < 0) {
            setValueErr("Amount must be positive value.")
        }
        else if (value === null || value === "") {
            setValueErr("This field can't be empty.")
        }
        else {
            setValueErr("")
        }
    }

    const handleCreate = async () => {
        if (selected) {
            await rentApi.updateVariableCredit(selected.id, { credit_amount: value })
        }
        handleCreateClose()
        fetchData()
    }

    const handleCreateOpen = (item: RentMaster) => {
        setValue(0)
        setSelected(item)
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setValue(0)
        setSelected(null)
    };

    const handlePagination = (page: number, rowsPerPage: number) => {
        setPagination({ pageNo: page, limit: rowsPerPage })
    };


    const createDialog = (
        < Dialog
            open={createOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{"Add Open Credit"}</DialogTitle>
            <DialogContent>
                {
                    selected &&
                    <>
                        <DialogContentText>
                            Professional Name : {selected?.username}
                        </DialogContentText>
                        <DialogContentText>
                            Professional Suite : {selected?.suite_name}
                        </DialogContentText>

                        {/* <DialogContentText>
                            Remaining Open Credits : {selected?.credit_balance}
                        </DialogContentText> */}
                    </>
                }
                <Grid
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <TextField
                            error={!!valueErr}
                            helperText={valueErr}
                            onChange={handleChange}
                            value={value}
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            type="number"
                            label="Open Credits"
                            variant="outlined" />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus disabled={!value || !!valueErr}>
                    Update
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    useEffect(() => {
        fetchData()
    }, [sort, pagination.limit, pagination.pageNo])

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div>
            <Table
                data={rent}
                isloading={isloading}
                onClick={handleCreateOpen}
                ignore={["user_id", "suite_id", "id"]}
                sortFunction={setSort}
                paginationFunction={handlePagination}
                count={rentMasterCount}
                sort={sort}
                overrides={[{ from: 'rent_amount', to: "Weekly Rent Amount" },{from:'credit_balance',to:'Open Credit Balance'}]}
                numberColumns={['rent_amount', 'credit_balance']}
            />
            {createDialog}
        </div >
    )
}
