import moment from 'moment'

const unixToDateTime = (unixTime: number) => {
    const dateString = moment.unix(unixTime).format("Do MMMM YYYY, hh:mm a");
    return dateString
}

const unixToDate = (unixTime: number) => {
    const date = moment.unix(unixTime).toDate();
    return date
}

const unixToDateString = (unixTime: number) => {
    const dateString = moment.unix(unixTime).format("DD/MM/YYYY");
    return dateString
}

const DateToUnix = (date: Date) => {
    const dateString = moment(date).unix();
    return dateString
}

const getToday = () => {
    const date = new Date()
    return date
}

const getYesterday = () => {
    let date = new Date()
    date.setDate(date.getDate() - 1)
    return date
}

const getTomorrow = () => {
    let date = new Date()
    date.setDate(date.getDate() + 1)
    return date
}

const getLastYear = () => {
    let date = moment().subtract(1, 'years').toDate()
    return date
}

const DateToString = (date: Date) => {
    const dateString = moment(date).format("DD/MM/YYYY hh:mm");
    return dateString
}

export default {
    unixToDateTime,
    unixToDateString,
    unixToDate,
    getToday,
    getYesterday,
    getTomorrow,
    getLastYear,
    DateToUnix,
    DateToString,
}