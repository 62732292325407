import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import './App.scss';
import Routings from './route_names/routings';
import { connect } from 'react-redux';
import "./firebase";

const theme = createTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif',
        fontSize: 10,
    },
    palette: {
        primary: {
            main: '#C7EBD0',
        },
        secondary: {
            main: '#CCFFFB',
        }
    },
});

function App(props: any) {
    return (
        <ThemeProvider theme={theme}>
            <Routings />
        </ThemeProvider>
    );
}

const mapStateToProps = (state: any) => {

    return {

    }
}

const mapDispatchToProps = () => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)
