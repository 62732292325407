import React, { useCallback, useEffect, useState } from 'react';
import routeNameObjects from '../../route_names/route_name_objects';
import Page from '../../shared/Page/Page';
import useStyles from './CustomerStyles';
import Table from '../../shared/Table/CustomTable';
import { Button, Grid } from '@material-ui/core';
import DateTimePicker from '../../shared/DateTimePicker/DateTimePicker';
import time from '../../core/services/time';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import bookingApi from '../../core/api/booking-api';
import { SortObject } from '../../models/sort';
import { useSelector } from 'react-redux';
import { IRootState } from '../../core/store';
import { Booking, BookingAll } from '../../models/booking';
import { Employee } from '../../models/employee';
import { Customer } from '../../models/customer';
import { Pagination } from '../../models/pagination';
import { Service } from '../../models/service';

import ClearAllIcon from '@material-ui/icons/ClearAll';
import CustomMultiSelectAutocomplete from '../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete';
import moment, { Moment } from 'moment';
import CustomDateRangePicker from '../../shared/DateRangePicker/DateRangePicker';
import { User } from '../../models/user';

export default function CustomerPage() {
    const classes = useStyles();
    const { userRole } = useSelector((state: IRootState) => state.userReducer);
    const AllEmployees: Employee[] = useSelector((state: IRootState) => state.filterReducer.employees);
    const AllCustomers: Customer[] = useSelector((state: IRootState) => state.filterReducer.customers);
    const AllBookings: Booking[] = useSelector((state: IRootState) => state.filterReducer.bookings);
    const AllServices: Service[] = useSelector((state: IRootState) => state.filterReducer.services);
    const bookings: Booking[] = useSelector((state: IRootState) => state.bookingReducer.bookings);
    const totalDataCount: number = useSelector((state: IRootState) => state.bookingReducer.total);
    const [bookingStartDate, setbookingStartDate] = useState<Date | null>(new Date(new Date().getFullYear(), 0, 1));
    const [bookingEndDate, setbookingEndDate] = useState<Date | null>(new Date(new Date().getFullYear(), 11, 31));
    const [selectDate, setSelectDate] = React.useState<[Moment, Moment] | null>([
        moment(new Date(new Date().getFullYear(), 0, 1)),
        moment(new Date(new Date().getFullYear(), 11, 31)),
    ]);
    const [empName, setempName] = useState<string[]>([]);
    const [customerName, setcustomerName] = useState<string[]>([]);
    const [bookingId, setbookingId] = useState<number[]>([]);
    const [category, setcategory] = useState<string[]>([]);
    const [empFilter, setempFilter] = useState<string[]>([]);
    const [customerFilter, setcustomerFilter] = useState<string[]>([]);
    const [categoryFilter, setcategoryFilter] = useState<string[]>([]);
    const [bookingFilter, setbookingFilter] = useState<number[]>([]);
    const [sort, setSort] = useState<SortObject>({ orderBy: 'id', order: 'asc' });
    const [pagination, setPagination] = useState<Pagination>({ pageNo: 1, limit: 25 });
    const [exportData, setexportData] = useState<BookingAll[]>([]);

    const [clearFilters, setClearFilters] = useState<boolean>(false);
    const [isloading, setloading] = useState<boolean>(true);

    const [customerTableInfo, setCustomerTableInfo] = useState<any[]>([]);

    useEffect(() => {
        let uniqueObjArray = [...new Map(AllCustomers.map((item: any) => [item['id'], item])).values()];

        setCustomerTableInfo(uniqueObjArray);
    }, [AllCustomers]);

    const fetchData = async () => {
        setloading(true);
        // let start: number = 0;
        // let end: number = 0;
        // let empFilterData: number[] = [];
        // let customerFilterData: number[] = [];
        // let serviceFilterData: number[] = [];
        // if (bookingStartDate && bookingEndDate) {
        //     start = time.DateToUnix(bookingStartDate);
        //     end = time.DateToUnix(bookingEndDate);
        // }
        // AllEmployees.map((item) => {
        //     if (empName.includes(item.full_name)) {
        //         empFilterData.push(item.id);
        //     }
        // });

        // AllServices.map((item) => {
        //     if (category.includes(item.type)) {
        //         serviceFilterData.push(item.id);
        //     }
        // });

        // AllCustomers.map((item) => {
        //     if (customerName.includes(item.email)) {
        //         customerFilterData.push(item.id);
        //     }
        // });

        // if (selectDate) {
        //     start = time.DateToUnix(selectDate[0].toDate());
        //     end = time.DateToUnix(selectDate[1].toDate());
        // }

        // try {
        //     await bookingApi.getAll(start, end, pagination, sort, empFilterData, bookingId, customerFilterData);
        // } catch (error) {
        //     setloading(false);
        // }
        // const res = await bookingApi.getAllWithOutPagination(
        //     start,
        //     end,
        //     sort,
        //     empFilterData,
        //     bookingId,
        //     customerFilterData,
        // );
        // console.log(res);
        // setexportData(res);

        setloading(false);
    };

    const setFilter = () => {
        let empFilterData: string[] = [];
        let customerFilterData: string[] = [];
        let bookingFilterData: number[] = [];
        let serviceFilterData: string[] = [];

        let empFilterDataTemp: number[] = [];
        let serviceFilterDataTemp: number[] = [];
        exportData.map((item) => {
            bookingFilterData.push(item.id);
            customerFilterData.push(item.customer.email);
            empFilterDataTemp.push(item.user_id);
            serviceFilterDataTemp.push(item.service_id);
        });

        AllEmployees.map((item) => {
            if (empFilterDataTemp.includes(item.id)) {
                empFilterData.push(item.full_name);
            }
        });

        AllServices.map((item) => {
            if (serviceFilterDataTemp.includes(item.id)) {
                serviceFilterData.push(item.type);
            }
        });

        empFilterData = [...new Set(empFilterData)];
        bookingFilterData = [...new Set(bookingFilterData)];
        serviceFilterData = [...new Set(serviceFilterData)];
        customerFilterData = [...new Set(customerFilterData)];
        empFilterData.sort();
        customerFilterData.sort();
        bookingFilterData.sort((a, b) => a - b);
        serviceFilterData.sort();
        setbookingFilter(bookingFilterData);
        setcustomerFilter(customerFilterData);
        setempFilter(empFilterData);
        setcategoryFilter(serviceFilterData);

        setloading(false);
        setClearFilters(false);
    };

    const isVendor = (): boolean => {
        if (userRole.name === 'vendor') {
            return true;
        } else {
            return false;
        }
    };

    const handleExport = () => {
        type Temp = {
            id: number;
            booked_date_time: Date;
            service_id: number;
            user_id: number;
            booking_duration: number;
            booking_value: null | number;
            status: string;
            gift_card_id: number;
            gift_card_value: number;
            customer: Customer;
            user: User;
            service: Service;
            redeem_type: string;
            redeemer: string;
        };
        let formatData: Temp[] = [];

        exportData.forEach((item) => {
            let temp: Temp = {
                id: item.id,
                booked_date_time: time.unixToDate(item.booked_date_time ? item.booked_date_time : 0),
                service_id: item.service_id,
                user_id: item.user_id,
                booking_duration: item.booking_duration,
                booking_value: item.booking_value,
                status: item.status,
                gift_card_id: item.gift_card_id,
                gift_card_value: item.gift_card_value,
                customer: item.customer,
                user: item.user,
                service: item.service,
                redeem_type: item.redeem_type,
                redeemer: item.redeemer,
            };
            formatData.push(temp);
        });
        const fileName = `Booking Data ${time.DateToString(new Date())}.xlsx`;
        const ws = XLSX.utils.json_to_sheet(formatData);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    };

    const handlePagination = (page: number, rowsPerPage: number) => {
        setPagination({ pageNo: page, limit: rowsPerPage });
    };

    const handleClearFilters = () => {
        setbookingId([]);
        setcustomerName([]);
        setempName([]);
        setFilter();
    };

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters();
        }
    }, [clearFilters]);

    useEffect(() => {
        setFilter();
    }, [exportData]);

    useEffect(() => {
        fetchData();
    }, [
        sort,
        selectDate,
        bookingStartDate,
        bookingEndDate,
        empName,
        customerName,
        bookingId,
        pagination.limit,
        pagination.pageNo,
    ]);

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Page currentRoute={routeNameObjects.customers} parentRoutes={[routeNameObjects.customers]}>
            <div className={classes.container}>
                <Table
                    data={customerTableInfo}
                    exportFunction={handleExport}
                    paginationFunction={handlePagination}
                    count={totalDataCount}
                    sortFunction={setSort}
                    sort={sort}
                    isloading={isloading}
                    ignore={
                        isVendor()
                            ? ['id', 'created_at', 'dob']
                            : [
                                  'redeemer',
                                  'redeem_type',
                                  'user_id',
                                  'service_id',
                                  'sub_service_id',
                                  'service_Description',
                                  'user_full_name',
                                  'user_email',
                                  'username',
                                  'suite_name',
                              ]
                    }
                    overrides={[
                        { from: 'suite_display_name', to: 'Sub-Suite Number' },
                        { from: 'sub_type', to: 'Sub-Service Type' },
                    ]}
                    // numberColumns={['booking_duration', 'booking_value', 'gift_card_value']}
                />
            </div>
        </Page>
    );
}
