import {
  HomeOutlined,
  LocalOffer,
  Timeline,
  AccountBalanceWalletOutlined,
  RedeemOutlined,
  BusinessCenterOutlined,
  EventNoteOutlined,
  PeopleAltOutlined,
  MonetizationOnOutlined,
  HomeWorkOutlined
} from "@material-ui/icons";
import LoginPage from '../pages/Login/Login'
import SignUpPage from '../pages/SignUp/SignUp'
import ResetPasswordPage from '../pages/ResetPassword/ResetPassword'
import DashboardPage from '../pages/Dashboard/Dashboard'
import SupportTicketPage from "../pages/SupportTicket_Master/SupportTicket"
import RentPaymentsPage from '../pages/RentPayments_Master/RentPayments'
import GiftCardsPage from '../pages/GiftCards_Master/GiftCards'
import ServicesPage from '../pages/Services_Master/Services'
import SuitesPage from '../pages/Suites_Master/Suites'
import BookingsPage from '../pages/Bookings_Master/Bookings'
import EmployeesPage from '../pages/Employees_Master/Employees'
import LocationsPage from '../pages/Location_Master/Location'

import EditProfile from '../pages/EditProfile/EditProfile';
import nestedRouteNameObject1 from './nested_route_name_objects1';
import CustomerPage from '../pages/Customers/Customers';

const routeNameObjects = {
  login: {
    name: "Login",
    route: "/login",
    component: LoginPage,
    children: [],
    nestedLevel: 0,
    icon: HomeOutlined,
  },
  signupUser: {
    name: "Sign Up",
    route: "/signup/vendor/:token",
    component: SignUpPage,
    children: [],
    nestedLevel: 0,
    icon: HomeOutlined,
  },
  signupAdmin: {
    name: "Sign Up",
    route: "/signup/admin/:token",
    component: SignUpPage,
    children: [],
    nestedLevel: 0,
    icon: HomeOutlined,
  },
  passwordReset: {
    name: "Password Reset",
    route: "/forgot-password/:token",
    component: ResetPasswordPage,
    children: [],
    nestedLevel: 0,
    icon: HomeOutlined,
  },
  updateUser: {
    name: "Account Update",
    route: "/account-update",
    component: SignUpPage,
    children: [],
    isHide: true,
    nestedLevel: 0,
    icon: HomeOutlined,
  },
  dashboard: {
    name: "Dashboard",
    route: "/dashboard",
    component: DashboardPage,
    children: [],
    nestedLevel: 0,
    icon: Timeline,
  },
  supportTicket: {
    name: "Support Ticket",
    route: "/support-ticket",
    component: SupportTicketPage,
    children: [nestedRouteNameObject1.supportTicket],
    nestedLevel: 0,
    icon: LocalOffer,
  },
  rentPayments: {
    name: "Rent Payments",
    route: "/rent-payments",
    component: RentPaymentsPage,
    nestedLevel: 0,
    icon: AccountBalanceWalletOutlined,
    children: [],
  },
  giftCards: {
    name: "Gift Cards",
    route: "/gift-cards",
    component: GiftCardsPage,
    children: [],
    nestedLevel: 0,
    icon: RedeemOutlined,
  },
  services: {
    name: "Services",
    route: "/services",
    component: ServicesPage,
    nestedLevel: 0,
    icon: BusinessCenterOutlined,
    children: [],
  },
  suites: {
    name: "Suites",
    route: "/suites",
    component: SuitesPage,
    nestedLevel: 0,
    icon: HomeWorkOutlined,
    children: [],
  },
  bookings: {
    name: "Bookings",
    route: "/bookings",
    component: BookingsPage,
    children: [],
    nestedLevel: 0,
    icon: EventNoteOutlined,
  },
  employees: {
    name: "Users",
    route: "/users",
    component: EmployeesPage,
    children: [],
    nestedLevel: 0,
    icon: PeopleAltOutlined,
  },
  editProfile: {
    name: "Edit Profile",
    route: "/edit-profile",
    component: EditProfile,
    children: [],
    nestedLevel: 0,
    icon: PeopleAltOutlined,
  },
  locations: {
    name: "Locations",
    route: "/location",
    component: LocationsPage,
    children: [],
    nestedLevel: 0,
    icon: PeopleAltOutlined,
  },


    customers: {
        name: 'Customers',
        route: '/customers',
        component: CustomerPage,
        children: [],
        nestedLevel: 0,
        icon: EventNoteOutlined,
    },
};

export default routeNameObjects;
