interface Action {
    type: string;
    payload: any;
}

const initialStandardHourDataState = () => {
    let state = {}
    return state
}

const initialTimeElementState = () => {
    let state = Array()
    return state
}

const initialCheckboxValues = () => {
    let state = {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false}
    return state
}

export const standardHourDataReducer = (state=initialStandardHourDataState(), action:Action) => {
    switch(action.type){
        case "ADD_HOUR_DATA":
            return addNewStandardHourData(state, action.payload)
        case "CLEAR_HOUR_DATA":
            return initialStandardHourDataState()
        case "UPDATE_HOUR_DATA":
            return updateStandardHourData(state, action.payload)
        default:
            return state
    }
}

export const standardHourTimeElementDataReducer = (state=initialTimeElementState(), action:Action) => {
    switch(action.type){
        case "ADD_ELEMENT_DATA":
            return addElementData(state, action.payload)
        case "CLEAR_ELEMENT_DATA":
            return initialTimeElementState()
        case "UPDATE_ELEMENT_DATA":
            return updateElementData(state, action.payload)
        default:
            return state
    }
}

export const standardHourCheckboxDataReducer = (state=initialCheckboxValues(), action:Action) => {
    switch(action.type){
        case "CLEAR_CHECKBOX_DATA":
            return initialCheckboxValues()
        case "UPDATE_CHECKBOX_DATA":
            return updateCheckboxData(state, action.payload)
        default:
            return state
    }
}

const addNewStandardHourData = (state:any, payload:any) => {
    if(!(payload.uuid in state)){
        state[payload.uuid] = payload.value
    }
    return state
}

const updateStandardHourData = (state:any, payload:any) => {
    return payload
}

const addElementData = (state:any, payload:any) => {
    return state.push(payload)
}

const updateElementData = (state:any, payload:any) => {
    return payload
}

const updateCheckboxData = (state:any, payload:any) => {
    return payload
}