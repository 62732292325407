import httpApi from './http-api';
import store from '../store';
import { SortObject } from '../../models/sort';
import { GiftCardActions, LoadingActions } from '../store/actions';
import { GiftCard, PurchasedGiftCard } from '../../models/giftCard';
import { arrayToString } from './utils';

class GiftCardApi {

    async getAll(sort?: SortObject, disable?: boolean[]): Promise<GiftCard[]> {

        let url: string = `/giftcard/master/all`
        if (sort && sort.orderBy) {
            url = url + `?sort=${sort.order}&column=${sort.orderBy}`
        }

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { gift_cards_master } = await httpApi.get(`${url}`);

            if (Array.isArray(gift_cards_master)) {
                store.dispatch({
                    type: GiftCardActions.UPDATE_MASTER,
                    payload: {
                        giftCards: gift_cards_master
                    }
                });
            }
            return gift_cards_master
        } catch (error) {
            return []
        }

    }

    async getAllPurchased(purchaseStartDate: number, purchaseEndDate: number, redeemedStartDate?: number, redeemedEndDate?: number, customer?: number[], redeemStatus?: string[], sort?: SortObject): Promise<PurchasedGiftCard[]> {
      
        let url: string = `/giftcard/getgAll`
        if (sort && sort.orderBy) {
            url = url + `&sort=${sort.order}&column=${sort.orderBy}&purchaseDateFrom=${purchaseStartDate}&purchaseDateTo=${purchaseEndDate}`
        }

        if (redeemedStartDate) {
            url = url + `&redeemDateFrom=${redeemedStartDate}`
        }

        if (redeemedEndDate) {
            url = url + `&redeemDateTo=${redeemedEndDate}`
        }

        if (redeemStatus && redeemStatus.length > 0) {
            let state: string[] = []
            redeemStatus.map((item) => {
                state.push(item.replace(" ", "_"))
            })
            url = url + `&redeemStatus=${arrayToString(state)}`
        }

        if (customer && customer.length > 0) {
            url = url + `&customer=${arrayToString(customer)}`
        }

        if (url !== '/giftcard/getgAll') {
            url = url.replace("&", "?")
        }

        try {
        //    const { gift_card } = await httpApi.get(`${url}`);
            const { gift_card } = await httpApi.get(`/giftcard/getgAll?purchaseDateFrom=1&purchaseDateTo=9999999999`);


            if (Array.isArray(gift_card)) {
                store.dispatch({
                    type: GiftCardActions.UPDATE_PURCHASED,
                    payload: {
                        purchasedGiftCards: gift_card
                    }
                });
            }

            return gift_card
        } catch (error) {
            return []
        }

    }

    async create(data: GiftCard) {

        let { name, value, validity_period } = data
        try {
            const res = await httpApi.post('/giftcard/master/add', { name, value, validity_period });
            this.getAll()
            return res
        } catch (error) {

        }

    }

    async update(id : number ,data: GiftCard) {
        let { name, value, validity_period } = data
        try {
            const res = await httpApi.post(`/giftcard/master/update/${id}`, { name, value, validity_period });
            this.getAll()
            return res
        } catch (error) {

        }

    }

    async validate(id: string, value: number) {
        try {
            const { Status, message } = await httpApi.get(`/giftcard/gift-card-verify?giftcardId=${id}&redeemValue=${value}`);

            if (message) {
                store.dispatch({
                    type: GiftCardActions.UPDATE_VALID_MESSAGE,
                    payload: {
                        validMessage: message,
                        validState: Status
                    }
                });
            }
        } catch (error) {

        }

    }

    async redeemGiftCardWalkIn(data: Object) {
        try {
            const res = await httpApi.put('/giftcard/redeemwalkIn', data);
            return res
        } catch (error) {

        }

    }

    async redeemGiftCardBooking(data: Object) {
        try {
            const res = await httpApi.put('/giftcard/redeem', data);
            return res
        } catch (error) {

        }

    }
    async toggleVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.put(`/giftcard/master/delete/${id}`, { disable: visibility });
            this.getAll()
        } catch (error) {

        }

    }

    async updateDiscountAll(data: number |null) {
        try {
            const res = await httpApi.post('/giftcard/update/discount', {discount:data});
            return res
        } catch (error) {

        }
    }

    async createGiftcard(data: any) {

        try {
            const res = await httpApi.post('/giftcard/add', data);
            return res
        } catch (error) {

        }

    }


}
export default new GiftCardApi();