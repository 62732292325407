import { CircularProgress, Paper } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../core/store'
import useStyles from './StatsViewStyles'

interface IProps {
    loading: boolean;
}
export default function SumOfRevenue(props: IProps) {
    const classes = useStyles()
    const sumOfRev: number = useSelector((state: IRootState) => state.dashboardReducer.sumOfRev)
    return (
        <Paper className={classes.dataCard}>
            <div className={classes.dataCardTitle}>
                Sum Of Revenue
            </div>
            <div className={classes.dataCardValue}>
                {
                    props.loading ?
                        <CircularProgress />
                        :
                        <>
                            ${sumOfRev.toFixed(1).toString()}
                        </>
                }
            </div>
        </Paper>
    )
}
