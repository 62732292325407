import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import useStyles from './SuitesStyles'
import suiteApi from '../../core/api/suite-api'
import { IRootState } from '../../core/store';
import { SortObject } from '../../models/sort';
import { Suite } from '../../models/suite';
import routeNameObjects from '../../route_names/route_name_objects'
import Page from '../../shared/Page/Page'
import Table from '../../shared/Table/CustomTable'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import CustomSelect from '../../shared/Select/Select';
import WarningButton from '../../shared/Button/WarningButton';
import SuccessButton from '../../shared/Button/SuccessButton';
import TextField from '../../shared/TextField/TextField';
import CustomSelectAutoComplete from '../../shared/SelectAutoComplete/SelectAutoComplete';
import CustomTab from '../../shared/Tab/Tab';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import LocationImage from './components/LocationImage/LocationImage';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CustomMultiSelectAutocomplete from '../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete'
import { Location } from '../../models/location';
import PlaceIcon from '@material-ui/icons/Place';
import locationApi from "../../core/api/location-api"

export default function Suites() {
    const classes = useStyles()
    const suites: Suite[] = useSelector((state: IRootState) => state.suiteReducer.allSuites)
    const mainSuites: Suite[] = useSelector((state: IRootState) => state.suiteReducer.allMainSuites)
    const locationData: Location[] = useSelector((state: IRootState) => state.locationReducer.allLocations)
    const [sort, setSort] = useState<SortObject>({ orderBy: 'id', order: "asc" });
    const [isloading, setloading] = useState<boolean>(true)
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [createType, setcreateType] = useState<string>('Create new suite')
    const [editType, seteditType] = useState<string>('Edit suite')
    const [error, setError] = useState<{ [key: string]: string }>({})
    const [suiteType, setsuiteType] = useState<string>('Signature')
    const [name, setName] = useState<string>('')
    const [locationStr, setLocationStr] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [rent, setRent] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    const [displayName, setDisplayName] = useState<string>('')
    const [selectedImageFiles, setSelectedImageFiles] = useState<any>([]);
    const [suiteFilter, setsuiteFilter] = useState<string[]>([])
    const [mainSuite, setmainSuite] = useState<string>('')

    const [toggleItem, setToggleItem] = useState<Suite | Location | null>(null)
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);

    const [editItem, setEditItem] = useState<Suite | Location | null | any>(null)
    const [editLocationItem, setEditLocationItem] = useState<Location | null>(null)
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [activeTableFilter, setActiveTableFilter] = useState<string[]>([])
    const [disableTableData, setDisableTableData] = useState<string[]>([])
    const [clearFilters, setClearFilters] = useState<boolean>(false)
    const [locations,setLocations]=useState<Location[] | null>(null)
    const [locationNames,setLocationNames]=useState<string[]>([])
    const [locationId,setLocationId]=useState<string>("")
    const [key, setKey] = useState<string>('')
    const { userRole } = useSelector((state: IRootState) => state.userReducer)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [location, setlocation] = useState<Location | null>(null)


    const fetchLocations = async () => {
        setloading(true)
        let activeFilterData: boolean[] = []
console.log("locationData suite", locationData);

        locationData.map((item) => {
            if (item.disabled && disableTableData.includes("Active")) {
                activeFilterData.push(false)
            }

            if (item.disabled === 0 && disableTableData.includes("Inactive")) {
                activeFilterData.push(true)
            }
        })

        activeFilterData = [...new Set(activeFilterData)]


        try {
            let locations;
            if (userRole.name === "admin"){
                locations =   await locationApi.getLocationsForAdmin()
            }
            else {
                locations =   await locationApi.getLocations()
           }
            let location_names:string[] = []
            locations.forEach((loc : any)=>location_names.push(loc.name))
            console.log("locations, locations", locations)
            setLocationNames(location_names)
            setLocations(locations)
        } catch (error) {
            setloading(false)
        }
        setloading(false)
    }

    useEffect( () => {
         fetchLocations()  
    }, [createOpen])
    

    const fetchData = async () => {
        setloading(true)
        let activeFilterData: boolean[] = []

        suites.map((item) => {
            if (item.disabled && disableTableData.includes("Active")) {
                // console.log('Active', item.disable, item)
                activeFilterData.push(false)
            }

            if(item.disabled === 0 && disableTableData.includes("Inactive")) {
                // console.log('Inactive', item.disable, item)
                activeFilterData.push(true)
            }
        })

        activeFilterData = [...new Set(activeFilterData)]

        // print new array
        // console.log('array', activeFilterData)

        try {
            
            if(userRole.name === "admin"){
              await suiteApi.getAllSuiteForAdmin(sort, activeFilterData)
            }
            else{
                await suiteApi.getAll(sort, activeFilterData)
            }
            
            await suiteApi.getAllMainSuites()
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }

    const setFilter = () => {
        let suiteFilterData: string[] = []
        let activeFilterData: string[] = []

        suites.map((item) => {
            if (item.is_main === 1 && item.disabled === 1) {
                suiteFilterData.push(item.name)
            }

            if (item.disabled || item.disabled == 0){
                if(item.disabled){
                    activeFilterData.push("Active")
                }
                if(item.disabled == 0){
                    activeFilterData.push("Inactive")
                }
                
            }
        })

        // remove duplicate elements from array
        const arr = activeFilterData.filter( function( item, index, inputArray ) {
            return inputArray.indexOf(item) === index;
        });
                    
        // print new array
        // console.log('all', arr)

        suiteFilterData = [...new Set(suiteFilterData)]
        suiteFilterData.sort()

        setsuiteFilter(suiteFilterData)
        setActiveTableFilter(arr)
        setloading(false)
        setClearFilters(false)
    }

    const handleError = (value: any, name?: string) => {
        const ErrVal: string = name ? name : ''
        let ErrVariable: string = ''

        if (!value) {
            ErrVariable = 'This field cannot be empty'
        }

        if (name === 'email') {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(value)) {
                ErrVariable = "Please enter valid email address";
            }
        }

        if (name === "image_url") {
            if (value?.length === 0) {
                ErrVariable = 'This field cannot be empty'
            }
        }

        setError(prevState => ({
            ...prevState,
            [ErrVal]: ErrVariable
        }))
    }

    const handleCreateTypeChange = (value: string) => {
        setcreateType(value)
        if (value === 'Create new sub-suite') {
            setError(prevState => ({
                ...prevState,
                name: '',
                image_url: '',
            }))
            setName('')
            setSelectedImageFiles([])
        }
    }

    const handleSuiteTypeChange = (value: string) => {

        if(editOpen && editItem ){
            setEditItem((prevState : any) => (prevState && {
            ...prevState,
            suite_type: value
        }))
        }

        setsuiteType(value)
    }

    const handleMainSuiteTypeChange = (value: string) => {
        setmainSuite(value)
    }

    const handleNameChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setName(value as string)
        setEditItem((prevState : any) => (prevState && {
            ...prevState,
            [key]: value
        }))
        handleError(value, "name")
    }


    const handleRentChange = (event: any) => {
        event.persist();
        const { value } = event.target
        if(editItem){
            setEditItem((prevState : any) => (prevState && {
                ...prevState,
                [key]: value
            }))
        }
        setRent(value as string)
        handleError(value, "rent")
    }

    const handleDescriptionChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setDescription(value as string)

        if(editItem){
            setEditItem((prevState : any) => (prevState && {
                ...prevState,
                "description": value as string
            }))
        }

        handleError(value, "description")
    }

    const handleLocationChange = (value: string) => {
        
        setLocationStr(value)
        const selectedLocation  = locations?.find((loc)=>loc.name === value)?.id as number
        setLocationId(selectedLocation?.toString())
        const selectedAddress  = locations?.find((loc)=>loc.name === value)?.address as string
        setAddress(selectedAddress)
    }

    const handleDisplayNameChange = (event: any) => {
        event.persist();
        const { value } = event.target
        if(editItem){
        setEditItem((prevState : any) => (prevState && {
            ...prevState,
            [key]: value
        }))
        }
        
        setDisplayName(value as string)
        handleError(value, "displayName")
    }

    const handleImageChange = (files: any) => {
        setSelectedImageFiles(files)
        handleError(files, "image_url")
    }

    const dataUrlToFile = (blobUrl: string, fileName: string): Promise<File> => new Promise(async (resolve) => {
        fetch(blobUrl,
            {
                method: 'GET',
                mode: 'no-cors',
            }).then((res) => {

                res.blob().then((blob) => {
                    const file = new File([blob], fileName, { type: blob.type })
                    resolve(file)
                    
                    return file;
                })
            })
    })

    const handleCreateOpen = () => {
        if(selectedTab === 1){
            const item: Location = {
            id: 0,
            name: "",
            address: "",
            disabled: 0,
        }
          setlocation(item)
            setError({
                name: "",
                message: ""
            })
        }
        else{
            setError({
                name: '',
                displayName: '',
                image_url: '',
                locationStr:'',
                address:'',
                rent:'',
                description:'',
            })

        }
        setCreateOpen(true);
        setcreateType("Create new suite")
    };

    const handleCreateClose = () => {
        setName('')
        setDisplayName('')
        setSelectedImageFiles([])
        setsuiteType('')
        setmainSuite('')
        setLocationStr('')
        setAddress('')
        setRent('')
        setDescription('')
        setCreateOpen(false);
        setEditOpen(false);
        setSubmitClicked(false)
        setlocation(null)
    };

    const handleCreate = async () => {
        if (createOpen && createType === 'Create new suite') {
            handleError(name, "name")
            handleError(address, "address")
            handleError(locationStr, "locationStr")
            handleError(rent, "rent")
            handleError(description, "description")
            // handleError(selectedImageFiles, "image_url")
        }

        if (createOpen && createType === 'Create new sub-suite') {
            handleError(displayName, "displayName")
        }

        if (editOpen) {
            if (!!selectedImageFiles[0]) {
                // handleError(selectedImageFiles, "image_url")
            }
   
            handleEditSubmit()
            handleCreateClose()
            
        }

        if (createOpen && location) {
            Object.keys(location).map((key) => {
                if (key === "name" || key === "address") {
                    handleError(Reflect.get(location, key), key)
                }

            })
            handleCreateSubmit()
            handleCreateClose()
        }
        // if (editOpen && editType === 'Edit sub-suite') {
        //     handleError(displayName, "displayName")
        //     handleEditSubmit()
        //     handleCreateClose()
        // }

        setSubmitClicked(true)
    };

    const handleCreateSubmit = async () => {
        
        if (selectedTab === 0) {
            let form_data = new FormData();

            const file = await dataUrlToFile(selectedImageFiles?.image_url, selectedImageFiles[0]?.name);

            form_data.append('name', name);
            form_data.append('display_name', name);

            form_data.append('image_url', file);

            form_data.append('suite_type', suiteType);
            form_data.append('location_id', locationId);
            form_data.append('address', address);
            form_data.append('weekly_rent_amount', rent);
            form_data.append('description', description);

            await suiteApi.createSuite(form_data)
          
            fetchData()
        }
        else if(selectedTab === 1){
            if (location) {
            await locationApi.create(location)
            fetchLocations()
        }
        }

    }

    const handleEditChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setEditItem((prevState : any) => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }

    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setlocation((prevState : any) => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }

    const handleLocationEditOpen = (item: Location) => {
        
        setError({
            name: '',
            address: '',
        })
        setEditLocationItem(item)
        setDisplayName(item.name)
        setEditOpen(true);
    };

    const handleEditOpen = (item: Suite | Location | any) => {
        console.log(item);
        
        // seteditType('Edit sub-suite')
        setError({
            name: '',
            displayName: '',
            image_url: '',
        })
        setEditItem(item)
        setDescription(item?.description)
        
        setDisplayName(selectedTab === 0 ? item.display_name  : item.name)
        setEditOpen(true);
    };

    const handleEditSubmit = async () => {
        
        console.log(editItem);
        
        if(selectedTab === 0){

            const id: number = editItem ? editItem.id : 0

            let form_data = new FormData();

                let file : File;
                                
                if(selectedImageFiles.length > 0){
                    file = await dataUrlToFile(selectedImageFiles?.image_url, selectedImageFiles[0]?.name);
                    form_data.append('image_url',  file);
                }
                else{
                    form_data.append('image_url', editItem ? editItem?.image_url : "");
                }


            form_data.append('name', editItem ? editItem.name : name);
            // form_data.append('display_name', editItem ? editItem.name : name);

            form_data.append('suite_type', editItem ? editItem.suite_type : suiteType);
            form_data.append('location_id', editItem ? editItem.location_id.toString() : locationStr);
            form_data.append('address', editItem ? editItem.address :  address);
            form_data.append('weekly_rent_amount', editItem ? editItem.weekly_rent_amount.toString() : rent);
            form_data.append('description',editItem ? editItem.description :  description);


            await suiteApi.updateSuite(id , form_data)
            fetchData()
        
        }
        else if(selectedTab === 1){
             const id: number = editItem ? editItem.id : 0
            if (editItem) {
                await locationApi.update( id ,editItem); // DONE
            }
            fetchLocations();
        }
        

    }

    const createDialog = (
        < Dialog
            open={createOpen || editOpen}
            onClose={handleCreateClose}
        >

                {
                    createOpen &&
                    <DialogTitle>
                        Create New {selectedTab === 0 ? "Suite"  : "Location"}
                    </DialogTitle>
                }
                {
                    editOpen &&
                    <DialogTitle>
                        Update {selectedTab === 0 ? "Suite"  : "Location"} {editItem?.display_name}
                    </DialogTitle>
                }

            <DialogContent>
                <Grid
                    style={{
                        marginBottom: "20px"
                    }}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid
                        spacing={2}
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        {
                            createOpen && selectedTab === 0 &&
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={handleNameChange}
                                        error={!!error.name}
                                        helperText={error.name}
                                        value={name}
                                        name="name"
                                        className='full-width'
                                        autoComplete="off"
                                        fullWidth
                                        label="Suite Number"
                                        variant="outlined" />
                                </Grid>

                                <Grid item xs={6}>
                                    <CustomSelect
                                        label="Location"
                                        onChange={handleLocationChange}
                                        // data={['Location 1', 'Location 2', 'Location 3']}
                                        data={locationNames}
                                        setInitial={false}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        onChange={handleRentChange}
                                        error={!!error.rent}
                                        helperText={error.rent}
                                        value={rent}
                                        name="rent"
                                        className='full-width'
                                        autoComplete="off"
                                        fullWidth
                                        label="Weekly Rent"
                                        variant="outlined" />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        onChange={handleDescriptionChange}
                                        error={!!error.description}
                                        helperText={error.description}
                                        value={description}
                                        name="description"
                                        className='full-width'
                                        autoComplete="off"
                                        fullWidth
                                        multiline
                                        label="Description"
                                        variant="outlined" />
                                </Grid>
                            </>
                        }

                        {    createOpen && selectedTab === 1 &&
                            <>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("name")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.name}
                                    helperText={error.name}
                                    value={location?.name}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Name"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("address")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.address}
                                    helperText={error.address}
                                    value={location?.address}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Address"
                                    variant="outlined" />
                            </Grid>
                            </>
                      
                        }

                        {
                            editOpen && editItem  &&  selectedTab === 0 &&
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                            onFocus={() => {
                                            setKey("name")
                                        }}
                                        onChange={handleDisplayNameChange}
                                        error={!!error.name}
                                        helperText={error.name}
                                        value={editItem.name}
                                        name="displayName"
                                        className='full-width'
                                        autoComplete="off"
                                        fullWidth
                                        label="Suite Number"
                                        variant="outlined" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onFocus={() => {
                                            setKey("weekly_rent_amount")
                                        }}
                                        onChange={handleRentChange}
                                        error={!!error.rent}
                                        helperText={error.rent}
                                        value={editItem.weekly_rent_amount}
                                        name="rent"
                                        className='full-width'
                                        autoComplete="off"
                                        fullWidth
                                        label="Weekly Rent"
                                        variant="outlined" />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        onChange={handleDescriptionChange}
                                        error={!!error.description}
                                        helperText={error.description}
                                        value={description}
                                        name="description"
                                        className='full-width'
                                        autoComplete="off"
                                        fullWidth
                                        multiline
                                        label="Description"
                                        variant="outlined" />
                                </Grid>
                            </>
                        }
                         {
                        editOpen && editItem &&  selectedTab === 1 &&
                        <>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("name")
                                    }}
                                    onChange={handleEditChange}
                                    error={!!error.name}
                                    helperText={error.name}
                                    value={editItem?.name}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Name"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("address")
                                    }}
                                    onChange={handleEditChange}
                                    error={!!error.address}
                                    helperText={error.address}
                                    value={editItem?.address}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Address"
                                    variant="outlined" />
                            </Grid>
                           

                        </>
                    }
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions
            style={ {display : 'flex', justifyContent: 'space-around'} }>

                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus>
                    {createOpen ? "Create" : "Update"}
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleToggleOpen = (item: Suite | Location) => {
        console.log(item);
        
        setToggleItem(item)
        setToggleOpen(true);
    };

    const handleToggleClose = () => {
        setToggleOpen(false);
    };

    const handleToggle = async () => {
        if (toggleItem && selectedTab == 0) {
            await suiteApi.toggleVisibilityById(toggleItem.id, toggleItem.disabled === 1 ? 0 : 1)
        }
        else  if (toggleItem && selectedTab == 1) {
            await locationApi.toggleVisibilityById(toggleItem.id, toggleItem.disabled === 1 ? 0 : 1)
        }

        setToggleOpen(false);
        fetchData()
        fetchLocations()
    };

    const toggleDialog = (
        < Dialog
            open={toggleOpen}
            onClose={handleToggleClose}
        >
            <DialogTitle >Update {selectedTab === 0 ? "Suite"  : "Location"} Status</DialogTitle>
            <DialogContent>
                {
                    toggleItem &&
                    <DialogContentText>
                        Are you sure you want to {toggleItem && toggleItem.disabled === 1 ? "deactivate" : "activate"}  {toggleItem && toggleItem.display_name} {selectedTab === 0 ? "Suite"  : "Location"} ?
                    </DialogContentText>
                }

            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleToggleClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleToggle} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    useEffect(() => {
        // console.log(error)
        if (submitClicked && createOpen && JSON.stringify(error) === JSON.stringify({
            name: '',
            displayName: '',
            image_url: '',
            locationStr: '',
            address:'',
            rent:'',            
            description:'',

        })) {
            handleCreateSubmit()
            handleCreateClose()
        }
        else if (submitClicked && editOpen && JSON.stringify(error) === JSON.stringify({
            name: '',
            displayName: '',
            image_url: '',
            locationStr: '',
            address:'',
            description:'',
            rent:'',
        })) {
            handleEditSubmit()
            handleCreateClose()

        }
        else {
            setSubmitClicked(false)
        }

    }, [error])

    const handleClearFilters = () => {
        setFilter()
    }

    useEffect(() => {
        setFilter()
    }, [mainSuites, suites])

    useEffect(() => {
        fetchData()
    }, [sort, disableTableData])

    useEffect(() => {
        fetchData()
        fetchLocations()
    }, [])

    const onSetSelectedTab = (index : any) => {
        setSelectedTab(index);
    };

    return (
        <Page
            currentRoute={routeNameObjects.suites}
            parentRoutes={[routeNameObjects.suites]}
        >
            <div className={classes.container}>
                <CustomTab
                    onSetSelectedTab={onSetSelectedTab}
                    tabsData={
                        userRole.name === "sys_admin"
                        ?
                        [
                        {
                            title: "Suites",
                            icon: <BusinessIcon />,
                            component: (
                            <div>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Grid item xs={12} md={6} lg={2}>
                                        <CustomMultiSelectAutocomplete
                                        label="Active/Inactive" 
                                        onChange={setDisableTableData} 
                                        data={activeTableFilter} 
                                        reset={clearFilters} 
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            style={{ float: "right", fontSize : "12px",
                                            padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                                            onClick={() => {
                                            setClearFilters(true);
                                            }}
                                            startIcon={<ClearAllIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Table
                                    data={suites}
                                    isloading={isloading}
                                    createFunction={handleCreateOpen}
                                    editFunction={handleEditOpen}
                                    sortFunction={setSort}
                                    sort={sort}
                                    ignore={["disabled", "suite_id", "is_main", "id",'display_name',"address", "location_id", "image_url", "suite_type"]}
                                    toggle={
                                        {
                                            toggleFunction: handleToggleOpen,
                                            toggleColumn: "disabled"
                                        }
                                    }
                                    overrides={[{ from: 'name', to: "Suite Number" }, 
                                    // { from: 'display_name', to: "Sub-Suite Number" }, 
                                    {
                                        from: 'booking_status', to: "Vacancy"
                                    }]}
                                />
                            </div>
                            ),
                        },
                        {
                            title: "Locations",
                            icon: <PlaceIcon />,
                            component: (
                                <div>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <Grid
                                            item
                                            xs={10}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={2}
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            <Grid item xs={12} md={6} lg={2}>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}
                                            style={{
                                                marginBottom: 10,
                                            }}>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                style={{ float: "right", fontSize : "12px",
                                                padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                                                onClick={() => {
                                                setClearFilters(true);
                                                }}
                                                startIcon={<ClearAllIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    
                                    <Table
                                        data={locationData}
                                        title='Locations'
                                        isloading={isloading}
                                        createFunction={handleCreateOpen}
                                        editFunction={handleEditOpen}
                                        // sortFunction={setSort}
                                        // sort={sort}
                                        ignore={["disabled" ]}
                                        order={["id", "name", "address", 'disabled']}
                                        // toggle={
                                        //         {
                                        //             toggleFunction: handleToggleOpen,
                                        //             toggleColumn: "disabled"
                                        //         }
                                        //     }
                                    />
                                </div>
                            ),
                        }]
                        :
                        [
                        {
                            title: "Suites",
                            icon: <BusinessIcon />,
                            component: (
                            <div>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Grid item xs={12} md={6} lg={2}>
                                        <CustomMultiSelectAutocomplete
                                        label="Active/Inactive" 
                                        onChange={setDisableTableData} 
                                        data={activeTableFilter} 
                                        reset={clearFilters} 
                                        />
                                    </Grid>
                                        </Grid>
                                    <Grid item xs={2}
                                    justifyContent="center"
                                        alignItems="flex-end">
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            style={{ float: "right", fontSize : "12px",
                                            padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                                            onClick={() => {
                                            setClearFilters(true);
                                            }}
                                            startIcon={<ClearAllIcon />}
                                        >
                                            Clear All
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Table
                                    data={suites}
                                    isloading={isloading}
                                    createFunction={handleCreateOpen}
                                    editFunction={handleEditOpen}
                                    sortFunction={setSort}
                                    sort={sort}
                                    ignore={["disabled", "suite_id", "is_main", "id",'display_name',"address", "location_id" ,  "image_url", "suite_type"]}
                                    toggle={
                                        {
                                            toggleFunction: handleToggleOpen,
                                            toggleColumn: "disabled"
                                        }
                                    }
                                    overrides={[{ from: 'name', to: "Suite Number" }, 
                                    // { from: 'display_name', to: "Sub-Suite Number" }, 
                                    {
                                        from: 'booking_status', to: "Vacancy"
                                    }]}
                                />
                            </div>
                            ),
                        }  
                        ]
                    }
                />

            </div>
            {createDialog}
            {toggleDialog}
        </Page >
    )
}
