import React, { useEffect, useState } from 'react'
import Table from '../../../../shared/Table/CustomTable'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import { Service } from '../../../../models/service'
import { IRootState } from "../../../../core/store";
import { useSelector } from "react-redux";
import TextField from '../../../../shared/TextField/TextField'
import { SortObject } from '../../../../models/sort'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import time from '../../../../core/services/time'
import SuccessButton from '../../../../shared/Button/SuccessButton'
import WarningButton from '../../../../shared/Button/WarningButton'
import CustomSelect from '../../../../shared/Select/Select'
import { Category } from '../../../../models/category'
import categoryApi from '../../../../core/api/category-api'
import MyLoader from '../../../../shared/Loader/my_loader';
import CategoryImage from '../CategoryImage/CategoryImage';
import CustomMultiSelectAutocomplete from '../../../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete'
import ClearAllIcon from '@material-ui/icons/ClearAll';

export default function CategoryTab() {
    const categories: Category[] = useSelector((state: IRootState) => state.categoryReducer.categories)
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);
    const [categoryItem, setcategoryItem] = useState<Category | null>(null);
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [sort, setSort] = useState<SortObject>({ orderBy: 'id', order: "asc" });
    const [key, setKey] = useState<string>('')
    const [error, setError] = useState<{ [key: string]: string }>({})
    const [selectedImageFiles, setSelectedImageFiles] = useState<any>([]);

    const [isloading, setloading] = useState<boolean>(true)
    const [activeTableFilter, setActiveTableFilter] = useState<string[]>([])
    const [disableTableData, setDisableTableData] = useState<string[]>([])
    const [clearFilters, setClearFilters] = useState<boolean>(false)
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<Category | null>(null)
    const [displayName, setDisplayName] = useState<string>('')

    const fetchCategories = async () => {
        setloading(true)
        let activeFilterData: boolean[] = []

        categories.map((item) => {
            if (item.disable && disableTableData.includes("Active")) {
                // console.log('Active', item.disable, item)
                activeFilterData.push(false)
            }

            if(item.disable === 0 && disableTableData.includes("Inactive")) {
                // console.log('Inactive', item.disable, item)
                activeFilterData.push(true)
            }
        })

        activeFilterData = [...new Set(activeFilterData)]

        // print new array
        // console.log('array', activeFilterData)

        try {
            await categoryApi.getAll(activeFilterData)
            setloading(false)
        } catch (error) {
            setloading(false)
        }

    }

    const setFilter = () => {
        let activeFilterData: string[] = []

            categories.map((item) => {
                if (item.disable || item.disable == 0){
                    if(item.disable){
                        activeFilterData.push("Active")
                    }
                    if(item.disable == 0){
                        activeFilterData.push("Inactive")
                    }
                    
                }
            })

        // remove duplicate elements from array
        const arr = activeFilterData.filter( function( item, index, inputArray ) {
        return inputArray.indexOf(item) === index;
        });

        // print new array
        // console.log('all', arr)

        setActiveTableFilter(arr)
        setClearFilters(false)
    }

    const handleClearFilters = () => {
        setFilter()
    }

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])


    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setcategoryItem(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }

    const handleEditChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setEditItem(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }

    const handleImageChange = (files: any) => {
        setSelectedImageFiles(files)
        handleError(files, "image_url")
    }

    const dataUrlToFile  = (blobUrl:string,  fileName : string): Promise<File> => new Promise((resolve) => {
        fetch(blobUrl).then((res) => {
        res.blob().then((blob) => {
            const file = new File([blob], fileName, {type: blob.type})
            resolve(file)
            return file;
        })
        })
    })

    const handleError = (value: any, name?: string) => {
        const ErrVal: string = name ? name : key
        let ErrVariable: string = ''

        if (!value) {
            ErrVariable = 'This field cannot be empty'
        }

        if (name === "image_url") {
            if (value?.length === 0) {
                ErrVariable = 'This field cannot be empty'
            }
        }

        setError(prevState => ({
            ...prevState,
            [ErrVal]: ErrVariable
        }))
    }

    const handleCategoryStateUpdate = async () => {
        setToggleOpen(false);
        if (categoryItem) {
            await categoryApi.toggleVisibilityById(categoryItem.id, categoryItem.disable === 1 ? 0 : 1)
            fetchCategories()
        }
    };

    const handleToggleOpen = (item: Category) => {
        setcategoryItem(item)
        setToggleOpen(true);
    };

    const handleToggleClose = () => {
        setToggleOpen(false);
    };

    const toggleDialog = (
        < Dialog
            open={toggleOpen}
            onClose={handleToggleClose}
        >
            <DialogTitle >{"Update Category Status"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {categoryItem && categoryItem.id ? "deactivate" : "activate"} {categoryItem && categoryItem.id} Category?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleToggleClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleCategoryStateUpdate} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleCategoryEditOpen = (item: Category) => {
        console.log(item);
        
        setError({
            name: '',
            address: '',
        })
        setEditItem(item)
        setDisplayName(item.name)
        setEditOpen(true);
    };

    const handleEditSubmit = async () => {
        console.log(selectedImageFiles);
        

        const id: number = editItem ? editItem.id : 0
        
            if (editItem) {
                let form_data = new FormData();
                let file : File;
                                
                form_data.append('name', editItem.name);

                if(selectedImageFiles.length > 0){
                    file = await dataUrlToFile(selectedImageFiles?.image_url, selectedImageFiles[0]?.name);
                    form_data.append('image_url',  file);
                }
                else{
                    // form_data.append('image_url',  null);
                }
                 
                await categoryApi.updateCategory( id ,form_data); 
            }
            fetchCategories();
    }

    const handleCreate = () => {
        if (categoryItem) {
            Object.keys(categoryItem).map((key) => {
                const ignore: string[] = ["id", "disable", 'color', "image_url"]
                if (!ignore.includes(key)) {
                    handleError(Reflect.get(categoryItem, key), key)
                }

            })
            handleError(selectedImageFiles, "image_url")
        }

        setSubmitClicked(true)

        if (editOpen) {
            handleError(displayName, "displayName")
            handleEditSubmit()
            handleCreateClose()
        }
    };

    const handleCreateSubmit = async () => {
        if (categoryItem?.name) {
            let form_data = new FormData();
            const file = await dataUrlToFile(selectedImageFiles?.image_url, selectedImageFiles[0]?.name);

            form_data.append('name', categoryItem.name);
            form_data.append('image_url', file);
            await categoryApi.create(form_data)
        }
        fetchCategories()
    }

    const handleCreateOpen = () => {
        const item: Category = {
            id: 0,
            name: "",
            color: "",
            disable: 0,
            image_url: ''
        }
        setcategoryItem(item)
        setError({
            name: '',
            image_url: ''
        })
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
         setEditItem(null)
        setcategoryItem(null)
        setKey('')
        setSelectedImageFiles([])
        setError({})
        setSubmitClicked(false)
    };

    const createDialog = (
        < Dialog
            open={createOpen || editOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{createOpen ? "Create New Category" : `Update ${editItem?.name} Category`}</DialogTitle>
            {
                createOpen && categoryItem &&
                <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12}>
                            <CategoryImage isUploading={false} selectedImageFiles={selectedImageFiles} setSelectedImageFiles={handleImageChange} />
                            <div style={{ color: "#f44336", paddingTop: 10, textAlign: "center" }}>{error?.image_url}</div>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                onFocus={() => {
                                    setKey("name")
                                }}
                                onChange={handleChange}
                                error={!!error.name}
                                helperText={error.name}
                                value={categoryItem.name}
                                className='full-width'
                                autoComplete="off"
                                fullWidth
                                label="Service Name"
                                variant="outlined" />
                        </Grid>
                    </Grid>
                </DialogContent>
            }

            {
                editOpen && editItem &&
                <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12}>
                            <CategoryImage  category = {editItem} isUploading={false} 
                            selectedImageFiles={selectedImageFiles} 
                            setEditItem = {setEditItem}
                            setSelectedImageFiles={handleImageChange} 
                            />
                            <div style={{ color: "#f44336", paddingTop: 10, textAlign: "center" }}>{error?.image_url}</div>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                onFocus={() => {
                                    setKey("name")
                                }}
                                onChange={handleEditChange}
                                error={!!error.name}
                                helperText={error.name}
                                value={editItem.name}
                                className='full-width'
                                autoComplete="off"
                                fullWidth
                                label="Service Name"
                                variant="outlined" />
                        </Grid>
                    </Grid>
                </DialogContent>
            }
            <DialogActions style={{display : 'flex', justifyContent : 'space-around'}}>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus>
                     {createOpen ? "Create" : "Update"}
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )


    const handleExport = () => {
        const fileName = `Categories Data ${time.DateToString(new Date())}.xlsx`
        const ws = XLSX.utils.json_to_sheet(categories);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    };

    useEffect(() => {
        if (submitClicked && JSON.stringify(error) === JSON.stringify({
            name: '',
            image_url: ''
        })) {
            handleCreateSubmit()
            handleCreateClose()
        }
        else {
            setSubmitClicked(false)
        }
    }, [error])

    useEffect(() => {
        fetchCategories()
    }, [disableTableData])

    useEffect(() => {
        setFilter()
    }, [categories])

    useEffect(() => {
        fetchCategories()
    }, [])
    
    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Grid item xs={12} md={6} lg={2}>
                    <CustomMultiSelectAutocomplete
                     label="Active/Inactive" 
                     onChange={setDisableTableData} 
                     data={activeTableFilter} 
                     reset={clearFilters} 
                     />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ float: "right", fontSize : "12px",
                        padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                        onClick={() => {
                        setClearFilters(true);
                        }}
                        startIcon={<ClearAllIcon />}
                    >
                        Clear All
                    </Button>
                </Grid>
            </Grid>
            <Table
                data={categories}
                isloading={isloading}
                createFunction={handleCreateOpen}
                exportFunction={handleExport}
                toggle={{
                    toggleFunction: handleToggleOpen,
                    toggleColumn: "disable"
                }}
                ignore={["disable", 'color', "id"]}
                editFunction={handleCategoryEditOpen}

            />
            {toggleDialog}
            {createDialog}
        </div>
    )
}
