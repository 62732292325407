import axios, { AxiosRequestConfig } from 'axios';
import store from '../store';
import { AlertActions, LoadingActions } from '../store/actions';
import authApi from './auth-api';
// const host = process.env.NODE_ENV != 'production' ? 'http://localhost:4000' : 'https://d2128vxwux9vxt.cloudfront.net';
// const host = "http://mintsuiteapi-env.eba-qp2kmvyp.us-east-2.elasticbeanstalk.com";
// const host = "http://18.118.99.160:4000";
// const host = 'http://localhost:4000'
// const host = "https://dev-api.mintsuites.com"
const host = 'https://api.mintsuites.com'
// const host = "https://d2128vxwux9vxt.cloudfront.net"
// const host = "https://9529-2402-4000-2380-65af-f444-efcf-772c-df56.in.ngrok.io"

class HttpApi {
    setAuthToken(token: string) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    async get(url: string, displayMsg: boolean = true): Promise<any> {
        return await axios
            .get(host + url)
            .then((res: any) => {
                const { data } = res;
                const { code } = data;
                if (code === 'SUCCESS') {
                    return data.data;
                } else if (code === 'RECORD_NOT_FOUND') {
                    console.log('RECORD_NOT_FOUND', url);
                    return [];
                } else if (code === 'FAIL') {
                    console.log('RECORD_NOT_FOUND', url);
                    return [];
                } else {
                    const { message, code } = data;
                    console.log('Error While Sending GET Request 3', url);
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: {
                                    open: true,
                                    alertType: 'error',
                                    message: message || code || 'Something went wrong',
                                },
                            },
                        });
                    }
                    return message || 'Something went wrong';
                }
            })
            .catch((err: any) => {
                const { data, status } = err.response;
                const { message, code } = data;

                if (status == 401) {
                    console.log('UNAUTHORIZED', url);
                    authApi.logout();
                }

                if (status == 400) {
                    console.log('BAD REQUEST', url);
                    return [];
                }

                if (status == 404) {
                    console.log('NOT FOUND', url);
                    return [];
                }

                if (displayMsg) {
                    console.log('Error While Sending GET Request 1', url);
                    store.dispatch({
                        type: AlertActions.UPDATE,
                        payload: {
                            alert: {
                                open: true,
                                alertType: 'error',
                                message: message || code || 'Something went wrong',
                            },
                        },
                    });
                }
                throw message || 'Something went wrong';
            });
    }

    async getWithBody(url: string, body: any) {
        store.dispatch({
            type: LoadingActions.UPDATE,
            payload: {
                loading: true,
            },
        });
        const { data } = await axios.get(host + url, body);
        const { code } = data;
        if (code === 'SUCCESS') {
            return data.data;
        } else {
            store.dispatch({
                type: LoadingActions.UPDATE,
                payload: {
                    loading: false,
                },
            });
            const { message, code } = data;
            console.log('Error While Sending GET Request 2', url);
            console.error(message);

            store.dispatch({
                type: AlertActions.UPDATE,
                payload: {
                    alert: { open: true, alertType: 'error', message: message || code || 'Something went wrong' },
                },
            });

            // throw new Error(message || "Something went error");
        }
    }

    async post(url: string, body: any, displayMsg: boolean = true, config?: AxiosRequestConfig) {
        return await axios
            .post(host + url, body, config)
            .then((res: any) => {
                const { data } = res;
                const { code } = data;
                const { message } = data;
                if (code === 'SUCCESS') {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: 'success', message: message || 'Successfully Added!' },
                            },
                        });
                    }
                    return data.data;
                } else {
                    console.log('Error While Sending POST Request', url);
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: {
                                    open: true,
                                    alertType: 'error',
                                    message: message || code || 'Something went wrong',
                                },
                            },
                        });
                    }
                    return message || 'Something went wrong';
                }
            })
            .catch((err: any) => {
                const { data, status } = err.response;
                const { message, code } = data;
                console.log('Error While Sending POST Request', url);

                if (status == 401) {
                    store.dispatch({
                        type: AlertActions.UPDATE,
                        payload: {
                            alert: { open: true, alertType: 'error', message: message || 'Invalid username/password' },
                        },
                    });
                    authApi.logout();
                }

                if (displayMsg) {
                    store.dispatch({
                        type: AlertActions.UPDATE,
                        payload: {
                            alert: {
                                open: true,
                                alertType: 'error',
                                message: message || code || 'Something went wrong',
                            },
                        },
                    });
                    authApi.logout();
                }
                return message || 'Something went wrong';
            });
    }

    async put(url: string, body: any, displayMsg: boolean = true, config?: AxiosRequestConfig) {
        return await axios
            .put(host + url, body, config)
            .then((res: any) => {
                const { data } = res;
                const { code } = data;
                if (code === 'SUCCESS') {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: 'success', message: 'Updated Successfully!' },
                            },
                        });
                    }

                    return data.data;
                } else if (code === 'CATEGORY_IS_IN_USE') {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: 'error', message: 'This category is in use' },
                            },
                        });
                    }

                    return [];
                } 
                else if (data.data === "Service is in Use ") {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: 'error', message: 'This service is in use' },
                            },
                        });
                    }

                    return [];
                } 
                else if (code === 'CANNOT_DISABLE_AN_OCCUPIED_SUITE') {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: 'error', message: 'Can not disable an occupied suite' },
                            },
                        });
                    }

                    return [];
                }  else if (code === 'DUPLICATE_RECORD') {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: {
                                    open: true,
                                    alertType: 'error',
                                    message: "It's like it already is. Can not make a duplicate",
                                },
                            },
                        });
                    }

                    return [];
                }else if (code === 'FAIL') {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: 'error', message: 'Failed!, Could not Update' },
                            },
                        });
                    }

                    return [];
                } else {
                    const { message, code } = data;
                    console.log('Error While Sending PUT Request', url);

                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: {
                                    open: true,
                                    alertType: 'error',
                                    message: message || code || 'Something went wrong',
                                },
                            },
                        });
                    }
                    return message || 'Something went wrong';
                }
            })
            .catch((err: any) => {
                const { data, status } = err.response;
                const { message, code } = data;
                console.log('Error While Sending PUT Request', url);

                if (status == 401) {
                    authApi.logout();
                }
                if (displayMsg) {
                    store.dispatch({
                        type: AlertActions.UPDATE,
                        payload: {
                            alert: {
                                open: true,
                                alertType: 'error',
                                message: message || code || 'Something went wrong',
                            },
                        },
                    });
                }
                return message || 'Something went wrong';
            });
    }

    async delete(url: string, displayMsg: boolean = true) {
        return await axios
            .delete(host + url)
            .then((res: any) => {
                const { data } = res;
                const { code } = data;
                if (code === 'SUCCESS') {
                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: { open: true, alertType: 'success', message: 'Updated Successfully!' },
                            },
                        });
                    }

                    return data.data;
                } else {
                    const { message, code } = data;
                    console.log('Error While Sending DELETE Request', url);

                    if (displayMsg) {
                        store.dispatch({
                            type: AlertActions.UPDATE,
                            payload: {
                                alert: {
                                    open: true,
                                    alertType: 'error',
                                    message: message || code || 'Something went wrong',
                                },
                            },
                        });
                    }
                    return message || 'Something went wrong';
                }
            })
            .catch((err: any) => {
                const { data, status } = err.response;
                const { message, code } = data;
                console.log('Error While Sending DELETE Request', url);

                if (status == 401) {
                    authApi.logout();
                }
                if (displayMsg) {
                    store.dispatch({
                        type: AlertActions.UPDATE,
                        payload: {
                            alert: {
                                open: true,
                                alertType: 'error',
                                message: message || code || 'Something went wrong',
                            },
                        },
                    });
                }
                return message || 'Something went wrong';
            });
    }
}

export default new HttpApi();
