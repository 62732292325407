import { Checkbox, TextField, Chip } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useRef, useState } from 'react'
import useStyles from './SelectStyle'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
    onChange?: Function;
    label: string;
    data: string[] | number[] | boolean[];
    disabled?: boolean;
    required?: boolean;
    reset?: boolean;
}

export default function CustomMultiSelectAutocomplete(props: Props) {
    const style = useStyles()
    const [value, setValue] = useState<string[]>([])
    const [data, setData] = useState<string[]>([])
    const [type, setType] = useState<string>('')
    const clearRef = useRef<HTMLElement>();

    const handleChange = (event: React.ChangeEvent<{}>, value: string[]) => {
        setValue(value as string[]);
    };

    const updateOnChange = (values: string[]) => {
        let selected: string[] = []
        let selectedNum: number[] = []
        values.forEach(item => {
            let val: string = item as string;
            if (type === 'number') {
                selectedNum.push(parseInt(val))
            }

            if (type === 'string') {
                selected.push(val)
            }

        });

        if (props.onChange) {
            if (selected.length > 0) {
                props.onChange(selected)
            }
            else {
                props.onChange(selectedNum)
            }

        }
    }

    const handleClose = () => {
        if(value.length !== 0){
        updateOnChange(value)
        }
    }

    const removeOption = (item: string) => {
        let newVal: string[] = value.filter(x => x !== item)
        setValue(newVal);
        updateOnChange(newVal)
    };

    useEffect(() => {
        if (props.reset) {
            if (clearRef && clearRef.current) {
                setValue([]);
                updateOnChange([])
            }
        }
    }, [props.reset]);

    useEffect(() => {
        let newData: string[] = []
        if (props.data && props.data.length > 0) {
            if (typeof props.data[0] === 'number') {
                setType('number')
            } else {
                setType('string')
            }
            props.data.map((item: string | number | boolean) => {
                if (item) {
                    newData.push(item.toString())
                }
            })
        }

        setData(newData)
    }, [props.data]);

    return (
        <Autocomplete
            ref={clearRef}
            multiple
            style={{
                width: "100%"
            }}
            size="small"
            options={data}
            value={value}
            disableCloseOnSelect
            disableClearable
            disabled={props?.disabled}
            onChange={handleChange}
            onClose={handleClose}
            getOptionLabel={(option) => option}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={props.label}
                />
            )}
            renderTags={(values) =>
                values.map((value, i) => (
                    <Chip
                        key={i}
                        label={value}
                        size='small'
                        classes={{ root: style.chipRoot }}
                        onDelete={() => {
                            removeOption(value);
                        }}
                    />
                ))
            }
        />
    )
}
