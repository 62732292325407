import httpApi from './http-api';
import store from '../store';
import { LoadingActions, ReviewActions } from '../store/actions';
import { SortObject } from '../../models/sort';
import { Review } from '../../models/review';

class ReviewApi {

    async getAll(): Promise<Review[]> {
        try {
            const { reviews_of_logged_in_user } = await httpApi.get('/review/by-customer');
            store.dispatch({
                type: ReviewActions.UPDATE,
                payload: {
                    reviews: reviews_of_logged_in_user
                }
            });
            return reviews_of_logged_in_user
        } catch (error) {
            return []
        }

    }

    async toggleVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.put(`/review/assign-to-profile/${id}`, { visible: visibility });
            this.getAll()
        } catch (error) {

        }

    }

}

export default new ReviewApi();