import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import useStyles from './CalendarStyle'
import moment from 'moment'
import time from '../../core/services/time';
import { Button, Grid } from '@material-ui/core'


interface Props {
    data: number[];
    onClick?: Function;
    disableFuture?: boolean;
    disablePast?: boolean;
    disabledDates?: string[];
    enabledDates?: string[];
    minDate?: Date;
    maxDate?: Date;
}

export default function Calendar(props: Props) {
    const classes = useStyles()
    const [data, setData] = useState<number[]>([]);
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [initialGap, setInitialGap] = useState<number>(0);
    const [numberOfSlots, setNumberOfSlots] = useState<number>(0);

    const [startDate, setStartDate] = useState<number>(0);
    const [endDate, setEndDate] = useState<number>(0);

    const [selected, setSelected] = useState<number[]>([]);

    const handleSelect = (index: number) => {
        let temp = [...selected]
        const i = temp.indexOf(index);

        if (i > -1) {
            temp.splice(i, 1);
            setSelected([...temp])
        }
        else {
            setSelected([...selected, index])
        }

        if (props.onClick) {
            props.onClick(data[index - initialGap - moment.unix(startDate).date() + 1])
        }

    }

    const isSelected = (index: number): boolean => {
        let isSelected: boolean = false

        if (selected.includes(index)) {
            isSelected = true
        }
        return isSelected
    }

    const isDisabled = (index: number): boolean => {
        let isDisabled: boolean = true
        let date: number = 0
        if (props.data.length > 0) {
            date = moment.unix(startDate).startOf("month").add(index - initialGap, 'day').unix()
            if (date >= startDate && date <= endDate) {
                isDisabled = false
            }
        }

        return isDisabled
    }

    useEffect(() => {
        if (props.data.length > 0) {
            let year = moment.unix(props.data[0]).format('YYYY')
            let month = moment.unix(props.data[0]).format('MMMM')
            let day = moment.unix(props.data[0]).startOf("month").day()
            let numberOfDaysInMonth = moment.unix(props.data[0]).daysInMonth()
            let numberOfSlotsInCalendar = day + numberOfDaysInMonth - 1

            let startDate = moment.unix(props.data[0]).startOf("day").unix()
            let endDate = moment.unix(props.data.slice(-1)[0]).endOf("day").unix()
            setYear(year)
            setMonth(month)
            setInitialGap(day === 0 ? 6 : day - 1)
            setNumberOfSlots(numberOfSlotsInCalendar)
            setStartDate(startDate)
            setEndDate(endDate)
        }
        setData(props.data)
    }, []);

    return (
        <div className={classes.container}>
            {/* <Grid
                direction='row'
                container
                alignItems="flex-start"
                justify='space-between'
            >
                <Grid item xs={6}>
                    {year}
                </Grid>

                <Grid item xs={6}>
                    {month}
                </Grid>
            </Grid> */}
            <div className={classes.monthName}>
                {month}
            </div>
            <div className={classes.weekContainer}>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(item => (
                    <div className={classes.weekName}>
                        {item}
                    </div>
                ))
                }
            </div>
            <div className={classes.weekContainer}>
                {[...Array(numberOfSlots)].map((item, index: number) => {
                    return index >= initialGap ?
                        <Button
                            className={classes.dayCell}
                            classes={{ root: classes.buttonRoot }}
                            key={"day-slot" + index}
                            disabled={isDisabled(index)}
                            variant={isDisabled(index) ? 'contained' : isSelected(index) ? 'contained' : 'text'}
                            color={isSelected(index) ? "primary" : "default"}
                            onClick={() => { handleSelect(index) }}
                        >
                            {index >= initialGap ? index - initialGap + 1 : ''}
                        </Button>
                        :
                        <div key={"day-slot" + index}></div>
                })
                }
            </div>
        </div>
    )
}
