import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20,
        },
        balance: {
            padding: 10,
            textAlign: "right",
            textTransform: 'capitalize',
            fontSize: 12,
            fontWeight: "bold",
            float: 'right'
        },
    }),
);

export default useStyles;