import React, { useEffect, useState } from 'react'
import Table from '../../../../shared/Table/CustomTable'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import serviceApi from '../../../../core/api/service-api'
import { Service } from '../../../../models/service'
import { IRootState } from "../../../../core/store";
import { useSelector } from "react-redux";
import TextField from '../../../../shared/TextField/TextField'
import { SortObject } from '../../../../models/sort'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import time from '../../../../core/services/time'
import SuccessButton from '../../../../shared/Button/SuccessButton'
import WarningButton from '../../../../shared/Button/WarningButton'
import CustomSelect from '../../../../shared/Select/Select'
import { Category } from '../../../../models/category'
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CustomMultiSelectAutocomplete from '../../../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete'
import categoryApi from '../../../../core/api/category-api'

export default function ServiceTab() {
    const services: Service[] = useSelector((state: IRootState) => state.serviceReducer.services)
    const allCategories: Category[] = useSelector((state: IRootState) => state.categoryReducer.categories)
    const availableCategoriesAdmin: Category[] = useSelector((state: IRootState) => state.filterReducer.availableCategories)
    const allAvailableCategories: Category[] = useSelector((state: IRootState) => state.filterReducer.activeCategories)
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);
    const [serviceItem, setserviceItem] = useState<Service | null>(null);
    const [category, setCategory] = useState<string>('')
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [sort, setSort] = useState<SortObject>({ orderBy: 'id', order: "asc" });
    const [key, setKey] = useState<string>('')
    const [error, setError] = useState<{ [key: string]: string }>({})

    const [allCategoryFilter, setAllCategoryFilter] = useState<string[]>([])
    const [allServiceFilter, setAllServicesFilter] = useState<string[]>([])
    const [selectedCategories, setSelectedCategories] = useState<string[]>([])
    const [selectedServices, setSelectedServices] = useState<string[]>([])
    const [avalilableCategoryFilter, setAvailableCategoryFilter] = useState<string[]>([])

    const [isloading, setloading] = useState<boolean>(true)
    const [clearFilters, setClearFilters] = useState<boolean>(false)
    const [activeTableFilter, setActiveTableFilter] = useState<string[]>([])
    const [disableTableData, setDisableTableData] = useState<string[]>([])
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<Service | null>(null)
    const [displayName, setDisplayName] = useState<string>('')

    const [viewOpen,setViewOpen]=useState<boolean>(false)
    const [professionals,setProfessionals]=useState<any[]>([])
    const [selectedService,setSelectedService]=useState<any>({})

    const fetchServices = async () => {
        setloading(true)
        let catIdList: number[] = []
        let serviceIdList: number[] = []
        let activeFilterData: boolean[] = []

        allCategories.map((item) => {
            if (selectedCategories.includes(item.name)) {
                catIdList.push(item.id)
            }
        })

        services.map((item) => {
            if (selectedServices.includes(item.type)) {
                serviceIdList.push(item.id)
            }

            if (item.disable && disableTableData.includes("Active")) {
                // console.log('Active', item.disable, item)
                activeFilterData.push(false)
            }

            if(item.disable === 0 && disableTableData.includes("Inactive")) {
                // console.log('Inactive', item.disable, item)
                activeFilterData.push(true)
            }
        })

        activeFilterData = [...new Set(activeFilterData)]

        // print new array
        // console.log('array', activeFilterData)

        try {
            await serviceApi.getAll(sort, catIdList, serviceIdList, activeFilterData)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }


    const setFilter = async () => {
        let catFilterData: string[] = []
        let availableCategoryFilterData: string[] = []
        let catIdList: number[] = []
        let serviceFilterData: string[] = []
        let activeFilterData: string[] = []

        availableCategoriesAdmin.map((item) => {
            availableCategoryFilterData.push(item.name)
        })

        if (allCategories) {
            allCategories.map((item) => {
                catFilterData.push(item.name)

                if (selectedCategories.includes(item.name)) {
                    catIdList.push(item.id)
                }
            })
        }

        services.map((item) => {
            if (catIdList && catIdList.length > 0) {
                if (catIdList.includes(item.category)) {
                    serviceFilterData.push(item.type)
                }
            }

            if (item.disable || item.disable == 0){
                if(item.disable){
                    activeFilterData.push("Active")
                }
                if(item.disable == 0){
                    activeFilterData.push("Inactive")
                }
                
            }
            else {
                serviceFilterData.push(item.type)
            }
        })

        // remove duplicate elements from array
        const arr = activeFilterData.filter( function( item, index, inputArray ) {
        return inputArray.indexOf(item) === index;
        });

        // print new array
        // console.log('all', arr)

        catFilterData = [...new Set(catFilterData)]
        availableCategoryFilterData = [...new Set(availableCategoryFilterData)]
        serviceFilterData = [...new Set(serviceFilterData)]
        availableCategoryFilterData.sort()
        serviceFilterData.sort()
        catFilterData.sort()

        let activeCat : string[] = [];
        await categoryApi.getActiveCategories().then(
            (data) => {
                activeCat = data.map(i => i.name).concat();
            });
        setAllCategoryFilter(catFilterData)
        setAvailableCategoryFilter(activeCat)
        setAllServicesFilter(serviceFilterData)
        setActiveTableFilter(arr)
        setClearFilters(false)
    }

    const handleClearFilters = () => {
        setSelectedCategories([])
        setSelectedServices([])
        setFilter()
    }

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])

    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setserviceItem(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }

    const handleEditChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setEditItem(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }
    const handleCategoryChange = (value: string) => {
        let catId: number = 0
        allCategories.map((item) => {
            if (item.name === value) {
                catId = item.id
            }
        })       
        setserviceItem(prevState => (prevState && {
            ...prevState,
            category: catId
        }))
    }

    const handleError = (value: any, name?: string) => {
        const ErrVal: string = name ? name : key
        let ErrVariable: string = ''

        if (!value) {
            ErrVariable = 'This field cannot be empty'
        }

        if (ErrVal === "Price") {
            if (value < 0) {
                ErrVariable = 'Minimum value is 0'
            }
        }

        setError(prevState => ({
            ...prevState,
            [ErrVal]: ErrVariable
        }))
    }

    const handleServiceStateUpdate = async () => {
        setToggleOpen(false);
        if (serviceItem) {
            await serviceApi.toggleVisibilityById(serviceItem.id, serviceItem.disable === 1 ? 0 : 1)
            fetchServices()
        }
    };

    const handleToggleOpen = (item: Service) => {
        // debugger
        // if(item.count_of_employees > 0){
        //     setViewOpen(false);
        //     alert("This service in use, can not delete this service");
        //     return;
        // }
        setserviceItem(item)
        setToggleOpen(true);
    };

    const handleToggleClose = () => {
        setToggleOpen(false);
    };

    const toggleDialog = (
        < Dialog
            open={toggleOpen}
            onClose={handleToggleClose}
        >
            <DialogTitle >{"Update Service Status"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {serviceItem && serviceItem?.count_of_employees > 0 ? "This service in use, can not delete this service. " :
                    `Are you sure you want to ${serviceItem && serviceItem.disable === 1 ? "deactivate" : "activate"} ${serviceItem && serviceItem.type} Service?`}
                </DialogContentText>
            </DialogContent>
            {serviceItem && serviceItem?.count_of_employees < 1 ? 
             <DialogActions>
                <WarningButton onClick={handleToggleClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleServiceStateUpdate} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
            :
            <DialogActions>
                <WarningButton fullWidth onClick={handleToggleClose} color="primary">
                    Close
                </WarningButton>
            </DialogActions>
            }
        </Dialog >
    )

    const handleServiceEditOpen = (item: Service) => {
        setError({
            name: '',
            address: '',
        })
        setEditItem(item)
        setDisplayName(item.type)
        setEditOpen(true);
    };

    useEffect(() => {
        if (editOpen ||toggleOpen) {
            setViewOpen(false);
        }
    }, [editItem, editOpen, toggleOpen])

    const handleEditSubmit = async () => {
        const id: number = editItem ? editItem.id : 0
            if (editItem) {
                console.log(editItem);
                
                await serviceApi.update( id ,editItem);
            }
            fetchServices();
    }

    const handleCreate = () => {
        if (serviceItem) {
            Object.keys(serviceItem).map((key) => {
                const ignore: string[] = ["id", "average_duration", 'count_of_employees', 'count_of_employees', 'Name', 'Description', "category", "Price", "disable"]
                if (!ignore.includes(key)) {
                    handleError(Reflect.get(serviceItem, key), key)
                }

            })
        }

        if (editOpen) {
            handleError(displayName, "displayName")
            handleEditSubmit()
            handleCreateClose()
        }
        setSubmitClicked(true)
    };

    const handleCreateSubmit = async () => {
        if (serviceItem) {
            await serviceApi.create(serviceItem)
        }
        fetchServices()
    }

    const handleCreateOpen = () => {
        const item: Service = {
            id: 0,
            Description: "",
            Price: 0,
            category: 0,
            type: "",
            average_duration: 1,
            count_of_employees: 1,
            disable: 0,
        }
        setserviceItem(item)
        setError({
            type: '',
            Description: ""
        })
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
        setserviceItem(null)
        setEditItem(null)
        setCategory('')
        setKey('')
        setError({})
        setSubmitClicked(false)
    };

    const createDialog = (
        < Dialog
            open={createOpen || editOpen}
            onClose={handleCreateClose}
        >
             <DialogTitle >{createOpen ? "Create New Service" : `Update ${editItem?.type} Service`}</DialogTitle>
            {
                serviceItem &&
                <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid
                            spacing={2}
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={6}>
                                <CustomSelect
                                    label="Service Category"
                                    onChange={handleCategoryChange}
                                    data={avalilableCategoryFilter}
                                    setInitial={true} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("type")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.type}
                                    helperText={error.type}
                                    value={serviceItem.type}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Service"
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            }
            {
                editOpen && editItem &&
                <>
                  <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12}>
                        <TextField
                            onFocus={() => {
                                setKey("type")
                            }}
                            onChange={handleEditChange}
                            error={!!error.type}
                            helperText={error.type}
                            value={editItem.type}
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            label="Service"
                            variant="outlined" />
                        </Grid>
                    </Grid>
                </DialogContent>
                </>
            }
            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus>
                     {createOpen ? "Create" : "Update"}
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )


    const handleExport = () => {
        const fileName = `Services Data ${time.DateToString(new Date())}.xlsx`
        const ws = XLSX.utils.json_to_sheet(services);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    };


    useEffect(() => {
        if (submitClicked && JSON.stringify(error) === JSON.stringify({
            type: '',
            Description: "",
        })) {
            handleCreateSubmit()
            handleCreateClose()
        }
        else {
            setSubmitClicked(false)
        }
    }, [error, submitClicked])

    useEffect(() => {
        fetchServices()
    }, [sort, selectedCategories, selectedServices, disableTableData])

    useEffect(() => {
        setFilter()
    }, [ services, allAvailableCategories, createOpen])

    useEffect(() => {
        fetchServices()
    }, [])


    const handleViewOpen = (item: any) => {
        if(item.professional_array === null){
            setProfessionals([])
        }else{        
            const arr = item.professional_array
            setProfessionals(arr.split(","))
        }
        setSelectedService({name:item.type,id:item.id})
        if(editItem){
            setViewOpen(false)
        }
        setViewOpen(true);
    };

    const handleViewClose = () => {
        setViewOpen(false);
        setProfessionals([])
        setSelectedService({})
    };

    const createViewDialog = (
        < Dialog
            open={viewOpen}
            onClose={handleViewClose}
        >
            <div style={{fontSize:"20px",margin:"20px"}} >{`Professionals offering ${selectedService.name} - ${selectedService.id}`} 
            <button style={{float:"right", marginLeft:"20px", background:"white",color:"black",cursor:"pointer"}} onClick={handleViewClose}>X</button>
            
            </div>
            <DialogContent>
                {professionals.length > 0 ? <div style={{fontSize:"16px",marginBottom:"15px"}}>{professionals.map((p,index)=><div>{index+1} - {p}</div>)}</div>:<div style={{fontSize:"16px",marginBottom:"15px"}}>No professionals available</div>}   
            </DialogContent>
        </Dialog >
    )


    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Grid item xs={12} md={6} lg={2}>
                        <CustomMultiSelectAutocomplete label="Category" onChange={setSelectedCategories} data={allCategoryFilter} reset={clearFilters} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        <CustomMultiSelectAutocomplete label="Service" onChange={setSelectedServices} data={allServiceFilter} reset={clearFilters} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                    <CustomMultiSelectAutocomplete
                     label="Active/Inactive" 
                     onChange={setDisableTableData} 
                     data={activeTableFilter} 
                     reset={clearFilters} 
                     />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ float: "right", fontSize : "12px",
                        padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                        onClick={() => {
                        setClearFilters(true);
                        }}
                        startIcon={<ClearAllIcon />}
                    >
                        Clear All
                    </Button>
                </Grid>
            </Grid>
            <Table
                onClick={handleViewOpen}
                data={services}
                isloading={isloading}
                createFunction={handleCreateOpen}
                exportFunction={handleExport}
                toggle={{
                    toggleFunction: handleToggleOpen,
                    toggleColumn: "disable"
                }}
                ignore={["disable", "category", "id","professional_array","Description"]}
                numberColumns={['price', 'average_duration', 'count_of_employees']}
                overrides={[{ from: 'type', to: "Service" }, { from: 'price', to: "Average Price" }, { from: 'count_of_employees', to: "Count Of Professionals" }]}
                order={["category_name", "type", "price", "average_duration", "count_of_employees"]}
                sortFunction={setSort}
                sort={sort}
                editFunction={handleServiceEditOpen}

            />
            {toggleDialog}
            {createDialog}
            {createViewDialog}
        </div >
    )
}
