import httpApi from './http-api';
import store from '../store';
import { DashboardActions, LoadingActions } from '../store/actions';
import { ChartData } from '../../models/dashboard';
import { arrayToString } from './utils';

class DashboardApi {

    async getCountOfBookingsBySuit(start: number, end: number, data_by: string): Promise<ChartData> {
        try {
            const { count_of_bookings_over_category } = await httpApi.get(`/dashboard/count-of-bookings-by-suite?to=${end}&from=${start}&data_by=${data_by}`);
            store.dispatch({
                type: DashboardActions.UPDATE_BOOKING_COUNT_BY_SUITS,
                payload: {
                    bookingBySuits: count_of_bookings_over_category
                }
            });
            return count_of_bookings_over_category
        } catch (error) {
            return { labels: [], data: [] }
        }

    }

    async getCountOfBookings(start: number, end: number, customer: number[], category: number[], vendor: number[]): Promise<number> {

        let url: string = `/dashboard/get-booking-count?to=${end}&from=${start}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }

        try {
            const { Booking_Counts } = await httpApi.get(`${url}`);
            const count: number = Booking_Counts[0].COUNT
            store.dispatch({
                type: DashboardActions.UPDATE_BOOKING_COUNT,
                payload: {
                    bookingCount: count
                }
            });
            return count
        } catch (error) {
            return 0
        }

    }

    async getSumOfRevenue(start: number, end: number, customer: number[], category: number[], vendor: number[]): Promise<number> {
        let url: string = `/dashboard/sum-of-revenue?to=${end}&from=${start}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }
        try {
            const { sum_of_revenue } = await httpApi.get(`${url}`);

            store.dispatch({
                type: DashboardActions.UPDATE_SUM_OF_REVENUE,
                payload: {
                    sumOfRev: sum_of_revenue
                }
            });

            return sum_of_revenue
        } catch (error) {
            return 0
        }
    }

    async getPricePerUnit(start: number, end: number, customer: number[], category: number[], vendor: number[]): Promise<number> {
        let url: string = `/dashboard/price-per-unit?to=${end}&from=${start}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }
        const { price_per_unit } = await httpApi.get(`${url}`);

        store.dispatch({
            type: DashboardActions.UPDATE_PRICE_PER_UNIT,
            payload: {
                pricePerUnit: price_per_unit
            }
        });
        return price_per_unit
    }

    async getOccupancyRate(start: number, end: number, customer: number[], category: number[], vendor: number[]): Promise<number> {
        let url: string = `/dashboard/occupancy-rate?to=${end}&from=${start}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }
        try {
            const { occupancy_rate } = await httpApi.get(`${url}`);

            store.dispatch({
                type: DashboardActions.UPDATE_OCCUPANCY_RATE,
                payload: {
                    occupancyRate: occupancy_rate
                }
            });
            return occupancy_rate
        } catch (error) {
            return 0
        }

    }

    async getCountOfBookingsByServices(start: number, end: number, customer: number[], category: number[], vendor: number[]): Promise<ChartData> {
        let url: string = `/dashboard/count-of-bookings-over-category?to=${end}&from=${start}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }

        try {
            const { count_of_bookings_over_category } = await httpApi.get(`${url}`);
            store.dispatch({
                type: DashboardActions.UPDATE_BOOKING_COUNT_BY_SERVICES,
                payload: {
                    bookingByServices: count_of_bookings_over_category
                }
            });

            return count_of_bookings_over_category
        } catch (error) {
            return { labels: [], data: [] }
        }

    }

    async getRevenueByServices(start: number, end: number, customer: number[], category: number[], vendor: number[]): Promise<ChartData> {
        let url: string = `/dashboard/revenue-by-service-type?to=${end}&from=${start}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }

        try {
            const { revenue_by_service_type } = await httpApi.get(`${url}`);

            store.dispatch({
                type: DashboardActions.UPDATE_REVENUE_BY_SERVICES,
                payload: {
                    revByServices: revenue_by_service_type
                }
            });

            return revenue_by_service_type
        } catch (error) {
            return { labels: [], data: [] }
        }

    }

    async getCountOfBookingsOverTime(start: number, end: number, data_by: string, customer: number[], category: number[], vendor: number[]): Promise<ChartData> {
        let url: string = `/dashboard/count-of-bookings-over-time?to=${end}&from=${start}&data_by=${data_by}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }

        try {
            const { count_of_bookings_over_time } = await httpApi.get(`${url}`);
            store.dispatch({
                type: DashboardActions.UPDATE_BOOKING_COUNT_OVER_TIME,
                payload: {
                    bookingOverTime: count_of_bookings_over_time
                }
            });
            return count_of_bookings_over_time
        } catch (error) {
            return { labels: [], data: [] }
        }


    }

    async getRevenueOverTime(start: number, end: number, data_by: string, customer: number[], category: number[], vendor: number[]): Promise<ChartData> {
        let url: string = `/dashboard/revenue-over-time?to=${end}&from=${start}&data_by=${data_by}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }

        try {
            const { revenue_over_time } = await httpApi.get(`${url}`);

            store.dispatch({
                type: DashboardActions.UPDATE_REVENUE_OVER_TIME,
                payload: {
                    revOverTime: revenue_over_time
                }
            });
            return revenue_over_time
        } catch (error) {
            return { labels: [], data: [] }
        }

    }

    async getBookingsByVendors(start: number, end: number, customer: number[], category: number[], vendor: number[]): Promise<ChartData> {
        let url: string = `/dashboard/count-of-bookings-by-professionals?to=${end}&from=${start}`

        if (customer && customer.length > 0) {
            url = url + `&customer_id=${arrayToString(customer)}`
        }
        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (vendor && vendor.length > 0) {
            url = url + `&vendor_id=${arrayToString(vendor)}`
        }

        try {
            const { count_of_bookings_over_category } = await httpApi.get(`${url}`);

            store.dispatch({
                type: DashboardActions.UPDATE_BOOKING_COUNT_BY_VENDOR,
                payload: {
                    bookingByVendors: count_of_bookings_over_category
                }
            });

            return count_of_bookings_over_category
        } catch (error) {
            return { labels: [], data: [] }
        }

    }
}

export default new DashboardApi();