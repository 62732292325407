import httpApi from './http-api';
import store from '../store';
import { Service } from '../../models/service';
import { LoadingActions, ServiceActions } from '../store/actions';
import { SortObject } from '../../models/sort';
import { arrayToString } from './utils';

class ServiceApi {

    async getAll(sort?: SortObject, category?: number[], service?: number[], disable?: boolean[]): Promise<Service[]> {

        let url: string = `/Services?`
        if (sort && sort.orderBy) {
            url = url + `sort=${sort.order}&column=${sort.orderBy}`
        }

        if (category && category.length > 0) {

            url = url + `&category=${arrayToString(category)}`
        }

        if (service && service.length > 0) {
            url = url + `&type=${arrayToString(service)}`
        }

        if (url === '/Services?') {
            url = url.replace("?", "")
        }

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { Services } = await httpApi.get(`${url}`);
            store.dispatch({
                type: ServiceActions.UPDATE,
                payload: {
                    services: Services
                }
            });

            return Services
        } catch (error) {
            return []
        }

    }

    async create(data: Service) {
        const { Description, type, category } = data
        try {
            const res = await httpApi.post('/Services/add', { Description, category, type });
            this.getAll()
            return res
        } catch (error) {

        }

    }

     async update(id : number,data: Service) {
        const { Description, type, category } = data
        try {
            const res = await httpApi.put(`/Services/update/${id}`, { Description, category, type });
            this.getAll()
            return res
        } catch (error) {

        }

    }


    async toggleVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.put(`/Services/disable/${id}`, { disable: visibility });
        } catch (error) {

        }

    }
}

export default new ServiceApi();