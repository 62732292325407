import { Divider } from '@material-ui/core'
import classNames from 'classnames'
import React, { useState } from 'react'
import Button from '../../../../shared/Button/Button'
import TextField from '../../../../shared/TextField/TextField'

import style from './ForgotForm.module.scss'

import authApi from '../../../../core/api/auth-api'

interface Istate {
    email: string,
}

interface Ierror {
    emailErrorText: string,
}

export default function ForgotForm() {
    const [state, setState] = useState<Istate>({
        email: "",
    })
    const [error, setError] = useState<Ierror>({
        emailErrorText: '',
    })


    const handleChange = (event: any) => {
        event.persist();
        const { name, value } = event.target

        if (name === "email") {
            let text: string = value
            text = text.toLowerCase()
            setState(prevState => ({
                ...prevState,
                [name]: text
            }))
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: value
            }))
        }

        handleError(name, value)

    }

    const handleError = (name: string = "submit", value: string = '') => {
        if (name !== "submit") {
            const ErrName: string = name + "ErrorText"
            let ErrVariable: string = ''

            if (!value) {
                ErrVariable = 'This field cannot be empty'
            }

            if (name === 'email') {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

                if (!pattern.test(value)) {
                    ErrVariable = "Please enter valid email address.";
                }
            }

            setError(prevErr => ({
                ...prevErr,
                [ErrName]: ErrVariable
            }))
        }
        else {
            const errors: Ierror = { ...error }
            Object.keys(state).forEach(key => {

                if (`${Reflect.get(state, key)}` === '') {
                    const ErrName: string = key + "ErrorText"
                    Reflect.set(errors, `${ErrName}`, 'This field cannot be empty')
                }
            });
            setError(errors)
        }


    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        handleError()
        let { emailErrorText } = error

        if (!emailErrorText) {
            try {
                await authApi.sendPasswordResetEmail(state.email);
            } catch (err:any) {
                setError({
                    emailErrorText: err.message
                })
            }
        }
    }

    return (
        <>
            <form className={style.formContainer} onSubmit={handleSubmit}>
                <div className={classNames('form')}>
                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    onChange={handleChange}
                                    error={!!error.emailErrorText}
                                    helperText={error.emailErrorText}
                                    value={state.email}
                                    name="email"
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Email address"
                                    placeholder="Enter Your Email address"
                                    variant="outlined" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Divider variant="fullWidth" /> */}

                <div className={style.actions}>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        disabled={!!error.emailErrorText || !state.email}
                        type="submit">
                        Send Code
                    </Button>
                </div>
            </form>
        </>
    )
}
