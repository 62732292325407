import React, { useEffect, useState } from 'react'
import routeNameObjects from '../../route_names/route_name_objects'
import Page from '../../shared/Page/Page'
import useStyles from './RentPaymentsStyles'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CustomTab from '../../shared/Tab/Tab'
import MasterTab from './components/Master/Master'
import HistoryTab from './components/History/History'
import { useSelector } from 'react-redux';
import { IRootState } from '../../core/store';

export default function RentPayments() {
    const classes = useStyles()
    const { userRole } = useSelector((state: IRootState) => state.userReducer)

    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }
    return (
        <Page
            currentRoute={routeNameObjects.rentPayments}
            parentRoutes={[routeNameObjects.rentPayments]}
        >
            <div className={classes.container}>
                {
                    isVendor()
                        ?
                        <HistoryTab />
                        :
                        <CustomTab
                            tabsData={[
                                {
                                    title: "Rent Master Data",
                                    icon: <AccountBalanceIcon />,
                                    component: (
                                        <MasterTab />
                                    ),
                                },
                                {
                                    title: "Rent Payment",
                                    icon: <AccountBalanceWalletIcon />,
                                    component: (
                                        <HistoryTab />
                                    ),
                                }
                            ]}
                        />
                }

            </div>
        </Page>
    )
}
