import { Availability, VendorService, VendorUser } from "../../models/vendor";
import { VendorActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface vendorState {
  vendorServices: VendorService[];
  vendorUser: VendorUser[];
  vendorAvailability: Availability[];
};

export const initialVendorState = {
  vendorServices: [],
  vendorUser: [],
  vendorAvailability: []
};

const vendorReducer = (state: vendorState = initialVendorState, action: Action) => {
  if (action && action.payload) {
    const { vendorServices, vendorUser, vendorAvailability } = action.payload;
    switch (action.type) {
      case VendorActions.UPDATE_SERVICES:
        if (vendorServices) {
          return {
            ...state,
            vendorServices: vendorServices
          };
        }
        break;

      case VendorActions.UPDATE_USER:
        if (vendorUser) {
          return {
            ...state,
            vendorUser: vendorUser
          };
        }
        break;

      case VendorActions.UPDATE_VACATION:
        if (vendorAvailability) {
          return {
            ...state,
            vendorAvailability: vendorAvailability
          };
        }
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default vendorReducer;
