import React from 'react'
import Page from '../../shared/Page/Page'
import useStyles from './DashboardStyles'
import routeNameObjects from '../../route_names/route_name_objects'
import CustomTab from '../../shared/Tab/Tab';
import CalendarView from './CalendarView/CalendarView';
import StatsView from './StatsView/StatsView';
import GiftCardView from './GiftCardView/GiftCartView';
import { useSelector } from 'react-redux';
import { IRootState } from '../../core/store';
import ActiveView from './AcitiveView/ActiveView';

import TodayIcon from '@material-ui/icons/Today';
import RedeemIcon from '@material-ui/icons/Redeem';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import BarChartIcon from '@material-ui/icons/BarChart';

export default function Dashboard() {
  const classes = useStyles()
  const { userRole } = useSelector((state: IRootState) => state.userReducer)

  const isVendor = (): boolean => {
    if (userRole.name === "vendor") {
      return true
    }
    else {
      return false
    }
  }

  return (
    <Page
      currentRoute={routeNameObjects.dashboard}
      parentRoutes={[routeNameObjects.dashboard]}
    >
      <div className={classes.container}>
        {isVendor() ?
          <CustomTab
            tabsData={[
              {
                title: "Calendar",
                component: <CalendarView />,
                icon: < TodayIcon />
              },
              {
                title: "Redeem Gift Card",
                component: <GiftCardView />,
                icon: <RedeemIcon />
              },
              {
                title: "Business Hours",
                component: <ActiveView />,
                icon: <FlightLandIcon />
              },
              {
                title: "Statistics",
                component: <StatsView />,
                icon: <BarChartIcon />
              }
            ]}
          />
          :
          <CustomTab
            tabsData={[
              {
                title: "Calendar",
                component: <CalendarView />,
                icon: < TodayIcon />
              },
              {
                title: "Statistics",
                component: <StatsView />,
                icon: <BarChartIcon />
              }
            ]}
          />
        }

      </div>
    </Page>
  )
}
