// @ts-nocheck
import { Paper } from '@material-ui/core'
import classNames from 'classnames';
import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux'
import { IRootState } from '../../../core/store'
import { ChartData } from '../../../models/dashboard';
import MyLoader from '../../../shared/Loader/my_loader';
import useStyles from './StatsViewStyles'


interface IProps {
    loading: boolean;
    theme: string[];
    options: Object;
}

export default function RevenueByCategory(props: IProps) {
    const classes = useStyles()
    const revByServices: ChartData = useSelector((state: IRootState) => state.dashboardReducer.revByServices)

    const dynamicColors = (length: number): string[] => {
        if (length <= props.theme.length && 0 < length) {
            return props.theme.slice(0, length)
        }
        else if (length > props.theme.length && 0 < length) {
            let newTheme: string[] = [...props.theme]
            for (let i = props.theme.length; i < length; i++) {
                let index = i % props.theme.length
                newTheme.push(props.theme[index])
            }
            return newTheme
        }
        else {
            return []
        }
    }
    return (
        <Paper className={classNames(classes.dataCard, classes.chartCard)}>
            <div className={classes.chartTitle}>Revenue by Category Types</div>
            {
                props.loading ?
                    <MyLoader height='339px' />
                    :
                    <Doughnut
                        type="doughnut"
                        data={{
                            labels: revByServices.labels,
                            datasets: [{
                                data: revByServices.data, backgroundColor: dynamicColors(revByServices.data.length),
                            }]
                        }}
                        options={props.options}
                    />
            }

        </Paper>

    )
}