import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '10px 90px',
        },
        logo: {

        },
        title: {
            fontSize: 25,
            fontWeight: "bold",
            textTransform: 'capitalize',
            textAlign: 'center',
        },
        services: {
            fontSize: 16,
            fontWeight: "bold",
            textTransform: 'capitalize',
            textAlign: 'center',
            marginTop: 10,
        },
        serviceName: {
            fontSize: 12,
            fontWeight: "bold",
            textTransform: 'capitalize',
            textAlign: 'start',
        },
        appBar: {
            position: 'relative',
        },
        dialogTitle: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }),
);

export default useStyles;