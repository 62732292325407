import React from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import useSTyles from "./my_loader_style";

interface IProps {
  height: string;
}

export default function MyLoader(props: IProps) {
  const classes = useSTyles();
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: `${props.height}`
    }}>
      <CircularProgress />
    </div>
  );
}
