import { ActiveStates, vendorPublicProfile } from "../../models/tvScreen";
import { TvScreenActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface tvScreenState {
  publicVendors: vendorPublicProfile[];
  activeStatus: ActiveStates[];
};

export const initialTvScreenState = {
  publicVendors: [],
  activeStatus: []
};

const tvScreenReducer = (state: tvScreenState = initialTvScreenState, action: Action) => {
  if (action && action.payload) {
    const { publicVendors, activeStatus } = action.payload;
    switch (action.type) {
      case TvScreenActions.UPDATE_VENDORS:
        if (publicVendors) {
          return {
            ...state,
            publicVendors: publicVendors
          };
        }

        break;

      case TvScreenActions.UPDATE_ACTIVE:
        if (activeStatus) {
          return {
            ...state,
            activeStatus: activeStatus
          };
        }

        break;

      default:
        return { ...state };
    }
  }

  return state;
};

export default tvScreenReducer;
