import React, { useEffect, useState } from 'react'
import routeNameObjects from '../../route_names/route_name_objects'
import Page from '../../shared/Page/Page'
import useStyles from './SupportTicketStyles'
import Table from '../../shared/Table/CustomTable'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button } from '@material-ui/core'
import DateTimePicker from '../../shared/DateTimePicker/DateTimePicker'
import MyNavigator from '../../route_names/navigation'
import time from '../../core/services/time'
import supportApi from '../../core/api/support-api'
import { SortObject } from '../../models/sort'

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { IRootState } from '../../core/store'
import { useSelector } from 'react-redux'
import { Support } from '../../models/support'
import { Employee } from '../../models/employee'
import { Suite } from '../../models/suite'
import TextField from '../../shared/TextField/TextField'
import CustomSelect from '../../shared/Select/Select'
import SuccessButton from '../../shared/Button/SuccessButton'
import WarningButton from '../../shared/Button/WarningButton'
import MyLoader from '../../shared/Loader/my_loader'
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CustomMultiSelectAutocomplete from '../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete'
import { VendorUser } from '../../models/vendor'
import CustomDateRangePicker from '../../shared/DateRangePicker/DateRangePicker'
import moment, { Moment } from 'moment'
import store from '../../core/store';
import { SupportActions } from '../../core/store/actions';

const TwilioChat = require("twilio-chat");

export default function SupportTicket() {
    const classes = useStyles()
    const Navigator = MyNavigator()
    const { userRole, user } = useSelector((state: IRootState) => state.userReducer)
    const vendorDetails: VendorUser[] = useSelector((state: IRootState) => state.vendorReducer.vendorUser)
    const AllEmployees: Employee[] = useSelector((state: IRootState) => state.filterReducer.employees)
    const AllSuites: Suite[] = useSelector((state: IRootState) => state.filterReducer.suites)
    const supportTicketData: Support[] = useSelector((state: IRootState) => state.supportReducer.supportTickets)
    const [startDate, setstartDate] = useState<Date | null>(time.getLastYear());
    const [endDate, setendDate] = useState<Date | null>(time.getToday());
    const [ticketDate, setTicketDate] = React.useState<[Moment, Moment] | null>([moment(time.getLastYear()), moment(time.getToday())]);
    const [empName, setempName] = useState<string[]>([])
    const [ticketId, setticketId] = useState<number[]>([])
    const [suiteName, setsuiteName] = useState<string[]>([])
    const [category, setcategory] = useState<string[]>([])

    const [empFilter, setempFilter] = useState<string[]>([])
    const [ticketIdFilter, setticketIdFilter] = useState<number[]>([])
    const [suiteFilter, setsuiteFilter] = useState<string[]>([])
    const [sort, setSort] = useState<SortObject>({ orderBy: 'ticket_raised_datetime', order: "desc" });
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [ticketItem, setticketItem] = useState<Support | null>(null)
    const [key, setKey] = useState<string>('')
    const [error, setError] = useState<{ [key: string]: string }>({})

    const [isloading, setloading] = useState<boolean>(true)
    const [clearFilters, setClearFilters] = useState<boolean>(false)


    const fetchSupportTickets = async () => {
        setloading(true)
        let start: number = 0
        let end: number = 0
        let empIdList: number[] = []
        let suiteIdList: number[] = []

        if (startDate && endDate) {
            start = time.DateToUnix(startDate)
            end = time.DateToUnix(endDate)
        }

        if (ticketDate) {
            start = time.DateToUnix(ticketDate[0].toDate())
            end = time.DateToUnix(ticketDate[1].toDate())
        }

        AllEmployees.map((item) => {
            if (empName.includes(item.full_name)) {
                empIdList.push(item.id)
            }

        })

        AllSuites.map((item) => {
            if (suiteName.includes(item.display_name)) {
                suiteIdList.push(item.id)
            }

        })

        if (isVendor()) {
            if (user) {
                try {
                    const res = await supportApi.getByUserId(start, end, sort, suiteIdList, ticketId)
                    if(res.length === 0){
                        setloading(false)
                    }
                } catch (error) {
                    setloading(false)
                }
            }
        }
        else if(userRole.name === "admin"){
            if (start > 0) {
                try {
                    await supportApi.getAllForAdmin(start, end, sort, empIdList, suiteIdList, ticketId)
                } catch (error) {
                    setloading(false)
                }
            }
        }
        else {
            if (start > 0) {
                try {
                    await supportApi.getAll(start, end, sort, empIdList, suiteIdList, ticketId)
                } catch (error) {
                    setloading(false)
                }
            }
        }
        setloading(false);
    }

    const setFilter = () => {
        let empFilterData: string[] = []
        let ticketIdFilterData: number[] = []
        let suiteFilterData: string[] = []

        AllEmployees.map((item) => {
            empFilterData.push(item.full_name)
        })

        supportTicketData.map((item) => {
            suiteFilterData.push(item.suite_name)
            ticketIdFilterData.push(item.id)
        })

        empFilterData = [...new Set(empFilterData)]
        suiteFilterData = [...new Set(suiteFilterData)]
        ticketIdFilterData = [...new Set(ticketIdFilterData)]
        empFilterData.sort()
        ticketIdFilterData.sort((a, b) => a - b)
        suiteFilterData.sort()

        setsuiteFilter(suiteFilterData)
        setticketIdFilter(ticketIdFilterData)
        setempFilter(empFilterData)

        setloading(false)
        setClearFilters(false)
    }

    const getToken = async () => {
        const token = await supportApi.getChatToken(user.id)
        return token
    }

    const handleEdit = async(ticket: Support) => {
        if(ticket?.id > 0){
            var newTicketLS:any = localStorage.getItem('NewTickets')
            var NewTickets:any = newTicketLS ? JSON.parse(newTicketLS) : []
            
            if(NewTickets.includes(ticket.id)){
                const index = NewTickets.indexOf(ticket.id)
                if (index > -1) {
                    NewTickets.splice(index, 1); 
                }
            }
            // if(NewTickets.length === 0){
            //     var dotElement:any  = document.getElementById('side_bar_dot');
            //     if(dotElement){
            //         dotElement.style.visibility = 'block'
            //     }
            // }
            localStorage.setItem('NewTickets',JSON.stringify(NewTickets))

            // store.dispatch({
            //     type: SupportActions.CHAT_STATUS,
            //     payload: {
            //         chatStatus: chatStatus,
            //     }
            // })
        }
        Navigator.pushNamed(`/support-ticket/${ticket.id}`)
    };

    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }


    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setticketItem(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }


    const handleError = (value: any, name?: string) => {
        const ErrVal: string = name ? name : key
        let ErrVariable: string = ''

        if (!value) {
            ErrVariable = 'This field cannot be empty'
        }

        setError(prevState => ({
            ...prevState,
            [ErrVal]: ErrVariable
        }))
    }

    const handleCreate = () => {
        if (ticketItem) {
            Object.keys(ticketItem).map((key) => {
                if (key === "name" || key === "message") {
                    handleError(Reflect.get(ticketItem, key), key)
                }

            })
        }
        setSubmitClicked(true)
    };

    const handleCreateSubmit = async () => {
        if (ticketItem) {
            await supportApi.create(ticketItem)
            fetchSupportTickets()
        }
    }

    const handleCreateOpen = () => {
        const item: Support = {
            id: 0,
            name: "",
            message: "",
            user_id: user.id,
            ticket_raised_datetime: 0,
            ticket_status: "NEW",
            vendor_email: '',
            suite_name: '',
            suite_id: vendorDetails[0].suite_id
        }
        setticketItem(item)
        setError({
            name: "",
            message: ""
        })
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setticketItem(null)
        setKey('')
        setError({})
        setSubmitClicked(false)
    };

    const createDialog = (
        < Dialog
            open={createOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{"Create New Support Ticket"}</DialogTitle>
            {
                ticketItem &&
                <DialogContent>
                    <Grid
                        style={{
                            marginBottom: "20px"
                        }}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid
                            spacing={2}
                            item
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={12}>
                                <TextField
                                    onFocus={() => {
                                        setKey("name")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.name}
                                    helperText={error.name}
                                    value={ticketItem.name}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Subject"
                                    variant="outlined" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    onFocus={() => {
                                        setKey("message")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.message}
                                    helperText={error.message}
                                    value={ticketItem.message}
                                    className='full-width'
                                    autoComplete="off"
                                    multiline
                                    fullWidth
                                    label="Description"
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            }

            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus>
                    Create Ticket
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleClearFilters = () => {
        setsuiteName([])
        setticketId([])
        setempName([])
        setFilter()
    }

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])

    useEffect(() => {
        if (submitClicked && JSON.stringify(error) === JSON.stringify({
            name: "",
            message: ""
        })) {
            handleCreateSubmit()
            handleCreateClose()
        }
        else {
            setSubmitClicked(false)
        }
    }, [error])


    useEffect(() => {
        if (supportTicketData.length > 0) {
            setFilter()
        }
    }, [AllSuites, AllEmployees])

    const isRealValue = (object:any) => {
        return object && object !== 'null' && object !== 'undefined';

    }

    useEffect(() => {
        if(supportTicketData && supportTicketData.length > 0){
            var newTicketLS:any = localStorage.getItem('NewTickets')
            var NewTickets:any = newTicketLS ? JSON.parse(newTicketLS) : []
            supportTicketData.map(ticket=>{
                const {
                    id,
                    ticket_status
                } = ticket

                if(id && ticket_status  === 'NEW'){
                    if(!NewTickets.includes(id)){
                        NewTickets.push(id)
                    }
                }
            })
            if(NewTickets.length > 0){
                localStorage.setItem('NewTickets',JSON.stringify(NewTickets))
            }
        }
        setFilter()
    }, [supportTicketData])

    useEffect(() => {
        fetchSupportTickets()
    }, [sort, startDate, endDate, empName, suiteName, ticketId, ticketDate])


    useEffect(() => {
        fetchSupportTickets()
    }, [])

    return (
        <Page
            currentRoute={routeNameObjects.supportTicket}
            parentRoutes={[routeNameObjects.supportTicket]}
        >
            <div className={classes.container}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        style={{
                            marginBottom: 10,
                        }}
                    >
                        <Grid item xs={12} md={6} lg={2}>
                            {/* <DateTimePicker label="Start Date" onChange={setstartDate} value={startDate} reset={clearFilters} defaultValue={time.getYesterday()} /> */}
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            {/* <DateTimePicker label="End Date" onChange={setendDate} value={endDate} reset={clearFilters} defaultValue={time.getToday()} /> */}
                            <CustomDateRangePicker label='Ticket Date' onChange={setTicketDate} value={ticketDate} reset={clearFilters} defaultValue={[moment(time.getLastYear()), moment(time.getToday())]} />
                        </Grid>
                        {
                            !isVendor() &&
                            <Grid item xs={12} md={6} lg={2}>
                                <CustomMultiSelectAutocomplete label="Suit Name" onChange={setsuiteName} data={suiteFilter} reset={clearFilters} />
                            </Grid>
                        }
                        <Grid item xs={12} md={6} lg={2}>
                            <CustomMultiSelectAutocomplete label="Ticket ID" onChange={setticketId} data={ticketIdFilter} reset={clearFilters} />
                        </Grid>
                        {
                            !isVendor() &&
                            <Grid item xs={12} md={6} lg={2}>
                                <CustomMultiSelectAutocomplete label="Professional Name" onChange={setempName} data={empFilter} reset={clearFilters} />
                            </Grid>
                        }
                        {/* <Grid item xs={12} md={6} lg={2}>
                        <CustomMultiSelectAutocomplete label="Category" onChange={setcategory} data={['ALL']} />
                    </Grid> */}
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ float: "right", fontSize : "12px",
                            padding: "6px 14px",  minWidth: "150px", fontWeight: "600", backgroundColor:"#C7EBD0" }}
                            onClick={() => {
                            setClearFilters(true);
                            }}
                            startIcon={<ClearAllIcon />}
                        >
                            Clear All
                        </Button>
                    </Grid>
                </Grid>
                {

                    isVendor() ?
                        <Table
                            data={supportTicketData}
                            title='Support Ticket'
                            isloading={isloading}
                            createFunction={handleCreateOpen}
                            onClick={handleEdit}
                            sortFunction={setSort}
                            sort={sort}
                            order={["id", "ticket_raised_datetime", 'name', 'message', 'ticket_status']}
                            ignore={['vendor_profile_url', 'user_id', 'suite_id', "vendor_name", 'chat_room', 'suite_name', 'is_seen']}
                            overrides={[{ from: 'name', to: "Subject" }, { from: 'message', to: "Description" }]}
                        />
                        :
                        <Table
                            data={supportTicketData}
                            title='Support Ticket'
                            isloading={isloading}
                            onClick={handleEdit}
                            sortFunction={setSort}
                            sort={sort}
                            order={["id", "ticket_raised_datetime", "vendor_name", 'suite_name', 'name', 'message', 'ticket_status']}
                            ignore={['vendor_profile_url', 'chat_room', 'is_seen']}
                            overrides={[{ from: 'name', to: "Subject" }, { from: 'message', to: "Description" }]}
                        />

                }

            </div>
            {createDialog}
        </Page>
    )
}
