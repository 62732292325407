import httpApi from './http-api';
import store from '../store';
import { Employee, Location } from '../../models/employee';
import { EmployeeActions, LoadingActions } from '../store/actions';
import { SortObject } from '../../models/sort';

class CustomerApi {

    async getallVendors(disable?: boolean[]): Promise<Employee[]> {

        let url: string = `/user/getallVendors?`

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { user } = await httpApi.get(`${url}`);
            store.dispatch({
                type: EmployeeActions.UPDATE,
                payload: {
                    employees: user
                }
            });
            return user
        } catch (error) {
            return []
        }

    }
    async getallVendorsForAdmin(disable?: boolean[]): Promise<Employee[]> {

        let url: string = `/user/admin/getallVendors?`

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { user } = await httpApi.get(`${url}`);
            store.dispatch({
                type: EmployeeActions.UPDATE,
                payload: {
                    employees: user
                }
            });
            return user
        } catch (error) {
            return []
        }

    }

    async getAllCustomers(): Promise<Employee[]> {
        try {
            const { customers } = await httpApi.get('/customer?sort=asc&column=email');
            store.dispatch({
                type: EmployeeActions.UPDATE_CUSTOMER,
                payload: {
                    customer: customers
                }
            });
            return customers
        } catch (error) {
            return []
        }

    }

    async getAllAdmin(disable?: boolean[]): Promise<Employee[]> {

        let url: string = `/user/admin/getAllAdmin?`

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { Admin } = await httpApi.get(`${url}`);
            store.dispatch({
                type: EmployeeActions.UPDATE_ADMIN,
                payload: {
                    admins: Admin
                }
            });
            return Admin
        } catch (error) {
            return []
        }

    }

    async getAllAdminForAdmin(disable?: boolean[]): Promise<Employee[]> {

        let url: string = `/user/admin/getAllAdminsforadmin?`

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const adminList = await httpApi.get(`${url}`);
            store.dispatch({
                type: EmployeeActions.UPDATE_ADMIN,
                payload: {
                    admins: adminList
                }
            });
            return adminList
        } catch (error) {
            return []
        }

    }

    async getAllSuperAdmin(disable?: boolean[]): Promise<Employee[]> {

        let url: string = `/user/super-admins?`

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { SuperAdmin } = await httpApi.get(`${url}`);
            store.dispatch({
                type: EmployeeActions.UPDATE_SUPER_ADMIN,
                payload: {
                    superAdmins: SuperAdmin
                }
            });
            return SuperAdmin
        } catch (error) {
            return []
        }

    }

    async getCustomerByEmail(email: string): Promise<{guest:boolean,customerId:number}> {
        try {
            const {guest,customerId}=await httpApi.post(`/customer/check-or-create`, { email: email });
            return {guest,customerId}
        } catch (error) {
            return {guest:false,customerId:0}
        }
    }


    async createCustomer(id:number,firstName: string,lastName:string,mobile:string) {
        let results = await httpApi.put(`/customer/${id}/update-by-admin`, { first_name: firstName,last_name:lastName,mobile_no:mobile })
        console.log(results)
        return results
    }

    async toggleVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.put(`/user/user-status?id=${id}`, { disable: visibility });
            this.getallVendors()
            this.getAllAdmin()
            this.getAllSuperAdmin()
        } catch (error) {

        }

    }


    async getLocations(): Promise<Location[]> {
        let url: string = `/location`
        try {
            const { locations } = await httpApi.get(`${url}`);
            return locations
        } catch (error) {
            return []
        }
    }

    async getLocationsForAdmin(): Promise<Location[]> {
        let url: string = `/location/admin`
        try {
            const { locations } = await httpApi.get(`${url}`);
            return locations
        } catch (error) {
            return []
        }
    }

  async getAllSuite (){
   const { Suites } = await httpApi.get('/suites/getsuite?sort=asc&column=id');
   return Suites;
  }

  async getAllSuiteForAdmin (){
   const { Suites } = await httpApi.get('/suites/admin/getsuite?sort=asc&column=id');
   return Suites;
  }
}

export default new CustomerApi();