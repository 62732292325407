import { Badge, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { IRootState } from "../../../../core/store";
import { SupportChatStatus, SupportStatus } from "../../../../models/support";
import MyNavigator from "../../../../route_names/navigation";
import { RouteNames } from "../../../../route_names/route_names";
import useStyles from "./NavItemStyles";
import { SupportActions } from '../../../../core/store/actions';

interface MyItemProps {
    routeDetails: RouteNames;
    currentRoute: string;
}

export default function NavItem(props: MyItemProps) {
    const classes = useStyles();

    const { pushToPage } = MyNavigator();
    const chatStatus: SupportChatStatus[] = useSelector((state: IRootState) => state.supportReducer.chatStatus)
    const { userRole, user } = useSelector((state: IRootState) => state.userReducer)
    const statusTicket : SupportStatus[] = useSelector((state: IRootState) => state.supportReducer.statusTicket)
    const navStatus:any = useSelector((state: IRootState) => state.supportReducer.navStatus)
    const [msgCount, setmsgCount] = useState<number>(0)
    const [invisible, setInvisible] = useState<boolean>(true);

    // useEffect(() => {
    //     let ticketStatus = true

        

    //     console.log(navStatus)

    // }, [navStatus])

    const findSelect = (route: RouteNames): boolean => {
        if (props.currentRoute === route.route) {
            return true
        }
        else {
            if (route.children.length !== 0) {
                let select = false
                route.children.map(item => {
                    select = select || findSelect(item)
                });
                return select
            }
            else {
                return false
            }
        }
    }

    const [isHover, setHover] = useState(false);
    const isSelected = findSelect(props.routeDetails)

    const onItemClick = (route: RouteNames) => {
        pushToPage(route);
    };

    // const isVendor = (): boolean => {
    //     if (userRole.name === "vendor") {
    //         return true
    //     }
    //     else {
    //         return false
    //     }
    // }
    
    return props.routeDetails.isHide ? (
        <div />
    ) : (
        <div>
            <div>
                <ListItem
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    button
                    onClick={() => { onItemClick(props.routeDetails); }}
                    className={classNames(classes.listItem, isSelected && classes.selected)}
                >
                    <ListItemIcon
                        className={classNames(
                            classes.listItemText,
                            (isHover || isSelected) && classes.listItemTextHover
                        )}
                    >
                        <props.routeDetails.icon />
                    </ListItemIcon>
                        <ListItemText
                        className={classNames(
                            classes.listItemText,
                            (isHover || isSelected) && classes.listItemTextHover
                        )}
                        primary={props.routeDetails.name === 'Support Ticket' ?
                            <>{props.routeDetails.name}<Badge
                                color="error"
                                variant="dot"
                                id="side_bar_dot"
                                invisible={navStatus}
                                style={{ marginLeft: 10, height: 15 }}
                            />
                            </>
                            : props.routeDetails.name
                        }
                    />
                </ListItem>     
            </div>
        </div>
    );
}
