import classNames from 'classnames'
import React, { useState } from 'react'
import authApi from '../../../../core/api/auth-api'
import Button from '../../../../shared/Button/Button'
import TextField from '../../../../shared/TextField/TextField'
import style from './LoginForm.module.scss'



interface Istate {
    userName: string,
    password: string,
}

interface Ierror {
    userNameErrorText: string,
    passwordErrorText: string,
}

export default function LoginForm() {
    const [state, setState] = useState<Istate>({
        userName: "",
        password: "",
    })
    const [error, setError] = useState<Ierror>({
        userNameErrorText: '',
        passwordErrorText: '',
    })


    const handleChange = (event: any) => {
        event.persist();
        const { name, value } = event.target

        if (name === "email") {
            let text: string = value
            text = text.toLowerCase()
            setState(prevState => ({
                ...prevState,
                [name]: text
            }))
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: value
            }))
        }

        handleError(name, value)

    }

    const handleError = (name: string = "submit", value: string = '') => {
        if (name !== "submit") {
            const ErrName: string = name + "ErrorText"
            let ErrVariable: string = ''

            if (!value) {
                ErrVariable = 'This field cannot be empty'
            }

            // if (name === 'email') {
            //     let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            //     if (!pattern.test(value)) {
            //         ErrVariable = "Please enter valid email address.";
            //     }
            // }

            setError(prevErr => ({
                ...prevErr,
                [ErrName]: ErrVariable
            }))
        }
        else {
            const errors: Ierror = { ...error }
            Object.keys(state).forEach(key => {

                if (`${Reflect.get(state, key)}` === '') {
                    const ErrName: string = key + "ErrorText"
                    Reflect.set(errors, `${ErrName}`, 'This field cannot be empty')
                }
            });
            setError(errors)
        }


    }

    const handleLogin = async (event: any) => {
        event.preventDefault();
        handleError()
        let { userNameErrorText, passwordErrorText } = error

        if (!userNameErrorText && !passwordErrorText) {
            try {
                await authApi.login(state.userName, state.password);
            } catch (err:any) {
                setError({
                    userNameErrorText: '',
                    passwordErrorText: err.message
                })
                setState(prevState => ({
                    ...prevState,
                    password: '',
                }))
            }
        }
    }

    return (
        <>
            <form className={style.formContainer} onSubmit={handleLogin}>
                <div className={classNames('form')}>
                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    onChange={handleChange}
                                    error={!!error.userNameErrorText}
                                    helperText={error.userNameErrorText}
                                    value={state.userName}
                                    name="userName"
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Username"
                                    variant="outlined" />
                            </div>
                        </div>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    onChange={handleChange}
                                    error={!!error.passwordErrorText}
                                    helperText={error.passwordErrorText}
                                    name="password"
                                    value={state.password}
                                    className='full-width'
                                    fullWidth
                                    autoComplete="off"
                                    type="password"
                                    label="Password"
                                    variant="outlined" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Divider variant="fullWidth" /> */}

                <div className={style.actions}>
                    <Button
                        onClick={handleLogin}
                        color="primary"
                        type="submit">
                        Sign In
                    </Button>
                </div>
            </form>
        </>
    )
}
