import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import routeNameObjects from './route_name_objects';

export interface RouteNames {
    name: string;
    route: string;
    component: any;
    children: RouteNames[];
    isHide?: boolean;
    nestedLevel: number;
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export const generalRouteNames: RouteNames[] = [
    routeNameObjects.login,
    routeNameObjects.signupUser,
    routeNameObjects.signupAdmin,
    routeNameObjects.passwordReset,
];

export const superAdminRouteNames: RouteNames[] = [
  routeNameObjects.dashboard,
  routeNameObjects.supportTicket,
  routeNameObjects.rentPayments,
  routeNameObjects.giftCards,
  routeNameObjects.services,
  routeNameObjects.suites,
  routeNameObjects.bookings,
  routeNameObjects.employees,
  routeNameObjects.updateUser,
  // routeNameObjects.locations,
];

export const adminRouteNames: RouteNames[] = [
  routeNameObjects.dashboard,
  routeNameObjects.supportTicket,
  // routeNameObjects.giftCards,
  routeNameObjects.services,
  routeNameObjects.suites,
  routeNameObjects.bookings,
  routeNameObjects.employees,
  routeNameObjects.updateUser,
];

export const userRouteNames: RouteNames[] = [
    routeNameObjects.dashboard,
    routeNameObjects.supportTicket,
    routeNameObjects.rentPayments,
    routeNameObjects.bookings,
    routeNameObjects.customers,
    routeNameObjects.editProfile,
    routeNameObjects.updateUser,
];
