import { createStyles, makeStyles, Theme } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipRoot: {
            marginRight: 1,
            marginBottom: 1
        }
    }),
);

export default useStyles;