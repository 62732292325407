import { Grid, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import supportApi from '../../../../core/api/support-api'
import time from '../../../../core/services/time'
import { IRootState } from '../../../../core/store'
import { Support } from '../../../../models/support'
import { User, UserRole } from '../../../../models/user'
import Button from '../../../../shared/Button/Button'
import WarningButton from '../../../../shared/Button/WarningButton'
import TextField from '../../../../shared/TextField/TextField'
import useStyles from './SupportTicketDataCardStyles'

interface Props {
    data: Support | null;
    closingTicketFunction: Function;
}

export default function SupportTicketDataCard(props: Props) {
    const classes = useStyles()
    const userRole: UserRole = useSelector((state: IRootState) => state.userReducer.userRole)
    const [data, setdata] = useState<Support | null>(null)
    const [remark, setRemark] = useState<string>('')
    const [cancelClicked, setCancelClicked] = useState<boolean>(false)
    const [closeClicked, setCloseClicked] = useState<boolean>(false)
    const [remarkError, setRemarkError] = useState<string>('This field cannot be empty')

    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }

    const handleValue = (value: any, key: string) => {
        const colNamesWithUnixTime: string[] = ['ticket_raised_datetime']
        let pattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/
        if (pattern.test(value)) {
            return (<img src={value} alt="" width="60px" />)
        }
        else if (colNamesWithUnixTime.includes(key)) {
            return time.unixToDateTime(value)
        }
        else {
            return handleEmpty(value)
        }
    }

    const handleEmpty = (value: any) => {
        let output: any
        if (value === '' || value === null) {
            output = "-"
        }
        else if (typeof value === 'boolean') {
            if (value) {
                output = 'True'
            }
            else {
                output = 'False'
            }
        }
        else {
            output = value
        }
        return output

    }

    const formatKey = (value: string) => {
        value = value.replace("vendor", 'professional')
        let output: string
        if (!!value) {
            output = value.split("_").join(" ")

            if (value === "ticket_raised_datetime") {
                output = "Raised On"
            }

            if (value === "ticket_status") {
                output = "Status"
            }

            if (value === "id") {
                output = "Ticket ID"
            }

            if (value === "name") {
                output = "Subject"
            }

            if (value === "message") {
                output = "Description"
            }

        }
        else {
            output = "unknown"
        }

        return output

    }
    const handleRemarkChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setRemark(value)
        handleRemarkError(value)
    }

    const handleRemarkError = (val: string) => {
        if (val) {
            setRemarkError('')
        }
        else {
            setRemarkError('This field cannot be empty')
        }

    }

    const handleAccept = async () => {
        if (data) {
            await supportApi.updateState(data.id, 'ACCEPTED')
        }

    }

    const handleCancel = async () => {
        if (data && remark) {
            await supportApi.updateState(data.id, 'CANCELLED', remark)
            setCancelClicked(false)
        }
    }

    const handleClose = async () => {
        if (data && remark) {
            await supportApi.updateState(data.id, 'RESOLVED', remark)
            setCloseClicked(false)
            props.closingTicketFunction(true)
        }
    }

    useEffect(() => {
        setdata(props.data)
    }, [props.data])

    return (
        <Paper className={classes.container}>
            <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={6}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {data && Object.keys(data).map((key:any) => {
                        if (data) {
                            let value = Reflect.get(data, `${key}`)
                            let ignore: string[] = ['message', 'ticket_raised_datetime', "ticket_status", 'full_name', 'chatMessages', 'chat_room', 'vendor_profile_url', 'vendor_name', 'user_id', 'suite_id', 'closing_remark']
                            if (!ignore.includes(key)) {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Grid
                                            item
                                            className={classes.dataHead}
                                        >
                                            <Grid item>
                                                {formatKey(key)} :
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.data}
                                        >
                                            {handleValue(value, key)}
                                        </Grid>

                                    </Grid>
                                )
                            }

                        }

                    })}
                </Grid>

                <Grid
                    item
                    xs={6}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {(isVendor() ? ['ticket_raised_datetime', "ticket_status", 'closing_remark'] : ['ticket_raised_datetime', "ticket_status", 'vendor_name', 'closing_remark']).map((key) => {
                        if (data) {
                            let value = Reflect.get(data, `${key}`)
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid
                                        item
                                        className={classes.dataHead}
                                    >
                                        <Grid item >
                                            {formatKey(key)} :
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.data}
                                    >
                                        {handleValue(value, key)}
                                    </Grid>

                                </Grid>
                            )

                        }

                    })}
                </Grid>

                <Grid
                    item
                    xs={12}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {['message'].map((key) => {
                        if (data) {
                            let value = Reflect.get(data, `${key}`)
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid
                                        item
                                        className={classes.dataHead}
                                    >
                                        <Grid item>
                                            {formatKey(key)} :
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        className={classes.data}
                                    >
                                        {handleValue(value, key)}
                                    </Grid>

                                </Grid>
                            )

                        }

                    })}
                </Grid>
                {
                    cancelClicked &&

                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <TextField
                                name="remark"
                                onChange={handleRemarkChange}
                                error={!!remarkError}
                                helperText={remarkError}
                                value={remark}
                                className='full-width'
                                fullWidth
                                multiline
                                minRows={4}
                                autoComplete="off"
                                label="Remark"
                                variant="outlined" />
                        </Grid>
                        <Grid item >
                            <WarningButton
                                color="primary"
                                onClick={() => setCancelClicked(false)}
                            >
                                Cancel
                            </WarningButton>
                        </Grid>
                        <Grid item >
                            <Button
                                color="primary"
                                onClick={handleCancel}
                                disabled={!remark}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                }

                {
                    closeClicked &&

                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <TextField
                                name="remark"
                                onChange={handleRemarkChange}
                                error={!!remarkError}
                                helperText={remarkError}
                                value={remark}
                                className='full-width'
                                fullWidth
                                multiline
                                minRows={4}
                                autoComplete="off"
                                label="Remark"
                                variant="outlined" />
                        </Grid>
                        <Grid item >
                            <WarningButton
                                color="primary"
                                onClick={() => setCloseClicked(false)}
                            >
                                Cancel
                            </WarningButton>
                        </Grid>
                        <Grid item >
                            <Button
                                color="primary"
                                onClick={handleClose}
                                disabled={!remark}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                }

                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                >
                    {
                        data && data.ticket_status === "NEW" && isVendor() && !cancelClicked &&

                        <Grid item >
                            <WarningButton
                                color="primary"
                                onClick={() => setCancelClicked(true)}
                            >
                                Cancel Ticket
                            </WarningButton>
                        </Grid>
                    }
                    {
                        data && data.ticket_status === "NEW" && !isVendor() &&

                        <Grid item >
                            <Button
                                color="primary"
                                onClick={handleAccept}
                            >
                                Accept Ticket
                            </Button>
                        </Grid>

                    }
                    {
                        data && data.ticket_status === "ACCEPTED" && !isVendor() && !closeClicked &&
                        <>
                            <Grid item >
                                <Button
                                    color="primary"
                                    onClick={() => setCloseClicked(true)}
                                >
                                    Close Ticket
                                </Button>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}
