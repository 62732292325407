import httpApi from './http-api';
import store from '../store';
import { CategoryActions, LoadingActions } from '../store/actions';
import { SortObject } from '../../models/sort';
import { Category } from '../../models/category';
import filterApi from './filters-api'

class CategoryApi {

    async getAll(disable?: boolean[]): Promise<Category[]> {
        let url: string = `/category/getall?`

        if(disable){
            url = url + `&disable=${disable}`
        }
        
        try {
            const { Category } = await httpApi.get(`${url}`);
            store.dispatch({
                type: CategoryActions.UPDATE,
                payload: {
                    categories: Category
                }
            });
            filterApi.getAllCategories()

            return Category
        } catch (error) {
            return []
        }

    }

    async getActiveCategories(): Promise<Category[]> {
        let url: string = `/category/getall?disable=false`

        try {
            const { Category } = await httpApi.get(`${url}`);
            return Category
        } catch (error) {
            return []
        }
    }

    async create(data: FormData) {
        try {
            const res = await httpApi.post('/category/add', data);
            this.getAll()
        } catch (error) {

        }

    }

    async updateCategory(id: number, data: FormData) {
        
        try {
            const res = await httpApi.post(`/category/update/${id}`, data);
            this.getAll()
        } catch (error) {
            return []
        }

    }

    async toggleVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.put(`/category/updateStatus/${id}`, { disable: visibility });
            filterApi.getAllCategories()
        } catch (error) {

        }

    }
}

export default new CategoryApi();