import { LoadingActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface loadingState {
  loading: boolean;
};

export const initialLoadingState = {
  loading: false
};

const loadingReducer = (state: loadingState = initialLoadingState, action: Action) => {
  if (action && action.payload) {
    const { loading } = action.payload;
    switch (action.type) {
      case LoadingActions.UPDATE:
        return {
          ...state,
          loading: loading
        };
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default loadingReducer;
