import httpApi from './http-api';
import store from '../store';
import { Employee } from '../../models/employee';
import { EmployeeActions, LoadingActions, RentActions } from '../store/actions';
import { SortObject } from '../../models/sort';
import { arrayToString } from './utils';
import { RentHistory, RentMaster } from '../../models/rent';
import { Pagination } from '../../models/pagination';

class RentApi {

    async getAll(from: number, to: number, sort?: SortObject, employee?: number[]): Promise<RentMaster[]> {
        let url: string = `/rent/getRentAll?from=${from}&to=${to}`
        if (sort && sort.orderBy) {
            url = url + `&sort=${sort.order}&column=${sort.orderBy}`
        }

        if (employee && employee.length > 0) {
            url = url + `&employee=${arrayToString(employee)}`
        }

        try {
            const { Rent } = await httpApi.get(`${url}`);
            if (Array.isArray(Rent)) {
                // store.dispatch({
                //     type: RentActions.UPDATE_MASTER,
                //     payload: {
                //         rentData: Rent
                //     }
                // });
            }

            return Rent
        } catch (error) {
            return []
        }


    }

    async getMaster(sort: SortObject, pagination: Pagination, employee?: number): Promise<RentMaster[]> {
        let url: string = `/rent/referential-rent?sort=${sort.order}&column=${sort.orderBy == "suite_id" ? 'rent_referential.suite_id' : sort.orderBy}&pageNo=${pagination.pageNo}&limit=${pagination.limit}`

        if (employee) {
            url = url + `&id=${employee}`
        }

        try {
            const { rent, rent_records } = await httpApi.get(`${url}`);
            if (Array.isArray(rent)) {
                store.dispatch({
                    type: RentActions.UPDATE_MASTER,
                    payload: {
                        rentMasterData: rent,
                        rentMasterCount: rent_records
                    }
                });
            }

            return rent
        } catch (error) {
            return []
        }

    }

    async getHistory(startDate: number, endDate: number, pagination: Pagination, sort: SortObject, suite?: number[], employee?: number[], status?: string[]): Promise<RentHistory[]> {
        let url: string = `/rentrecord/getrent-records?to=${endDate}&from=${startDate}&pageNo=${pagination.pageNo}&limit=${pagination.limit}&sort=${sort.order}&column=${sort.orderBy}`
        const count: number = url.length

        if (suite && suite.length > 0) {
            url = url + `&suite_id=${arrayToString(suite)}`
        }

        if (employee && employee.length > 0) {
            url = url + `&vendor_id=${arrayToString(employee)}`
        }

        if (status && status.length > 0) {
            url = url + `&rent_status=${arrayToString(status)}`
        }

        try {
            const { rent_records, rent_records_total } = await httpApi.get(`${url}`);
            if (Array.isArray(rent_records)) {
                store.dispatch({
                    type: RentActions.UPDATE_HISTORY,
                    payload: {
                        rentHistoryData: rent_records,
                        rentHistoryCount: rent_records_total
                    }
                });
            }

            return rent_records
        } catch (error) {
            return []
        }

    }

    async getHistoryAll(startDate: number, endDate: number, sort: SortObject, suite?: number[], employee?: number[], status?: string[]): Promise<RentHistory[]> {
        let url: string = `/rentrecord/getrent-records-all?to=${endDate}&from=${startDate}&sort=${sort.order}&column=${sort.orderBy}`
        const count: number = url.length

        if (suite && suite.length > 0) {
            url = url + `&suite_id=${arrayToString(suite)}`
        }

        if (employee && employee.length > 0) {
            url = url + `&vendor_id=${arrayToString(employee)}`
        }

        if (status && status.length > 0) {
            url = url + `&rent_status=${arrayToString(status)}`
        }

        try {
            const { rent_records, rent_records_total } = await httpApi.get(`${url}`);

            return rent_records
        } catch (error) {
            return []
        }

    }

    async updateFixedCredit(data: object) {
        let url: string = `/rentrecord/update_rent_history`
        try {
            await httpApi.put(`${url}`, data);
        } catch (error) {

        }

    }

    async updateVariableCredit(id: number, data: object) {
        let url: string = `/rent/referential-rent/${id}`
        try {
            await httpApi.put(`${url}`, data);
        } catch (error) {

        }

    }

}

export default new RentApi();