/* eslint-disable react/jsx-no-undef */
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import locationApi from "../../core/api/location-api"
import { IRootState } from "../../core/store"
import { Location } from '../../models/location'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, TextField, DialogContentText } from '@material-ui/core'
import Table from '../../shared/Table/CustomTable'
import routeNameObjects from "../../route_names/route_name_objects"
import Page from '../../shared/Page/Page'
import useStyles from './LocationStyles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import MyNavigator from "../../route_names/navigation"
import WarningButton from "../../shared/Button/WarningButton"
import SuccessButton from "../../shared/Button/SuccessButton"
import PlaceIcon from '@material-ui/icons/Place';
import CustomTab from "../../shared/Tab/Tab"


export default function LocationPage() {

    const classes = useStyles()
    const Navigator = MyNavigator()
    const { userRole, user } = useSelector((state: IRootState) => state.userReducer)
    const [isloading, setloading] = useState<boolean>(true)
    const locationData: Location[] = useSelector((state: IRootState) => state.locationReducer.allLocations)
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [location, setlocation] = useState<Location | null>(null)
    const [error, setError] = useState<{ [key: string]: string }>({})
    const [key, setKey] = useState<string>('')
    const [clearFilters, setClearFilters] = useState<boolean>(false)
    const [disableTableData, setDisableTableData] = useState<string[]>([])
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [editItem, setEditItem] = useState<Location | null>(null)
    const [toggleItem, setToggleItem] = useState<Location | null>(null)
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);
    const [displayName, setDisplayName] = useState<string>('')


    const fetchLocations = async () => {
        setloading(true)
        let activeFilterData: boolean[] = []

        locationData.map((item) => {
            if (item.disabled && disableTableData.includes("Active")) {
                activeFilterData.push(false)
            }

            if (item.disabled === 0 && disableTableData.includes("Inactive")) {
                activeFilterData.push(true)
            }
        })

        activeFilterData = [...new Set(activeFilterData)]


        try {
            const location = await locationApi.getLocations()
        } catch (error) {
            setloading(false)
        }
        setloading(false)
    }


    const handleToggleOpen = (item: Location) => {
        console.log(item);
        
        setToggleItem(item)
        setToggleOpen(true);
    };

    const handleToggleClose = () => {
        setToggleOpen(false);
    };

    const handleToggle = async () => {
        if (toggleItem) {
            await locationApi.toggleVisibilityById(toggleItem.id, toggleItem.disabled === 1 ? 0 : 1)
        }
        setToggleOpen(false);
        fetchLocations()
    };


    useEffect(() => {
        fetchLocations()
    }, [])

    // useEffect(() => {
    //     fetchLocations()
    // }, [createOpen])

    useEffect(() => {
        if (submitClicked && JSON.stringify(error) === JSON.stringify({
            name: "",
            message: ""
        })) {
            handleCreateSubmit()
            handleCreateClose()
        }
        else {
            setSubmitClicked(false)
        }
    }, [error])

    const handleChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setlocation(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }
    const handleEditChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setEditItem(prevState => (prevState && {
            ...prevState,
            [key]: value
        }))

        handleError(value)
    }

    const handleError = (value: any, name?: string) => {
        const ErrVal: string = name ? name : key
        let ErrVariable: string = ''

        if (!value) {
            ErrVariable = 'This field cannot be empty'
        }

        setError(prevState => ({
            ...prevState,
            [ErrVal]: ErrVariable
        }))
    }
    const handleCreate = () => {
        if (location) {
            Object.keys(location).map((key) => {
                if (key === "name" || key === "address") {
                    handleError(Reflect.get(location, key), key)
                }

            })
        }

        if (createOpen) {
            handleError(displayName, "displayName")
        }

        if (editOpen) {
            handleError(displayName, "displayName")
            handleEditSubmit()
            handleCreateClose()
        }

        setSubmitClicked(true)
        handleCreateSubmit();
        handleCreateClose();
    };

    const handleCreateSubmit = async () => {
        if (location) {
            await locationApi.create(location)
            fetchLocations()
        }
    }

    const handleCreateOpen = () => {
        const item: Location = {
            id: 0,
            name: "",
            address: "",
            disabled: 0,
        }
        setlocation(item)
        setError({
            name: "",
            message: ""
        })
        setCreateOpen(true);
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setEditOpen(false);
        setlocation(null)
        setEditItem(null)
        setKey('')
        setError({})
        setSubmitClicked(false)
    };

    const handleLocationEditOpen = (item: Location) => {
        
        setError({
            name: '',
            address: '',
        })
        setEditItem(item)
        setDisplayName(item.name)
        setEditOpen(true);
    };

    const handleEditSubmit = async () => {
        const id: number = editItem ? editItem.id : 0
            if (editItem) {
                await locationApi.update( id ,editItem); // DONE
            }
            fetchLocations();
    }
    

    const createDialog = (
        < Dialog
            open={createOpen || editOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{createOpen ? "Create New Location" : `Update ${editItem?.name} Location`}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        marginBottom: "20px"
                    }}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    {
                        createOpen &&
                        <>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("name")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.name}
                                    helperText={error.name}
                                    value={location?.name}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Name"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("address")
                                    }}
                                    onChange={handleChange}
                                    error={!!error.address}
                                    helperText={error.address}
                                    value={location?.address}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Address"
                                    variant="outlined" />
                            </Grid>
                        </>
                    }

                    {
                        editOpen && editItem &&
                        <>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("name")
                                    }}
                                    onChange={handleEditChange}
                                    error={!!error.name}
                                    helperText={error.name}
                                    value={editItem?.name}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Name"
                                    variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    onFocus={() => {
                                        setKey("address")
                                    }}
                                    onChange={handleEditChange}
                                    error={!!error.address}
                                    helperText={error.address}
                                    value={editItem?.address}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Address"
                                    variant="outlined" />
                            </Grid>
                           

                        </>
                    }

                </Grid>
            </DialogContent>
            <DialogActions style={ {display : 'flex', justifyContent: 'space-around'} }>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus>
                    {createOpen ? "Create" : "Update"}
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )


    const toggleDialog = (
        < Dialog
            open={toggleOpen}
            onClose={handleToggleClose}
        >
            <DialogTitle >{"Update Location Status"}</DialogTitle>
            <DialogContent>
                {
                    toggleItem &&
                    <DialogContentText>
                        Are you sure you want to {toggleItem && toggleItem.disabled === 1 
                        ? "deactivate" : "activate"}  {toggleItem && toggleItem.id} Suite?
                    </DialogContentText>
                }

            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleToggleClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleToggle} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )
    return (
        <Page
            currentRoute={routeNameObjects.locations}
            parentRoutes={[routeNameObjects.locations]}
        >
            <div className={classes.container}>
                <CustomTab
                    tabsData={
                        [{
                            title: "Locations",
                            icon: <PlaceIcon />,
                            component: (
                                <div>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <Grid
                                            item
                                            xs={10}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={2}
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            <Grid item xs={12} md={6} lg={2}>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button
                                            variant="contained"
                                            size="medium"
                                                style={{ float: "right", fontSize : "12px",
                                                padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                                                onClick={() => {
                                                setClearFilters(true);
                                                }}
                                                startIcon={<ClearAllIcon />}
                                            >
                                                Clear All
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            ),
                        }]} />
                <Table
                    data={locationData}
                    title='Locations'
                    isloading={isloading}
                    createFunction={handleCreateOpen}
                    editFunction={handleLocationEditOpen}
                    // sortFunction={setSort}
                    // sort={sort}
                    ignore={["disabled" ]}
                    order={["id", "name", "address", 'disabled']}
                    toggle={
                            {
                                toggleFunction: handleToggleOpen,
                                toggleColumn: "disabled"
                            }
                        }
                />
            </div>
            {createDialog}
            {toggleDialog}
        </Page>
    )
}