import React, { useCallback, useEffect, useState } from 'react'
import routeNameObjects from '../../route_names/route_name_objects'
import Page from '../../shared/Page/Page'
import useStyles from './BookingsStyles'
import Table from '../../shared/Table/CustomTable'
import { Button, Grid, Slider } from '@material-ui/core'
import DateTimePicker from '../../shared/DateTimePicker/DateTimePicker'
import time from '../../core/services/time'

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import bookingApi from '../../core/api/booking-api'
import { SortObject } from '../../models/sort'
import { useSelector } from 'react-redux'
import { IRootState } from '../../core/store'
import { Booking, BookingAll } from '../../models/booking'
import { Employee } from '../../models/employee'
import { Customer } from '../../models/customer'
import { Pagination } from '../../models/pagination'
import { Service } from '../../models/service'

import ClearAllIcon from '@material-ui/icons/ClearAll';
import CustomMultiSelectAutocomplete from '../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete'
import moment, { Moment } from 'moment'
import CustomDateRangePicker from '../../shared/DateRangePicker/DateRangePicker'
import { User } from '../../models/user'

import { Button as MUIButton, Dialog, DialogActions, DialogContent,  DialogTitle, Grid as MUIGrid} from '@material-ui/core'
import SuccessButton from '../../shared/Button/SuccessButton'
import WarningButton from '../../shared/Button//WarningButton'
import TextField from './../../shared/TextField/TextField';
import { Availability } from '../../models/vendor'
import CustomSelectAutoComplete from '../../shared/SelectAutoComplete/SelectAutoComplete'
import TimeSlider from './../../shared/Slider/Slider';

export default function Bookings() {
    const classes = useStyles()
    const { userRole } = useSelector((state: IRootState) => state.userReducer)
    const AllEmployees: Employee[] = useSelector((state: IRootState) => state.filterReducer.employees)
    const AllCustomers: Customer[] = useSelector((state: IRootState) => state.filterReducer.customers)
    const AllBookings: Booking[] = useSelector((state: IRootState) => state.filterReducer.bookings)
    const AllServices: Service[] = useSelector((state: IRootState) => state.filterReducer.services)
    const bookings: Booking[] = useSelector((state: IRootState) => state.bookingReducer.bookings)
    const totalDataCount: number = useSelector((state: IRootState) => state.bookingReducer.total)
    const [bookingStartDate, setbookingStartDate] = useState<Date | null>(new Date(new Date().getFullYear(), 0, 1));
    const [bookingEndDate, setbookingEndDate] = useState<Date | null>(new Date(new Date().getFullYear(), 11, 31));
    const [selectDate, setSelectDate] = React.useState<[Moment, Moment] | null>([moment(new Date(new Date().getFullYear(), 0, 1)), moment(new Date(new Date().getFullYear(), 11, 31))]);
    const [empName, setempName] = useState<string[]>([])
    const [customerName, setcustomerName] = useState<string[]>([])
    const [bookingId, setbookingId] = useState<number[]>([])
    const [category, setcategory] = useState<string[]>([])
    const [empFilter, setempFilter] = useState<string[]>([])
    const [customerFilter, setcustomerFilter] = useState<string[]>([])
    const [categoryFilter, setcategoryFilter] = useState<string[]>([])
    const [bookingFilter, setbookingFilter] = useState<number[]>([])
    const [sort, setSort] = useState<SortObject>({ orderBy: 'id', order: "asc" });
    const [pagination, setPagination] = useState<Pagination>({ pageNo: 1, limit: 25 })
    const [exportData, setexportData] = useState<BookingAll[]>([])

    const [clearFilters, setClearFilters] = useState<boolean>(false)
    const [isloading, setloading] = useState<boolean>(true)
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<null| any>(null)
    const [durationErr,setDurationErr]=useState<string|null>(null)
    const [durationLoading,setDurationLoading]=useState<boolean>(false)
    const [hours, setHours] = useState<string>('');
    const [minutes, setMinutes] = useState<string>('15');


    const fetchData = async () => {
        setloading(true)
        let start: number = 0
        let end: number = 0
        let empFilterData: number[] = []
        let customerFilterData: number[] = []
        let serviceFilterData: number[] = []
        if (bookingStartDate && bookingEndDate) {
            start = time.DateToUnix(bookingStartDate)
            end = time.DateToUnix(bookingEndDate)
        }
        AllEmployees.map((item) => {
            if (empName.includes(item.full_name)) {
                empFilterData.push(item.id)
            }
        })

        AllServices.map((item) => {
            if (category.includes(item.type)) {
                serviceFilterData.push(item.id)
            }
        })

        AllCustomers.map((item) => {
            if (customerName.includes(item.email)) {
                customerFilterData.push(item.id)
            }
        })


        if (selectDate) {
            start = time.DateToUnix(selectDate[0].toDate())
            end = time.DateToUnix(selectDate[1].toDate())
        }

        try {
            await bookingApi.getAll(start, end, pagination, sort, empFilterData, bookingId, customerFilterData)
        } catch (error) {
            setloading(false)
        }
        const res = await bookingApi.getAllWithOutPagination(start, end, sort, empFilterData, bookingId, customerFilterData)
        setexportData(res)
    }

    const setFilter = () => {
        let empFilterData: string[] = []
        let customerFilterData: string[] = []
        let bookingFilterData: number[] = []
        let serviceFilterData: string[] = []

        let empFilterDataTemp: number[] = []
        let serviceFilterDataTemp: number[] = []
        exportData.map((item) => {
            bookingFilterData.push(item.id)
            customerFilterData.push(item.customer.email)
            empFilterDataTemp.push(item.user_id)
            serviceFilterDataTemp.push(item.service_id)
        })

        AllEmployees.map((item) => {
            if (empFilterDataTemp.includes(item.id)) {
                empFilterData.push(item.full_name)
            }
        })

        AllServices.map((item) => {
            if (serviceFilterDataTemp.includes(item.id)) {
                serviceFilterData.push(item.type)
            }
        })

        empFilterData = [...new Set(empFilterData)]
        bookingFilterData = [...new Set(bookingFilterData)]
        serviceFilterData = [...new Set(serviceFilterData)]
        customerFilterData = [...new Set(customerFilterData)]
        empFilterData.sort()
        customerFilterData.sort()
        bookingFilterData.sort((a, b) => a - b)
        serviceFilterData.sort()
        setbookingFilter(bookingFilterData)
        setcustomerFilter(customerFilterData)
        setempFilter(empFilterData)
        setcategoryFilter(serviceFilterData)

        setloading(false)
        setClearFilters(false)
    }

    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }

    const handleExport = () => {
        type Temp = {
            id: number;
            booked_date_time: Date;
            service_id: number;
            user_id: number;
            booking_duration: number;
            booking_value: null | number;
            status: string;
            gift_card_id: number;
            gift_card_value: number;
            customer: Customer;
            user: User;
            service: Service;
            redeem_type: string;
            redeemer: string;
        }
        let formatData: Temp[] = []

        exportData.forEach(item => {
            let temp: Temp = {
                id: item.id,
                booked_date_time: time.unixToDate(item.booked_date_time ? item.booked_date_time : 0),
                service_id: item.service_id,
                user_id: item.user_id,
                booking_duration: item.booking_duration,
                booking_value: item.booking_value,
                status: item.status,
                gift_card_id: item.gift_card_id,
                gift_card_value: item.gift_card_value,
                customer: item.customer,
                user: item.user,
                service: item.service,
                redeem_type: item.redeem_type,
                redeemer: item.redeemer
            }
            formatData.push(temp)
        })
        const fileName = `Booking Data ${time.DateToString(new Date())}.xlsx`
        const ws = XLSX.utils.json_to_sheet(formatData);

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    };

    const handlePagination = (page: number, rowsPerPage: number) => {
        setPagination({ pageNo: page, limit: rowsPerPage })
    };

    const handleClearFilters = () => {
        setbookingId([])
        setcustomerName([])
        setempName([])
        setFilter()
    }

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])

    useEffect(() => {
        setFilter()
    }, [exportData])

    useEffect(() => {
        fetchData()
    }, [
        sort, selectDate, bookingStartDate, bookingEndDate, empName, customerName, bookingId, pagination.limit, pagination.pageNo
    ])

    useEffect(() => {
        fetchData()
    }, [])

     const handleCreateOpen = (item:Booking) => {
        setMinutes(item.booking_duration.toString())
        setCreateOpen(true);
        setSelected(item.id)
    };

    const handleCreateClose = () => {
        setCreateOpen(false);
        setSelected(null)
        setMinutes('');
        setHours('');
    };


    const handleMinutesChange = (e: any,value:any) => {
        setMinutes(value)
    }


    const handleCreate = async () => {
        setDurationLoading(true)
        await bookingApi.changeBookingDuration(selected, parseInt(minutes))
        setDurationLoading(false)
        handleCreateClose()
        setSelected(null)
        fetchData()
    }

    const createDialog = (
        < Dialog
            open={createOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{`Change duration of booking ${selected}`}</DialogTitle>
            <DialogContent>
            <Grid
                    style={{
                        paddingTop:'30px',
                        marginBottom: "20px"
                    }}
                    spacing={4}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                > 
                    <Grid item xs={12}>
                        <TimeSlider minutes={minutes} handleMinutesChange={handleMinutesChange}/>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                            onChange={(e)=>handleDurationChange(e)}
                            value={duration}
                            error={!!durationErr}
                            helperText={durationErr}
                            name="duration"
                            type="number"
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            label="duration"
                            variant="outlined" 
                        />
                    </Grid> */}
                </Grid>
            </DialogContent>

            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus disabled={durationErr !== null || durationLoading ||parseInt(minutes)===0}>
                    Update
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    return (
        <Page
            currentRoute={routeNameObjects.bookings}
            parentRoutes={[routeNameObjects.bookings]}
        >
            <div className={classes.container}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <Grid
                        item
                        xs={10}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        style={{
                            marginBottom: 10,
                        }}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            {/* <DateTimePicker label="Booking Start Date" onChange={setbookingStartDate} value={bookingStartDate} reset={clearFilters} defaultValue={new Date(new Date().getFullYear(), 0, 1)} /> */}
                            <CustomDateRangePicker label='Booking Date' onChange={setSelectDate} value={selectDate} reset={clearFilters} defaultValue={[moment(new Date(new Date().getFullYear(), 0, 1)), moment(new Date(new Date().getFullYear(), 11, 31))]} />
                        </Grid>
                        {/* <Grid item xs={12} md={6} lg={2}>
                            <DateTimePicker label="Booking End Date" onChange={setbookingEndDate} value={bookingEndDate} reset={clearFilters} defaultValue={new Date(new Date().getFullYear(), 11, 31)} />
                        </Grid> */}
                        <Grid item xs={12} md={6} lg={2}>
                            <CustomMultiSelectAutocomplete label="Booking ID" onChange={setbookingId} data={bookingFilter} reset={clearFilters} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <CustomMultiSelectAutocomplete label="Customer Email" onChange={setcustomerName} data={customerFilter} reset={clearFilters} />
                        </Grid>
                        {
                            !isVendor() &&
                            <Grid item xs={12} md={6} lg={2}>
                                <CustomMultiSelectAutocomplete label="Professional Name" onChange={setempName} data={empFilter} reset={clearFilters} />
                            </Grid>
                        }


                    </Grid>
                    <Grid item xs={2}>
                        <Button
                         variant="contained"
                         size="medium"
                            style={{ float: "right", fontSize : "12px",
                            padding: "6px 14px",  minWidth: "150px", fontWeight: "600", backgroundColor:"#C7EBD0" }}
                            onClick={() => {
                            setClearFilters(true);
                            }}
                            startIcon={<ClearAllIcon />}
                        >
                            Clear All
                        </Button>
                    </Grid>
                </Grid>

                <Table
                    data={bookings}
                    exportFunction={handleExport}
                    onClick={handleCreateOpen}
                    paginationFunction={handlePagination}
                    count={totalDataCount}
                    sortFunction={setSort}
                    sort={sort}
                    isloading={isloading}
                    ignore={isVendor() ? [
                        "redeemer",
                        "redeem_type",
                        "user_id",
                        "service_id"
                        ,"sub_service_id",
                        "service_Description",
                        'salon_name',
                        'user_full_name',
                        'user_email',
                        'username',
                        "suite_name",
                        'suite_display_name'
                    ] :
                        [
                            "redeemer",
                            "redeem_type",
                            "user_id",
                            "service_id"
                            , "sub_service_id",
                            "service_Description",
                            'user_full_name',
                            'user_email',
                            'username',
                            "suite_name",
                        ]
                    }
                    overrides={[{ from: "suite_display_name", to: 'Sub-Suite Number' }, { from: "sub_type", to: 'Sub-Service Type' }]}
                    numberColumns={['booking_duration', 'booking_value', "gift_card_value"]}
                />
                            {createDialog}

            </div>
        </Page>
    )
}
