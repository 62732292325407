const FilterActions = {
    UPDATE_EMPLOYEES: 'UPDATE__EMPLOYEES',
    UPDATE_CUSTOMERS: 'UPDATE__CUSTOMERS',
    UPDATE_BOOKINGS: 'UPDATE__BOOKINGS',
    UPDATE_SUITES: 'UPDATE__SUITES',
    UPDATE_SERVICES: 'UPDATE__SERVICES',
    UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
    UPDATE_AVAILABLE_SUITES: 'UPDATE_AVAILABLE_SUITES',
    UPDATE_AVAILABLE_CATEGORIES: 'UPDATE_AVAILABLE_CATEGORIES',
    UPDATE_LOCATIONS : 'UPDATE_LOCATIONS',
}

export default FilterActions;