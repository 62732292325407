import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20,
        },

    }),
);

export default useStyles;