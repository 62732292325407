import { Category } from "../../models/category";
import { CategoryActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface categoryState {
  categories: Category[];
};

export const initialCategoryState = {
  categories: []
};

const categoryReducer = (state: categoryState = initialCategoryState, action: Action) => {
  if (action && action.payload) {
    const { categories } = action.payload;
    switch (action.type) {
      case CategoryActions.UPDATE:
        if (categories) {
          return {
            ...state,
            categories: categories
          };
        }

        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default categoryReducer;
