import { Card, CardActionArea, CardContent, CardMedia, Paper, Typography, Grid, Badge } from '@material-ui/core'
import classnames from 'classnames'
import classNames from 'classnames'
import React from 'react'
import Circle from '../circle/circle'
import UserIcon from '../UserIcon/UserIcon'
import useStyles from './itemCardStyles'


interface IProps {
    active: boolean;
    name: string;
    onClick: Function;
    url: string;
    suite_id: number;
    suite_name: string;
    categories: string[];
}
export default function ItemCard(props: IProps) {
    const classes = useStyles()

    return (
        <div onClick={() => { props.onClick() }} className={classes.divContainer} >
            {/* <Paper className={classnames(classes.container, props.active ? classes.activeBanner : classes.inactiveBanner)} onClick={() => { props.onClick() }}> */}
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                    marginTop: 2
                }}
            >
                <Grid
                    item
                >
                    {/* <UserIcon url={props.url} active={props.active} />
                    <div style={{ marginTop: "-25px", display: "flex", flexFlow: "row-reverse" }}>
                        {props.active ?
                            <Circle size={15} color="green" />
                            :
                            <Circle size={15} color="red" />
                        }

                    </div> */}

                    <div className={classnames(classes.imgContainer, props.active ? classes.activeBannerDiv : classes.inactiveBannerDiv)}>
                        <div style={{ backgroundColor: "black" }}>
                            <img src={props.url} height='150px' width="150px" style={{ opacity: props.active ? 1 : 0.4 }} />
                        </div>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <div className={classes.name}>
                        {props.name}
                    </div>
                    <div className={classes.suit}>
                        {props.suite_name}
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={{
                    marginTop: 2
                }}
            >
                {
                    props.categories.map((color: string) => {
                        return (
                            <Grid
                                item
                            >
                                <Circle size={15} color={color} />
                            </Grid>
                        )
                    })
                }


            </Grid>
            {/* </Paper>   */}
        </div>
    )
}
