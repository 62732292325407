import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import style from './Footer.module.scss'

export default class Footer extends Component {

    render() {
        return (
            <div className={style.footer}>
                <Paper className={style.paper} square={true}>
                    <Grid container direction='row' alignItems="flex-start" justifyContent='center' spacing={1}>
                        <Grid item>
                            <div className={style.text}>
                                Powered by
                            </div>
                        </Grid>
                        <Grid item>
                            <Link to={{ pathname: "https://kalisodigital.com/" }} target="_blank" rel="noopener noreferrer">
                                <div className={style.link}>
                                    Kaliso
                                </div>
                            </Link>
                        </Grid>
                    </Grid>

                </Paper>
            </div>
        )
    }
}
