import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import myColors from "../../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20,
        },
        selectTitle: {
            textTransform: "capitalize",
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '13px',
            whiteSpace: 'nowrap',
        },
        root: {
            color: `#2196f3 !important`,
            '&$checked': {
                color: `#2196f3 !important`,
            },
        },
        paper: {
            padding: 20,
            [theme.breakpoints.up('sm')]: {
                backgroundColor: 'transparent !important',
                boxShadow: 'none'
            }
        },
        appBar: {
            position: 'relative',
        },
        dialogTitle: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);

export default useStyles;