import { makeStyles, Theme, createStyles } from "@material-ui/core";
import myColors from '../../styles/colors'

const TableStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: "60vh",
        },
        actionCell: {
            width: "30px",
        },
        tableData: {
            height: "30px",
            fontSize: '12px',
            cursor: 'pointer',
            // whiteSpace: 'nowrap',
            textOverflow: "ellipsis",
            overflow: "hidden",
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: "rgb(224, 224, 224)",
            whiteSpace: "pre-wrap",
            minWidth : "200px",
            maxWidth : "250px"
        },
        maxDataWidth: {
            maxWidth: 200,
        },
        valueCell: {
            // textAlign: `right`,
        },
        tableHead: {
            backgroundColor: `${myColors.primaryColor} !important`,
            color: myColors.fontColor,
            height: "30px",
            fontSize: '13px',
            fontWeight: "bolder",
            textTransform: "capitalize",
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: "rgb(224, 224, 224)",
        },
        title: {
            fontWeight: "bold",
            textTransform: "capitalize",
        },
        header: {
            marginBottom: 20,
        },
        pagination: {
            flexFlow: "wrap",
            [theme.breakpoints.down('sm')]: {
                justifyContent: "center",
            },
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
            },
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        }, fixColumn: {
            position: 'sticky',
            right: 0,
            backgroundColor: '#fff'
        },
        badgeRoot: {
            height: 15
        }

    })
);

export default TableStyles;
