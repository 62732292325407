import moment from 'moment'
import { Calendar } from 'react-big-calendar'
import './CalenderStyles.sass'

const BigCalender = (props:any) => {
    return (<div className="dashboard-calendar-container">
        <Calendar
            date={props.bookingDate?.toDate()}
            style={{ height: '73vh',fontSize:'12pt' }}
            localizer={props.localizer}
            events={props.availabilityEvents}
            startAccessor="start"
            endAccessor="end"
            popup={true}
            defaultView="week"
            defaultDate={props.bookingDate?props.bookingDate.toDate():new Date()}
            views={['week']}
            view='week'
            step={7.5}
            onSelectEvent={props.handleDateChangeBooking}
            onNavigate={props.handleCalenderNavigate}
            eventPropGetter={(date) => {
                if (moment(date.start).unix() == props.selectedTime){
                    return {
                    className: 'special-day',
                    }
                }else return {}
            }}
        />
    </div>)
}     

export default BigCalender