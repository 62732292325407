const DashboardActions = {
    UPDATE_BOOKING_COUNT_OVER_TIME: 'UPDATE_BOOKING_COUNT_OVER_TIME',
    UPDATE_BOOKING_COUNT_BY_SUITS: 'UPDATE_BOOKING_COUNT_BY_SUITS',
    UPDATE_BOOKING_COUNT_BY_SERVICES: 'UPDATE_BOOKING_COUNT_BY_SERVICES',
    UPDATE_REVENUE_BY_SERVICES: 'UPDATE_REVENUE_BY_SERVICES',
    UPDATE_BOOKING_COUNT: 'UPDATE_BOOKING_COUNT',
    UPDATE_REVENUE_OVER_TIME: 'UPDATE_REVENUE_OVER_TIME',
    UPDATE_SUM_OF_REVENUE: 'UPDATE_SUM_OF_REVENUE',
    UPDATE_PRICE_PER_UNIT: 'UPDATE_PRICE_PER_UNIT',
    UPDATE_BOOKING_COUNT_BY_VENDOR: 'UPDATE_BOOKING_COUNT_BY_VENDOR',
    UPDATE_OCCUPANCY_RATE: 'UPDATE_OCCUPANCY_RATE',
}

export default DashboardActions;