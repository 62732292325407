import { Drawer, Grid, Hidden, List } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useTheme, } from '@material-ui/core/styles';
import useStyles from './NavBarStyles'
import { RouteNames } from '../../../../route_names/route_names';
import { getRouteUser } from '../../../../route_names/route_users';
import NavItem from '../NavItem/NavItem';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../core/store';
import logo from '../../../../img/logo.png'

interface IProps {
    mobileOpen: boolean;
    handleDrawerToggle: Function;
    currentRoute: string;
    window?: () => Window;
}

export default function NavBar(props: IProps) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const container = window !== undefined ? () => window().document.body : undefined;

    const userRole = useSelector((state: IRootState) => state.userReducer.userRole)
    let routeUser = getRouteUser(userRole);

    const handleDrawerToggle = () => {
        props.handleDrawerToggle()
    };

    useEffect(() => {
        routeUser = getRouteUser(userRole);
    }, [userRole])

    const drawer = (
        <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.drawerGrid}
        >
            <Grid item>
                <div className={classes.logo}><img src={logo} style={{ width: "200px" }} /></div>
            </Grid>
            <Grid item container
                justifyContent="flex-start"
                alignItems="center">
                <List className={classes.list}>
                    {routeUser.routes.map((route: RouteNames, index: number) => {
                        if (!!route.isHide) {
                            if (route.isHide && !route.isHide) {
                                return (
                                    <NavItem
                                        key={index}
                                        routeDetails={route}
                                        currentRoute={props.currentRoute}
                                    />
                                )
                            }
                        }
                        else {
                            return (
                                <NavItem
                                    key={index}
                                    routeDetails={route}
                                    currentRoute={props.currentRoute}
                                />
                            )
                        }
                    })}

                </List>
            </Grid>
            {/* <Grid item className={classes.logout}>
                <Button
                    variant="contained"
                    color="primary"
                >
                    Log Out
                </Button>
            </Grid> */}

        </Grid>
    );

    return (
        <div>
            <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={props.mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </div>
    )
}


