import { Booking } from "../../models/booking";
import { BookingActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface bookingState {
  bookings: Booking[];
  total: number;
};

export const initialBookingState = {
  bookings: [],
  total: 0,
};

const bookingReducer = (state: bookingState = initialBookingState, action: Action) => {
  if (action && action.payload) {
    const { bookings, total } = action.payload;
    switch (action.type) {
      case BookingActions.UPDATE:
        if (bookings) {
          return {
            ...state,
            bookings: bookings,
            total: total
          };
        }
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default bookingReducer;
