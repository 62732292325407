import { ChartData } from "../../models/dashboard";
import { DashboardActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface dashboardState {
  bookingOverTime: ChartData;
  bookingBySuits: ChartData;
  bookingByServices: ChartData;
  revByServices: ChartData;
  bookingCount: number;
  revOverTime: ChartData;
  bookingByVendors: ChartData;
  sumOfRev: number;
  pricePerUnit: number;
  occupancyRate: number;
};

export const initialDashboardState = {
  bookingOverTime: { labels: [], data: [] },
  bookingBySuits: { labels: [], data: [] },
  bookingByServices: { labels: [], data: [] },
  revByServices: { labels: [], data: [] },
  bookingCount: 0,
  revOverTime: { labels: [], data: [] },
  bookingByVendors: { labels: [], data: [] },
  sumOfRev: 0,
  pricePerUnit: 0,
  occupancyRate: 0,
};

const dashboardReducer = (state: dashboardState = initialDashboardState, action: Action) => {
  if (action && action.payload) {
    const { bookingOverTime, bookingBySuits, bookingByServices, revByServices, bookingCount, revOverTime, sumOfRev, pricePerUnit, bookingByVendors, occupancyRate } = action.payload;
    switch (action.type) {
      case DashboardActions.UPDATE_BOOKING_COUNT_OVER_TIME:
        if (bookingOverTime) {
          return {
            ...state,
            bookingOverTime: bookingOverTime
          };
        }
        break;
      case DashboardActions.UPDATE_BOOKING_COUNT_BY_SUITS:
        if (bookingBySuits) {
          return {
            ...state,
            bookingBySuits: bookingBySuits
          };
        }
        break;
      case DashboardActions.UPDATE_BOOKING_COUNT_BY_SERVICES:
        if (bookingByServices) {
          return {
            ...state,
            bookingByServices: bookingByServices
          };
        }
        break;
      case DashboardActions.UPDATE_REVENUE_BY_SERVICES:
        if (revByServices) {
          return {
            ...state,
            revByServices: revByServices
          };
        }
        break;
      case DashboardActions.UPDATE_BOOKING_COUNT:
        if (bookingCount) {
          return {
            ...state,
            bookingCount: bookingCount
          };
        }
        break;

      case DashboardActions.UPDATE_REVENUE_OVER_TIME:
        if (revOverTime) {
          return {
            ...state,
            revOverTime: revOverTime
          };
        }
        break;

      case DashboardActions.UPDATE_SUM_OF_REVENUE:
        if (sumOfRev) {
          return {
            ...state,
            sumOfRev: sumOfRev
          };
        }
        break;

      case DashboardActions.UPDATE_PRICE_PER_UNIT:
        if (pricePerUnit) {
          return {
            ...state,
            pricePerUnit: pricePerUnit
          };
        }
        break;
      case DashboardActions.UPDATE_BOOKING_COUNT_BY_VENDOR:
        if (bookingByVendors) {
          return {
            ...state,
            bookingByVendors: bookingByVendors
          };
        }
        break;
      case DashboardActions.UPDATE_OCCUPANCY_RATE:
        if (occupancyRate) {
          return {
            ...state,
            occupancyRate: occupancyRate
          };
        }
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default dashboardReducer;
