import { Suite } from "../../models/suite";
import { SuiteActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface suiteState {
  allSuites: Suite[];
  allMainSuites: Suite[];
  unoccupiedSuites: Suite[]
};

export const initialSuiteState = {
  allSuites: [],
  allMainSuites: [],
  unoccupiedSuites: []
};

const suiteReducer = (state: suiteState = initialSuiteState, action: Action) => {
  if (action && action.payload) {
    const { allSuites, allMainSuites, unoccupiedSuites } = action.payload;
    switch (action.type) {
      case SuiteActions.UPDATE:
        if (allSuites) {
          return {
            ...state,
            allSuites: allSuites
          };
        }
        break;

      case SuiteActions.UPDATE_PRIMARY_SUITES:
        if (allMainSuites) {
          return {
            ...state,
            allMainSuites: allMainSuites
          };
        }
        break;

      case SuiteActions.UPDATE_UNOCCUPIED_SUITES:
        if (unoccupiedSuites) {
          return {
            ...state,
            unoccupiedSuites: unoccupiedSuites
          };
        }
        break;

      default:
        return { ...state };
    }
  }

  return state;
};

export default suiteReducer;
