import httpApi from './http-api';
import {Location } from '../../models/location'
import store from '../store';
import LocationActions from '../store/actions/location';
import filtersApi from './filters-api';

class LocationApi {
    async getLocations(): Promise<Location[]> {
        let url: string = `/location`
        try {
            const { locations } = await httpApi.get(`${url}`);

             if (Array.isArray(locations)) {
                store.dispatch({
                    type: LocationActions.UPDATE,
                    payload: {
                        allLocations: locations
                    }
                });
            }

            return locations
        } catch (error) {
            return []
        }
    }
    async getLocationsForAdmin(): Promise<Location[]> {
        let url: string = `/location/admin`
        try {
            const { locations } = await httpApi.get(`${url}`);

             if (Array.isArray(locations)) {
                store.dispatch({
                    type: LocationActions.UPDATE,
                    payload: {
                        allLocations: locations
                    }
                });
            }

            return locations
        } catch (error) {
            return []
        }
    }

     async create(data: Location) {
        const { name, address, disabled} = data
        try {
            const res = await httpApi.post('/location/add', { name, address, disabled });
            return res
        } catch (error) {

        }

    }

     async update(id: number, data: Location) {
        const { name, address, disabled} = data
        try {
            const res = await httpApi.post(`/location/update/${id}`, { name, address, disabled });
            return res
        } catch (error) {

        }

    }

   
    async toggleVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.post(`/location/status/${id}`, { disabled: visibility });
            filtersApi.getLocations()
        } catch (error) {

        }

    }   

}
export default new LocationApi();