import { Star } from "@material-ui/icons";
import SupportTicket from '../pages/SupportTicket_View/SupportTicket'
const nestedRouteNameObject1 = {
  supportTicket: {
    name: "Support Ticket",
    route: '/support-ticket/:ticketId',
    component: SupportTicket,
    children: [],
    nestedLevel: 1,
    icon: Star,
  },

};

export default nestedRouteNameObject1;
