import { Paper } from '@material-ui/core'
import React from 'react'

interface IProps {
    size: number;
    color: string;
}
export default function Circle(props: IProps) {
    return (
        <Paper style={{
            height: props.size,
            width: props.size,
            backgroundColor: props.color,
            borderRadius: "50%"
        }}
            elevation={0}
        >

        </Paper>
    )
}
