export const StandardHourDataActions = {
    ADD_HOUR_DATA: 'ADD_HOUR_DATA',
    CLEAR_HOUR_DATA: "CLEAR_HOUR_DATA",
    UPDATE_HOUR_DATA: 'UPDATE_HOUR_DATA'
}

export const standardHourTimeElementDataActions = {
    ADD_ELEMENT_DATA: 'ADD_ELEMENT_DATA',
    CLEAR_ELEMENT_DATA: 'CLEAR_ELEMENT_DATA',
    UPDATE_ELEMENT_DATA: 'UPDATE_ELEMENT_DATA'
}

export const standardHourCheckboxDataActions = {
    CLEAR_CHECKBOX_DATA: 'CLEAR_CHECKBOX_DATA',
    UPDATE_CHECKBOX_DATA: 'UPDATE_CHECKBOX_DATA'
}