import { UserRole } from "../models/user";
import { userRouteNames, adminRouteNames, superAdminRouteNames, generalRouteNames } from "./route_names";

const routeUsers = {
  superAdmin: {
    routes: superAdminRouteNames,
    firstRoute: "/dashboard",
  },
  admin: {
    routes: adminRouteNames,
    firstRoute: "/dashboard",
  },
  user: {
    routes: userRouteNames,
    firstRoute: "/dashboard",
  },
  general: {
    routes: generalRouteNames,
    firstRoute: "/login",
  }
};

export const getRouteUser = (userRole: UserRole | undefined) => {
  if (userRole?.name === "sys_admin") {
    return routeUsers.superAdmin;
  }
  else if (userRole?.name === "admin") {
    return routeUsers.admin;
  }
  else if (userRole?.name === "vendor") {
    return routeUsers.user;
  }
  else {
    return routeUsers.general;
  }
};

export const getGeneralRoutes = () => {
  return routeUsers.general
};

export default routeUsers;
