import httpApi from './http-api';
import store from '../store';
import { SortObject } from '../../models/sort';
import { arrayToString } from './utils';
import { BookingActions, LoadingActions } from '../store/actions';
import { Pagination } from '../../models/pagination';
import { Booking, BookingAll } from '../../models/booking';

class BookingApi {

    async getAll(
        startDate: number,
        endDate: number,
        pagination: Pagination,
        sort?: SortObject,
        employee?: number[],
        booking?: number[],
        customer?: number[],
    ): Promise<Booking[]> {

        let url: string = `/Booking/by-pagination?pageNo=${pagination.pageNo}&limit=${pagination.limit}&to=${endDate}&from=${startDate}`
        if (sort && sort.orderBy) {
            url = url + `&sort=${sort.order}&column=${sort.orderBy}`
        }

        if (employee && employee.length > 0) {
            url = url + `&employee=${arrayToString(employee)}`
        }
        if (booking && booking.length > 0) {
            url = url + `&booking=${arrayToString(booking)}`
        }

        if (customer && customer.length > 0) {
            url = url + `&customer=${arrayToString(customer)}`
        }

        try {
            const { bookings, bookingsTotal } = await httpApi.get(`${url}`);

            if (Array.isArray(bookings)) {
                store.dispatch({
                    type: BookingActions.UPDATE,
                    payload: {
                        bookings: bookings,
                        total: bookingsTotal
                    }
                });
            }
            return bookings
        } catch (error) {
            return []
        }


    }

    async getAllWithOutPagination(
        startDate: number,
        endDate: number,
        sort?: SortObject,
        employee?: number[],
        booking?: number[],
        customer?: number[],
    ): Promise<BookingAll[]> {

        let url: string = `/Booking?to=${endDate}&from=${startDate}`
        if (sort && sort.orderBy) {
            url = url + `&sort=${sort.order}&column=${sort.orderBy}`
        }

        if (employee && employee.length > 0) {
            url = url + `&employee=${arrayToString(employee)}`
        }
        if (booking && booking.length > 0) {
            url = url + `&booking=${arrayToString(booking)}`
        }

        if (customer && customer.length > 0) {
            url = url + `&customer=${arrayToString(customer)}`
        }
        try {
            const { Bookings } = await httpApi.get(`${url}`);
            if (Array.isArray(Bookings)) {
                return Bookings
            } else {
                return []
            }

        } catch (error) {
            return []
        }

    }

    async create(
        user_id: number,
        customer_id: number,
        service_id: number,
        sub_service_id: number,
        booked_date_time: number
    ) {
        try {
            const { booking_id } = await httpApi.post(`/booking/vendor/add`, {
                booked_date_time,
                sub_service_id,
                service_id,
                customer_id,
                user_id
            });
        } catch (error) {

        }

    }

    async cancelBooking(id: number) {
        await httpApi.put(`/booking/${id}/vendor/cancel`, null);
    }

    async rescheduleBooking(id: number, time: number) {
        await httpApi.put(`/booking/${id}/vendor/reschedule`, { booked_date_time: time });
    }

    async changeBookingDuration(id: number, duration: number |null) {
        const data = await httpApi.put(`/Booking/duration/update/${id}`, { booking_duration: duration });
        return data;
    }

}


export default new BookingApi();