import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import SuccessButton from '../../../Button/SuccessButton';
import WarningButton from '../../../Button/WarningButton';

interface IProps {
    open: boolean;
    exportFunc: Function;
    handleOpen: Function;
}

export default function ExportDialog(props: IProps) {
    const handleExport = () => {
        props.exportFunc()
        handleClose()
    };

    const handleClose = () => {
        props.handleOpen(false);
    };

    return (
        < Dialog
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle >{"Export Data"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to export following data?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleClose} color="primary">
                    No
                </WarningButton>
                <SuccessButton onClick={handleExport} color="primary" autoFocus>
                    Yes
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )
}
