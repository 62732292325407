import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20,
        },
        urlPaper: {
            fontSize: 12,
            fontWeight: 400,
            textTransform: "lowercase",
            padding: 10
        },
        selectTitle: {
            textTransform: "capitalize",
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '13px',
            whiteSpace: 'nowrap',
        },
        root: {
            color: `#2196f3 !important`,
            '&$checked': {
                color: `#2196f3 !important`,
            },
        },
        formControl: {
            minWidth: 268,

            '& div': {
                '& div': {
                    padding: 12,
                },
                '& svg': {
                    marginRight: 5,
                },

            },
        },
        label: {
            marginLeft: 15,
            marginTop: -7,
        }
    }),
);

export default useStyles;