import React, { useState } from 'react';
import { Typography } from '@material-ui/core'
import './FeatureVideo.sass';
import FileUploadForm from '../../../../shared/FileUploadForm/FileUploadForm';
import vendorApi from '../../../../core/api/vendor-api';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../core/store';


const FeatureVideo = () => {
  ;
  // Component State
  const [isUploading, setIsUploading] = useState(false)
  const [selectedVideoFiles, setSelectedVideoFiles] = useState([])

  // Redux State
  const user = useSelector((state: IRootState) => state.vendorReducer.vendorUser)

  const onChangeHandler = async (files: any) => {
    setSelectedVideoFiles(files);
    uploadSelectedVideo(files);
  }

  const uploadSelectedVideo = async (value: any) => {
    if (!value || value?.length === 0) return;

    try {
      setIsUploading(true);

      await vendorApi.uploadProfileVideo(value[value.length - 1]);

      setSelectedVideoFiles([]);
      setTimeout(() => {
        setIsUploading(false);
      }, 1000);
    } catch (e) {
      setSelectedVideoFiles([]);
      setIsUploading(false);
    }
  }

  const hasProfilevideoUrl = user && user?.length > 0 && user[0].profile_video_url && user[0].profile_video_url !== '';

  return (
    <div className="feature-video-component-container">
      <Typography variant="h6" noWrap className="main-title">
        Feature Video
      </Typography>
      <p>
        Upload a short video to introduce yourself to your customers!
      </p>
      <p>
        {hasProfilevideoUrl ? null : "No feature video uploaded yet!"}
      </p>
      <p>
        <b>Note:</b> Only supports MP4 videos and if you upload again, the current video will be overidden!
      </p>
      <div className="feature-video-container">
        <FileUploadForm
          files={selectedVideoFiles}
          onChange={onChangeHandler}
          isUploading={isUploading}
          type="video/mp4"
        />
        {hasProfilevideoUrl ? (
          <video width="450" height="250" controls>
            <source src={user[0].profile_video_url} type="video/mp4" />
          </video>
        ) : null}
      </div>
    </div>
  )
}

export default FeatureVideo;