import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: "rgba(0, 0, 0, 0.54)",
            backgroundColor: "#fff",
            width: 25,
            height: 25,
            border: `2px solid ${theme.palette.background.paper}`,
            borderRadius: "50%"
        },
        avatarRoot: {
            width: "130px",
            height: "130px",
        },
        avatarRootCrop: {
             '& div': {
                '& img': {
                   minWidth: "200px",
                   minHeight: "200px",
                }
            },
            
        }
    }),
);

export default useStyles;