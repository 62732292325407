import classNames from 'classnames'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import authApi from '../../../../core/api/auth-api'
import Button from '../../../../shared/Button/Button'
import TextField from '../../../../shared/TextField/TextField'
import MyNavigator from '../../../../route_names/navigation'

import style from './AccForm.module.scss'
import { IRootState } from '../../../../core/store'
import { useSelector } from 'react-redux'
import { User } from '../../../../models/user'
import WarningButton from '../../../../shared/Button/WarningButton'
import { InputAdornment } from '@material-ui/core'
interface Iprops {

}

interface Istate {
    userName: string,
    fullName: string,
    email: string;
    mobileNumber: string,
    password: string,
    cPassword: string,
}

interface Ierror {
    userNameErrorText: string,
    fullNameErrorText: string,
    emailErrorText: string,
    mobileNumberErrorText: string,
    passwordErrorText: string,
    cPasswordErrorText: string,
}

const formatMobile = (value: string): string => {
    if (value){
        const onlyNums: string = value.replace(/[^0-9]/g, '');
        let number: string = ''
        if (onlyNums.length < 10) {
            number = onlyNums
        }
        if (onlyNums.length === 10) {
            number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
            );

        }
        return number
    }
    return ""
}

function AccForm(props: Iprops) {
    const Navigator = MyNavigator()
    const user: User = useSelector((state: IRootState) => state.userReducer.user)
    const [state, setState] = useState<Istate>({
        userName: user.username,
        fullName: user.full_name,
        email: user.email,
        mobileNumber: formatMobile(user.mobile_no),
        password: "",
        cPassword: '',
    })
    const [error, setError] = useState<Ierror>({
        userNameErrorText: '',
        fullNameErrorText: '',
        emailErrorText: '',
        mobileNumberErrorText: '',
        passwordErrorText: '',
        cPasswordErrorText: '',
    })


    const handleChange = (event: any) => {
        event.persist();
        const { name, value } = event.target

        if (name === "email") {
            let text: string = value
            text = text.toLowerCase()
            setState(prevState => ({
                ...prevState,
                [name]: text
            }))
        }
        else if (name === "mobileNumber") {
            const onlyNums: string = value?.replace(/[^0-9]/g, '');
            if (onlyNums.length < 11) {
                let number = formatMobile(value)
                setState(prevState => ({
                    ...prevState,
                    [name]: number
                }))
            }
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: value
            }))
        }

        handleError(name, value)

    }


    const handleUpdate = async (event: any) => {
        event.preventDefault();

        if (state.password || state.cPassword) {
            handleError('password', state.password)
            handleError('cPassword', state.cPassword)
        }

        if (state.fullName || state.mobileNumber) {
            handleError('fullName', state.fullName)
            handleError('mobileNumber', state.mobileNumber)
        }

        let { userNameErrorText,
            fullNameErrorText,
            emailErrorText,
            mobileNumberErrorText,
            passwordErrorText,
            cPasswordErrorText } = error

        let data = {
            full_name: state.fullName,
            mobile_no: state.mobileNumber.replace(/[^0-9]/g, ''),
        }

        if (user.full_name !== state.fullName || formatMobile(user.mobile_no) !== state.mobileNumber) {
            if (!userNameErrorText && !fullNameErrorText && !mobileNumberErrorText) {
                try {
                    await authApi.updateUser(data);
                } catch (err:any) {
                    setError({
                        ...error,
                        userNameErrorText: err.message,
                    })
                }
            }
        }

        if (user.email !== state.email) {
            if (!emailErrorText) {
                try {
                    await authApi.updateUserEmail(user.id, { email: state.email });
                } catch (err:any) {
                    setError({
                        ...error,
                        emailErrorText: err.message,
                    })
                }
            }
        }

        if (state.password && !passwordErrorText && !cPasswordErrorText) {
            try {
                await authApi.updatePassword({ password: state.password });
            } catch (err:any) {
                setError({
                    ...error,
                    passwordErrorText: err.message,
                    cPasswordErrorText: err.message
                })
            }
        }
    }

    const handleError = (name: string = "submit", value: string = '') => {
        if (name !== "submit") {
            const ErrName: string = name + "ErrorText"
            let ErrVariable: string = ''

            if (!value) {
                ErrVariable = 'This field cannot be empty'
            }

            if (name === 'cPassword') {
                if (state.password !== value) {
                    ErrVariable = "Password did not match.";
                }
            }

            if (name === "mobileNumber") {
                // let mobileRegex = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
                if (value.length < 10) {
                    ErrVariable = "Mobile number must have 10 digits";
                }
            }

            if (name === 'email') {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

                if (!pattern.test(value)) {
                    ErrVariable = "Please enter valid email address.";
                }
            }

            setError(prevErr => ({
                ...prevErr,
                [ErrName]: ErrVariable
            }))
        }
        else {
            const errors: Ierror = { ...error }
            Object.keys(state).forEach(key => {
                if (`${Reflect.get(state, key)}` === '') {
                    const ErrName: string = key + "ErrorText"
                    Reflect.set(errors, `${ErrName}`, 'This field cannot be empty')
                }
            });
            setError(errors)
        }


    }
    return (
        <>
            <form className={style.container} onSubmit={handleUpdate}>
                <div className={style.title}>
                    <p>
                        <b>
                            Update your basic account data :
                        </b>
                    </p>
                </div>
                <div className={classNames('split-form')}>
                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>

                                <TextField
                                    name="userName"
                                    onChange={handleChange}
                                    error={!!error.userNameErrorText}
                                    helperText={error.userNameErrorText}
                                    value={state.userName}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="User Name"
                                    disabled
                                    variant="outlined" />
                            </div>
                        </div>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="fullName"
                                    onChange={handleChange}
                                    error={!!error.fullNameErrorText}
                                    helperText={error.fullNameErrorText}
                                    value={state.fullName}
                                    className='full-width'
                                    fullWidth
                                    autoComplete="off"
                                    label="Full Name"
                                    variant="outlined" />
                            </div>
                        </div>
                    </div>

                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>

                                <TextField
                                    name="email"
                                    onChange={handleChange}
                                    error={!!error.emailErrorText}
                                    helperText={error.emailErrorText}
                                    value={state.email}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Email address"
                                    variant="outlined" />
                            </div>
                        </div>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="mobileNumber"
                                    onChange={handleChange}
                                    error={!!error.mobileNumberErrorText}
                                    helperText={error.mobileNumberErrorText}
                                    value={state.mobileNumber}
                                    InputProps={{
                                        startAdornment:
                                            < InputAdornment position="start" >
                                                +1
                                            </InputAdornment>
                                    }}
                                    className='full-width'
                                    fullWidth
                                    autoComplete="off"
                                    label="Mobile Number"
                                    variant="outlined" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.title}>
                    Update your password :
                </div>
                <div className={classNames('split-form')}>
                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="password"
                                    onChange={handleChange}
                                    error={!!error.passwordErrorText}
                                    helperText={error.passwordErrorText}
                                    value={state.password}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    type='password' />
                            </div>
                        </div>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="cPassword"
                                    onChange={handleChange}
                                    error={!!error.cPasswordErrorText}
                                    helperText={error.cPasswordErrorText}
                                    value={state.cPassword}
                                    className='full-width'
                                    fullWidth
                                    autoComplete="off"
                                    label="Confirm Password"
                                    variant="outlined"
                                    type='password' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.actions}>
                    <WarningButton
                        onClick={() => { Navigator.pushNamed('/dashboard') }}
                        variant="contained"
                        color="primary"
                    >
                        Return To Home
                    </WarningButton>
                    <Button
                        onClick={handleUpdate}
                        variant="contained"
                        color="primary"
                        type="submit">
                        Update Account
                    </Button>
                </div>
            </form>
        </>
    )
}

export default AccForm
