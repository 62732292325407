import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slider, IconButton } from '@material-ui/core'
import moment, { Moment } from 'moment'
import './EventDetailModalStyles.sass';
import WarningButton from '../../../../../shared/Button/WarningButton';
import Button from '../../../../../shared/Button/Button';
import bookingApi from '../../../../../core/api/booking-api';
import SuccessButton from '../../../../../shared/Button/SuccessButton';
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CustomSelectAutoComplete from '../../../../../shared/SelectAutoComplete/SelectAutoComplete';
import TimeSlider from '../../../../../shared/Slider/Slider';

import { Close } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { momentLocalizer } from 'react-big-calendar'
import { nanoid } from 'nanoid'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../../../core/store'
import vendorApi from '../../../../../core/api/vendor-api'
import BigCalender from '../../components/DialogCalender/Calender'


const EventDetailModal = ({ event, onClose, onReload }: any) => {

  const [cancelOpen, setCancelOpen] = useState<boolean>(false)
  const [rescheduleOpen, setRescheduleOpen] = useState<boolean>(false)
  const [bookingId, setBookingId] = useState<number | null>(null)
  const [updateDurationDialogOpen, setupdateDurationDialogOpen] = useState<boolean>(false);
  const [hours, setHours] = useState<string>('');
  const [minutes, setMinutes] = useState<string>('15');
  const [durationLoading,setDurationLoading]=useState<boolean>(false)
  const [durationTime,setDurationTime]=useState<number>(event.booking.booking_duration);

  const { userRole, user } = useSelector((state: IRootState) => state.userReducer)
  const [bookingDate, setBookingDate] = useState<Moment>(moment(new Date()))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false)
  const [selectedTime, setSelectedTime] = useState<number>(0)
  const [isRescheduleCalenderOpen, setIsRescheduleCalenderOpen] = useState<boolean>(false)
  const [selectedWeek, setSelectedWeek] = useState<number>(moment(new Date()).week())
  const [availabilityEvents, setAvailabilityEvents] = useState<any>([])

  const localizer = momentLocalizer(moment)

  const formatMobile = (value: string = ''): string => {
    if(!value){
      return ''
    }
    const onlyNums: string = value.replace(/[^0-9]/g, '');
    let number: string = ''
    if (onlyNums.length < 10) {
      number = onlyNums
    }
    if (onlyNums.length === 10) {
      number = onlyNums.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '($1) $2-$3'
      );
      number = "+1 " + number

    }
    return number
  }

  const handleCancelBooking = async () => {
    if (bookingId) {
      await bookingApi.cancelBooking(bookingId)
      onClose()
      onReload()
    }

  };

  const handleCancelClose = () => {
    setCancelOpen(false)
    setBookingId(null)
  }

  const cancelDialog = (
    < Dialog
      open={cancelOpen}
      onClose={handleCancelClose}
    >
      <DialogTitle >{"Cancel Booking"}</DialogTitle>
      <DialogContent>

        <DialogContentText>
          Are you sure you want to cancel this booking?
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <WarningButton onClick={handleCancelClose} color="primary">
          No
        </WarningButton>
        <SuccessButton onClick={handleCancelBooking} color="primary" autoFocus>
          Yes
        </SuccessButton>
      </DialogActions>
    </Dialog >
  )

  const handleRescheduleBooking = async () => {
    if (bookingId && bookingDate) {
      await bookingApi.rescheduleBooking(bookingId, bookingDate.unix())
      onClose()
      onReload()
    }

  };

  const updateDurationDialogCreateClose = () => {
    setupdateDurationDialogOpen(false);
    setMinutes('');
    setHours('');
  };

  const handleHoursChange = (value: string) => {
      setHours(value)
  }

  const handleMinutesChange = (e: any,value:any) => {
      setMinutes(value)
  }

  const handleCreateNewDuration = async () => {
    setDurationLoading(true)
    const data = await bookingApi.changeBookingDuration(event.booking.id, parseInt(minutes));
    console.log("CLOSING")
    setDurationLoading(false);
    setDurationTime(parseInt(minutes));
    updateDurationDialogCreateClose();
    onClose();
    onReload();
  }

  // Reschedule Big Calender Logic
  const handleRescheduleOpen = () => {
    fetchAllAvailabilityForWeek(bookingDate, event.booking.service.sub_service_id).then((events) => {
      setAvailabilityEvents(events)
    })

    setIsRescheduleCalenderOpen(true)
  }

  const handleRescheduleClose = () => {
    setIsRescheduleCalenderOpen(false)
  }

  const handleDatePickerClose = () => {
    setIsOpenDatePicker(false)
    //handleRescheduleOpen()
  }

  const handleDatePickerOpen = () => {
    //handleRescheduleClose()
    setIsOpenDatePicker(true)
  }

  const handleDateChange = (value: Moment | null = moment()) => {
    if (!value) value = moment(new Date())

    setBookingDate(value)

    if(selectedWeek != value.week()){
      setSelectedWeek(value.isoWeek())
      setBookingDate(value)

      fetchAllAvailabilityForWeek(value, event.booking.service.sub_service_id).then((events) => {
        setAvailabilityEvents(events)
      })
    }

    handleDatePickerClose()
  }

  const handleCalenderEventSelect = (event:any) => {
    setSelectedTime(moment(event.start).unix())
  }

  const handleCalenderNavigate = (e: any) => {
    let date = moment(e)
    fetchAllAvailabilityForWeek(date, event.booking.service.sub_service_id).then((events) => {
      setAvailabilityEvents(events)
    })
    setBookingDate(date)
  }

  const fetchReschedule = async() => {
    let results = await bookingApi.rescheduleBooking(event.booking.id, selectedTime)
    handleRescheduleClose()
    handleDatePickerClose()
    onReload()
    onClose()
  }

  const fetchAllAvailabilityForWeek = (date:any, subServiceId?:any) => {
    return new Promise(async(resolve, reject)=>{
      setIsLoading(true)
      let weekNum = date.isoWeek()

      let slotList:any = await vendorApi.availabilitySlotsForWeek(
        weekNum,
        user.id,
        subServiceId
      )

      const events = slotList?.map((slot: any) => ({
        id: nanoid(),
        title: moment.unix(slot.start).format('hh:mm a'),
        start: moment.unix(slot.start)?.toDate(),
        end: moment.unix(slot.end)?.toDate()
      }))

      setIsLoading(false)
      resolve(events)
    })
  }
  // End
    
  const updateDurationDialog = (
    < Dialog
        open={updateDurationDialogOpen}
        onClose={updateDurationDialogCreateClose}
    >
        <DialogTitle >{`Change duration of booking ${event.booking.id}`}</DialogTitle>
        <DialogContent>
        <Grid
                style={{
                  paddingTop:'30px',
                  marginBottom: "20px"
              }}
              spacing={4}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
            > 
                <Grid item xs={6}>
                </Grid>                      

                <Grid item xs={12}>
                <TimeSlider minutes={minutes} handleMinutesChange={handleMinutesChange}/>
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <WarningButton onClick={updateDurationDialogCreateClose} color="primary">
                Cancel
            </WarningButton>
            <SuccessButton onClick={handleCreateNewDuration} color="primary" autoFocus disabled={durationLoading ||parseInt(minutes)===0}>
                Update
            </SuccessButton>
        </DialogActions>
    </Dialog >
)

const dateChangePicker = (
  <Dialog scroll="body" open={isOpenDatePicker} onClose={handleDatePickerClose} maxWidth='sm'>
    <DialogTitle
      disableTypography
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <h2>Create New Booking</h2>
      <IconButton onClick={handleDatePickerClose}>
        <Close />
      </IconButton>
    </DialogTitle>

    <DialogContent style={{ minWidth: 300 }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
            autoOk={true}
            openTo="date"
            variant="static"
            value={bookingDate?.toDate()}
            onChange={handleDateChange}
            disablePast={true}
            format="MM/DD/yyyy"
          />
      </MuiPickersUtilsProvider>
    </DialogContent>
  </Dialog>
)

const rescheduleCalender = (
  <Dialog scroll="body" open={isRescheduleCalenderOpen} onClose={handleRescheduleClose}>
    <DialogTitle
      disableTypography
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <h2>Reschedule Your Booking</h2>
      <IconButton onClick={handleRescheduleClose}>
        <Close />
      </IconButton>
    </DialogTitle>

    <DialogContent style={{ minWidth: 600 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 99 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Button color='primary' onClick={handleDatePickerOpen} style={{maxWidth: "10px" , maxHeight: "20px"}}> <span>Pick Date</span></Button>
      </div>
      <BigCalender 
        bookingDate = {bookingDate}
        localizer =  {localizer}
        availabilityEvents = {availabilityEvents}
        handleDateChangeBooking = {handleCalenderEventSelect}
        handleCalenderNavigate = {handleCalenderNavigate}
        selectedTime = {selectedTime}
      />
    </DialogContent>
    <DialogActions>
      <SuccessButton onClick={fetchReschedule} style={{ backgroundColor: '#C7EBD0'}}>
        Reschedule
      </SuccessButton>
    </DialogActions>
  </Dialog>
)

return (
  <Dialog
    open={!!event}
    onClose={onClose}
  >
    <DialogContent>
      <div className="calendar-event-detail-modal-container">
        <p className="event-detail-title">Booking ID : {event.booking.id}</p>
        <div className="calendar-event-detail-modal-content-container">
          <div className="event-detail-left-section">
            <h3>Status</h3>
            <p className="event-detail-status">{event.booking.status || 'ACCEPTED'}</p>
            <h3>Date</h3>
            <p>{moment.unix(event.booking.booked_date_time).format("Do MMM YYYY")}</p>
            <h3>Start Time</h3>
            <p>{moment.unix(event.booking.booked_date_time).format("hh:mm A")}</p>
            <h3>Duration</h3>
            <p>{`${durationTime
              ? Math.floor(durationTime / 60) +
              " hour " +
              (durationTime % 60) +
              " minutes"
              : "0 Hour"
              }`}
              <Button
                style={{  textDecoration: "none" }}
                onClick={() => {      
                  setMinutes(durationTime.toString());                                   
                  setupdateDurationDialogOpen(true);
                }}
                color="primary"
              > Update Duration
              </Button>
            </p>
            {event.booking.status === 'PLACED' &&
              <Button
                onClick={() => {
                  handleRescheduleOpen()
                }}
                color="primary"
              >Reschedule
              </Button>
            }
          </div>

          <div className="event-detail-right-section">
            <h3>Service Name</h3>
            <p>{`${event.booking?.service_type}-${event.booking?.service?.sub_type}`}</p>

            <h3>Customer Name</h3>
            <p>{event.booking?.customer?.first_name || event.booking?.customer?.last_name ? `${event.booking?.customer?.first_name || ''} ${event.booking?.customer?.last_name || ''}` : '-'}</p>
            <h3>Customer Email</h3>
            <p><a href={`mailto:${event.booking?.customer?.email}`}>{event.booking?.customer?.email}</a></p>
            <h3>Customer Mobile Number</h3>
            <p>{formatMobile(event.booking?.customer?.mobile_no)}</p>
            {event.booking.status === 'PLACED' &&
              <WarningButton
                onClick={() => {
                  setBookingId(event.booking.id)
                  setCancelOpen(true)
                }}
                color="primary"
              >Cancel
              </WarningButton>
            }
          </div>
        </div>
      </div>
    </DialogContent>
    {cancelDialog}
    {rescheduleCalender}
    {dateChangePicker}
    {updateDurationDialog}
  </Dialog>
  )
}

export default EventDetailModal;