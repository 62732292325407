import { Tab, Tabs, useTheme, Paper, Hidden } from "@material-ui/core";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import useStyles from "./TabStyles";

export default function CustomTab(props: {
  tabsData: { title: string; component: any; icon: any;
  }[];
  onSetSelectedTab? : any ;
  orientation?: 'horizontal' | 'vertical';
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
    if(props.onSetSelectedTab){
      props.onSetSelectedTab(newValue)
    }
  };

  return (
    <div>
      <Paper square style={{ marginBottom: 10 }}>

        <Hidden smDown>
          <Tabs
            orientation={props.orientation ? props.orientation : 'horizontal'}
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="off"
          >
            {props.tabsData.map((t, index) => (
              <Tab key={index} className={classes.tabStyle} label={t.title} icon={t.icon} />
            ))}
          </Tabs>

        </Hidden>
        <Hidden smUp>
          <Tabs
            orientation={props.orientation ? props.orientation : 'horizontal'}
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="off"
          >
            {props.tabsData.map((t, index) => (
              <Tab key={index} className={classes.tabStyle} icon={t.icon} />
            ))}
          </Tabs>

        </Hidden>
      </Paper>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={selectedTab}
      >
        {props.tabsData.map((t, index) => (
          <div
            key={index}
            hidden={selectedTab !== index}
            className={classes.listPadding}
          >
            {t.component}
          </div>
        ))}
      </SwipeableViews>
    </div>
  );
}
