import { getFCMToken, supportedNotifications } from '../../firebase';
import { SuiteForProf } from '../../models/employee';
import { AdminUser, UserRole } from '../../models/user';
import store from '../store';
import { SupportActions, UserActions } from '../store/actions';
import filtersApi from './filters-api';
import httpApi from './http-api';
import vendorApi from './vendor-api';

class AuthApi {
    constructor() {
        const token = localStorage.getItem('authToken') || '';
        const role = localStorage.getItem('userRole') || '';
        httpApi.setAuthToken(token);
        if (token) {
            filtersApi.getAll();
            if (role) {
                let userRole: UserRole = JSON.parse(role);
                if (userRole.name === 'vendor') {
                    vendorApi.getSelectedServices({ orderBy: 'id', order: 'asc' });
                    vendorApi.getUser();
                }
            }
        }
    }

    async init() {
        let token: string = '';
        const { userRole, user } = await this.isValidToken();
        if (user) {
            token = user.accessToken;

            httpApi.setAuthToken(token);
            store.dispatch({
                type: UserActions.LOGIN,
                payload: {
                    user: user,
                    userRole: userRole,
                },
            });
        }
    }

    async login(username: string, password: string) {
        let token: string = '';
        let role: string = '';
        try {
            try {
                const { userRole, user } = await httpApi.post(
                    '/user/admin/login',
                    {
                        username,
                        password,
                    },
                    false,
                );
                // Check if notifications are supported by browser first
                console.log(supportedNotifications);
                if (supportedNotifications) {
                    const notificationToken = await getFCMToken();
                    httpApi.setAuthToken(user.accessToken);
                    // Update fcm token to get notifications from firebase
                    await httpApi.put(`/user/update-fcm/${user.id}`, {
                        fcm_token: notificationToken,
                    });
                }

                if (user) {
                    token = user.accessToken;
                }
                role = userRole.name;

                httpApi.setAuthToken(token);

                localStorage.setItem('authToken', token);
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('userRole', JSON.stringify(userRole));

                store.dispatch({
                    type: UserActions.LOGIN,
                    payload: {
                        user: user,
                        userRole: userRole,
                    },
                });
            } catch (error) {
                return;
            }

            filtersApi.getAll();
            if (role === 'vendor') {
                vendorApi.getSelectedServices({ orderBy: 'id', order: 'asc' });
                vendorApi.getUser();
            }
        } catch (error: any) {
            return error;
        }
    }

    async logout() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('userRole');
        store.dispatch({
            type: UserActions.LOGOUT,
            payload: {},
        });
        store.dispatch({
            type: SupportActions.CLEAR,
            payload: {
                supportTickets: [],
            },
        });
        window.location.href = '/login';
    }

    //initialize user and send registration mail
    async createUser(data: Object) {
        try {
            const res = await httpApi.post('/user/admin/new-user', data);
            filtersApi.getAvailableSuites();

            if(res.code === "DUPLICATE_RECORD"){
                alert("Duplicate record")
                return [];
            }
            return res;
        } catch (error) {}
    }

    async createAdmin(data: AdminUser) {
        try {
            const res = await httpApi.post('/user/admin/register', { email: data.email, location_array:data.location_array });
            return res;
        } catch (error) {}
    }

    async updateAdmin(id : number ,data: AdminUser) {
        try {
            const res = await httpApi.put(`/user/admin/update/${id}`, data);
            return res;
        } catch (error) {}
    }

    async updatePasswordForUser(id : number ,password: string) {
        try {
            const res = await httpApi.put(`/user/${id}/update-password`, {password : password});
            return res;
        } catch (error) {}
    }

    async createSuperAdmin(data: string) {
        try {
            const res = await httpApi.post('/user/sys-admin/register', { email: data });
            return res;
        } catch (error) {}
    }

    //confirm user creation
    async confirmAdmin(token: string, data: Object) {
        try {
            const res = await httpApi.post(`/user/admin/confirm-account-creation-admin/${token}`, data);
            return res;
        } catch (error) {}
    }

    async confirmUser(token: string, data: Object) {
        try {
            const res = await httpApi.post(`/user/confirm-account-creation/${token}`, data);
            return res;
        } catch (error) {}
    }
    async validateResetToken(token: string) {
        try {
            const res = await httpApi.get(`/user/reset-token/${token}/validate`);
            return res;
        } catch (error) {
            return error;
        }
    }

    async updateUserEmail(id: number, data: Object) {
        try {
            await httpApi.put(`/user/update-email/${id}`, data);
            try {
                const { userRole, user } = await httpApi.get('/user/me');
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('userRole', JSON.stringify(userRole));

                store.dispatch({
                    type: UserActions.LOGIN,
                    payload: {
                        user: user,
                        userRole: userRole,
                    },
                });

                vendorApi.getUser();
            } catch (error) {
                return error;
            }
        } catch (error) {
            return error;
        }
    }

    async updateUser(data: Object) {
        try {
            await httpApi.put(`/user/edit-profile`, data);
            try {
                const { userRole, user } = await httpApi.get('/user/me');
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('userRole', JSON.stringify(userRole));

                store.dispatch({
                    type: UserActions.LOGIN,
                    payload: {
                        user: user,
                        userRole: userRole,
                    },
                });

                vendorApi.getUser();
            } catch (error) {
                return error;
            }
        } catch (error) {
            return error;
        }
    }

    async updatePassword(data: Object) {
        try {
            await httpApi.put(`/user/update-password`, data);
            try {
                const { userRole, user } = await httpApi.get('/user/me');
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('userRole', JSON.stringify(userRole));

                store.dispatch({
                    type: UserActions.LOGIN,
                    payload: {
                        user: user,
                        userRole: userRole,
                    },
                });

                vendorApi.getUser();
            } catch (error) {
                return error;
            }
        } catch (error) {
            return error;
        }
    }

    async isValidToken(): Promise<any> {
        try {
            const { userRole, user } = await httpApi.get('/user/me');
            if (!!user) {
                return { userRole, user };
            }
        } catch (error) {
            return {};
        }
    }

    async sendPasswordResetEmail(email: string) {
        try {
            await httpApi.put(`/user/forgot-password`, { email: email });
        } catch (error) {}
    }

    async resetForgotPassword(token: string, password: string) {
        try {
            await httpApi.post(`/user/confirm-new-password/${token}`, { password: password });
        } catch (error) {}
    }


     async updateProfWithNewSuite(id: number, data : SuiteForProf ) {
        try {
            await httpApi.post(`/user/professsional/suite/update/${id}`,data);
        } catch (error) {
        }

    }
}

export default new AuthApi();
