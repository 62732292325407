import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: "100%",
            width: "100%"
        },
        title: {
            fontWeight: "bold",
            textTransform: "capitalize",
            minHeight: 30,
            display: 'contents'
        },
        serviceCard: {
            padding: 10,
            boxShadow: 'none',
            border: "solid",
            borderWidth: "1px",
            borderColor: "lightgray",
            borderRadius: "5px",
        },
    }),
);

export default useStyles;