import * as React from 'react';
import { DatePicker, Radio, Space } from 'antd';
import type { RangeValue } from '../../../node_modules/rc-picker/lib/interface';
import './DateRangePickerStyle.scss';
import { useEffect } from 'react';
import moment, { Moment } from 'moment';
import { DateType } from '@date-io/type';

const { RangePicker } = DatePicker;

interface Props {
    value: [Moment, Moment] | null;
    onChange: Function;
    label: string;
    reset?: boolean;
    defaultValue?: [Moment, Moment] | null;
    disableFuture?: boolean;
    disablePast?: boolean;
    minDate?: Date;
    maxDate?: Date;
}

export default function CustomDateRangePicker(props: Props) {
    const [value, setValue] = React.useState<RangeValue<Moment> | null>();

    const dateTimeOnChange = (
        date: RangeValue<Moment> | null,
        dateString: [string, string]
    ): void => {
        setValue(date)
    };

    useEffect(() => {
        setValue(props.value)
    }, []);

    useEffect(() => {
        if (value) {
            props.onChange([moment(value[0]).startOf("day"), moment(value[1]).endOf("day")])
        }
    }, [value]);

    useEffect(() => {
        if (props.reset) {
            if (props.defaultValue) {
                setValue(props.defaultValue)
            }
            else {

            }

        }
    }, [props.reset]);

    return (
        <div className="dateRange-container">
            <div className="label">
                {props.label}
            </div>
            <RangePicker
                size='small'
                format='MM/DD/yyyy'
                suffixIcon={null}
                allowClear={false}
                value={value}
                onChange={(dates: RangeValue<Moment> | null, dateStrings: [string, string]) => { dateTimeOnChange(dates, dateStrings) }}
            />
        </div>
    );
}