import React, { useEffect, useState } from 'react'
import routeNameObjects from '../../route_names/route_name_objects'
import Page from '../../shared/Page/Page'
import useStyles from './EditProfileStyles'
import Table from '../../shared/Table/CustomTable'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core'
import Services from './components/Services/Services'
import SocialMedia from './components/SocialMedia/SocialMedia'
import Gallery from './components/Gallery/Gallery'
import Basic from './components/Basic/Basic'
import reviewApi from '../../core/api/review-api'
import { Review } from '../../models/review'
import { IRootState } from '../../core/store'
import { useSelector } from 'react-redux'
import WarningButton from '../../shared/Button/WarningButton'
import SuccessButton from '../../shared/Button/SuccessButton'
import FeatureVideo from './components/FeatureVideo/FeatureVideo'

import ContactsIcon from '@material-ui/icons/Contacts';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import CustomTab from '../../shared/Tab/Tab'
import MyLoader from '../../shared/Loader/my_loader'
import ExternalBooking from './components/ExternalBooking/ExternalBooking'

export default function EditProfile() {
    const classes = useStyles()
    const reviews: Review[] = useSelector((state: IRootState) => state.reviewReducer.reviews)
    const [toggleItem, setToggleItem] = useState<Review | null>(null)
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);

    const [isloading, setloading] = useState<boolean>(true)

    const fetchData = async () => {
        setloading(true)
        try {
            await reviewApi.getAll()
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }

    const handleToggleOpen = (item: Review) => {
        setToggleItem(item)
        setToggleOpen(true);
    };

    const handleToggleClose = () => {
        setToggleOpen(false);
    };

    const handleVisibilityUpdate = async () => {
                setToggleOpen(false);
        if (toggleItem) {
                setloading(true);
                await reviewApi.toggleVisibilityById(toggleItem.id, toggleItem.show_in_profile === 1 ? 0 : 1).then(() => {
                setloading(false)
            });
        }
    };

    const toggleDialog = (
        < Dialog
            open={toggleOpen}
            onClose={handleToggleClose}
        >
            <DialogTitle >{"Update Review Visibility"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {toggleItem && toggleItem.show_in_profile === 1 ? "deactivate" : "activate"} {toggleItem && toggleItem.id} Review?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleToggleClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleVisibilityUpdate} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    useEffect(() => {
        fetchData()
    }, []);
    return (
        <Page
            currentRoute={routeNameObjects.editProfile}
            parentRoutes={[routeNameObjects.editProfile]}
        >
            <div className={classes.container}>
                <CustomTab
                    tabsData={[
                        {
                            title: "Basic Information",
                            component: <div style={{ height: "100%", width: "100%" }}>
                                <Basic />
                                {
                                    isloading
                                        ?
                                        <MyLoader height='400px' />
                                        :
                                        <>
                                        <SocialMedia />

                                        <div style={{margin:"16px 0"}}>
                                        <ExternalBooking/>
                                        </div>
                                        </>
                                }
                            </div>,
                            icon: < ContactsIcon />
                        },
                        {
                            title: "Services",
                            component: <Services />,
                            icon: <RoomServiceIcon />
                        },
                        {
                            title: "Gallery",
                            component: <div>
                                <Gallery />
                                <FeatureVideo />
                            </div>,
                            icon: <PhotoLibraryIcon />
                        },
                        {
                            title: "Reviews",
                            component:
                                <Table
                                    data={reviews}
                                    isloading={isloading}
                                    title="Customer Reviews"
                                    toggle={
                                        {
                                            toggleFunction: handleToggleOpen,
                                            toggleColumn: "show_in_profile"
                                        }
                                    }
                                    ignore={["show_in_profile", "vendor_email", "id"]}
                                    frozenColumn="toggle"
                                />,
                            icon: <RateReviewIcon />
                        }
                    ]}
                />
                {/* <Grid
                    spacing={2}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <Basic />
                    </Grid>
                    <Grid item xs={12}>
                        <Services />
                    </Grid>
                    <Grid item xs={12}>
                        <SocialMedia />
                    </Grid>
                    <Grid item xs={12}>
                        <Gallery />
                    </Grid>
                    <Grid item xs={12}>
                        <FeatureVideo />
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            data={reviews}
                            title="Customer Reviews"
                            toggle={
                                {
                                    toggleFunction: handleToggleOpen,
                                    toggleColumn: "show_in_profile"
                                }
                            }
                            ignore={["show_in_profile", "vendor_email"]}
                        />
                    </Grid>
                </Grid> */}
            </div>
            {toggleDialog}
        </Page>
    )
}
