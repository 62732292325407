import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../../../styles/colors";


const drawerWidth = 240;


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            height: "100%",
        },
        drawerGrid: {
            height: "100%",
            display : "flex",
            flexWrap: "nowrap"
        },
        logo: {
            color: myColors.secondaryColor,
            fontSize: "40px",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        admin: {
            fontSize: "25px",
            fontWeight: "bold",
            textAlign: "center",
            paddingBottom: "20px",
        },
        list: {
            width: "100%",
        },
        logout: {
            marginTop: "auto",
            marginBottom: "20px",
            width: "100%",
        },
    }),
);

export default useStyles;