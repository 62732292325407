import httpApi from './http-api';
import store from '../store';
import { Employee } from '../../models/employee';
import { FilterActions, LoadingActions, SupportActions } from '../store/actions';
import { SortObject } from '../../models/sort';
import { Booking } from '../../models/booking';
import { Customer } from '../../models/customer';
import { Suite } from '../../models/suite';
import { Service } from '../../models/service';
import { SupportStatus } from '../../models/support';
import { Category } from '../../models/category';
import { Location } from '../../models/location';
import  authApi  from './auth-api';

class FilterApi {

    getAll() {
        this.getAllEmployees()
        this.getAllBookings()
        this.getAllCustomers()
        this.getAllSuites()
        this.getAvailableSuites()
        this.getAllServices()
        this.getAllCategories()
        this.getAllCategoriesActive()
        this.getLocations()

    }

    async getAllEmployees(): Promise<Employee[]> {
        try {
            const { user } = await httpApi.get('/user/admin/getallVendors');
            if (Array.isArray(user)) {
                store.dispatch({
                    type: FilterActions.UPDATE_EMPLOYEES,
                    payload: {
                        employees: user,
                    }
                });
            }
            return user
        } catch (error) {
            return []
        }

    }

    async getAllCustomers(): Promise<Customer[]> {
        try {
            const { customers } = await httpApi.get('/customer/by-user');
            if (Array.isArray(customers)) {
                store.dispatch({
                    type: FilterActions.UPDATE_CUSTOMERS,
                    payload: {
                        customers: customers
                    }
                });
            }
            return customers
        } catch (error) {
            return []
        }

    }

    async getAllBookings(): Promise<Booking[]> {
        try {
            const { Bookings } = await httpApi.get('/booking/all');
            if (Array.isArray(Bookings)) {
                store.dispatch({
                    type: FilterActions.UPDATE_BOOKINGS,
                    payload: {
                        bookings: Bookings
                    }
                });
            }

            return Bookings
        } catch (error) {
            return []
        }

    }

    async getAllSuites(): Promise<Suite[]> {
        try {
            const { Suites } = await httpApi.get('/suites/getsuite?sort=asc&column=id');
            if (Array.isArray(Suites)) {
                store.dispatch({
                    type: FilterActions.UPDATE_SUITES,
                    payload: {
                        suites: Suites
                    }
                });
            }

            return Suites
        } catch (error) {
            return []
        }

    }

    async getAvailableSuites(): Promise<Suite[]> {
        try {
            const { Suites } = await httpApi.get('/suites/get-unoccupied');
            if (Array.isArray(Suites)) {
                store.dispatch({
                    type: FilterActions.UPDATE_AVAILABLE_SUITES,
                    payload: {
                        availableSuites: Suites
                    }
                });
            }

            return Suites
        } catch (error) {
            return []
        }

    }

    async getAllServices(): Promise<Service[]> {
        try {
            const { Services } = await httpApi.get(`/Services/enabledservice`);
            store.dispatch({
                type: FilterActions.UPDATE_SERVICES,
                payload: {
                    services: Services
                }
            });

            return Services
        } catch (error) {
            return []
        }

    }

    async getUniqueUser(){
        try {  
            
            const res = await httpApi.get('/user/me');
            return res
        } catch (error) {
            return []
        }

    }


    async getAllCategories(): Promise<Category[]> {
        try {  
            
            const res = await httpApi.get('/user/me');

            const id = res.user.id
            const { Category } = await httpApi.get(`/category?user_id=${id}`);
            store.dispatch({
                type: FilterActions.UPDATE_CATEGORIES,
                payload: {
                    activeCategories: Category
                }
            });

            return Category
        } catch (error) {
            return []
        }

    }

    async getAllCategoriesActive(): Promise<Category[]> {
        try {  
            const { Category } = await httpApi.get(`/category/getallActive`);
            
            store.dispatch({
                type: FilterActions.UPDATE_AVAILABLE_CATEGORIES,
                payload: {
                    availableCategories: Category
                }
            });

            return Category
        } catch (error) {
            return []
        }

    }

    async getLocations(): Promise<Location[]> {
        let url: string = `/location`
        try {
            const { locations } = await httpApi.get(`${url}`);
            store.dispatch({
                type: FilterActions.UPDATE_LOCATIONS,
                payload: {
                    availableCategories: locations
                }
            });
            return locations
        } catch (error) {
            return []
        }
    }

}

export default new FilterApi();