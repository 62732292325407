import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    FormHelperText,
    ListItemText,
    Checkbox,
    TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useRef, useState } from 'react';
import useStyles from './SelectStyle';

interface Props {
    onChange?: Function;
    label: string;
    data: string[] | number[];
    disabled?: boolean;
    required?: boolean;
    setInitial?: string;
    reset?: boolean;
}

export default function CustomSelectAutoComplete(props: Props) {
    const classes = useStyles();
    const [value, setValue] = useState<string>('');
    const [data, setData] = useState<any[]>([]);
    const [type, setType] = useState<string>('');
    const clearRef = useRef<HTMLElement>();

    const handleChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        setValue(value as string);
    };

    useEffect(() => {
        if (props.reset) {
            if (clearRef && clearRef.current) {
                let element: HTMLElement = clearRef.current.getElementsByClassName(
                    'MuiAutocomplete-clearIndicator',
                )[0] as HTMLElement;
                element.click();
            }
        }
    }, [props.reset]);

    useEffect(() => {
        if (props.onChange) {
            if (type === 'number') {
                props.onChange(parseInt(value));
            } else {
                props.onChange(value);
            }
        }
    }, [value]);

    useEffect(() => {
        let newData: string[] = [];
        if (props.data && props.data.length > 0) {
            if (typeof props.data[0] === 'number') {
                setType('number');
            } else {
                setType('string');
            }
            props.data.map((item: string | number) => {
                if (item) {
                    newData.push(item.toString());
                }
            });
        }
        setData(newData);

        if (props.required && newData.length > 0) {
            setValue(newData[0]);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.setInitial) {
            setValue(props.setInitial);
        }
    }, [props.setInitial]);

    return (
        <Autocomplete
            ref={clearRef}
            style={{
                width: '100%',
            }}
            size="small"
            options={data}
            value={value}
            disableClearable={props.setInitial || props.required ? true : false}
            disabled={props?.disabled}
            onChange={handleChange}
            getOptionLabel={(option) => {
                console.log(`option ==>`, JSON.stringify(option))
                return (option?.type ? option.type : option)
            }}
            autoSelect
            autoComplete
            autoHighlight
            renderInput={(params) => <TextField {...params} variant="outlined" label={props.label} />}
        />
    );
}
