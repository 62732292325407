import { Support, SupportChatStatus, SupportStatus } from "../../models/support";
import { SupportActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface supportState {
  supportTickets: Support[];
  chatStatus: SupportChatStatus[];
  statusTicket: SupportStatus[];
  navStatus: Boolean;
};

export const initialSupportState = {
  supportTickets: [],
  chatStatus: [],
  statusTicket: [],
  navStatus: false
};

const supportReducer = (state: supportState = initialSupportState, action: Action) => {
  if (action && action.payload) {
    const { supportTickets, chatStatus, statusTicket, navStatus } = action.payload;
    switch (action.type) {
      case SupportActions.UPDATE:
        if (supportTickets) {
          return {
            ...state,
            supportTickets: supportTickets
          };
        }
        break;

      case SupportActions.CLEAR:
        if (supportTickets) {
          return {
            ...state,
            supportTickets: []
          };
        }
        break;

      case SupportActions.CHAT_STATUS:
        if (chatStatus) {
          return {
            ...state,
            chatStatus: chatStatus
          };
        }
        break;

      case SupportActions.T_STATUS:
        if (statusTicket) {
          return {
            ...state,
            statusTicket: statusTicket
          };
        }
        break;

      case SupportActions.UPDATE_NAV:
        if (navStatus) {
          return {
            ...state,
            navStatus: navStatus
          };
        }
        break;

      default:
        return { ...state };
    }
  }

  return state;
};

export default supportReducer;
