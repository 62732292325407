import { CircularProgress, Paper } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../core/store'
import useStyles from './StatsViewStyles'

interface IProps {
    loading: boolean;
}
export default function PricePerUnit(props: IProps) {
    const classes = useStyles()
    const pricePerUnit: number = useSelector((state: IRootState) => state.dashboardReducer.pricePerUnit)
    return (
        <Paper className={classes.dataCard}>
            <div className={classes.dataCardTitle}>
                Price Per Unit
            </div>
            <div className={classes.dataCardValue}>
                {
                    props.loading ?
                        <CircularProgress />
                        :
                        <>
                            ${pricePerUnit.toFixed(1).toString()}
                        </>
                }
            </div>
        </Paper>
    )
}
