import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import style from './SignUp.module.scss';

import Footer from '../../shared/Footer/Footer';
import EmpForm from './components/EmpForm/EmpForm';
import { RouteComponentProps } from 'react-router-dom';
import AdminForm from './components/AdminForm/AdminForm';
import AccForm from './components/AccountForm/AccForm';

import MyNavigator from '../../route_names/navigation';
import authApi from '../../core/api/auth-api';
import { CircularProgress } from '@material-ui/core';

function SignUp(props: RouteComponentProps) {
    const navigate = MyNavigator();
    const [token, settoken] = useState<string>('');
    const [showSpinner, setShowSpinner] = useState(true);
    const urlType = () => {
        if (props.match.path === '/signup/vendor/:token') {
            return 'vendor';
        } else if (props.match.path === '/signup/admin/:token') {
            return 'admin';
        } else {
            return 'account';
        }
    };

    const handleTokenValidation = useCallback(async () => {
        try {
            const response = await authApi.validateResetToken(token);
            console.log(response);
            if (response && Array.isArray(response)) {
                navigate.pushNamed('/login');
            }
        } catch (err: any) {
            console.log(err);
        }
        setShowSpinner(false);
    }, [token, navigate]);

    useEffect(() => {
        const token = Reflect.get(props.match.params, 'token');
        settoken(token);
    }, []);

    useEffect(() => {
        if (token) {
            handleTokenValidation().then();
        }else{
            setShowSpinner(false)
        }
    }, [token, handleTokenValidation]);

    if (showSpinner) {
        return (
            <div className={style.container}>
                <div className={style.signUpCard}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center !important',
                            boxShadow: 'none !important',
                            background: 'transparent !important',
                        }}
                    >
                        <CircularProgress />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div className={style.container}>
            <div className={style.signUpCard}>
                <Paper className={style.signUpPaper}>
                    <div className={style.title}>
                        {urlType() === 'vendor'
                            ? 'User Sign Up'
                            : urlType() === 'admin'
                            ? 'Admin Sign Up'
                            : 'Update Account'}
                    </div>
                    <div className={style.subtitle}>{urlType() !== 'account' && "It's quick and easy."}</div>
                    <Divider variant="fullWidth" />
                    {urlType() === 'vendor' && <EmpForm token={token} />}
                    {urlType() === 'admin' && <AdminForm token={token} />}
                    {urlType() === 'account' && <AccForm />}
                </Paper>
            </div>
            <Footer />
        </div>
    );
}

export default SignUp;
