import { RentMaster, RentHistory } from "../../models/rent";
import { RentActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface rentState {
  rentMasterData: RentMaster[];
  rentMasterCount: number;
  rentHistoryData: RentHistory[];
  rentHistoryCount: number;
};

export const initialRentState = {
  rentMasterData: [],
  rentMasterCount: 0,
  rentHistoryData: [],
  rentHistoryCount: 0
};

const rentReducer = (state: rentState = initialRentState, action: Action) => {
  if (action && action.payload) {
    const { rentMasterData, rentHistoryData, rentHistoryCount, rentMasterCount } = action.payload;
    switch (action.type) {
      case RentActions.UPDATE_MASTER:
        if (rentMasterData) {
          return {
            ...state,
            rentMasterData: rentMasterData,
            rentMasterCount: rentMasterCount
          };
        }
        break;

      case RentActions.UPDATE_HISTORY:
        if (rentHistoryData) {
          return {
            ...state,
            rentHistoryData: rentHistoryData,
            rentHistoryCount: rentHistoryCount
          };
        }
        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default rentReducer;
