import { CircularProgress, Paper } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../core/store'
import useStyles from './StatsViewStyles'

interface IProps {
    loading: boolean;
}
export default function NumberOfBookings(props: IProps) {
    const classes = useStyles()
    const bookingCount: number = useSelector((state: IRootState) => state.dashboardReducer.bookingCount)
    return (
        <Paper className={classes.dataCard}>
            <div className={classes.dataCardTitle}>
                Number Of Bookings
            </div>
            <div className={classes.dataCardValue}>
                {
                    props.loading ?
                        <CircularProgress />
                        :
                        <>
                            {bookingCount.toString()}
                        </>
                }
            </div>
        </Paper>
    )
}
