import {  Slider } from '@material-ui/core'

interface Props {
  minutes:any,
  handleMinutesChange:Function,
}

const TimeSlider = (props:Props) => {

  const {minutes,handleMinutesChange}=props;

    const generateLabel = (value:any)=>{
        const hours = Math.floor(value/60);
        const mins = value%60;
        var text = ''
        if(hours>0){
            text = text+hours.toString()+"h "
        }
        if(mins>0){
            text=text+mins.toString()+"m"
        }
        if(hours ===0 && mins===0){
            text=text+'0'
        }
        return text
    }
  return (
    <Slider
                            aria-label="Always visible"
                            defaultValue={parseInt(minutes, 10)}
                            onChange={(e,n)=>handleMinutesChange(e,n)}
                            step={15}
                            marks={[
                                {
                                  value: 0,
                                  label: '0',
                                },
                                {
                                    value: 60,
                                    label: '1h',
                                  },
                                  {
                                    value: 120,
                                    label: '2h',
                                  },
                                  {
                                    value: 180,
                                    label: '3h',
                                  },
                                  {
                                    value: 240,
                                    label: '4h',
                                  },
                                  {
                                    value: 300,
                                    label: '5h',
                                  },
                              ]}
                            valueLabelDisplay="on"
                            min={0}
                            max={300}
                            valueLabelFormat={value=>generateLabel(value)}
                        />
  )
}

export default TimeSlider