import React, { useState } from 'react'
import { Paper } from '@material-ui/core';
import style from './Login.module.scss'


import Footer from '../../shared/Footer/Footer';
import LoginForm from './components/LoginForm/LoginForm'
import UserIcon from './components/UserIcon/UserIcon'
import logo from '../../img/logo.png'
import ForgotForm from './components/ForgotForm/ForgotForm';


export default function Login() {
    const [forgotPassword, setforgotPassword] = useState<boolean>(false)
    return (
        <div className={style.container}>
            <div className={style.section}>
                <Paper elevation={5} className={style.formPaper}>
                    {/* <UserIcon /> */}
                    <div className={style.loginCard}>
                        <div className={style.logo}><img src={logo} /></div>
                        <div className={style.formContainer}>
                            {
                                forgotPassword ?
                                    <>
                                        <div className={style.title}>Forgot Password</div>
                                        <ForgotForm />
                                        <div className={style.forgotten} onClick={() => { setforgotPassword(false) }}>
                                            Back to login
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={style.title}>Log In</div>
                                        <LoginForm />
                                        <div className={style.forgotten} onClick={() => { setforgotPassword(true) }}>
                                            Forgot Password?
                                        </div>
                                    </>
                            }

                            {/* <Divider variant="fullWidth" /> */}
                        </div>
                    </div >
                </Paper>
            </div>
            <Footer />
        </div >
    )
}
