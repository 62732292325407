import React from 'react'
import './style.sass'
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { CircularProgress } from '@material-ui/core';

const { Dragger } = Upload;

const FileUploadForm = ({ files, onChange, type = "image", isUploading = false }: any) => {

  const getAcceptType = () => {
    switch (type) {
      case 'image':
        return 'image/*';
      case 'pdf':
        return 'application/pdf';
      case 'video':
        return 'video/mp4,video/x-m4v,video/*"';
      default:
        return type;
    }
  }

  return (
    <div className="file-upload-form-component">
      <Dragger
        name="file"
        multiple={false}
        accept={getAcceptType()}
        action={(file: RcFile): any => {
          const selectedFiles = Array.isArray(files) ? [...files, file] : [file]
          onChange(selectedFiles)
        }}
        onRemove={(file: UploadFile<any>): any => {
          if (Array.isArray(files)) {
            const filteredFiles = files.filter(f => f.uid !== file.uid)
            const selectedFiles = filteredFiles.length > 0 ? filteredFiles : null
            onChange(selectedFiles)
          }
        }}
        fileList={files ? files.map((file: any) => {
          file.status = 'done'
          return file
        }) : null}
        disabled={isUploading}
      >
        {isUploading ? (
          <div>
            <CircularProgress />
            <p className="ant-upload-text">Please wait...this may take a while!</p>
          </div>
        ) : (
          <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </div>
        )}
      </Dragger>
    </div>
  );
}

export default FileUploadForm