import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Avatar, Badge, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../../../../shared/Button/Button'
import useStyles from './BasicStyles'
import CreateIcon from '@material-ui/icons/Create';
import TextField from '../../../../shared/TextField/TextField'
import vendorApi from '../../../../core/api/vendor-api'
import { VendorUser } from '../../../../models/vendor'
import { useSelector } from 'react-redux'
import { IRootState } from '../../../../core/store'
import ProfilePicture from './components/ProfilePicture/ProfilePicture'
import './BasicStyles.sass'
import MyLoader from '../../../../shared/Loader/my_loader';

export default function Basic() {
    const useStyle = useStyles()
    const [salonName, setSalonName] = useState<string>('')
    const user: VendorUser[] = useSelector((state: IRootState) => state.vendorReducer.vendorUser)
    const [salonDescription, setSalonDescription] = useState<string>('')
    const [qualification, setQualification] = useState<string>('')
    const [editNameOpen, seteditNameOpen] = useState<boolean>(false);
    const [editDescriptionOpen, seteditDescriptionOpen] = useState<boolean>(false);
    const [editQualificationOpen, seteditQualificationOpen] = useState<boolean>(false);

    const [isloading, setloading] = useState<boolean>(true)

    const fetchData = async () => {
        setloading(true)
        await vendorApi.getUser()
        setloading(false)
    }

    const handleNameChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setSalonName(value)
    }

    const handleDescriptionChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setSalonDescription(value)
    }

    const handleQualificationChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setQualification(value)
    }

    const handleNameEdit = async () => {
        await vendorApi.updateSalonName(salonName)
        handleNameEditClose()
    };

    const handleDescriptionEdit = async () => {
        await vendorApi.updateDescription(salonDescription, qualification)
        handleDescriptionEditClose()
    };

    const handleQualificationEdit = async () => {
        await vendorApi.updateDescription(salonDescription, qualification)
        handleQualificationEditClose()
    };

    const handleNameEditOpen = () => {
        seteditNameOpen(true);
    };

    const handleNameEditClose = () => {
        seteditNameOpen(false);
    }

    const handleDescriptionEditOpen = () => {
        seteditDescriptionOpen(true);
    };

    const handleDescriptionEditClose = () => {
        seteditDescriptionOpen(false);
    }

    const handleQualificationEditOpen = () => {
        seteditQualificationOpen(true);
    };

    const handleQualificationEditClose = () => {
        seteditQualificationOpen(false);
    }

    useEffect(() => {
        if (user.length > 0) {
            setSalonName(user[0].salon_name)
            setSalonDescription(user[0].bio_details ? user[0].bio_details : '')
        }
    }, [user]);

    useEffect(() => {
        fetchData()
    }, []);
    return (
        <div className="edit-profile-basic-section-container">
            <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >

                <Grid
                    item
                    xs={12}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} md={2} lg={2}>
                        <ProfilePicture user={user} />
                    </Grid>
                    {
                        isloading
                            ?
                            <Grid
                                item xs={12} md={10} lg={10}
                            >
                                <MyLoader height='125px' />
                            </Grid>
                            :
                            <Grid
                                item xs={12} md={10} lg={10}
                                spacing={2}
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {editNameOpen ?
                                    <Grid item style={{ width: "100%" }}>
                                        <TextField
                                            onChange={handleNameChange}
                                            onBlur={handleNameEdit}
                                            value={salonName}
                                            name="url"
                                            className='full-width'
                                            autoComplete="off"
                                            fullWidth
                                            label="Salon Name"
                                            variant="outlined" />
                                    </Grid>
                                    :

                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            {
                                                salonName
                                                    ?
                                                    <Typography variant="h4" className={useStyle.title} >
                                                        {salonName}
                                                    </Typography>
                                                    :
                                                    "Add Salon Name"

                                            }

                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={handleNameEditOpen}>
                                                <CreateIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    editDescriptionOpen ?
                                        <>
                                            <Grid item style={{ width: "100%" }}>
                                                <TextField
                                                    onChange={handleDescriptionChange}
                                                    value={salonDescription}
                                                    onBlur={handleDescriptionEdit}
                                                    name="url"
                                                    className='full-width'
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="Salon Description"
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                />
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >
                                                <Grid item style={{ whiteSpace: "pre-wrap" }}>
                                                    {salonDescription ? salonDescription : "Add Salon Description"}
                                                </Grid>
                                                <Grid item xs={2} style={{ marginTop: "-20px" }}>
                                                    <IconButton onClick={handleDescriptionEditOpen}>
                                                        <CreateIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                }
                                {
                                    editQualificationOpen ?
                                        <>
                                            <Grid item style={{ width: "100%" }}>
                                                <TextField
                                                    onChange={handleQualificationChange}
                                                    value={qualification}
                                                    onBlur={handleQualificationEdit}
                                                    name="url"
                                                    className='full-width'
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="Qualifications"
                                                    variant="outlined"
                                                    multiline
                                                    rows={5}
                                                />
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="flex-start"
                                            >
                                                <Grid item style={{ whiteSpace: "pre-wrap" }}>
                                                    {qualification ? qualification : 'Add Qualifications'}
                                                </Grid>
                                                <Grid item xs={2} style={{ marginTop: "-20px" }}>
                                                    <IconButton onClick={handleQualificationEditOpen}>
                                                        <CreateIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                }
                            </Grid>
                    }
                </Grid>
                <Grid item
                    xs={12}>
                    <Divider variant='fullWidth' />
                </Grid>

            </Grid>
        </div>
    )
}
