import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { IRootState } from "../core/store";
import { RouteNames } from "./route_names";
import { getRouteUser, getGeneralRoutes } from "./route_users";

import LoginPage from '../pages/Login/Login'
import SignUpPage from '../pages/SignUp/SignUp'
import ResetPasswordPage from '../pages/ResetPassword/ResetPassword'
import CustomAlert from "../shared/Alert/Alert";
import TvScreentPage from '../pages/tvScreen/tvSceen'

export default function Routings() {
  const userRole = useSelector((state: IRootState) => state.userReducer.userRole)

  let routeUser = getRouteUser(userRole);

  let routeList = GetRouteListByRouteUser(routeUser)
  const generalRoutes = getGeneralRoutes();
  return (
    <Router>
      {routeUser.firstRoute === generalRoutes.firstRoute ?
        <Switch>
          <Fragment>
            <Route exact path='/'>
              <Redirect to='/login' />
            </Route>
            <Route exact path='/login' component={LoginPage} />
            <Route exact path="/signup/vendor/:token" component={SignUpPage} />
            <Route exact path="/signup/admin/:token" component={SignUpPage} />
            <Route exact path="/forgot-password/:token" component={ResetPasswordPage} />
            <Route exact path="/tv-screen" component={TvScreentPage} />
          </Fragment>
        </Switch>
        :
        <Switch>
          <Fragment>
            {routeUser.routes.map((route, index) => (
              <MyRoute route={route} key={index} />
            ))}
            {
              routeList.includes(window.location.pathname) ?
                <Route render={() => <Redirect to={window.location.pathname} />} />
                :
                <Route render={() => <Redirect to={routeUser.firstRoute} />} />
            }
            <Route exact path="/tv-screen" component={TvScreentPage} />
          </Fragment>
        </Switch>
      }
      {/* <Switch>
        <Fragment>
          <Route exact path="/tvScrren" component={TvScreentPage} />
        </Fragment>
      </Switch> */}
      {/* <Switch>
        <Fragment>
          {routeUser.routes.map((route, index) => (
            <MyRoute route={route} key={index} />
          ))}
          {
            routeList.includes(window.location.pathname) ?
              <Route render={() => <Redirect to={window.location.pathname} />} />
              :
              <Route render={() => <Redirect to={routeUser.firstRoute} />} />
          }

        </Fragment>
      </Switch> */}
      <CustomAlert />
    </Router>
  )
}

interface MyRouteProps {
  route: RouteNames;
}

function MyRoute(props: MyRouteProps) {
  return (
    <div>
      {props.route.component ? (
        <Route
          exact
          path={props.route.route}
          component={props.route.component}
        />
      ) : (
        <div />
      )}
      {props.route.children.map((nested, index) => (
        <MyRoute key={index} route={nested} />
      ))}
    </div>
  );
}

function GetRouteList(route: RouteNames): string[] {
  let routeList: string[] = [route.route]
  if (route.children.length !== 0) {
    route.children.map(item => {
      let newList: string[] = GetRouteList(item)
      routeList = [...routeList, ...newList]
    });
  }
  return routeList
}

function GetRouteListByRouteUser(routeUser: {
  routes: RouteNames[];
  firstRoute: string;
}
): string[] {
  let routeList: string[] = []
  routeUser.routes.map((route) => {
    let newList: string[] = GetRouteList(route)
    routeList = [...routeList, ...newList]
  })

  return routeList
}