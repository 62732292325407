import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import myColors from "../../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '20px 2px',
            overflowY: 'clip'
        },
        chartTitle: {
            fontSize: '18px',
            textTransform: "capitalize",
            fontWeight: 'bold',
            textAlign: 'left',
            padding: '10px'

        },
        filterTitle: {
            fontSize: '14px',
            textTransform: "capitalize",
            fontWeight: 'bold',
            textAlign: 'left',

        },
        chartCard: {
            [theme.breakpoints.up('sm')]: {
                // height: 520,
            }

        },
        top: {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: 15,
            },
        },
        dataCard: {
            padding: 10,
            boxShadow: 'none',
            border: "solid",
            borderWidth: "1px",
            borderColor: "lightgray",
            borderRadius: "5px",
        },
        dataCardTitle: {
            fontSize: '12px',
            textTransform: "capitalize",
            fontWeight: 'bold',
            textAlign: 'left',
            marginBottom: 5,
            marginLeft: 5,
            color: myColors.subtitleFontColor

        },
        dataCardValue: {
            height: 35,
            fontSize: '25px',
            textTransform: "capitalize",
            fontWeight: 'bold',
            textAlign: 'left',
            marginLeft: 5,
        },
        root: {
            color: blue[400],
            '&$checked': {
                color: blue[600],
            },
        },
    }),
);

export default useStyles;