import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import style from './ResetPassword.module.scss'

import Footer from '../../shared/Footer/Footer';
import { RouteComponentProps } from 'react-router-dom';
import ResetForm from './components/AccountForm/ResetForm';

function ResetPassword(props: RouteComponentProps) {
    const [token, settoken] = useState<string>('')
    const urlType = () => {
        if (props.match.path === "/forgot-password/:token") {
            return 'reset'
        }
    }
    useEffect(() => {
        const token = Reflect.get(props.match.params, "token")
        settoken(token)
    }, [])
    return (
        <div className={style.container}>
            <div className={style.signUpCard}>
                <Paper className={style.signUpPaper}>
                    <div className={style.title}>
                        Reset Your Password
                    </div>
                    {/* <div className={style.subtitle}>
                        It's quick and easy
                    </div> */}
                    <Divider variant="fullWidth" />
                    {urlType() === 'reset' && <ResetForm token={token} />}
                </Paper>
            </div>
            <Footer />
        </div>
    )
}

export default ResetPassword
