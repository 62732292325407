import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Avatar, Badge, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import vendorApi from '../../../../../../core/api/vendor-api';
import CreateIcon from '@material-ui/icons/Create';
import userImage from '../../../../../../img/user.png'
import useStyles from './ProfilePictureStyles'
import './ProfilePictureStyles.sass'
import FileUploadForm from '../../../../../../shared/FileUploadForm/FileUploadForm'
import Button from '../../../../../../shared/Button/Button';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import WarningButton from '../../../../../../shared/Button/WarningButton';
import SuccessButton from '../../../../../../shared/Button/SuccessButton';
import Compressor from 'compressorjs';

const ProfilePicture = ({ user }: any) => {
  const style = useStyles()

  const [profilePictureUrl, setProfilePictureUrl] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [cropOpen, setCropOpen] = useState<boolean>(false);
  const [upImg, setUpImg] = useState<string>('');
  const [crop, setCrop] = useState<Crop>(
    {
      unit: 'px',
      x: 75,
      y: 75,
      aspect: 0.5 / 1,
      width: 280,
      height: 440
    }
  )
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [selectedImageFiles, setSelectedImageFiles] = useState<File[]>([]);

  useEffect(() => {
    if (user && user.length > 0) {
      setProfilePictureUrl(user[0]?.profile_image_url || '');
    }
  }, [user]);


  const onChangeHandler = async (files: any) => {
    setSelectedImageFiles(files);

    const reader = new FileReader();
    reader.addEventListener('load', () => setUpImg(reader.result as string));
    reader.readAsDataURL(files[files.length - 1]);

    setCropOpen(true)
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const compressImage = (image:any) => {
    return new Promise((resolve, reject) => {
      new Compressor(image, {
        quality: 0.6,

        success(result:any) {
          return resolve(result)
        },
        error(err:any) {
          return resolve(true)
        },

      })
    })
  }

  const uploadSelectedImage = async (value: any) => {
    if (!value || value?.length === 0) return;

    try {
      setIsUploading(true);

      compressImage(value[0]).then(async (result:any)=>{
        console.log(result)
        await vendorApi.uploadProfilePicture(result, selectedImageFiles[selectedImageFiles.length-1].name);
      })

      setSelectedImageFiles([]);
      setTimeout(() => {
        setIsEditMode(false);
        setIsUploading(false);
      }, 2000);
    } catch (e) {
      setIsUploading(false);
    }
  }

  const handleCropSubmit = () => {
    if (completedCrop && imgRef && imgRef.current) {
      const image = imgRef.current;
      var canvas = document.createElement('canvas')
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = 'high';

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );
        }
        canvas.toBlob(
          (blob) => {
            uploadSelectedImage([blob])
          },
          'image/png',
          1
        );

      }
    }

    setCropOpen(false)
    setCrop({ unit: '%', width: 30, aspect: 1 / 1, height: 50, x: 25, y: 25 })
    setCompletedCrop(null)
  }

  const handleCropClose = () => {
    setCropOpen(false)
    setCompletedCrop(null)
  }

  const cropDialog = (
    < Dialog
      open={cropOpen}
      onClose={handleCropClose}
    >
      {/* <DialogTitle >{"Update User Status"}</DialogTitle> */}
      <DialogContent>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
      </DialogContent>
      <DialogActions style={{ display: 'flex', alignSelf: 'center' }}>
        <WarningButton onClick={handleCropClose} color="primary">
          Cancel
        </WarningButton>
        <SuccessButton onClick={handleCropSubmit} color="primary" autoFocus disabled={!completedCrop?.width || !completedCrop?.height}>
          Submit
        </SuccessButton>
      </DialogActions>
    </Dialog >
  )

  if (isEditMode) {
    return (
      <div className="profile-picture-component-file-upload-container">
        <FileUploadForm
          files={selectedImageFiles}
          onChange={onChangeHandler}
          isUploading={isUploading}
        />
        {isUploading || (
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setIsEditMode(false)}
          >
            Cancel
          </Button>
        )}
        {cropDialog}
      </div>
    );
  }

  return (
    <div className="profile-picture-component-container">
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <IconButton onClick={() => setIsEditMode(true)}>
            <CreateIcon className={style.root} />
          </IconButton>
        }
      >
        <Avatar classes={{ root: style.avatarRoot }} alt="Profile Picture" src={profilePictureUrl !== '' ? profilePictureUrl : userImage} />
      </Badge>
    </div>
  )
}

export default ProfilePicture