import { Button, Grid, Hidden, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ArrowDropDown, Person } from "@material-ui/icons";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from "clsx";
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import authApi from '../../core/api/auth-api';
import filterApi from '../../core/api/filters-api';
import supportApi from '../../core/api/support-api';
import store, { IRootState } from '../../core/store';
import { SupportActions } from '../../core/store/actions';
import time from '../../core/services/time'
import { onBackgroundFCMMessageListener, onForegroundFCMMessageListener } from '../../firebase';
import { Support, SupportChatStatus, SupportStatus } from '../../models/support';
import { User, UserRole } from '../../models/user';
import MyNavigator from '../../route_names/navigation';
import { RouteNames } from '../../route_names/route_names';
import Availability from './component/Availability/Availability';
import NavBar from './component/NavBar/NavBar';
import useStyles from './PageStyle';
import moment, { Moment } from 'moment'

const TwilioChat = require("twilio-chat");

interface IProps {
    children: any;
    currentRoute: RouteNames;
    parentRoutes: RouteNames[];
}

export default function Page(props: IProps) {
    const classes = useStyles();
    const Navigator = MyNavigator()
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const user: User = useSelector((state: IRootState) => state.userReducer.user)
    const userRole: UserRole = useSelector((state: IRootState) => state.userReducer.userRole)
    const supportTicketData: Support[] = useSelector((state: IRootState) => state.supportReducer.supportTickets)

    useEffect(() => {
        init()
    }, [])

    const getId = async () => {
        const id = await filterApi.getUniqueUser()
        return id;
    }

    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }

    const fetchSupportTickets = async () => {
        console.log("FETCHING SUPPORT TICKETS")
        let startDate = time.getLastYear()
        let endDate = time.getToday()
        let ticketDate = [moment(time.getLastYear()), moment(time.getToday())]

        let start: number = 0
        let end: number = 0

        if (startDate && endDate) {
            start = time.DateToUnix(startDate)
            end = time.DateToUnix(endDate)
        }

        if (ticketDate) {
            start = time.DateToUnix(ticketDate[0].toDate())
            end = time.DateToUnix(ticketDate[1].toDate())
        }

        if (isVendor()) {
            if (user) {
                try {
                    const res = await supportApi.getByUserId(start, end)
                    return res
                } catch (error) {
                    console.log(error)
                    return []
                }
            }
        }
        else if(userRole.name === "admin"){
            if (start > 0) {
                try {
                    const res = await supportApi.getAllForAdmin(start, end)
                    return res
                } catch (error) {
                    return []
                }
            }
        }
        else {
            if (start > 0) {
                try {
                    const res = await supportApi.getAll(start, end)
                    return res
                } catch (error) {
                    return []
                }
            }
        }
    }

    const getSupportTickets = async(supportTickets:any) => {
        let statusTicket:any = []
        let ticketStatus = true

        console.log(supportTickets)
        supportTickets?.forEach((ticket:any) => {
            statusTicket.push({uniqueId:ticket['chat_room'], status: ticket['ticket_status'], userId: user.id, loggedInUser: user.id, role: userRole.id, is_seen: ticket['is_seen'], ticketId: ticket['id']})
        })

        for (var i = 0; i < statusTicket.length; i++) {
            let supportTicketData:any = statusTicket[i] 
            // console.log(supportTicketData['ticket_status'], supportTicketData.role)
            if (supportTicketData.status == "NEW" && supportTicketData.role == 1 && supportTicketData['is_seen'] == 0){
                ticketStatus = false
                break
            }

            if (supportTicketData.status == "ACCEPTED" && supportTicketData.role == 3 && supportTicketData['is_seen'] == 0){
                ticketStatus = false
                break
            }

            if (supportTicketData.status == "CANCELLED" && supportTicketData.role == 1 && supportTicketData['is_seen'] == 0){
                ticketStatus = false
                break
            }

            if (supportTicketData.status == "RESOLVED" && supportTicketData.role == 3 && supportTicketData['is_seen'] == 0){
                ticketStatus = false
                break
            }
        }

        console.log(statusTicket)

        // if (statusTicket.length == 0){
        //     ticketStatus = true
        // }

        store.dispatch({
            type: SupportActions.UPDATE_NAV,
            payload: {
                navStatus: ticketStatus,
            }
        })

        store.dispatch({
            type: SupportActions.T_STATUS,
            payload: {
                statusTicket: statusTicket,
            }
        })
    }
    
    const init = async () => {
        let supportTickets = await fetchSupportTickets()
        console.log("SUPPORT TICKET FETCHED")
        await getSupportTickets(supportTickets)

        // console.log("ININT")
        // const token = await getToken();
        // const client = await TwilioChat.Client.create(token);
        // onForegroundFCMMessageListener().then((message:any) => {
        //     console.log("FCM MESSAGE FOREGROUNDe",message)
        // })
        // onBackgroundFCMMessageListener().then((message:any) => {
        //     console.log("FCM MESSAGE BACKGROUND",message)
        // })
        // // this has a property called channelState for the channel. In that, another property called attribute should be there with status of the channel
        // // And the user id of the user that created ticket the should be available in the attribute
        // // The application saves the user role and the user id in the state use that and check if the user is the owner of the channel.
        // // If the user is the owner then the application should show the red dot.
        // // For the admin users you dont need to check the channel owner.
        // // sys_admin and admin are the admin user roles
        // client.getPublicChannelDescriptors().then(async (paginator: any) => {
        //     let chatStatus: SupportChatStatus[] = []
        //     let statusTicket: SupportStatus[] = []

        //     for (let i = 0; i < paginator?.state?.items?.length; i++) {
        //         const channelData = paginator?.state?.items[i];
        //         const status = channelData.attributes.status;
        //         const id = await getId();
        //         const userId = id.user.id;
        //         const roleId = id.userRole.id;
        //         const uId = channelData.attributes.user_id;
        //         const uniqueName = channelData.uniqueName;
        //         const messagesCount = channelData.messagesCount;
        //         const lastReadIndex = channelData.lastConsumedMessageIndex

        //         // const channel = await channelData.getChannel()
        //         // channel.getUnconsumedMessagesCount().then((count:any)=>{
        //         //     chatStatus.push({uniqueName: uniqueName, unreadCount: count && count == 0 ? 0 : 1})
        //         //     //console.log("UNREAD MESSAGE COUNT", count)   
        //         //     store.dispatch({
        //         //         type: SupportActions.CHAT_STATUS,
        //         //         payload: {
        //         //             chatStatus: chatStatus,
        //         //         }
        //         //     });
        //         // })

        //         //statusTicket.push({status: status, userId: uId, loggedInUser: userId, role: roleId})
        //     }
        //     // statusTicket.push({status: status, userId: uId, loggedInUser: userId, role: roleId})
        //     // store.dispatch({
        //     //     type: SupportActions.T_STATUS,
        //     //     payload: {
        //     //         statusTicket: statusTicket,
        //     //     }
        //     // });

        // });

    }

    const getToken = async () => {
        const token = await supportApi.getChatToken(user.id);
        return token;
    }

    const handleAdminClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await authApi.logout()

    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const pageForward = () => {
        if (props.parentRoutes[0].route === props.currentRoute.route) {
            Navigator.pushNamed(props.currentRoute.route)
        }
        else {
            Navigator.back()
        }

    }


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div onClick={pageForward} style={{ minWidth: "fit-content" }}>
                        {
                            props.parentRoutes[0].route === props.currentRoute.route
                                ? (
                                    <Typography variant="h5" noWrap className={classes.name} >
                                        {props.currentRoute.name}
                                    </Typography>
                                )
                                :
                                (<Typography variant="h5" noWrap className={classes.name} >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <ArrowBackIosIcon style={{ marginTop: 5 }} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={11}
                                        >
                                            Back To {props.currentRoute.name}
                                        </Grid>

                                    </Grid>
                                </Typography>
                                )
                        }

                    </div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {userRole.name === 'vendor' &&
                            <Grid
                                item
                            >
                                <Availability />
                            </Grid>
                        }

                        <Grid
                            item
                        >
                            <Button onClick={handleAdminClick} className={classes.adminButton}>
                                <Person className={classes.greyIcon} />
                                <Hidden smDown>
                                    <span className={clsx(classes.greyIcon, classes.titleCase)}>
                                        {user.full_name}
                                    </span>
                                </Hidden>
                                <ArrowDropDown className={classes.greyIcon} />
                            </Button>
                        </Grid>

                    </Grid>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem style={{ width: "120px" }} onClick={() => { Navigator.pushNamed('/account-update') }}>
                            Account
                        </MenuItem>
                        <MenuItem style={{ width: "120px" }} onClick={handleLogout}>
                            Logout
                        </MenuItem>

                    </Menu>

                </Toolbar>
            </AppBar>
            <NavBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} currentRoute={props.currentRoute.route} />
            <div className={classes.content}>
                {props.children}

            </div>
        </div>
    );
}
