import { FormControlLabel, Hidden, Switch, Typography, } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import vendorApi from '../../../../core/api/vendor-api';
import { IRootState } from '../../../../core/store';
import { VendorUser } from '../../../../models/vendor';
import useStyles from './AvailabilityStyles';

export default function Availability() {
    const classes = useStyles();

    const user: VendorUser[] = useSelector((state: IRootState) => state.vendorReducer.vendorUser)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        vendorApi.toggleOnlineOffline(user[0].availability === "ONLINE" ? "OFFLINE" : "ONLINE")
    }
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={user.length > 0 && user[0].availability === "ONLINE" ? true : false}
                    onChange={handleChange}
                    classes={{
                        switchBase: classes.switchBase,
                        track: classes.track,
                        colorSecondary: classes.colorPrimary
                    }}
                />
            }
            label={user.length > 0 && user[0].availability === "ONLINE" ?
                <div>
                    <Hidden smDown>
                        < Typography variant='h5' >Accepting Walk-Ins</Typography >
                    </Hidden>
                </div>
                :
                <div>
                    <Hidden smDown>
                        < Typography variant='h5' >Not Accepting Walk-Ins</Typography >
                    </Hidden>
                </div>
            }
            labelPlacement="start"
        />
    )
}
