import { makeStyles } from "@material-ui/core";
import myColors from "../../styles/colors";

const useStyles = makeStyles({
  tabSelected: { backgroundColor: myColors.primaryColor, color:"#000" },
  tabStyle: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  listPadding: {
    overflowY: 'clip',
    height: '100%',
    padding: 20,
  }
});

export default useStyles;
