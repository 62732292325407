import httpApi from './http-api';
import store from '../store';
import { LoadingActions, VendorActions } from '../store/actions';
import { SortObject } from '../../models/sort';
import { Availability, VenderSocial, VendorCategory, VendorService, VendorUser } from '../../models/vendor';
import { arrayToString } from './utils';

class VendorApi {
    async getUser(): Promise<VendorUser[]> {
        try {
            const { user } = await httpApi.get('/user/getuser');
            store.dispatch({
                type: VendorActions.UPDATE_USER,
                payload: {
                    vendorUser: user
                }
            });
            return user
        } catch (error) {
            return []
        }

    }

    async getSelectedServices(sort: SortObject, category?: number[],
        service?: number[], disable?: boolean[]): Promise<VendorService[]> {
        let url: string = `/Services/getselectedservices?sort=${sort.order}&column=${sort.orderBy}`

        if (category && category.length > 0) {
            url = url + `&category=${arrayToString(category)}`
        }
        if (service && service.length > 0) {
            url = url + `&service=${arrayToString(service)}`
        }

        if(disable){
            url = url + `&disable=${disable}`
        }

        try {
            const { user } = await httpApi.get(`${url}`);
            store.dispatch({
                type: VendorActions.UPDATE_SERVICES,
                payload: {
                    vendorServices: user
                }
            });
            return user
        } catch (error) {
            return []
        }

    }


    async getSelectedCategories(disable?: boolean): Promise<VendorCategory[]> {
        let url: string = `/category/getAll?disable=${disable}`

        try {
            const {Category} = await httpApi.get(`${url}`);
            return Category
        } catch (error) {
            return []
        }
    }


    async updateServices(data: Object[]) {
        try {
            const res = await httpApi.put('/Services/multipleUpdate', { services: data });
        } catch (error) {

        }

    }

    async updateName(data: string) {
        try {
            const res = await httpApi.put('/user/full-name', { full_name: data });
            this.getUser()
        } catch (error) {

        }

    }

    async updateSalonName(data: string) {
        try {
            const res = await httpApi.put('/user/salon-name', { salon_name: data });
            this.getUser()
        } catch (error) {

        }

    }

    async updateDescription(bio: string, qualification: string) {
        try {
            const res = await httpApi.put('/user/bio-details', { bio_details: bio, qualifications: qualification });
            this.getUser()
        } catch (error) {

        }

    }

    async updateContactDetails(data: VenderSocial) {
        try {
            const res = await httpApi.put('/user/contact-details', data);
            this.getUser()
        } catch (error) {

        }

    }

    
    async updateBookingUrlDetails(data: Record<string, any>) {
        try {
            const res = await httpApi.put('/user/booking-url-details', data);
            this.getUser()
        } catch (error) {

        }
    }


    async uploadProfilePicture(data: any, name: string) {
        const files = new FormData();
        files.append('files', data, name);

        try {
            const res = await httpApi.put('/user/uploadProfilePic', files, true, { headers: { 'Content-Type': 'multipart/form-data' } });
            this.getUser()
            return res
        } catch (error) {

        }
    }

    async uploadProfileVideo(data: any) {
        const files = new FormData();
        files.append('video', data);

        try {
            const res = await httpApi.post('/user/upload/video', files, true, { headers: { 'Content-Type': 'multipart/form-data' } });
            this.getUser()
            return res
        } catch (error) {

        }
    }

    async uploadImageToGallery(data: any, name: string) {
        const files = new FormData();
        files.append('files', data, name);

        try {
            const res = await httpApi.post('/user/upload', files, true, { headers: { 'Content-Type': 'multipart/form-data' } });
            this.getUser()
            return res;
        } catch (error) {

        }
    }

    async deleteGalleryImage(data: any) {
        try {
            const res = await httpApi.put('/user/remove-image', { 'url': data });
            this.getUser()
            return res;
        } catch (error) {

        }

    }

    async getAvailableHours(sort: SortObject,): Promise<Availability[]> {

        try {
            const { vendor_slots } = await httpApi.get(`/vendor-working-slot/logged-in-user-slots?sort=${sort.order}&column=${sort.orderBy}`);
            // const { vendor_slots } = await httpApi.get(`/vendor-working-slot/logged-in-user-slots`);
            store.dispatch({
                type: VendorActions.UPDATE_VACATION,
                payload: {
                    vendorAvailability: vendor_slots
                }
            });
            return vendor_slots;
        } catch (error) {
            return []
        }

    }

    async setAvailableHours(data: Object[]) {
        try {
            await httpApi.post(`/vendor-working-slot/multiple-slot-times`, { slot_times: data });
        } catch (error) {

        }

    }

    async updateAvailableHours(slot_id: number, time_slot_id: number, start: number, end: number) {
        try {
            await httpApi.put(`/vendor-working-slot/${slot_id}/slot-time/${time_slot_id}`, { working_from: start, working_to: end });
        } catch (error) {

        }

    }

    async deleteAvailableHours(slot_id: number, time_slot_id: number) {
        try {
            await httpApi.delete(`/vendor-working-slot/${slot_id}/slot-time/${time_slot_id}`);
        } catch (error) {

        }

    }

    async updateAvailability(data: Object) {
        try {
            const res = await httpApi.put(`/user/updateAvailability`, data);
            this.getUser()
            return res;
        } catch (error) {

        }

    }

    async toggleSubServiceVisibilityById(id: number, visibility: number) {
        try {
            const res = await httpApi.put(`/Services/selected-services/disable/${id}`, { disable: visibility });
        } catch (error) {

        }

    }

    async toggleOnlineOffline(data: string) {
        try {
            const res = await httpApi.put(`/user/updateAvailability-instant`, { switchAvailable: data });
            this.getUser()
            return res;
        } catch (error) {

        }

    }

    async getVacationHistory(): Promise<Availability[]> {
        try {
            const { vacation_history } = await httpApi.get('/VacationHistory/getVacation-history');
            store.dispatch({
                type: VendorActions.UPDATE_VACATION,
                payload: {
                    vendorAvailability: vacation_history
                }
            });
            return vacation_history
        } catch (error) {
            return []
        }

    }

    async getAvailableHoursByDay(date: number, customerId: number, userId: number, serviceId: number): Promise<number[]> {
        try {
            const data = await httpApi.get(`/user/booking-slot/${date}?customer_id=${customerId}&user_id=${userId}&sub_id=${serviceId}`);
            return data

        } catch (error) {
            return []
        }

    }

    // Standard Hour Implementation
    async getAllStandardHours(): Promise<any> {
        try {
            const data = await httpApi.get(`/vendor-working-slot/standardHours/get`)
            return data

        } catch (error) {
            return false
        }

    }

    async addStandardHours(standardHourData:any): Promise<any> {
        try {
            const data = await httpApi.post(`/vendor-working-slot/standardHours/create`, standardHourData)
            return data

        } catch (error) {
            return false
        }

    }

    async updateStandardHours(standardHourData:any): Promise<any> {
        try {
            const data = await httpApi.put(`/vendor-working-slot/standardHours/update`, standardHourData)
            return data

        } catch (error) {
            return false
        }

    }

    async availabilitySlotsForWeek(weekNumber:any, vendorId:any, serviceId:any): Promise<any> {
        try {
            const data = await httpApi.get(`/user/availabilitySlotsForWeek/${weekNumber}?user_id=${vendorId}&service_id=${serviceId}`)
            return data
        } catch (error) {
            return false
        }

    }

}

export default new VendorApi();