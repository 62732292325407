import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, Paper, Typography, Button, Slider, FormLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import TextField from '../../../../shared/TextField/TextField'
import useStyles from './ServiceStyles'
import store, { IRootState } from '../../../../core/store'
import { useSelector } from 'react-redux'
import { VendorService } from '../../../../models/vendor'
import vendorApi from '../../../../core/api/vendor-api'
import { Service } from '../../../../models/service'
import WarningButton from '../../../../shared/Button/WarningButton'
import SuccessButton from '../../../../shared/Button/SuccessButton'
import { Category } from '../../../../models/category'
import CustomSelectAutoComplete from '../../../../shared/SelectAutoComplete/SelectAutoComplete'
import Table from '../../../../shared/Table/CustomTable';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { SortObject } from '../../../../models/sort'
import CustomMultiSelectAutocomplete from '../../../../shared/MultiSelectAutoComplete/MultiSelectAutocomplete'
import Alert from '@material-ui/lab/Alert';
import TimeSlider from './../../../../shared/Slider/Slider';


export default function Services() {
    const useStyle = useStyles()
    const allServices: Service[] = useSelector((state: IRootState) => state.filterReducer.services)
    const allCategories: Category[] = useSelector((state: IRootState) => state.filterReducer.activeCategories)
    const availableCategoriesAdmin: Category[] = useSelector((state: IRootState) => state.filterReducer.availableCategories)
    const { user } = useSelector((state: IRootState) => state.userReducer)
    const services: VendorService[] = useSelector((state: IRootState) => state.vendorReducer.vendorServices)
    const [subService, setSubService] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [serviceType, setserviceType] = useState<string>('')
    const [category, setCategory] = useState<string>('')
    const [price, setPrice] = useState<number>(0)
    const [hours, setHours] = useState<string>('');
    const [minutes, setMinutes] = useState<string>('15');
    const [selectedList, setSelectedList] = useState<VendorService[]>([])
    const [createOpen, setcreateOpen] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [sort, setSort] = useState<SortObject>({ orderBy: 'id', order: "asc" });
    const [serviceFilter, setServiceFilter] = useState<string[]>([])
    const [categoryFilter, setCategoryFilter] = useState<string[]>([])
    const [subServicesData, setSubServicesData] = useState<{ sub_service: string, price: number,description:string, duration: string }[]>([]);
    const [isCreateAddDisabled, setIsCreateAddDisabled] = useState<boolean>(true);

    const [isloading, setloading] = useState<boolean>(true)
    const [clearFilters, setClearFilters] = useState<boolean>(false)

    const [editItem, setEditItem] = useState<VendorService | null>(null)
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [isEditDisabled, setIsEditDisabled] = useState<boolean>(false);

    const [toggleItem, setToggleItem] = useState<VendorService | null>(null)
    const [toggleOpen, setToggleOpen] = useState<boolean>(false);

    const [serviceTableFilter, setServiceTableFilter] = useState<string[]>([])
    const [categoryTableFilter, setCategoryTableFilter] = useState<string[]>([])
    const [activeTableFilter, setActiveTableFilter] = useState<string[]>([])
    const [serviceTableData, setServiceTableData] = useState<string[]>([])
    const [disableTableData, setDisableTableData] = useState<string[]>([])
    const [categoryTableData, setCategoryTableData] = useState<string[]>([])

    const fetchData = async () => {
        setloading(true)
        let categoryFilterData: number[] = []
        let serviceFilterData: number[] = []
        let activeFilterData: boolean[] = []

        services.map((item) => {
            if (item.service_type && serviceTableData.includes(item.service_type)) {
                serviceFilterData.push(item.service_id)
            }

            if (item.category_name && item.category_id && categoryTableData.includes(item.category_name)) {
                categoryFilterData.push(item.category_id)
            }

             if (item.disable && disableTableData.includes("Active")) {
                // console.log('Active', item.disable, item)
                activeFilterData.push(false)
            }

            if(item.disable === 0 && disableTableData.includes("Inactive")) {
                // console.log('Inactive', item.disable, item)
                activeFilterData.push(true)
            }
        })
        categoryFilterData = [...new Set(categoryFilterData)]
        serviceFilterData = [...new Set(serviceFilterData)]
        activeFilterData = [...new Set(activeFilterData)]

        // print new array
        // console.log('array', activeFilterData)

        try {
            await vendorApi.getSelectedServices(sort, categoryFilterData, serviceFilterData, activeFilterData)
        } catch (error) {
            setloading(false)
        }
        setloading(false)
    }
    const setFilter = () => {
        let serviceFilterData: string[] = []
        // let serviceIds: number[] = []
        let categoryId: number | null = null

        availableCategoriesAdmin.map((item) => {
            if (item.name === category) {
                categoryId = item.id
            }
        })

        allServices.map((item) => {
            if (categoryId) {
                if (item.category === categoryId) {
                    serviceFilterData.push(item.type)
                }
            } else {
                serviceFilterData.push(item.type)
            }
        })

        serviceFilterData = [...new Set(serviceFilterData)]
        serviceFilterData.sort()
        setServiceFilter(serviceFilterData)
        setloading(false)
    }

    const setTableFilter = () => {
        let serviceFilterData: string[] = []
        let categoryFilterData: string[] = []
        let activeFilterData: string[] = []

        services.map((item) => {
            if (item.service_type) {
                serviceFilterData.push(item.service_type)
            }

            if (item.category_name) {
                categoryFilterData.push(item.category_name)
            }

            if (item.disable || item.disable == 0){
                if(item.disable){
                    activeFilterData.push("Active")
                }
                if(item.disable == 0){
                    activeFilterData.push("Inactive")
                }
                
            }
        })

        // remove duplicate elements from array
        const arr = activeFilterData.filter( function( item, index, inputArray ) {
            return inputArray.indexOf(item) === index;
          });

        // print new array
        // console.log('all', arr)

        serviceFilterData = [...new Set(serviceFilterData)]
        serviceFilterData.sort()
        categoryFilterData = [...new Set(categoryFilterData)]
        categoryFilterData.sort()
        setServiceTableFilter(serviceFilterData)
        setCategoryTableFilter(categoryFilterData)
        setActiveTableFilter(arr)
        setloading(false)
        setClearFilters(false)
    }

    const handleClearFilters = () => {
        setServiceTableData([])
        setCategoryTableData([])
        setDisableTableData([])
        setTableFilter()
    }

    const handlePriceChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setPrice(value as number)
    }

    const handleSubServiceChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setSubService(value as string)
    }

    const handleDescriptionChange = (event: any) => {
        event.persist();
        const { value } = event.target
        setDescription(value as string)
    }


    const handleServiceChange = (value: string) => {
        setserviceType(value)
    }

    const handleCategoryChange = (value: string) => {
        setCategory(value)
    }

    const handleHoursChange = (value: string) => {
        setHours(value)
    }

    const handleMinutesChange = (e: any,value:any) => {
        
        setMinutes(value)
    }

    const handleCreateOpen = () => {
        setcreateOpen(true);
    };

    const handleCreateClose = () => {
        setcreateOpen(false);
        setserviceType('')
        setCategory('')
        setPrice(0)
        setDescription('')
        setSubServicesData([])
        setMinutes('')
    };

    const handleCreateAddSubService = () => {
        //validate and set errors
        try {

        } catch (error) {
            // store.dispatch({
            //     type: AlertActions.UPDATE,
            //     payload: {
            //         alert: { open: true, alertType: "error", message: "Start time can't be greater than the End time" }
            //     }
            // });
        }

        setSubServicesData([...subServicesData, { sub_service: subService, description:description,price: price, duration: minutes }])
        setSubService('')

    };

    const validateAdd = () => {
        if (createOpen) {
            if (!!serviceType && !!subService && price > 0 && parseInt(minutes)>0) {
                setIsCreateAddDisabled(false)
            }
            else {
                setIsCreateAddDisabled(true)
            }
        }

        if (editOpen) {
            if (!!subService && price > 0 && parseInt(minutes)>0) {
                setIsEditDisabled(false)
            }
            else {
                setIsEditDisabled(true)
            }
        }

    };

    const handleCreate = async () => {
        if (subServicesData.length > 0) {
            let serviceId: number = 0
            allServices.map((item) => {
                if (item.type === serviceType) {
                    serviceId = item.id
                }
            })
            let newItem: VendorService[] = [...selectedList]
            subServicesData.map(item => {
                let newService: VendorService = { price: item.price, description:item.description, service_id: serviceId, duration: parseInt(item.duration), user_id: user.id, sub_type: item.sub_service, disable: 1 }
                newItem.push(newService)
            })
            await vendorApi.updateServices(newItem)
            handleCreateClose()
            fetchData()
        }


    };

    const createDialog = (
        < Dialog
            open={createOpen}
            onClose={handleCreateClose}
        >
            <DialogTitle >{"Create Services"}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        marginBottom: "20px"
                    }}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <CustomSelectAutoComplete
                            label="Category"
                            onChange={handleCategoryChange}
                            data={categoryFilter}
                            setInitial={categoryFilter.length > 0 ? categoryFilter[0] : ''}
                            disabled={subServicesData.length > 0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomSelectAutoComplete
                            label="Service"
                            onChange={handleServiceChange}
                            data={serviceFilter}
                            disabled={!category || subServicesData.length > 0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={handleSubServiceChange}
                            value={subService}
                            name="subService"
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            label="Service Type"
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            onChange={handleDescriptionChange}
                            value={description}
                            name="description"
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            multiline
                            label="Description"
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            onChange={handlePriceChange}
                            value={price}
                            name="price"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                },
                                startAdornment:
                                    < InputAdornment position="start" >
                                        $
                                    </InputAdornment>
                            }}
                            type="number"
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            label="price"
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12} style={{marginTop:'25px'}}>
                        <TimeSlider minutes={minutes} handleMinutesChange={handleMinutesChange}/>
                    </Grid>

                </Grid>
                <div style={{ display: "flex", justifyContent: 'center', marginBottom: 20 }}>
                    <Button
                        disabled={isCreateAddDisabled}
                        variant='contained'
                        color='primary'
                        onClick={handleCreateAddSubService}
                    >
                        Add
                    </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                    {
                        subServicesData.length > 0 &&
                        <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}>
                            Selected Sub Services
                        </div>
                    }
                    {
                        subServicesData.length > 0 && subServicesData.map(item => {
                            return <div style={{ marginBottom: 2, display: 'grid', gridTemplateColumns: "20% 40% 20% 20%", fontSize: 12 }}>
                                <div>
                                    {item.sub_service}
                                </div>
                                <div>
                                    {item.description}
                                </div>
                                <div>
                                    ${item.price}
                                </div>
                                <div>
                                    {`${Math.floor(parseInt(item.duration)/60)}h:${parseInt(item.duration)%60}min`}
                                </div>
                            </div>
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleCreateClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleCreate} color="primary" autoFocus disabled={subServicesData.length === 0}>
                    Create
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleEditOpen = (i: VendorService) => {
        setEditItem(i)
        setSubService(i.sub_type)
        setPrice(i.price)
        setHours(Math.floor(i.duration / 60).toString()) 
        setMinutes(i.duration.toString())
        setEditOpen(true);
        setDescription(i.description);
    };

    const handleEditClose = () => {
        setEditItem(null)
        setserviceType('')
        setCategory('')
        setPrice(0)
        setEditOpen(false);
        setDescription('');
        setMinutes('');
    };

    const handleEdit = async () => {
        let newList: VendorService[] = [...selectedList]
        if (editItem) {
            let mins: number = parseInt(hours) * 60
            if (minutes) {
                mins = mins + parseInt(minutes);
            }
            
            newList = newList.filter(item => item.id !== editItem.id)
            newList.push({
                id: editItem.id,
                service_id: editItem.service_id,
                user_id: editItem.user_id,
                sub_type: subService,
                price: parseInt(price.toString()),
                duration: parseInt(minutes),
                service_description: editItem.service_description,
                service_type: editItem.service_type,
                category_id: editItem.category_id,
                category_name: editItem.category_name,
                disable: editItem.disable,
                description:description,
            })
            
            await vendorApi.updateServices(newList)
        }
        handleEditClose()
        fetchData()
    };

    const editDialog = (
        < Dialog
            open={editOpen}
            onClose={handleEditClose}
        >
            <DialogTitle >{`Update ${editItem && editItem.sub_type}`}</DialogTitle>
            <DialogContent>
                <Grid
                    style={{
                        marginBottom: "20px"
                    }}
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <CustomSelectAutoComplete
                            label="Category"
                            data={[editItem?.category_name ? editItem?.category_name : '']}
                            setInitial={editItem?.category_name ? editItem?.category_name : ''}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomSelectAutoComplete
                            label="Service"
                            data={[editItem?.service_type ? editItem?.service_type : '']}
                            setInitial={editItem?.service_type ? editItem?.service_type : ''}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={handleSubServiceChange}
                            value={subService}
                            name="subService"
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            label="Service Type"
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            onChange={handleDescriptionChange}
                            value={description}
                            name="description"
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            multiline
                            label="Description"
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            onChange={handlePriceChange}
                            value={price}
                            name="price"
                            InputProps={{
                                inputProps: {
                                    min: 0
                                },
                                startAdornment:
                                    < InputAdornment position="start" >
                                        $
                                    </InputAdornment>
                            }}
                            type="number"
                            className='full-width'
                            autoComplete="off"
                            fullWidth
                            label="price"
                            variant="outlined" />
                    </Grid>

                    <Grid item xs={12} style={{marginTop:'25px'}}>
                        <TimeSlider minutes={minutes} handleMinutesChange={handleMinutesChange}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleEditClose} color="primary">
                    Cancel
                </WarningButton>
                <SuccessButton onClick={handleEdit} color="primary" autoFocus disabled={isEditDisabled}>
                    Update
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    const handleToggleOpen = (item: VendorService) => {
        setToggleItem(item)
        setToggleOpen(true);
    };

    const handleToggleClose = () => {
        setToggleOpen(false);
    };

    const handleToggle = async () => {
        if (toggleItem && toggleItem.id) {
            await vendorApi.toggleSubServiceVisibilityById(toggleItem.id, toggleItem.disable === 1 ? 0 : 1)
            handleToggleClose()
            fetchData()
        }
        else {
            handleToggleClose()
        }

    };

    const toggleDialog = (
        < Dialog
            open={toggleOpen}
            onClose={handleToggleClose}
        >
            <DialogTitle >{"Update Service Type Status"}</DialogTitle>
            <DialogContent>
                {
                    toggleItem &&
                    <DialogContentText>
                        Are you sure you want to {toggleItem && toggleItem.disable === 1 ? "deactivate" : "activate"}  {toggleItem && toggleItem.sub_type} service?
                    </DialogContentText>
                }

            </DialogContent>
            <DialogActions>
                <WarningButton onClick={handleToggleClose} color="primary">
                    Disagree
                </WarningButton>
                <SuccessButton onClick={handleToggle} color="primary" autoFocus>
                    Agree
                </SuccessButton>
            </DialogActions>
        </Dialog >
    )

    useEffect(() => {
        if (clearFilters) {
            handleClearFilters()
        }
    }, [clearFilters])

    useEffect(() => {
        validateAdd()
    }, [serviceType, minutes, , hours, subService, category, price]);

    useEffect(() => {
        setserviceType('')
        setSelectedList(services)
        setFilter()
        setTableFilter()
    }, [services]);

    useEffect(() => {
        let categoryFilterData: string[] = []
        availableCategoriesAdmin.map((item) => {
            categoryFilterData.push(item.name)

        })
        categoryFilterData = [...new Set(categoryFilterData)]
        categoryFilterData.sort()
        setCategoryFilter(categoryFilterData)
    }, [availableCategoriesAdmin]);

    useEffect(() => {
        setserviceType('')
        setFilter()
    }, [allServices, category, availableCategoriesAdmin]);

    useEffect(() => {
        fetchData()
    }, [sort, serviceTableData, categoryTableData, disableTableData])

    useEffect(() => {
        fetchData()
    }, []);
    return (
        <div className={useStyle.container}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid
                    item
                    xs={10}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                    style={{
                        marginBottom: 10,
                    }}
                >
                    <Grid item xs={12} md={6} lg={2}>
                        <CustomMultiSelectAutocomplete label="Category" onChange={setCategoryTableData} data={categoryTableFilter} reset={clearFilters} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                        <CustomMultiSelectAutocomplete label="Service" onChange={setServiceTableData} data={serviceTableFilter} reset={clearFilters} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                    <CustomMultiSelectAutocomplete
                     label="Active/Inactive" 
                     onChange={setDisableTableData} 
                     data={activeTableFilter} 
                     reset={clearFilters} 
                     />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ float: "right", fontSize : "12px",
                        padding: "6px 14px",  minWidth: "150px", fontWeight: "600" }}
                        onClick={() => {
                        setClearFilters(true);
                        }}
                        startIcon={<ClearAllIcon />}
                    >
                        Clear All
                    </Button>
                </Grid>
            </Grid>
            <Table
                data={services}
                isloading={isloading}
                ignore={['service_id', 'user_id', "category_id", "disable", "id","service_description"]}
                order={["category_name", "service_type", "sub_type"]}
                overrides={[{ from: 'sub_type', to: "Service Type" }, { from: 'category_name', to: "Category" },{from:"description", to:"Description"}, { from: 'service_type', to: "Service" }]}
                createFunction={handleCreateOpen}
                editFunction={handleEditOpen}
                sortFunction={setSort}
                sort={sort}
                toggle={
                    {
                        toggleFunction: handleToggleOpen,
                        toggleColumn: "disable"
                    }
                }
            />
            {
                services.length === 0 &&
                <Alert style={{ alignItems: "center", marginTop: 20 }} severity="info">Eg - For a Hair Cut service, the Service Types could be Men's Hair Cut, Boy's Hair Cut, etc...</Alert>
            }
            {toggleDialog}
            {createDialog}
            {editDialog}
        </div>
    )
}
