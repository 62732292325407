import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import routeNameObjects from '../../route_names/route_name_objects'
import nestedRouteNameObject1 from '../../route_names/nested_route_name_objects1'
import Page from '../../shared/Page/Page'
import useStyles from './SupportTicketStyles'
import { Grid, Fab } from '@material-ui/core'
import { CommentOutlined } from '@material-ui/icons'
import SupportTicketDataCard from './components/SupportTicketDataCard/SupportTicketDataCard'
import Chat from './components/Chat/Chat'
import { useSelector } from 'react-redux'
import store, { IRootState } from '../../core/store'
import supportApi from '../../core/api/support-api';
import { SupportActions } from '../../core/store/actions';
import { Support } from '../../models/support'
import './SupportTicketStyles.sass'

export default function SupportTicket(props: RouteComponentProps) {
    const classes = useStyles()
    const { userRole, user } = useSelector((state: IRootState) => state.userReducer)
    const supportTicketData: Support[] = useSelector((state: IRootState) => state.supportReducer.supportTickets)
    const supportTicketStatusData: any = useSelector((state: IRootState) => state.supportReducer.statusTicket)
    const [id, setId] = useState<string>('')
    const [selectedTicket, setselectedTicket] = useState<Support | null>(null)
    const [showFloatingChat, setShowFloatingChat] = useState(false)
    const [isClosingTicket, setIsClosingTicket] = useState(false)

    const fetchSupportById = async () => {
        if (id) {
            supportTicketData.map((item: any) => {
                if (item.id === parseInt(id)) {
                    delete item['is_seen']
                    setselectedTicket(item)
                }
            })
        }
    }

    const isVendor = (): boolean => {
        if (userRole.name === "vendor") {
            return true
        }
        else {
            return false
        }
    }

    useEffect(() => {
        fetchSupportById()
    }, [id, supportTicketData])


    useEffect(() => {
        const ticketId = Reflect.get(props.match.params, "ticketId")
        setId(ticketId)
    }, [props.match.params])

    useEffect(() => {
        const ticketId = Reflect.get(props.match.params, "ticketId")

        let ticket = supportTicketStatusData.find((f:any) => f['ticketId'] == ticketId)

        if (ticket){
            if (ticket['is_seen'] != 1){
                ticket['is_seen'] = 1
                supportApi.updateSeenState(ticketId, 1)
                
                store.dispatch({
                    type: SupportActions.T_STATUS,
                    payload: {
                        statusTicket: supportTicketStatusData,
                    }
                })
            }
        }

        let isAllSeen = supportTicketStatusData.every( (val:any, i:any, arr:any) => val == 1 )
        if (isAllSeen){
            let element = document.getElementById('side_bar_dot')
            if (element){
                element.hidden = true
            }
        }

    }, [])

    return (
        <Page
            currentRoute={nestedRouteNameObject1.supportTicket}
            parentRoutes={[routeNameObjects.supportTicket]}
        >
            <div className={classes.container}>
                <Grid
                    spacing={2}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid
                        item
                        md={6}
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        {
                            !!selectedTicket &&
                            <Grid
                                item
                                xs={12}
                            >
                                <SupportTicketDataCard data={selectedTicket} closingTicketFunction={setIsClosingTicket} />
                            </Grid>
                        }
                    </Grid>


                    <Grid
                        item
                        md={6}
                        style={{ width: "100%" }}
                    >
                        {selectedTicket && user && selectedTicket.ticket_status === 'ACCEPTED' && (
                            <div className="SupportTicket-chat">
                                <Chat
                                    selectedTicket={selectedTicket}
                                    user={user}
                                    isVendor={isVendor()}
                                    setAllRead={isClosingTicket}
                                />
                            </div>
                        )}
                    </Grid>

                </Grid>
            </div>

            <div style={{ position: "fixed", bottom: 60, right: 0, margin: 15, zIndex: 999 }} className="SupportTicket-floating-chat">
                {selectedTicket && user && selectedTicket.ticket_status === 'ACCEPTED' && showFloatingChat && (
                    <Chat
                        selectedTicket={selectedTicket}
                        user={user}
                        isVendor={isVendor()}
                        setAllRead={isClosingTicket}
                    />
                )}
            </div>
            <div className="SupportTicket-floating-btn-chat">
                <Fab
                    onClick={() => setShowFloatingChat(!showFloatingChat)}
                    color="primary"
                    aria-label="chat"

                    style={{ position: "fixed", bottom: 0, right: 0, margin: 15, zIndex: 999 }}
                >
                    <CommentOutlined />
                </Fab>
            </div>
        </Page>
    )
}
