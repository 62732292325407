import { useState, useEffect } from "react"
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
} from "@chatscope/chat-ui-kit-react"
import supportApi from "../../../../core/api/support-api"
import "./ChatStyle.sass"
import logo from "../.././../../img/logo-square.png"

const TwilioChat = require("twilio-chat")

const Chat = ({ selectedTicket, user, isVendor, setAllRead }) => {
  const [currentChannel, setCurrentChannel] = useState(null)
  const [chatMessages, setChatMessages] = useState(null)
  const [loadingChat, setLoadingChat] = useState(false)

  const [text, setText] = useState(null)

  useEffect(async () => {
    await init()
    return () => {
      currentChannel.removeAllListeners()
    }
  }, [])

  useEffect(async () => {
    if (chatMessages) {
      // let lastReadIndex = chatMessages.length - 1
      // for (let i = lastReadIndex; i >= 0; i--) {
      //   let msg = chatMessages[i]
      //   if (
      //     i === lastReadIndex &&
      //     user.id &&
      //     user.id.toString() === msg.state.author
      //   ) {
      //     break
      //   }

      //   if (user.id && user.id.toString() !== msg.state.author) {
      //     lastReadIndex = i
      //     break
      //   }
      // }
      // currentChannel.updateLastConsumedMessageIndex(99).then(()=>console.log("Updated"))
    }
  }, [chatMessages])

  const getToken = async () => {
    const token = await supportApi.getChatToken(user.id)
    return token
  }

  const init = async () => {
    setLoadingChat(true)
    
    const token = await getToken()
    const client = await TwilioChat.Client.create(token)

    client.on("tokenAboutToExpire", async () => {
      const token = await getToken()
      client.updateToken(token)
    })

    client.on("tokenExpired", async () => {
      const token = await getToken()
      client.updateToken(token)
    })

    try {
      const channel = await client.getChannelByUniqueName(
        selectedTicket.chat_room
      )
      if (channel.channelState.status !== "joined") {
        await channel.join()
      }

      setCurrentChannel(channel)

      channel.on("messageAdded", (message) => {
        if (!selectedTicket.chatMessages) {
          selectedTicket.chatMessages = []
        }

        selectedTicket.chatMessages = [...selectedTicket.chatMessages, message]
        setChatMessages(selectedTicket.chatMessages)
      })

      if (
        !selectedTicket.chatMessages ||
        selectedTicket.chatMessages.length === 0
      ) {
        const msgs = await channel.getMessages()
        selectedTicket.chatMessages = msgs.items
        setChatMessages(selectedTicket.chatMessages)
      }

      setLoadingChat(false)
    } catch (err) {
      console.log("ERROR =>", err)
    }
  }

  const sendMessage = () => {
    if (text && currentChannel) {
      currentChannel.sendMessage(String(text).trim())
      setText("")
    }
  }

  return (
    <div className="chat-component-container">
      <MainContainer>
        <ChatContainer>
          <ConversationHeader>
            <Avatar
              src={
                !isVendor && selectedTicket.vendor_profile_url
                  ? selectedTicket.vendor_profile_url
                  : logo
              }
              name="user"
            />
            <ConversationHeader.Content
              userName={!isVendor ? selectedTicket.vendor_name : "Admin"}
              info={
                !isVendor ? "Live chat with vendor!" : "Live chat with admin!"
              }
            />
          </ConversationHeader>
          <MessageList>
            {!chatMessages ||
              chatMessages.map((m, i) => (
                <Message
                  key={`${m.state.sid}-${i}`}
                  model={{
                    message: m.state.body,
                    sentTime: m.state.timestamp,
                    sender: m.state.author,
                    direction:
                      user.id && user.id.toString() === m.state.author
                        ? "outgoing"
                        : "incoming",
                  }}
                />
              ))}
            {!loadingChat || (
              <MessageList.Content
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                  fontSize: "1.2em",
                }}
              >
                Loading chat...
              </MessageList.Content>
            )}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            attachButton={false}
            onSend={sendMessage}
            onChange={(val) => setText(val)}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  )
}

export default Chat
