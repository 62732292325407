import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItemText: {
            fontSize: "22px",
            fontWeight: "bold",
        },
        listItem: {
            transition: "0.5s",
            "&:hover": {
                backgroundColor: `${myColors.primaryColor} !important`,
                color: `${myColors.inverseFontColor} !important`,
            },
        },
        listItemTextHover: {
            color: `${myColors.inverseFontColor} !important`,
        },
        selected: {
            backgroundColor: `${myColors.primaryColor} !important`,
            color: `${myColors.inverseFontColor} !important`,
        },
        badgeRoot: {
            height: 15
        }
    }),
);

export default useStyles;