import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import bookingApi from '../../../core/api/booking-api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import time from '../../../core/services/time';
import EventDetailModal from './components/EventDetailModal/EventDetailModal';
import BigCalender from './components/DialogCalender/Calender'
import './CalendarViewStyles.sass';
import {
  Grid,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  MobileStepper,
  useTheme,
  IconButton,
} from '@material-ui/core';
import AllBookingsList from './components/AllBookingsList/AllBookingsList';
import Button from '../../../shared/Button/Button';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../core/store';
import { KeyboardArrowLeft, KeyboardArrowRight, Close } from '@material-ui/icons';
import CustomSelectAutoComplete from '../../../shared/SelectAutoComplete/SelectAutoComplete';
import { VendorService,VendorCategory } from '../../../models/vendor';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import vendorApi from '../../../core/api/vendor-api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '../../../shared/TextField/TextField';
import employeeApi from '../../../core/api/employee-api';
import Alert from '@material-ui/lab/Alert';
import { Availability } from '../../../models/vendor';
import { Service } from '../../../models/service';
import { nanoid } from 'nanoid'
import { cpuUsage } from 'process';
import { textAlign } from '@mui/system';

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const localizer = momentLocalizer(moment)

const formatMobile = (value: string): string => {
  const onlyNums: string = value.replace(/[^0-9]/g, '');
  let number: string = '';
  if (onlyNums.length < 10) {
    number = onlyNums;
  }
  if (onlyNums.length === 10) {
    number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return number;
};

const CalendarView = () => {
  // State
  const vendorAvailability: Availability[] = useSelector(
    (state: IRootState) => state.vendorReducer.vendorAvailability,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<any[]>([]);
  const [availabilityEvents, setAvailabilityEvents] = useState<any>([])
  const [dateArray, setTestArray] = useState<any[]>([]);
  const [currentSelectedEvent, setCurrentSelectedEvent] = useState<any>(null);
  const { userRole, user } = useSelector((state: IRootState) => state.userReducer);
  const services: VendorService[] = useSelector((state: IRootState) => state.vendorReducer.vendorServices);
  const allServices: Service[] = useSelector(
    (state: IRootState) => state.filterReducer.services
  );
  const [serviceFilter, setServiceFilter] = useState<{ label: string; id: number,category_id:number }[]>([]);
  const [selectedService, setSelectedService] = useState<{ label: string; id: number,category_id:number }>({ label: '', id: 0,category_id:0 });

  const [categories, setCategories] = useState<VendorCategory[]>([])
  const [selectedCategory, setSelectedCategory] = useState<{ label: string; id: number }>({ label: '', id: 0 })
  const [selectedRescheduleBookingId, setSelectedRescheduleBookingId] = useState<number>(0)
  const [bookingDate, setBookingDate] = useState<Moment | null>(moment(new Date()))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [timeSlotList, setTimeSlotList] = useState<number[]>([])
  const [selectedTime, setSelectedTime] = useState<number>(0)
  const [selectedWeek, setSelectedWeek] = useState<number>(moment(new Date()).week())

  const [email, setEmail] = useState<string>('');
  const [emailErr, setEmailErr] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [mobileErr, setMobileErr] = useState<string>('This Field cannot be empty');
  const [isProceedClicked, setIsProceedClicked] = useState<boolean>(false);

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);

  const [proceedClicked, setProceedClicked] = useState<boolean>(false);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);

  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<number>(0);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState<number>(0);

  const [isCreating, setIsCreating] = useState(false);

  const [selectedServices,setSelectedServices]=useState<any|null>([])

  const [selectedServiceForType, setSelectedServiceForType] = useState<any>({});
  const [selectedServiceType, setSelectedServiceType] = useState<any>({});
  const [serviceTypes, setServiceTypes] = useState<any>([]);

  const handleNext = async() => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        fetchCustomerByEmail()
      }

      if(activeStep == 0){
        setLoading(true)
        let currentDate = bookingDate
        if (currentDate == null) currentDate = moment(new Date())

        fetchAllAvailabilityForWeek(currentDate).then((events) => {
          setAvailabilityEvents(events)
          setLoading(false)
        })
      }

      if(activeStep == 1 && isGuest == false && selectedTime != 0){
        handleCreate()
      }

      if(activeStep == 2){
        setIsProceedClicked(true)
        handleCreate()
      }

      return prevActiveStep + 1
    })
  }

  const handleCalenderNavigate = (e: any) => {
    let date = moment(e)
    fetchAllAvailabilityForWeek(date).then((events) => {
      setAvailabilityEvents(events)
    })
    setBookingDate(date)
  }
  
  useEffect(() => {
    vendorAvailability.map((item) => {
      if (item.slot_times.length > 0) {
        item.slot_times.map((slot) => {
          let data = moment.unix(item.slot_date).format('MM/DD/yyyy');
          dateArray.push(data);
        });
      }
    });
  }, [vendorAvailability]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDateChangeBooking = (event:any) => {
    setSelectedTime(moment(event.start).unix())
  }

  // Side effects
  useEffect(() => {
    initEvents();
  }, []);

  const initEvents = async () => {
    setLoading(true);
    const startDate = time.DateToUnix(moment().subtract(1, 'years').toDate());
    const endDate = time.DateToUnix(moment().add(1, 'years').toDate());
    const bookings = await bookingApi.getAllWithOutPagination(startDate, endDate);

    const _events = bookings?.map((b: any) => ({
      id: b.id,
      title: `#${b.id} :: ${b.service_type}-${b.service?.sub_type} - ${b.customer?.first_name} ${b.customer?.last_name}`,
      start: moment.unix(b.booked_date_time)?.toDate(),
      end: moment.unix(b.booked_date_time)?.add(b.booking_duration, 'minutes')?.toDate(),
      allDay: false,
      booking: b,
    }));
    setEvents(_events);
    setLoading(false);
  };

  const onSelectEvent = (event: any) => {
    setCurrentSelectedEvent(event);
  };

  const isVendor = (): boolean => {
    if (userRole.name === 'vendor') {
      return true;
    } else {
      return false;
    }
  };

  const fetchCustomerByEmail = async () => {
    setFilterLoading(true);

    if (email && !emailErr) {
      try {
        const { guest, customerId } = await employeeApi.getCustomerByEmail(email);
        setProceedClicked(true);
        setCustomerId(customerId);
        setIsGuest(guest);
      } catch (error) {
        setProceedClicked(false);
        setFilterLoading(false);
      }
    }
    setFilterLoading(false);
  };

  const setFilter = () => {
    let serviceFilterData: { label: string; id: number,category_id:number|any }[] = [];

    services.map((item) => {
      if (item.disable === 1 && item.service_type && item.id) {
        serviceFilterData.push({ label: `${item.service_type}-${item.sub_type}`, id: item.id, category_id:item?.category_id });
      }
    });
    // serviceFilterData = [...new Set(serviceFilterData)]
    // serviceFilterData.sort()

    setServiceFilter(serviceFilterData);
  };

  const getDates = (day: any) => {
    return !dateArray.includes(day);
  };

  var uniqueServices:any = [];
  const newTypes = services.filter(
    (serviceType) =>  serviceType.disable ===1
  );
  newTypes.filter(function(item){
      var i = uniqueServices.findIndex((x:any) => x.id === item.service_id );
      if(i <= -1 ){
          uniqueServices.push({id:item.service_id,type:item.service_type});
      }
      return null;
    });


  const handleService = (value: any) => {
    setSelectedServiceForType(value);
    const newTypes = services.filter(
      (serviceType) => serviceType.service_id === value.id && serviceType.disable ===1
    );
    setSelectedServiceType({});
    console.log(`setServiceTypes`, serviceTypes)
    setServiceTypes(newTypes);
  };

  const handleServiceTypeChange = (value: any) => {
    console.log(`value ==>`, value)
    setSelectedServiceType(value);
  };

  const handleEmailChange = (event: any) => {
    event.persist();
    const { value } = event.target;
    let text: string = value;
    text = text.toLowerCase();
    let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    );

    if (!pattern.test(text)) {
      setEmailErr('Please enter valid email address');
    } else {
      setEmailErr('');
    }
    setEmail(text);
  };

  const handleMobileChange = (event: any) => {
    event.persist();
    const { value } = event.target;
    const onlyNums: string = value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 11) {
      let number = formatMobile(value);
      setMobile(number);
      if (onlyNums.length == 10) {
        setMobileErr('');
      } else {
        setMobileErr('Mobile number must have 10 digits');
      }
    } else {
      setMobileErr('');
    }
  };

  useEffect(() => {
    setFilter();
  }, [services]);

  const handleCreateClose = () => {
    setCreateOpen(false);
    setBookingDate(null);
    setSelectedWeek(moment(new Date()).week())
    setSelectedService({ label: '', id: 0,category_id:0 });
    setSelectedCategory({ label: '', id: 0 });
    setEmail('');
    setEmailErr('');
    setProceedClicked(false);
    setFirstName('');
    setLastName('');
    setMobile('');
    setMobileErr('This Field cannot be empty');
    setTimeSlotList([]);
    setSelectedTime(0);
    setActiveStep(0);
    setSelectedServices([]);
    setSelectedServiceForType({});
    setSelectedServiceType({});
  };

  //Booking UI

  const handleCreateOpen = () => {
    // setIsReschedule(isReschedule)
    setSelectedServices(serviceFilter)
    setCreateOpen(true)
    setServiceTypes(services)
  }

  const handleDatePickerClose = () => {
    setIsOpenDatePicker(false)
    setBookingDate(moment(new Date()))
  }

  const handleDateChange = (value: Moment | null = moment()) => {
    if (!value) value = moment(new Date())

    setBookingDate(value)

    if(selectedWeek != value.week()){
      setSelectedWeek(value.isoWeek())
      setBookingDate(value)

      fetchAllAvailabilityForWeek(value).then((events) => {
        setAvailabilityEvents(events)
        setLoading(false)
      })
    }

    setIsOpenDatePicker(false)
  }

  const handleCreate = async () => {
    setIsCreating(true)
    setLoading(true)

    if (isGuest) {
      await employeeApi.createCustomer(customerId, firstName, lastName, mobile.replace(/[^0-9]/g, ''))
    }

    let bookingResults = await bookingApi.create(
      user.id,
      customerId,
      selectedServiceType.service_id,
      selectedServiceType.id,
      selectedTime
    )
    
    setLoading(false)
    handleCreateClose()
    setIsCreating(false)
    initEvents()
  }

  const fetchAllAvailabilityForWeek = (date:any, subServiceId?:any) => {
    return new Promise(async(resolve, reject)=>{
      setIsLoading(true)

      let weekNum = date.isoWeek()

      let selectedSubServiceId = subServiceId ? subServiceId : selectedServiceType.id

      let slotList:any = await vendorApi.availabilitySlotsForWeek(
        weekNum,
        user.id,
        selectedSubServiceId
      )

      const events = slotList?.map((slot: any) => ({
        id: nanoid(),
        title: moment.unix(slot.start).format('hh:mm a'),
        start: moment.unix(slot.start)?.toDate(),
        end: moment.unix(slot.end)?.toDate()
      }))

      setIsLoading(false)
      resolve(events)
    })
  }

  // END

  useEffect(() => {
    if (activeStep === 1 && !bookingDate) {
      handleDateChange()
    }
  }, [activeStep])

  useEffect( () =>  {
    const fetchCategories = async() =>{
     var data = await vendorApi.getSelectedCategories(false );  
     data = data.map( (data => ({...data, label:data.name}) ));
     console.log(data)
     setCategories(data)
     }

    fetchCategories();

 }, [])

 const DatePickDialog = (
  <Dialog scroll="body" open={isOpenDatePicker} onClose={handleDatePickerClose} maxWidth='sm'>
    <DialogTitle
      disableTypography
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <h2>Create New Booking</h2>
      <IconButton onClick={handleCreateClose}>
        <Close />
      </IconButton>
    </DialogTitle>

    <DialogContent style={{ minWidth: 300 }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
            autoOk={true}
            openTo="date"
            variant="static"
            value={bookingDate?.toDate()}
            onChange={handleDateChange}
            disablePast={true}
            format="MM/DD/yyyy"
          />
      </MuiPickersUtilsProvider>
    </DialogContent>
  </Dialog>
)

const createDialog = (
  <Dialog scroll="body" open={createOpen} onClose={handleCreateClose}>
    <DialogTitle
      disableTypography
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <h2>Create New Booking</h2>
      <IconButton onClick={handleCreateClose}>
        <Close />
      </IconButton>
    </DialogTitle>

    <DialogContent style={{ minWidth: 600 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: 99 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {activeStep === 0 && (
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <TextField
              onChange={handleEmailChange}
              error={!!emailErr}
              helperText={emailErr}
              value={email}
              disabled={proceedClicked}
              name="email"
              className="full-width"
              autoComplete="off"
              fullWidth
              label="Email Address"
              variant="outlined"
            />
          </Grid>

          {selectedServiceForType && (
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <Autocomplete
                style={{
                  width: '100%',
                }}
                size="small"
                options={uniqueServices}
                value={selectedServiceForType}
                disableClearable={true}
                onChange={(a, b) => {
                  handleService(b);
                }}
                autoSelect
                autoHighlight
                getOptionLabel={(option) => option.type}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select a Service"
                  />
                )}
              />
            </Grid>
          )}

          {serviceTypes && (
            <Grid item xs={12}>
              <Autocomplete
                style={{
                  width: '100%',
                }}
                size="small"
                options={serviceTypes}
                value={selectedServiceType}
                disableClearable={true}
                onChange={(a, b) => {
                  handleServiceTypeChange(b);
                }}
                autoSelect
                autoHighlight
                getOptionLabel={(option) => option.sub_type}
                renderInput={(params) => (
                  <TextField {...params}
                    variant="outlined"
                    label="Select a Service Type"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      )}

      {activeStep === 1 && (
        <Grid container>
          <Grid item xs={12} style={{textAlign: "right"}}>
            <div>
              <Button color='primary' onClick={()=>setIsOpenDatePicker(true)} style={{maxWidth: "10px" , maxHeight: "20px"}}> <span>Pick Date</span></Button>
            </div>
            <BigCalender 
              bookingDate = {bookingDate}
              localizer =  {localizer}
              availabilityEvents = {availabilityEvents}
              handleDateChangeBooking = {handleDateChangeBooking}
              handleCalenderNavigate = {handleCalenderNavigate}
              selectedTime = {selectedTime}
            />
          </Grid>
        </Grid>
      )}

      {activeStep === 2 && (
        <Grid container>
          {
            !filterLoading && (
              <>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <TextField
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                    error={(firstName == "" && isProceedClicked)}
                    helperText={firstName ? '' : 'This Field cannot be empty'}
                    value={firstName}
                    name="firstName"
                    className="full-width"
                    autoComplete="off"
                    fullWidth
                    label="First Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <TextField
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                    error={(lastName == "" && isProceedClicked)}
                    helperText={lastName ? '' : 'This Field cannot be empty'}
                    value={lastName}
                    name="lastName"
                    className="full-width"
                    autoComplete="off"
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <TextField
                    onChange={handleMobileChange}
                    error={(mobileErr == ""  && isProceedClicked)} 
                    helperText={mobileErr}
                    value={mobile}
                    name="mobile"
                    className="full-width"
                    autoComplete="off"
                    fullWidth
                    label="Mobile Number"
                    variant="outlined"
                  />
                </Grid>
              </>
            )
          }
        </Grid>
      )}
    </DialogContent>
    <DialogActions>
      <MobileStepper
        variant="progress"
        steps={isGuest ? 3 : 2}
        position="static"
        activeStep={activeStep}
        style={{ flexGrow: 1 }}
        nextButton={
          <Button
            size="small"
            variant="text"
            color="primary"
            style={{color:"black"}}
            onClick={handleNext}
            disabled={
              (activeStep === 1 && selectedTime === 0) ||
              (activeStep === 0 && !email) ||
              (activeStep === 0 && selectedServiceType.id === 0) ||
              (activeStep === 2 && (!!emailErr || !firstName || !lastName || !mobile || !!mobileErr || isCreating))
            }
          >
            {(activeStep == 1 && isGuest == false)|| activeStep == 2 ? "PROCEED" : "NEXT"}
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button
          size="small"
          variant="text"
          color="primary"
            onClick={handleBack}
            disabled={activeStep === 0 || isCreating}
          >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </DialogActions>
  </Dialog>
);

  if (loading) {
  return (
      <div className="dashboard-calendar-loading-container">
      <CircularProgress />
    </div>
  );
}

return (
  <div className="dashboard-calendar-container">
  <Grid container>
      <Grid item md={9}>
        <Calendar
          style={{ height: '73vh' }}
          localizer={localizer}
          events={loading ? [] : events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          popup={true}
          views={['month', 'week', 'day']}
          step={30}
        />
      </Grid>
      <Grid item md={3}>
        {isVendor() && (
          <Grid container justifyContent="center">
            <Button
              color="primary"
              variant="contained"
              style={{ marginLeft: 20, marginBottom: 10 }}
              onClick={handleCreateOpen}
            >
              Create New Booking
            </Button>
          </Grid>
        )}

        <AllBookingsList events={loading ? [] : events} onSelectEvent={onSelectEvent} />
      </Grid>
    </Grid>
    {!currentSelectedEvent || (
      <EventDetailModal
        event={currentSelectedEvent}
        onClose={() => setCurrentSelectedEvent(null)}
        onReload={initEvents}
      />
    )}
    {createDialog}
    {DatePickDialog}
  </div>
  );
};

export default CalendarView;