import React from 'react';
import moment from 'moment'
import './AllBookingsListStyles.sass';

const AllBookingsList = ({events, onSelectEvent} : any) => {
  
  const futureEvents = events?.filter((e : any) => {
    return moment.unix(e.booking.booked_date_time).isAfter(moment());
  });

  const renderDateLabel = (event: any, i: number) => {
    const shouldRenderLabel =
      i === 0 ||
      moment.unix(event?.booking?.booked_date_time)?.format("Do MMM YYYY") !== moment.unix(futureEvents[i - 1]?.booking?.booked_date_time)?.format("Do MMM YYYY")

    const isToday = moment.unix(event?.booking?.booked_date_time).format("Do MMM YYYY") === moment().format("Do MMM YYYY");

    if (shouldRenderLabel) {
      return (
        <div className="bookings-list-date-label">
          <p>{isToday ? "Today" : moment.unix(event.booking.booked_date_time).format("Do MMM YYYY")}</p>
        </div>
      )
    }
  };

  return (
    <div className="all-bookings-list-container">
      <div className="all-bookings-list-container-header">
        <h1>Upcoming Bookings</h1>
      </div>
      <div className="all-bookings-list-container-scroll-view">
        {futureEvents?.length === 0 ? (
          <div>
            <p>No Upcoming Bookings!</p>
          </div>
        ) : futureEvents?.map((e : any, i : number) => (
          <div>
            {renderDateLabel(e, i)}
            <div className="bookings-list-item" key={e.title} onClick={() => onSelectEvent(e)}>
              <p className="time-slot-label">{moment.unix(e.booking.booked_date_time).format("hh:mm A")} - {moment.unix(e.booking.booked_date_time).add(e.booking.booking_duration, 'minutes').format("hh:mm A")}</p>
              <p className="bookings-list-item-title">{e.title}</p>
              <p >Status: <span className="bookings-list-item-status">{e.booking.status}</span></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AllBookingsList;