import { Employee } from "../../models/employee";
import { EmployeeActions } from "./actions";

interface Action {
  type: string;
  payload: any;
}

interface employeeState {
  employees: Employee[];
  admins: Employee[];
  superAdmins: Employee[];
  customer: Object[]
};

export const initialEmployeeState = {
  employees: [],
  admins: [],
  superAdmins: [],
  customer: []
};

const employeeReducer = (state: employeeState = initialEmployeeState, action: Action) => {
  if (action && action.payload) {
    const { employees, admins, superAdmins, customer } = action.payload;
    switch (action.type) {
      case EmployeeActions.UPDATE:
        if (employees) {
          return {
            ...state,
            employees: employees
          };
        }
        break;
      case EmployeeActions.UPDATE_ADMIN:
        if (admins) {
          return {
            ...state,
            admins: admins
          };
        }

        break;

      case EmployeeActions.UPDATE_CUSTOMER:
        if (customer) {
          return {
            ...state,
            customer: customer
          };
        }

        break;
      case EmployeeActions.UPDATE_SUPER_ADMIN:
        if (superAdmins) {
          return {
            ...state,
            superAdmins: superAdmins
          };
        }

        break;
      default:
        return { ...state };
    }
  }

  return state;
};

export default employeeReducer;
