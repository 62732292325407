import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AlertObject } from '../../models/alert';
import store, { IRootState } from '../../core/store';
import { useSelector } from 'react-redux';
import { AlertActions } from '../../core/store/actions';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function CustomAlert() {
    const classes = useStyles();
    const alert: AlertObject = useSelector((state: IRootState) => state.alertReducer.alert)

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        store.dispatch({
            type: AlertActions.UPDATE,
            payload: {
                alert: { open: false, alertType: alert.alertType, message: "" }
            }
        });
    };

    return (
        <div className={classes.root}>
            <Snackbar open={alert.open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alert.alertType}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
}