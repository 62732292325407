// @ts-nocheck
import { Button, ButtonGroup, Grid, Paper } from '@material-ui/core'
import classNames from 'classnames';
import React from 'react'
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux'
import { IRootState } from '../../../core/store'
import { ChartData } from '../../../models/dashboard';
import MyLoader from '../../../shared/Loader/my_loader';
import useStyles from './StatsViewStyles'


interface IProps {
    loading: boolean;
    options: Object;
    theme?: string[];
    filterFunction?: Function;
    filterBy?: string;
}
export default function BooingByVendors(props: IProps) {
    const classes = useStyles()
    const bookingByVendors: ChartData = useSelector((state: IRootState) => state.dashboardReducer.bookingByVendors)

    const dynamicColors = (length: number): string[] => {
        if (props.theme && length <= props.theme.length && 0 < length) {
            return props.theme.slice(0, length)
        }
        else if (props.theme && length > props.theme.length && 0 < length) {
            let newTheme: string[] = [...props.theme]
            for (let i = props.theme.length; i < length; i++) {
                let index = i % props.theme.length
                newTheme.push(props.theme[index])
            }
            return newTheme
        }
        else {
            return []
        }
    }

    const handleFilterByChange = (value: string) => {
        if (props.filterFunction) {
            props.filterFunction(value)
        }
    };
    return (
        <Paper className={classNames(classes.dataCard, classes.chartCard)}>
            <Grid container alignItems="flex-start" justifyContent='space-between' className={classes.top}>
                <Grid item>
                    <div className={classes.chartTitle}>Count of bookings by Professional</div>
                </Grid>
                {
                    props.filterBy &&
                    <Grid item>
                        <ButtonGroup color="primary" disableElevation>
                            <Button variant={props.filterBy === "date" ? 'contained' : 'outlined'} onClick={() => {
                                handleFilterByChange("date")
                            }}>Day</Button>
                            <Button variant={props.filterBy === "month" ? 'contained' : 'outlined'} onClick={() => {
                                handleFilterByChange("month")
                            }}>Month</Button>
                            <Button variant={props.filterBy === "year" ? 'contained' : 'outlined'} onClick={() => {
                                handleFilterByChange("year")
                            }}>Year</Button>
                        </ButtonGroup>
                    </Grid>
                }

            </Grid>
            {
                props.loading ?
                    <MyLoader height='354px' />
                    :
                    <Bar
                        type="bar"
                        data={{
                            labels: bookingByVendors.labels,
                            datasets: [{
                                data: bookingByVendors.data, backgroundColor: props.theme && props.theme.length > 0 ? dynamicColors(bookingByVendors.data.length) : '#39A2DB',
                            }]
                        }}
                        options={props.options} />

            }

        </Paper>
    )
}
