import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: "bold",
            textTransform: "capitalize",
            minHeight: 30,
        },
        urlTitle: {
            textAlign: 'center',
            display: 'flex'
        },
    }),
);

export default useStyles;