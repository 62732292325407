import React, { useEffect, useState } from 'react'
import useStyles from './tvScrenStyles'
import { AppBar, Button, Dialog, Divider, Grid, IconButton, MobileStepper, Paper, Slide, Toolbar, Typography, useTheme, Zoom } from '@material-ui/core'
import ItemCard from './components/itemCard/itemCard'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import Circle from './components/circle/circle'
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import logo from '../../img/logo.png'
import { QRCode } from "react-qr-svg";
import tvScreenApi from '../../core/api/tvScreen-api'
import { useSelector } from 'react-redux'
import { Category } from '../../models/category'
import store, { IRootState } from '../../core/store'
import { ActiveStates, vendorPublicProfile } from '../../models/tvScreen'
import socketIOClient from "socket.io-client";
import { TvScreenActions } from '../../core/store/actions'

const host = "https://d2128vxwux9vxt.cloudfront.net"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    // return <Slide direction="up" ref={ref} {...props} />;
    return <Zoom ref={ref} {...props} />
});

export default function TvScreen() {
    const classes = useStyles()
    const theme = useTheme();

    const categories: Category[] = useSelector((state: IRootState) => state.categoryReducer.categories)
    const publicVendors: vendorPublicProfile[] = useSelector((state: IRootState) => state.tvScreenReducer.publicVendors)
    const activeStatus: ActiveStates[] = useSelector((state: IRootState) => state.tvScreenReducer.activeStatus)
    const [sortedVendors, setsortedVendors] = useState<vendorPublicProfile[]>([]);
    const [newActiveStatus, setNewActiveStatus] = useState<ActiveStates | null>(null);

    const [activeStep, setActiveStep] = useState<number>(0);
    const [steps, setSteps] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [openSalon, setOpenSalon] = useState<vendorPublicProfile | null>(null);
    const [originTransition, setOriginTransition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const rowsPerPage: number = 12;
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [fetchInterval, setFetchInterval] = useState<ReturnType<typeof setInterval> | null>(null);

    let timeout: ReturnType<typeof setTimeout>;

    const setTimer = async () => {
        await clearTimeout(timeout);
        timeout = setTimeout(() => {
            handleClose()
            setCategoryFilter([])
            filterByCategory([])
        }, 300000);
    }

    const setFetchTimer = async function () {
        if (fetchInterval) {
            console.log("exist", fetchInterval)
            await clearInterval(fetchInterval);
        }
        // console.log("exist1", fetchInterval)

        setFetchInterval(setInterval(() => {
            tvScreenApi.getAllCategories()
            tvScreenApi.getAllVendors()
        }, 1800000))

    }

    document.addEventListener("click", () => {
        setTimer()
        // setFetchTimer()
    })
    document.addEventListener("keyup", () => {
        setTimer()
        // setFetchTimer()
    })

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleClickOpen = (salon: vendorPublicProfile) => {
        setOpenSalon(salon)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCategoryFilter = (i: string) => {
        let temp: string[] = categoryFilter
        if (categoryFilter.includes(i)) {
            temp = temp.filter(item => item !== i)
        }
        else {
            temp.push(i)
        }
        setCategoryFilter(temp)
        filterByCategory(temp)
    };

    const getUserCategories = (ids: string[]): string[] => {
        let colors: string[] = []
        categories.map((item) => {
            if (ids.includes(item.id.toString())) {
                colors.push(item.color)
            }
        })
        return colors
    }

    const sortVendors = (): vendorPublicProfile[] => {
        let temp: vendorPublicProfile[] = publicVendors
        if (activeStatus.length > 0) {
            let activeVendorIds: number[] = []
            activeStatus.map((item) => {
                if (item.status === "ONLINE") {
                    activeVendorIds.push(item.user_id)
                }
            })
            let activeVendors: vendorPublicProfile[] = publicVendors.filter((vendor) => activeVendorIds.includes(vendor.id))
            let deActiveVendors: vendorPublicProfile[] = publicVendors.filter((vendor) => !activeVendorIds.includes(vendor.id))
            temp = [...activeVendors, ...deActiveVendors]
        }
        return temp
    }

    const filterByCategory = (catFilter: string[]) => {
        let temp: vendorPublicProfile[] = sortVendors()
        if (catFilter.length > 0) {
            temp = temp.filter(vendor => vendor.cateID.length > 0 && vendor.cateID.filter(cat => catFilter.includes(cat)).length > 0)
        }
        setsortedVendors(temp)
        setActiveStep(0)
        setSteps(Math.ceil(temp.length / rowsPerPage))
    };

    useEffect(() => {
        if (activeStatus.length > 0) {
            let sorted: vendorPublicProfile[] = sortVendors()
            setsortedVendors(sorted)
        }
    }, [activeStatus])

    useEffect(() => {
        if (newActiveStatus) {
            let tempActive: ActiveStates[] = activeStatus.map(obj => [newActiveStatus].find(o => o.user_id === obj.user_id) || obj)
            store.dispatch({
                type: TvScreenActions.UPDATE_ACTIVE,
                payload: {
                    activeStatus: tempActive
                }
            });
        }
    }, [newActiveStatus])

    useEffect(() => {
        tvScreenApi.getAllCategories()
        tvScreenApi.getAllVendors()
        setFetchTimer()

        const socket = socketIOClient(host, {
            withCredentials: true,
            extraHeaders: {
                "my-custom-header": "abcd"
            }
        });

        socket.on("vendor_status", data => {
            if (data) {
                setNewActiveStatus(data)
            }
        });
    }, [])

    useEffect(() => {
        let tempActive: ActiveStates[] = []
        publicVendors.map((item) => {
            tempActive.push({
                user_id: item.id,
                suite_id: item.suite_id,
                status: item.availability
            })
        })

        store.dispatch({
            type: TvScreenActions.UPDATE_ACTIVE,
            payload: {
                activeStatus: tempActive
            }
        });
        setSteps(Math.ceil(publicVendors.length / rowsPerPage))
    }, [publicVendors])

    const updateMousePosition = (ev: React.MouseEvent<HTMLDivElement>) => {
        setOriginTransition({ x: ev.clientX, y: ev.clientY })
    };


    const displayDialog = (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} TransitionProps={{ style: { transformOrigin: `${originTransition.x}px ${originTransition.y}px` } }}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.dialogTitle}>
                        {
                            openSalon && openSalon.salon_name
                        }
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                style={{
                    padding: 20
                }}
            >
                <Grid
                    item
                    xs={6}
                    style={{
                        height: `100vh`
                    }}
                >
                    <iframe src={openSalon && openSalon.site_url ? openSalon.site_url : 'https://mintsuites.com/'} width="100%" height='100%' frameBorder="0"></iframe>
                </Grid>

                <Grid
                    item
                    xs={6}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <img
                                src={openSalon ? openSalon.suite_image_url : ''}
                                alt='suite location'
                                height='500px'
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={4}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <div className={classes.services}>
                                    Scan The QR Code to make an appointment
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <QRCode
                                    bgColor="#000"
                                    fgColor="#000000"
                                    level="Q"
                                    style={{ width: 256 }}
                                    value={openSalon && openSalon.site_url ? openSalon.site_url : 'https://mintsuites.com/'}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )

    return (
        <div className={classes.container}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                style={{ marginBottom: 10 }}
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <img src={logo} width='180px' />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <div className={classes.title}>
                        welcome message
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid
                    item
                    xs={12}
                >
                    <Divider variant="fullWidth" />
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                    style={{ minHeight: '55vh', margin: "30px 0" }}
                >
                    {

                        sortedVendors.slice(activeStep * rowsPerPage, activeStep * rowsPerPage + rowsPerPage).map((item: vendorPublicProfile, i) => {
                            return (
                                <Grid
                                    item
                                    xs={12} md={4} lg={2}
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >
                                    <div onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
                                        updateMousePosition(event)
                                    }}>
                                        <ItemCard
                                            active={activeStatus.filter(vendor => vendor.user_id === item.id).map(obj => obj.status)[0] === "ONLINE"}
                                            name={item.salon_name}
                                            onClick={() => {
                                                handleClickOpen(item)
                                            }}
                                            url={item.profile_image_url ? item.profile_image_url : "https://cdn5.vectorstock.com/i/1000x1000/17/34/beauty-logo-vector-6691734.jpg"}
                                            suite_id={item.suite_id}
                                            suite_name={item.suite_name}
                                            categories={getUserCategories(item.cateID)}
                                        />
                                    </div>

                                </Grid>
                            )
                        })
                    }
                </Grid>

                <Grid
                    item
                    xs={12}
                >
                    <MobileStepper
                        variant="dots"
                        steps={steps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="large" onClick={handleNext} disabled={activeStep === steps - 1}>
                                Next
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Back
                            </Button>
                        }
                    />
                    <Divider variant="fullWidth" />
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                style={{ marginTop: 10 }}
            >
                {

                    categories.map((item: Category, i) => {
                        return (
                            <Grid
                                item
                                xs={6} md={2} lg={2}
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Button
                                    startIcon={<Circle size={10} color={item.color} />}
                                    onClick={() => {
                                        handleCategoryFilter(item.id.toString())
                                    }}
                                    fullWidth
                                    color={categoryFilter.includes(item.id.toString()) ? 'primary' : 'default'}
                                    variant={categoryFilter.includes(item.id.toString()) ? 'contained' : 'text'}
                                >
                                    {item.name}
                                </Button>
                                {/* <Grid item xs={1}>
                                    <Circle size={10} color={item.color} />
                                </Grid>
                                <Grid item xs={10}>
                                    <div className={classes.serviceName}>
                                        {item.name}
                                    </div>
                                </Grid> */}
                            </Grid>
                        )
                    })
                }
            </Grid>
            {displayDialog}
        </div>
    )
}
