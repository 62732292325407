import classNames from 'classnames'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import authApi from '../../../../core/api/auth-api'
import MyNavigator from '../../../../route_names/navigation'
import Button from '../../../../shared/Button/Button'
import TextField from '../../../../shared/TextField/TextField'

import style from './ResetForm.module.scss'
interface Iprops {
    token: string
}

interface Istate {
    password: string,
    cPassword: string,
}

interface Ierror {
    passwordErrorText: string,
    cPasswordErrorText: string,
}

function ResetForm(props: Iprops) {
    const Navigator = MyNavigator()
    const [state, setState] = useState<Istate>({
        password: "",
        cPassword: '',
    })
    const [error, setError] = useState<Ierror>({
        passwordErrorText: '',
        cPasswordErrorText: '',
    })


    const handleChange = (event: any) => {
        event.persist();
        const { name, value } = event.target

        setState(prevState => ({
            ...prevState,
            [name]: value
        }))

        handleError(name, value)

    }


    const handleUpdate = async (event: any) => {
        event.preventDefault();

        if (state.password || state.cPassword) {
            handleError('password', state.password)
            handleError('cPassword', state.cPassword)
        }

        let { passwordErrorText, cPasswordErrorText } = error

        if (state.password && !passwordErrorText && !cPasswordErrorText) {
            try {
                await authApi.resetForgotPassword(props.token, state.password);
                Navigator.pushNamed('/login')
            } catch (err: any) {
                setError({
                    ...error,
                    passwordErrorText: err.message,
                    cPasswordErrorText: err.message
                })
            }
        }
    }

    const handleError = (name: string = "submit", value: string = '') => {
        if (name !== "submit") {
            const ErrName: string = name + "ErrorText"
            let ErrVariable: string = ''

            if (!value) {
                ErrVariable = 'This field cannot be empty'
            }

            if (name === 'cPassword') {
                if (state.password !== value) {
                    ErrVariable = "Password did not match.";
                }
            }

            setError(prevErr => ({
                ...prevErr,
                [ErrName]: ErrVariable
            }))
        }
        else {
            const errors: Ierror = { ...error }
            Object.keys(state).forEach(key => {
                if (`${Reflect.get(state, key)}` === '') {
                    const ErrName: string = key + "ErrorText"
                    Reflect.set(errors, `${ErrName}`, 'This field cannot be empty')
                }
            });
            setError(errors)
        }


    }
    return (
        <>
            <form className={style.container} onSubmit={handleUpdate}>
                {/* <div className={style.title}>
                    Update your password :
                </div> */}
                <div className={classNames('split-form')}>
                    <div className={classNames('row')}>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="password"
                                    onChange={handleChange}
                                    error={!!error.passwordErrorText}
                                    helperText={error.passwordErrorText}
                                    value={state.password}
                                    className='full-width'
                                    autoComplete="off"
                                    fullWidth
                                    label="Password"
                                    variant="outlined"
                                    type='password' />
                            </div>
                        </div>
                        <div className={classNames('form-item')}>
                            <div className={classNames('formControl')}>
                                <TextField
                                    name="cPassword"
                                    onChange={handleChange}
                                    error={!!error.cPasswordErrorText}
                                    helperText={error.cPasswordErrorText}
                                    value={state.cPassword}
                                    className='full-width'
                                    fullWidth
                                    autoComplete="off"
                                    label="Confirm Password"
                                    variant="outlined"
                                    type='password' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.actions}>
                    <Button
                        onClick={handleUpdate}
                        variant="contained"
                        color="primary"
                        disabled={!!error.passwordErrorText || !!error.cPasswordErrorText || !state.password}
                        type="submit">
                        Update Password
                    </Button>
                </div>
            </form>
        </>
    )
}

export default ResetForm
