import React, { useEffect, useState } from 'react';
import useStyles from './GiftCardViewStyles';
import {
    AppBar,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    Hidden,
    Paper,
    Radio,
    RadioGroup,
    Typography,
    Slide,
    IconButton,
    Toolbar,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import store, { IRootState } from '../../../core/store';
import { useSelector } from 'react-redux';
import Button from '../../../shared/Button/Button';
import TextField from '../../../shared/TextField/TextField';
import WarningButton from '../../../shared/Button/WarningButton';
import SuccessButton from '../../../shared/Button/SuccessButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Service } from '../../../models/service';
import Alert from '@material-ui/lab/Alert';
import giftCardApi from '../../../core/api/giftCard-api';
import { Employee } from '../../../models/employee';
import { Customer } from '../../../models/customer';
import { GiftCardActions } from '../../../core/store/actions';
import bookingApi from '../../../core/api/booking-api';
import time from '../../../core/services/time';
import { Booking, BookingAll } from '../../../models/booking';
import CustomTable from '../../../shared/Table/CustomTable';
import { VendorService } from '../../../models/vendor';
import CustomSelectAutoComplete from '../../../shared/SelectAutoComplete/SelectAutoComplete';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import QRscanner from '../../../shared/QRscanner/QRscanner';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GiftCardView() {
    const classes = useStyles();
    const { validMessage, validState } = useSelector((state: IRootState) => state.giftCardReducer);
    const AllServices: Service[] = useSelector((state: IRootState) => state.filterReducer.services);
    const VendorServices: VendorService[] = useSelector((state: IRootState) => state.vendorReducer.vendorServices);

    const AllEmployees: Employee[] = useSelector((state: IRootState) => state.filterReducer.employees);
    const AllCustomers: Customer[] = useSelector((state: IRootState) => state.filterReducer.customers);
    const [redeemType, setredeemType] = useState<string>('booking');
    const [isValidateClicked, setIsValidateClicked] = useState<boolean>(false);

    const [scanOpen, setScanOpen] = useState<boolean>(false);

    const [giftCardId, setGiftCardId] = useState<string>('');
    const [value, setValue] = useState<number>(0);
    const [bookingId, setBookingId] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [serviceType, setServiceType] = useState<string>('');
    const [serviceFilter, setServiceFilter] = useState<any[]>([]);
    const [empFilter, setempFilter] = useState<string[]>([]);
    const [cusFilter, setcusFilter] = useState<string[]>([]);
    const [bookings, setBookings] = useState<BookingAll[]>([]);

    const [selectedVendorService, setSelectedVendorService] = useState<Record<string, any>>({});

    const [selectedServiceServiceTypes, setSelectedServiceServiceTypes] = useState<Record<string, any>[]>([]);
    const [selectedSubServiceType, setSelectedSubServiceType] = useState<Record<string, any>>({});

    const fetchBookingData = async () => {
        let start: number = time.DateToUnix(time.getYesterday());
        let end: number = time.DateToUnix(time.getTomorrow());
        const res = await bookingApi.getAllWithOutPagination(start, end);
        console.log(`res ==>`, res)
        setBookings(res.filter(x => x.status !== ''  || x.customer.id !== 1));
    };

    const setFilter = () => {
        let serviceFilterData: any[] = [];
        let empFilterData: string[] = [];
        let cusFilterData: string[] = [];
        let vendorServiceIds: number[] = [];

        const vendorSpecificServices = VendorServices.reduce((acc, vs) => {
            const existingService = acc.find((es) => es.id === vs.service_id);

            if (!existingService) {
                acc.push({
                    id: vs.service_id,
                    type: vs.service_type,
                });
            }

            return acc;
        }, [] as Record<string, any>[]);

        VendorServices.map((item) => {
            vendorServiceIds.push(item.service_id);
        });

        AllServices.map((item) => {
            if (vendorServiceIds.includes(item.id)) {
                serviceFilterData.push(item.type);
            }
        });

        var uniqueServices: any = [];
        const newTypes = serviceFilterData.filter((serviceType: any) => serviceType.disable === 1);
        newTypes.filter(function (item: any) {
            var i = uniqueServices.findIndex((x: any) => x.id === item.service_id);
            if (i <= -1) {
                uniqueServices.push({ id: item.service_id, type: item.service_type });
            }
            return null;
        });

        AllEmployees.map((item) => {
            empFilterData.push(item.full_name);
        });

        AllCustomers.map((item) => {
            cusFilterData.push(item.name);
        });

        empFilterData = [...new Set(empFilterData)];
        cusFilterData = [...new Set(cusFilterData)];
        serviceFilterData = [...new Set(uniqueServices)];
        serviceFilterData.sort();
        empFilterData.sort();
        cusFilterData.sort();

        setServiceFilter(vendorSpecificServices);
        setempFilter(empFilterData);
        setcusFilter(cusFilterData);
    };

    const handleServiceSelection = (val: Record<string, any>) => {
        setSelectedVendorService(val);

        const subServiceTypes = VendorServices.filter(
            (serviceType) => serviceType.service_id === val.id && serviceType.disable === 1,
        );

        console.log(`subServiceTypes ==>`, subServiceTypes);

        setSelectedServiceServiceTypes(subServiceTypes);
    };

    const handleOnClick = (item: Booking) => {
        setBookingId(item.id.toString());
    };

    const handleBookingIdChange = (event: any) => {
        event.persist();
        const { value } = event.target;
        setBookingId(value);
    };

    const handleGiftCardChange = (event: any) => {
        event.persist();
        const { value } = event.target;
        setGiftCardId(value);
    };

    const handleUserNameChange = (event: any) => {
        event.persist();
        const { value } = event.target;
        setUserName(value);
    };

    const handleValueChange = (event: any) => {
        event.persist();
        const { value } = event.target;
        setValue(value);
    };

    const handleRedeemTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setredeemType((event.target as HTMLInputElement).value);
    };

    const handleClose = () => {
        setIsValidateClicked(false);
        setGiftCardId('');
        setValue(0);
        setBookingId('');
        setUserName('');
        setServiceType('');

        store.dispatch({
            type: GiftCardActions.UPDATE_VALID_MESSAGE,
            payload: {
                validMessage: 'clear',
                validState: null,
            },
        });
    };

    const handleValidate = async () => {
        setIsValidateClicked(false);
        await giftCardApi.validate(giftCardId, value);
        setIsValidateClicked(true);
    };

    const handleRedeem = async () => {
        if (redeemType === 'booking') {
            await giftCardApi.redeemGiftCardBooking({
                bookingId: bookingId,
                giftcardId: giftCardId,
                redeemValue: value,
            });
        } else {
            // let serviceId: number = 0;

            // AllServices.map((item) => {
            //     if (item.type === serviceType) {
            //         serviceId = item.id;
            //     }
            // });
            const { id, service_id, ...rest } = selectedSubServiceType;
            await giftCardApi.redeemGiftCardWalkIn({
                redeemer: userName,
                service_id,
                sub_service_id: id,
                redeemValue: value,
                GiftCardId: giftCardId,
                ...rest
            });
        }
        handleClose();
    };

    const handleScanClose = () => {
        setGiftCardId('');
        setScanOpen(false);
    };

    const scanDialog = (
        <Dialog fullScreen open={scanOpen} onClose={handleScanClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleScanClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.dialogTitle}>
                        Scan Gift Card
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8} lg={4}>
                    <div style={{ padding: 20 }}>
                        <QRscanner updateFunction={setGiftCardId} />
                    </div>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                <Grid item>
                    <WarningButton color="primary" onClick={handleScanClose}>
                        Cancel
                    </WarningButton>
                </Grid>
            </Grid>
        </Dialog>
    );

    useEffect(() => {
        setScanOpen(false);
    }, [giftCardId]);

    useEffect(() => {
        setFilter();
    }, [AllServices, VendorServices]);

    useEffect(() => {
        fetchBookingData();
        setFilter();
    }, []);
    return (
        <div className={classes.container}>
            <Grid direction="row" container alignItems="flex-start" justifyContent="center">
                <Grid item sm={12} lg={4}>
                    <Paper className={classes.paper}>
                        <Grid
                            spacing={2}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Grid item xs={6}>
                                <TextField
                                    onChange={handleGiftCardChange}
                                    value={giftCardId}
                                    name="giftCardId"
                                    className="full-width"
                                    autoComplete="off"
                                    fullWidth
                                    label="Gift Card Id"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    onChange={handleValueChange}
                                    value={value}
                                    name="value"
                                    type="number"
                                    className="full-width"
                                    autoComplete="off"
                                    fullWidth
                                    label="Redeem Value"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid
                                spacing={2}
                                xs={12}
                                item
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{
                                    marginBottom: 10,
                                    marginTop: 10,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddAPhotoIcon />}
                                    onClick={() => {
                                        setScanOpen(true);
                                    }}
                                >
                                    Scan Gift Voucher
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            spacing={2}
                            container
                            direction="column"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            style={{ paddingTop: 10 }}
                        >
                            <Grid item>
                                <WarningButton onClick={handleClose} color="primary">
                                    Cancel
                                </WarningButton>
                            </Grid>
                            <Grid item>
                                <SuccessButton
                                    onClick={handleValidate}
                                    color="primary"
                                    autoFocus
                                    disabled={!giftCardId || !value}
                                >
                                    Validate
                                </SuccessButton>
                            </Grid>
                        </Grid>
                        {isValidateClicked && (
                            <div
                                style={{
                                    marginTop: '20px',
                                }}
                            >
                                {typeof validState === 'number' && isFinite(validState) && (
                                    <Grid
                                        style={{
                                            marginBottom: '20px',
                                        }}
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid item xs={12} container alignItems="flex-start" justifyContent="center">
                                            <Alert severity={validState === 1 ? 'info' : 'error'}>{validMessage}</Alert>
                                        </Grid>
                                    </Grid>
                                )}
                                {validState && validState === 1 && (
                                    <Grid
                                        style={{
                                            marginBottom: '20px',
                                        }}
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid item xs={12} className={classes.selectTitle}>
                                            Select the booking type
                                        </Grid>
                                        <Grid item xs={12} container alignItems="flex-start" justifyContent="center">
                                            <FormControl component="fieldset">
                                                <RadioGroup row value={redeemType} onChange={handleRedeemTypeChange}>
                                                    <FormControlLabel
                                                        value="booking"
                                                        control={<Radio classes={{ root: classes.root }} />}
                                                        label="Existing Booking"
                                                    />
                                                    <FormControlLabel
                                                        value="walkIn"
                                                        control={<Radio classes={{ root: classes.root }} />}
                                                        label="Walk In"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        {redeemType === 'walkIn' ? (
                                            <Grid
                                                item
                                                xs={12}
                                                spacing={2}
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="flex-start"
                                            >
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={handleUserNameChange}
                                                        value={userName}
                                                        name="userName"
                                                        className="full-width"
                                                        autoComplete="off"
                                                        fullWidth
                                                        label="User Name"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        size="small"
                                                        options={serviceFilter}
                                                        value={selectedVendorService}
                                                        onChange={(e, val) => {
                                                            handleServiceSelection(val);
                                                            // console.log(b);
                                                        }}
                                                        autoSelect
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.type}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Select a Service"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        size="small"
                                                        options={selectedServiceServiceTypes}
                                                        value={selectedSubServiceType}
                                                        onChange={(e, val) => {
                                                            setSelectedSubServiceType(val);
                                                            // console.log(b);
                                                        }}
                                                        autoSelect
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.sub_type}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label="Select a Service Type"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="flex-start"
                                            >
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={handleBookingIdChange}
                                                        value={bookingId}
                                                        name="bookingId"
                                                        className="full-width"
                                                        autoComplete="off"
                                                        fullWidth
                                                        label="Booking Id"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomTable
                                                        data={bookings}
                                                        isloading={false}
                                                        ignore={[
                                                            'user_id',
                                                            'booking_duration',
                                                            'booking_value',
                                                            'status',
                                                            'gift_card_id',
                                                            'gift_card_value',
                                                            'redeemer',
                                                            'redeem_type',
                                                            'user',
                                                            'service',
                                                            'customer',
                                                            'service_id',
                                                            'customer_id',
                                                            'id',
                                                        ]}
                                                        onClick={handleOnClick}
                                                        height="20vh"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {validState && validState === 1 && (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{ paddingTop: 10 }}
                                    >
                                        <Grid item>
                                            <SuccessButton
                                                onClick={handleRedeem}
                                                color="primary"
                                                autoFocus
                                                disabled={redeemType === 'walkIn' ? !userName : !bookingId}
                                            >
                                                Redeem
                                            </SuccessButton>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        )}
                    </Paper>
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={8}>
                        <div
                            style={{
                                backgroundImage: `url(${'img/gift_cover.jpg'})`,
                                backgroundSize: 'cover',
                                width: '100%',
                                height: `calc(100vh - 225px)`,
                            }}
                        ></div>
                    </Grid>
                </Hidden>
            </Grid>
            {scanDialog}
        </div>
    );
}
