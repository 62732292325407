import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        switchBase: {
            color: theme.palette.error.main,
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.error.main,
                '& + $track': {
                    backgroundColor: theme.palette.error.main,
                    borderColor: theme.palette.error.main,
                },
            },

        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.error.light,
        },
        colorPrimary: {
            "&.Mui-checked": {
                color: "#4CAF50",
            },
            "&.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "#4CAF50",
            },
        },
    }),
);

export default useStyles;