import React, { useEffect, useState } from 'react'
import { ReactComponent as DeleteIcon } from '../../img/icons/trash.svg'
import { ReactComponent as EditIcon } from '../../img/icons/edit.svg'
import TableStyles from './CustomTableStyles'
import TablePaginationActions from './components/Paginator/TablePaginationActions'
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    TablePagination,
    Grid,
    Typography,
    TableSortLabel,
    Switch,
    Badge,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import Button from '../Button/Button'
import ExportDialog from './components/ExportDialog/ExportDialog'
import { SortObject } from '../../models/sort'
import time from '../../core/services/time'
import MyLoader from '../Loader/my_loader'
import { SupportChatStatus } from '../../models/support'
import { useSelector } from 'react-redux'
import { IRootState } from '../../core/store'

interface IToggle {
    toggleFunction: Function;
    toggleColumn: string;
}

interface IOverride {
    from: string;
    to: string;
}

interface Props {
    data: Object[];
    createFunction?: Function;
    deleteFunction?: Function;
    editFunction?: Function;
    editFunctionForPassword?: Function;
    exportFunction?: Function;
    toggle?: IToggle;
    paginationFunction?: Function;
    sortFunction?: Function;
    onClick?: Function;
    sort?: SortObject;
    count?: number;
    title?: string;
    ignore?: string[];
    numberColumns?: string[];
    order?: string[];
    overrides?: IOverride[];
    height?: string;
    frozenColumn?: string,
    isloading: boolean;
}


const widthControl: string[] = ['message', 'vendor_name', 'image_url']
const colNamesWithUnixTime: string[] = ['last_login_at', 'created_at', "ticket_raised_datetime", "exp_date", "purchase_date", "last_redeemed_date", "review_date", "booked_date_time", "week_date"]
function CustomTable(props: Props) {
    const TableStyle = TableStyles()
    const [tableData, setTableData] = useState<Object[]>([])
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [exportOpen, setExportOpen] = useState<boolean>(false);
    const [ignore, setignore] = useState<string[]>([]);
    const [tableHeader, setTableHeader] = useState<string[]>([]);
    const chatStatus: SupportChatStatus[] = useSelector((state: IRootState) => state.supportReducer.chatStatus)
    const supportTicketStatusData: any = useSelector((state: IRootState) => state.supportReducer.statusTicket)

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleValue = (value: any, key: string, row: object) => {

        let pattern = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg|webp))/

        if (pattern.test(value)) {
            return (<img src={value} alt="" width="60px" />)
        }
        else if (key === 'mobile_no' && value) {
            value = value.toString()
            return "+1 " + value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        else if (key === 'discount' && value) {
            return `${value}%`;
        }
        else if (key.toLocaleLowerCase().search("duration") > -1 && value) {
            return `${Math.floor(value / 60)}h ${(value % 60).toFixed(0)}m`;
        }
        else if (key.toLocaleLowerCase().search("price") > -1 && value) {
            return `$${value.toFixed(2)}`;
        }
        else if (colNamesWithUnixTime.includes(key)) {
            return time.unixToDateTime(value)
        }
        else if (props.numberColumns && props.numberColumns.length > 0 && props.numberColumns.includes(key) && value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else if (props?.title === 'Support Ticket' && key === "name") {
            let uniqueName = Reflect.get(row, "chat_room")
            if (uniqueName) {
                let isInvisible = true

                let ticket = supportTicketStatusData.find((f:any) => f['uniqueId'] == uniqueName)
                if (ticket){
                    if (ticket.status == "NEW" && ticket.role == 1 && ticket['is_seen'] == 0){
                        isInvisible = false
                    }
        
                    if (ticket.status == "ACCEPTED" && ticket.role == 3 && ticket['is_seen'] == 0){
                        isInvisible = false
                    }
        
                    if (ticket.status == "CANCELLED" && ticket.role == 1 && ticket['is_seen'] == 0){
                        isInvisible = false
                    }
        
                    if (ticket.status == "RESOLVED" && ticket.role == 3 && ticket['is_seen'] == 0){
                        isInvisible = false
                    }
                }

                return (<>{handleEmpty(value)}<Badge
                    color="error"
                    variant="dot"
                    invisible={isInvisible}
                    // badgeContent={count}
                    // max={999}
                    style={{ marginLeft: 10, height: 15 }}
                // classes={{ badge: TableStyle.badgeRoot }}
                /></>)
            }
            else {
                return handleEmpty(value)
            }
        }
        else {
            return handleEmpty(value)
        }
    }

    const handleEmpty = (value: any) => {
        let output: any
        if (value === '' || value === null) {
            output = "-"
        }
        else if (typeof value === 'boolean') {
            if (value) {
                output = 'True'
            }
            else {
                output = 'False'
            }
        }
        else {
            output = value
        }
        return output

    }

    const formatKey = (value: string) => {
        
        value = value.replace("vendor", 'professional')
        if (!value.includes("valid")) {
            if(props.title === "Support Ticket"){
                value = value.replace("id", 'Ticket Number')
            }
            value = value.replace("id", 'ID')

        }

        let output: string
        if (!!value) {
            output = value.split("_").join(" ")

            if (value === "ticket_raised_datetime") {
                output = "Raised on"
            }

            if (props.overrides) {
                props.overrides.map((item) => {
                    if (value === item.from) {
                        output = item.to
                    }
                })
            }

        }
        else {
            output = "unknown"
        }

        return output

    }

    const toggleChecked = (i: any) => {
        if (props.toggle) {
            props.toggle.toggleFunction(i)
        }
    };

    const handelDelete = (i: any) => {
        if (!!props.deleteFunction) {
            props.deleteFunction(i)
        }
    }

    const handelEdit = (i: any) => {
        if (!!props.editFunction) {
            props.editFunction(i)
        }
    }
    const handelEditForSpecific = (i: any) => {
        if (!!props.editFunctionForPassword) {
            props.editFunctionForPassword(i)
        }
    }

    const handelExport = () => {
        if (!!props.exportFunction) {
            props.exportFunction()
        }
    }

    const handelOnClick = (i: any) => {
        if (!!props.onClick) {
            props.onClick(i)
        }
    }

    const handlePagination = () => {
        if (props.paginationFunction) {
            if (rowsPerPage === -1) {
                props.paginationFunction(page + 1, !!props.count ? props.count : props.data.length)
            } else {
                props.paginationFunction(page + 1, rowsPerPage)
            }

        }
    };

    const setCreate = () => {
        if (!!props.createFunction) {
            props.createFunction()
        }
    }

    const handleRequestSort = (property: string) => {
        if (props.sortFunction && props.sort) {
            const isAsc = props.sort.orderBy === property && props.sort.order === 'asc';
            props.sortFunction({ orderBy: property, order: isAsc ? 'desc' : 'asc' })
        }
    };

    useEffect(() => {
        handlePagination()
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (props.ignore) {
            setignore(props.ignore)
        }
    }, [props.ignore]);

    useEffect(() => {
        let tempTableHeaders: string[] = []
        if (
            props.data &&
            props.data[0] &&
            Object.keys(props.data[0]).length > 0
        ) {
            Object.keys(props.data[0]).map((key: string, i) => { tempTableHeaders.push(key) })
            if (props.order && props.order.length > 0) {
                props.order.map((item: string) => {
                    if (tempTableHeaders.includes(item)) {
                        let index = tempTableHeaders.indexOf(item);
                        if (index !== -1) {
                            tempTableHeaders.splice(index, 1);
                        }
                    }
                })
                tempTableHeaders = props.order.concat(tempTableHeaders)
                setTableHeader(tempTableHeaders)
            } else {
                setTableHeader(tempTableHeaders)
            }

        }
        setTableData(props.data)
    }, [props.data, props.order]);

    return (
        <div>
            {
                props.isloading
                    ?
                    <MyLoader height={props.height ? props.height : "65vh"} />
                    :
                    <>
                        <Grid container direction="row"
                            alignItems="center"
                            className={TableStyle.header}
                        >
                            <Grid
                                item
                                spacing={2}
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                {
                                    !!props.exportFunction &&
                                    (
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                disabled={tableData && tableData.length === 0}
                                                onClick={
                                                    () => {
                                                        setExportOpen(true)
                                                    }
                                                }
                                            >
                                                Export To Excel
                                            </Button>
                                        </Grid>
                                    )
                                }

                                {
                                    !!props.createFunction &&
                                    (
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={setCreate}
                                            >
                                                Create New
                                            </Button>
                                        </Grid>
                                    )
                                }

                            </Grid>

                        </Grid>
                        {
                            tableData && tableData.length > 0 ?
                                <Paper>
                                    <TableContainer style={{ height: `${props.height ? props.height : "65vh"}` }}>
                                        <Table stickyHeader size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {(() => {
                                                        if (
                                                            tableData &&
                                                            tableData[0] &&
                                                            tableHeader.length > 0
                                                        ) {
                                                            return tableHeader.map((key: string, i: number) => {
                                                                if (!ignore.includes(key)) {
                                                                    if (props.sortFunction && props.sort) {
                                                                        return (
                                                                            <TableCell key={"head" + key} className={TableStyle.tableHead}>

                                                                                <TableSortLabel
                                                                                    active={key === props.sort.orderBy}
                                                                                    direction={key === props.sort.orderBy ? props.sort.order : 'asc'}
                                                                                    onClick={() => { handleRequestSort(key) }}
                                                                                >
                                                                                    {formatKey(key)}
                                                                                    {key === props.sort.orderBy ? (
                                                                                        <span className={TableStyle.visuallyHidden}>
                                                                                            {props.sort.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                                        </span>
                                                                                    ) : null}
                                                                                </TableSortLabel>
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <TableCell key={"head" + key} className={TableStyle.tableHead}>
                                                                                {formatKey(key)}
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                }



                                                            })
                                                        }
                                                    })()}
                                                    {!!props.toggle && <TableCell key={"toggle-head"} className={classNames(TableStyle.actionCell, TableStyle.tableHead, !!props.frozenColumn && props.frozenColumn === 'toggle' && TableStyle.fixColumn)}>Active/Inactive</TableCell>}
                                                    {!!props.editFunction && <TableCell key={"edit-head"} className={classNames(TableStyle.actionCell, TableStyle.tableHead)}> Edit</TableCell>}
                                                    {!!props.editFunctionForPassword && <TableCell key={"edit-password"} className={classNames(TableStyle.actionCell, TableStyle.tableHead)}>Edit Password</TableCell>}
                                                    {!!props.deleteFunction && <TableCell key={"delete-head"} className={classNames(TableStyle.actionCell, TableStyle.tableHead)}></TableCell>}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    tableData && tableData.length !== 0 && !!tableData[0] &&
                                                    (tableData).map((item, i) => {
                                                        return (
                                                            <TableRow key={"row" + i} hover onClick={() => { handelOnClick(item) }}>
                                                                {tableHeader.map((key, j) => {
                                                                    let value = Reflect.get(item, key)
                                                                    if (!ignore.includes(key)) {
                                                                        return (
                                                                            <TableCell
                                                                                key={"head" + i.toString() + j.toString()}
                                                                                align={props.numberColumns && props.numberColumns.length > 0 && props.numberColumns.includes(key) ? "right" : 'left'}
                                                                                className={classNames(TableStyle.tableData, widthControl.includes(key) && TableStyle.maxDataWidth, props.numberColumns && props.numberColumns.length > 0 && props.numberColumns.includes(key) && TableStyle.valueCell)}>
                                                                                {handleValue(value, key, item)}
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                })}
                                                                {!!props.toggle &&
                                                                    <TableCell key={"toggle"} className={classNames(TableStyle.actionCell, !!props.frozenColumn && props.frozenColumn === 'toggle' && TableStyle.fixColumn)}>

                                                                        <Switch color="primary" checked={Reflect.get(item, `${props.toggle.toggleColumn}`) === 1 ? true : false} onClick={() => {
                                                                            toggleChecked(item)
                                                                        }} />
                                                                    </TableCell>
                                                                }
                                                                {!!props.editFunction &&
                                                                    <TableCell key={"edit"} className={TableStyle.actionCell}>
                                                                        <IconButton size="small" onClick={() => {
                                                                            handelEdit(item)
                                                                        }}>
                                                                            <EditIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                }
                                                                 {!!props.editFunctionForPassword &&
                                                                    <TableCell key={"edit-password"} className={TableStyle.actionCell}>
                                                                        <IconButton size="small" onClick={() => {
                                                                            handelEditForSpecific(item)
                                                                        }}>
                                                                            <EditIcon fontSize="small" /> <EditIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                }
                                                                {!!props.deleteFunction &&
                                                                    <TableCell key={"delete"} className={TableStyle.actionCell}>
                                                                        <IconButton size="small" onClick={() => {
                                                                            handelDelete(item)
                                                                        }}>
                                                                            <DeleteIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                }
                                                            </TableRow>
                                                        )

                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {
                                        props.paginationFunction &&
                                        props.count &&

                                        <TablePagination
                                            classes={{ toolbar: TableStyle.pagination }}
                                            rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
                                            component="div"
                                            count={!!props.paginationFunction ? props.count : tableData ? tableData.length : 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    }

                                </Paper>
                                :
                                <div>
                                    <Alert style={{ alignItems: "center" }} severity="error">No Data Available!</Alert>
                                </div>
                        }
                    </>
            }

            <ExportDialog open={exportOpen} handleOpen={setExportOpen} exportFunc={handelExport} />
        </div>
    )
}

export default CustomTable

