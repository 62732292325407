import { createStyles, makeStyles, Theme } from "@material-ui/core";
import myColors from "../../../../styles/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: "bold",
            textTransform: "capitalize",
            minHeight: 30,
        },
        root: {
            color: "#D3D3D3",
            backgroundColor: "#fff",
            width: 30,
            height: 30,
            border: `2px solid ${theme.palette.background.paper}`,
            borderRadius: "50%"
        },
        avatarRoot: {
            width: "100px",
            height: "100px",
        }
    }),
);

export default useStyles;