import { createStyles, makeStyles, Theme } from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: "10px",
            textAlign: "center"
        },
        monthName: {
            fontSize: 15,
            fontWeight: 700,
            marginBottom: 20
        },
        weekContainer: {
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)"
        },
        weekName: {
            fontSize: 12,
            paddingBottom: 5,
            borderBottom: "solid",
            borderWidth: "thin !important",
            fontStyle: "italic"
        },
        dayCell: {
            padding: 10,
            fontSize: 12,
        },
        buttonRoot: {
            borderRadius: 0
        },
    }),
);

export default useStyles;